const statuses = [
    { id: 0, name: 'Acknowledged' },
    { id: 1, name: 'Unacknowledged' },
    { id: 2, name: 'Deleted' },
];

const alarmTypes = [
    { id: 0, name: 'Level' },
    { id: 1, name: 'Tampering' },
    { id: 2, name: 'Inactive' },
];

const levelAlarmStatus = Object.freeze([
    { id: 0, name: 'Inactive' },
    { id: 1, name: 'Active' },
]);

const levelAlarmOperator = {
    gt: 0,
    gte: 1,
    lt: 2,
    lte: 3,
    eq: 4,
};

export { statuses, alarmTypes, levelAlarmStatus, levelAlarmOperator };
