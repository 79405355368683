import { useEffect, useState } from 'react';
import { getAccessToken } from '../../auth/auth0Functions';

export default function useRaftData(siteId, fromDate, toDate) {
    const [isLoading, setIsLoading] = useState(siteId && fromDate && toDate ? true : false);
    const [error, setError] = useState(null);

    const [readings, setReadings] = useState(null);
    const [nextFrom, setNextFrom] = useState(null);

    useEffect(() => {
        if (!siteId || !fromDate || !toDate) return;

        getData();
        async function getData() {
            setIsLoading(true);
            const tsFrom = fromDate.getTime();
            const tsTo = toDate.getTime();
            const token = await getAccessToken();
            const attributes = ['temp', 'do', 'tur', 'cond', 'ph', 'bat'];
            const result = await fetchData(token, siteId, tsFrom, tsTo, attributes);
            if (result.error) {
                setError(result.error);
                setReadings(null);
                setNextFrom(null);
            } else {
                setError(null);
                setReadings(result.readings);
                setNextFrom(result.nextFromDate);
            }
            setIsLoading(false);
        }
    }, [siteId, fromDate, toDate]);

    // Add readings based on pagination
    useEffect(() => {
        if (nextFrom) {
            getData();
        }

        async function getData() {
            setIsLoading(true);
            const tsFrom = fromDate.getTime();
            const tsTo = toDate.getTime();
            const token = await getAccessToken();
            const attributes = ['temp', 'do', 'tur', 'cond', 'ph', 'bat'];
            const result = await fetchData(token, siteId, tsFrom, tsTo, attributes, nextFrom);
            if (result.error) {
                setError(result.error);
                setReadings(null);
                setNextFrom(null);
            } else {
                setError(null);
                setReadings((rds) => [...rds, ...result.readings]);
                setNextFrom(result.nextFromDate);
            }
            setIsLoading(false);
        }
    }, [siteId, nextFrom, fromDate, toDate]);

    return {
        data: readings,
        isLoading,
        error,
    };
}

async function fetchData(token, siteId, from, to, attributes, nextFrom) {
    let result;
    let paramsObject = { fromDate: from, toDate: to, attributes, useEmptyReadings: true };
    if (nextFrom) paramsObject.nextFromDate = nextFrom;
    const params = new URLSearchParams(paramsObject);
    const url = `${process.env.REACT_APP_API_BASE_URL}/analytics-raft/${siteId}?${params}`;
    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: token ?? '',
            },
        });
        if (response.status === 200) {
            const payload = await response.json();
            result = {
                readings: payload.readings,
                nextFromDate: payload.nextFromDate,
            };
        } else {
            result = {
                error: `HTTP error status: ${response.status}`,
            };
        }

        result.status = response.status;
    } catch (error) {
        result = {
            error: 'Fetch error',
        };
    }

    return result;
}
