import React from 'react';
import {
    useShowController,
    useRedirect,
    ShowContextProvider,
    TabbedShowLayout,
    Tab,
    usePermissions,
} from 'react-admin';
import { Box, Typography } from '@mui/material';
import Breadcrumb from '../menu/Breadcrumb';
import { makeStyles } from '@mui/styles';
import Layout from '../components/Layout';
import GROUPS from '../auth/groups';
import Loading from '../components/Loading';
import ROOT from '../theme/root';
import DeviceOverview from './overview';
import DeviceOnboarding from './onboarding';
import Error from '../components/Error';
import UnlinkDevice from './Unlink';
import DeviceParameters from './parameters';
import DownlinkCommands from '../downlink';
import DevicesData from '../devices-data';

const useStyles = makeStyles(() => ({
    siteTitle: {
        fontSize: '32px',
        fontWeight: 'bold',
        lineHeight: '43px',
    },
    tabs: {
        '& [class*="MuiTabs-indicator"]': {
            backgroundColor: ROOT.COLORS.PRIMARY,
            top: 'unset',
            bottom: '0px',
            borderLeft: 'none',
            borderRight: 'none',
        },
        '& [class*="MuiTab-textColorInherit"][class*="Mui-selected"]': {
            backgroundColor: 'transparent',
            color: ROOT.COLORS.PRIMARY,
            borderLeft: 'none',
            borderRight: 'none',
        },
        '& [class*="MuiTabs-flexContainer"]': {
            borderBottom: ROOT.BORDERS.BORDER,
        },

        backgroundColor: 'transparent',
    },
}));

/**
 * Fetches Device data and creates Tabs
 * Wraps children with Show context component
 */
const ShowDevice = () => {
    // Props
    const { permissions } = usePermissions();

    // Hooks
    const classes = useStyles();
    const controllerProps = useShowController();
    const { record: device, isLoading: recordLoading } = controllerProps;
    const redirect = useRedirect();

    // Permissions check
    if (!!permissions && !permissions.includes(GROUPS.HSW_ADMIN)) {
        redirect('/');
    }

    // Breadcrumbs overrides
    const overrides = {};
    if (device && device.id) overrides[device.id] = device.deviceId;

    // Loading & Error
    if (recordLoading || !permissions) return <Loading />;
    if (!recordLoading && !device) {
        return <Error message="Could not load device" />;
    }

    return (
        <ShowContextProvider value={controllerProps}>
            <Layout>
                <Breadcrumb overrides={overrides} />
                <Box display="flex" width="100%" alignItems="center" marginBottom="16px">
                    <Typography variant="h5" className={classes.siteTitle}>
                        Device {device.deviceId}
                    </Typography>
                    <UnlinkDevice permissions={permissions} />
                </Box>
                <TabbedShowLayout className={classes.tabs}>
                    <Tab label="Overview">
                        <DeviceOverview />
                    </Tab>
                    <Tab label="Onboarding History">
                        <DeviceOnboarding />
                    </Tab>
                    {device?.deviceApplication !== 4 && (
                        <Tab label="Device Parameters">
                            <DeviceParameters />
                        </Tab>
                    )}
                    {device?.deviceApplication !== 4 && (
                        <Tab label="Downlink">
                            <DownlinkCommands deviceId={device.deviceId} deviceUuid={device.id} />
                        </Tab>
                    )}
                    <Tab label="Data">
                        <DevicesData
                            deviceId={device.deviceId}
                            deviceUuid={device.id}
                            createdAt={device.createdAt}
                            deviceApplication={device.deviceApplication}
                        />
                    </Tab>
                </TabbedShowLayout>
            </Layout>
        </ShowContextProvider>
    );
};

export default ShowDevice;
