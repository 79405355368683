import React from 'react';
import { SaveButton, Toolbar } from 'react-admin';

const CreateToolBar = (props) => (
    <Toolbar>
        <SaveButton disabled={props?.saving} />
    </Toolbar>
);

export default CreateToolBar;
