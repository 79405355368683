import React, { useEffect, useRef, useState } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import funnel from 'highcharts/modules/funnel.js';
import { makeStyles } from '@mui/styles';
import { useSidebarState } from 'react-admin';
import { removeSVGFromChart } from '../utils';
import { levelAlarmOperator } from '../../alarms/alarm';

funnel(Highcharts);
const colors = ['#eeeeee', '#00AEEF'];

const SENSOR = ['M247.366 30H261.308L268.411 33.1817V37.2724H240V33.1817L247.366 30Z'];
const LID_TOP = ['M287.995 30.0115L219.334 30V34.6751L287.995 34.6866V30.0115Z'];
const TOP_LINE = ['M127 35L219.144 35'];
const BUTTOM_OF_LENGTH_ABOVE_GROUND = ['M173 70L219.078 70.2242'];
const BUTTOM_OF_TUBE_BOTTOM = ['M127.256 294.699L219.342 296.064'];
const TUBE_DIAMETER_LEFT = ['M219.341 27.416V13.6391'];
const TUBE_DIAMETER_RIGHT = ['M288 27.416L288 13.6391'];

const OUTSIDE_COVER = [
    'M229.513 32.3362H219.341L219.841 302.5H288.5L288 32.3362H277.828V297H229.513V32.3362Z',
];

const ARROW_FOR_TUBE_DIAMETER = [
    'M287.507 19.2596L282.507 16.3728V22.1463L287.507 19.2596ZM219.805 19.2596L224.805 22.1463V16.3728L219.805 19.2596ZM283.007 18.7596L224.305 18.7596V19.7596L283.007 19.7596V18.7596Z',
];
const ARROW_FOR_BENCHING = [
    'M160.291 35.5L157.405 40.5L163.178 40.5L160.291 35.5ZM160.291 295.5L163.178 290.5L157.405 290.5L160.291 295.5ZM159.791 40L159.791 291L160.791 291L160.791 40L159.791 40Z',
];

const ARROW_FOR_LENGTH_ABOVE_GROUND = [
    'M208.826 35.5L205.94 40.5L211.713 40.5L208.826 35.5ZM208.826 69.5L211.713 64.5L205.94 64.5L208.826 69.5ZM208.326 40L208.326 65L209.326 65L209.326 40L208.326 40Z',
];

const BACKGROUND_TOP = ['M434 3H47V70H434V3Z'];
const BACKGROUND_BGROUND = ['M434 319V71H47V319H434Z'];
const WATER_BACKGROUND = ['M277.427 32.9477 H229.857 V295.614 H277.427 V164.281 V32.9477 Z'];
const renderLevelThresholdsText = (chart, thresholds, thresholdsGroup) => {
    const textCSS = {
        fontFamily: 'Arial, Helvetica, sans-serif',
        fontWeight: 'bolder',
        fontSize: '90%',
    };

    const X_BASE = 300; // starting point of X axis to draw the text
    const Y_BASE = 37.9477; // starting point of Y axis to draw the text
    const Y_TOTAL = 290 - 32.9477;

    thresholds.forEach((t) => {
        const { percent: threshold, operator } = t;
        const type = operator === levelAlarmOperator.lt ? arrowDown : arrowUp;
        const y = Y_BASE + (Y_TOTAL * (100 - threshold)) / 100 + 4;
        chart.renderer.text(`${threshold}% ${type}`, X_BASE, y).css(textCSS).add(thresholdsGroup);
    });
};

const renderLevelThresholdsDashLines = (chart, levelPercent, thresholds, thresholdsGroup) => {
    let lineCSS = {
        'stroke-width': 1,
        'stroke-dasharray': '8,4',
        stroke: 'grey',
    };

    const Y_BASE = 39.6; // starting point of Y axis to draw the line
    const Y_TOTAL = 290 - 32;

    thresholds.forEach((t) => {
        let cmd;
        const { percent: threshold } = t;
        const y = Y_BASE + (Y_TOTAL * (100 - threshold)) / 100;
        if (y) {
            cmd = 'M 275 ' + `${y}` + 'H 232';
        }
        if (threshold <= levelPercent) {
            lineCSS.stroke = 'Gainsboro';
        } else {
            lineCSS.stroke = 'grey';
        }
        chart.renderer.path([cmd]).attr(lineCSS).add(thresholdsGroup);
    });
};

const calculateLevelThresholds = (rules, maxDepth) => {
    let thresholds = [];

    if (!!rules && rules.length > 0) {
        for (const rule of rules) {
            if (rule.conditions) {
                for (const condition of rule.conditions) {
                    if (condition.fact === 'level' && maxDepth) {
                        const percent = condition.isPercent
                            ? condition.value
                            : Math.round((condition.value * 100) / maxDepth);
                        thresholds.push({ percent, operator: condition.operator });
                    }
                }
            }
        }
    }

    return thresholds;
};

const adjustDataForLowPercentage = (data) => {
    let remainder = data[0];
    let level = data[1];
    const total = level.y + remainder.y;

    if (level.y !== 0 && level.y / total < 0.01) {
        level.y = total * 0.01;
        remainder.y = total - level.y;
    }

    return [remainder, level];
};

const useStyles = makeStyles((theme) => ({
    ChartWrapper: {
        position: 'relative',
        height: '100%',
        width: '100%',
        display: 'flex',
        flex: 1,
        textTransform: 'none',
    },
    MonitorContainer: {
        position: 'absolute',
        minHeight: '310px',
        minWidth: '600px',
        backgroundColor: 'transparent',
        transform: (props) =>
            props.open ? 'translate(-5%, 18%) scale(1.08)' : 'translate(3%, 18%) scale(1.08)',
        [theme.breakpoints.up('xl')]: {
            height: '310px',
            width: '600px',
            transform: (props) =>
                props.open ? 'translate(24%, 23%) scale(1.3)' : 'translate(32%, 23%) scale(1.3)',
        },
    },
    title: {
        position: 'absolute',
        fontSize: '20px',
        marginLeft: '5px',
        height: '100%',
        width: '100%',
    },
    latestLevel: {
        position: 'absolute',
        fontSize: '20px',
        marginLeft: '20px',
        marginTop: '1px',
        fontWeight: 'bold',
        paddingLeft: '160px',
    },
}));

const chartOptions = {
    chart: {
        type: 'funnel',
        backgroundColor: 'transparent',
    },
    title: {
        text: '  ',
    },
    subtitle: {
        text: '',
    },
    xAxis: {
        visible: false,
    },
    colors: colors,
    tooltip: {
        zIndex: 99999,
        useHTML: true,
        borderWidth: 3,
        shadow: false,
        marker: {
            symbol: 'circle',
            radius: 18, // set the radius of the color point
        },
        headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        pointFormat:
            '<span style="color:{point.color}">\u2B24</span> {point.name}: <b> {point.percentage:.0f}%</b> of total<br/>',
        backgroundColor: 'white',
    },
    credits: {
        enabled: false,
    },
    exporting: { enabled: false },
    plotOptions: {
        tooltip: {
            pointFormat:
                '<tr><td style="color:{series.color};padding:0">{456}: </td>' +
                '<td style="padding:0"><b>{789} mm</b></td></tr>',
            useHTML: true,

            followPointer: true,
            followTouchMove: true,
        },
        series: {
            center: ['42%', '42%'],
            neckWidth: '10%',
            width: '10%',
            height: '69%',
            dataLabels: {
                enabled: false,
                format: '<b>{point.name}</b> {point.y:.0f}mm',
                softConnector: true,
            },
        },
    },
    accessibility: {
        enabled: false,
    },
    legend: {
        enabled: false,
    },
    responsive: {
        rules: [
            {
                condition: {
                    maxWidth: 100,
                    maxHeight: 100,
                },
            },
        ],
    },
};

const arrowUp = '&#x25B2;';
const arrowDown = '&#x25BC;';

const GroundWater = (props) => {
    const { data, value, maxDepth, rules, tubeDiameter, lengthAboveGround, distanceToBottom } =
        props;
    let level = data && data[1] && data[1].y ? data[1].y : '';
    const thresholds = calculateLevelThresholds(rules, maxDepth);

    const [open] = useSidebarState();
    const classes = useStyles({ open });

    const chartComponent = useRef(null);

    const [options, setOptions] = useState({
        ...chartOptions,
        chart: {
            ...chartOptions.chart,
            events: {
                load: function () {
                    var chart = this;
                    drawBackground(chart);
                },
            },
        },
    });

    useEffect(() => {
        const chart = chartComponent.current?.chart;
        if (!chart) return;
        drawDimensions(chart, lengthAboveGround, distanceToBottom, tubeDiameter);
        drawThresholds(chart, value, maxDepth, thresholds);
    }, [thresholds, value, maxDepth, tubeDiameter, lengthAboveGround, distanceToBottom]);

    useEffect(() => {
        const adjustedData = adjustDataForLowPercentage(data);
        setOptions({
            series: [
                {
                    name: 'Latest Water Level',
                    data: adjustedData,
                },
            ],
        });
    }, [data]);

    return (
        <div>
            <div className={classes.ChartWrapper}>
                <span className={classes.title}>Latest Water Level </span>
                <span className={classes.latestLevel}>{level} mm</span>
                <div className={classes.MonitorContainer}>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={options}
                        ref={chartComponent}
                    />
                </div>
            </div>
        </div>
    );
};

function drawBackground(chart) {
    // Remove to avoid drawing same elements multiple times
    removeSVGFromChart(chart, 'gw-2d-background');

    // Group for all static elements
    const background = chart.renderer
        .g('gw-2d-background-group')
        .attr({ id: 'gw-2d-background' })
        .add();

    //BG Top
    chart.renderer
        .path(BACKGROUND_TOP)
        .attr({
            'stroke-width': 1,
            fill: '#FFFFFF',
        })
        .add(background);
    //BG ground
    chart.renderer
        .path(BACKGROUND_BGROUND)
        .attr({
            'stroke-width': 1,
            fill: '#A4A3A3',
        })
        .add(background);
    //BG Water
    chart.renderer
        .path(WATER_BACKGROUND)
        .attr({
            fill: '#FFFFFF',
        })
        .add(background);
    //outside
    chart.renderer
        .path(OUTSIDE_COVER)
        .attr({
            'stroke-width': 1,
            fill: '#D9D9D9',
            stroke: 'black',
        })
        .add()
        .toFront();
    //Lid top
    chart.renderer
        .path(LID_TOP)
        .attr({
            'stroke-width': 1,
            fill: '#414040',
            stroke: 'black',
        })
        .add(background);
    //Sensor
    chart.renderer
        .path(SENSOR)
        .attr({
            'stroke-width': 1,
            fill: '#828282',
            stroke: 'black',
        })
        .add(background);
    chart.renderer
        .path(ARROW_FOR_TUBE_DIAMETER)
        .attr({
            'stroke-width': 1,
            fill: 'black',
        })
        .add(background);
    //arrow for brenching
    chart.renderer
        .path(ARROW_FOR_LENGTH_ABOVE_GROUND)
        .attr({
            'stroke-width': 1,
            fill: 'black',
        })
        .add(background);
    //arrow for brenching
    chart.renderer
        .path(ARROW_FOR_BENCHING)
        .attr({
            'stroke-width': 1,
            fill: 'black',
        })
        .add(background);
    //Top line for both
    chart.renderer
        .path(TOP_LINE)
        .attr({
            'stroke-width': 1,
            stroke: 'black',
        })
        .add(background);
    chart.renderer
        .path(BUTTOM_OF_LENGTH_ABOVE_GROUND)
        .attr({
            'stroke-width': 1,
            stroke: 'black',
        })
        .add(background);
    chart.renderer
        .path(BUTTOM_OF_TUBE_BOTTOM)
        .attr({
            'stroke-width': 1,
            stroke: 'black',
        })
        .add(background);
    // arrow for tube diameter
    chart.renderer
        .path(TUBE_DIAMETER_LEFT)
        .attr({
            'stroke-width': 1,
            stroke: 'black',
        })
        .add(background);
    chart.renderer
        .path(TUBE_DIAMETER_RIGHT)
        .attr({
            'stroke-width': 1,
            stroke: 'black',
        })
        .add(background);

    chart.renderer
        .text('A = Tube Diameter', 35, 330)
        .css({
            fontFamily: 'Arial, Helvetica, sans-serif',
            fontWeight: 'bolder',
            fontSize: '80%',
        })
        .add(background);
    chart.renderer
        .text('C = Tube length above ground', 135, 330)
        .css({
            fontFamily: 'Arial, Helvetica, sans-serif',
            fontWeight: 'bolder',
            fontSize: '80%',
        })
        .add(background);
    chart.renderer
        .text('E = Sensor distance to tube bottom', 295, 330)
        .css({
            fontFamily: 'Arial, Helvetica, sans-serif',
            fontWeight: 'bolder',
            fontSize: '80%',
        })
        .add(background);
}

function drawDimensions(chart, lengthAboveGround, distanceToBottom, tubeDiameter) {
    // Remove to avoid drawing same elements multiple times
    removeSVGFromChart(chart, 'gw-2d-dimensions');

    // Group for dimensions related elements
    const dimensions = chart.renderer
        .g('gw-2d-dimensions-group')
        .attr({ id: 'gw-2d-dimensions' })
        .add()
        .toFront();

    chart.renderer
        .text('C = ' + lengthAboveGround, 165, 50)
        .css({
            fontFamily: 'Arial, Helvetica, sans-serif',
            fontWeight: 'bold',
            fontSize: '80%',
        })
        .add(dimensions);
    chart.renderer
        .text('E = ' + distanceToBottom, 110, 115)
        .css({
            fontFamily: 'Arial, Helvetica, sans-serif',
            fontWeight: 'bold',
            fontSize: '80%',
        })
        .add(dimensions);
    chart.renderer
        .text('A = ' + tubeDiameter, 240, 10)
        .css({
            fontFamily: 'Arial, Helvetica, sans-serif',
            fontWeight: 'bold',
            fontSize: '80%',
        })
        .add(dimensions);
}

function drawThresholds(chart, value, maxDepth, thresholds) {
    // Remove to avoid drawing same elements multiple times
    removeSVGFromChart(chart, 'gw-2d-thresholds');

    // Group for thresholds
    const thresholdsGroup = chart.renderer
        .g('gw-2d-thresholds-group')
        .attr({ id: 'gw-2d-thresholds' })
        .add()
        .toFront();

    // dash lines for level thresholds
    renderLevelThresholdsDashLines(chart, (value * 100) / maxDepth, thresholds, thresholdsGroup);
    // text for level thresholds
    renderLevelThresholdsText(chart, thresholds, thresholdsGroup);
}

export default GroundWater;
