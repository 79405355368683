import React from 'react';
import { makeStyles } from '@mui/styles';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ROOT from '../../theme/root';

const useStyles = makeStyles(() => ({
    select: {
        '& .Mui-disabled': {
            color: 'white',
            '-webkit-text-fill-color': 'white',
        },
    },
}));

const statuses = (status) => {
    let results = [];
    switch (status) {
        case 0:
            results = [
                { id: 0, name: 'Pending Survey' },
                { id: 1, name: 'Survey Submitted' },
                { id: 2, name: 'Approved' },
                { id: 3, name: 'Rejected' },
                { id: 5, name: 'Installed' },
            ];
            break;

        case 1:
            results = [
                { id: 1, name: 'Survey Submitted' },
                { id: 2, name: 'Approved' },
                { id: 3, name: 'Rejected' },
            ];
            break;
        case 2:
        case 3:
            results = [
                { id: 2, name: 'Approved' },
                { id: 3, name: 'Rejected' },
            ];
            break;
        default:
            results = [
                { id: 0, name: 'Pending Survey' },
                { id: 1, name: 'Survey Submitted' },
                { id: 2, name: 'Approved' },
                { id: 3, name: 'Rejected' },
                { id: 4, name: 'Installed' },
                { id: 5, name: 'Deleted' },
            ];
            break;
    }
    return results;
};

const StatusSelector = ({ status, onChange }) => {
    const classes = useStyles();
    const statusActions = statuses(status);
    if (!statusActions || statusActions.length === 0) return <></>;
    return (
        <Select
            id="status-selector"
            className={classes.select}
            disabled={status !== 1 && status !== 2 && status !== 3}
            value={status}
            variant="outlined"
            onChange={(event) => {
                if (onChange) onChange(event);
            }}
            native={false}
            style={{ backgroundColor: ROOT.COLORS.PRIMARY, color: '#fff' }}
        >
            {statusActions &&
                statusActions.length > 0 &&
                statusActions.map((option) => {
                    return (
                        <MenuItem key={option.id} value={option.id}>
                            {option.name}
                        </MenuItem>
                    );
                })}
        </Select>
    );
};

export default StatusSelector;
