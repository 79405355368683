import { Box, Card } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { Loading, useShowContext } from 'react-admin';
import Error from '../../components/Error';
import { useAppContext } from '../../context/AppContext';
import OnboardHistory from './OnboardHistory';

/**
 * Device onboarding information
 * Use context to get Device information
 * @returns Onboarding container
 */
const DeviceOnboarding = () => {
    // Hooks
    const { record: device, isLoading } = useShowContext();
    const [appStore] = useAppContext();
    const [organisationId, setOrganisationId] = useState(appStore.organisationId);

    // Effect Hooks
    useEffect(() => setOrganisationId(appStore.organisationId), [appStore]);

    // Loading & Error
    if (isLoading) return <Loading />;
    if (!isLoading && !device) {
        return <Error message="Could not load device" />;
    }

    return (
        <Box>
            <Card>
                <OnboardHistory deviceId={device.deviceId} organisationId={organisationId} />
            </Card>
        </Box>
    );
};

export default DeviceOnboarding;
