import React from 'react';
import { Box, Card, LinearProgress, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Map from '../../components/Map';
import { Marker } from '@react-google-maps/api';
import SiteDeviceDetails from '../components/SiteDeviceDetails';
import WaterLevel from './WaterLevel';
import Manhole from './Manhole';
import GroundWater from './GroundWater';
import LastUpdate from '../components/LastUpdate';
import SiteAlarms from './SiteAlarms';
import DateRangeSummary from '../components/DateRangeSummary';
import useFetch from '../../hooks/useFetch';
import { useGetOne, useNotify } from 'react-admin';
import useAnalytics from '../hooks/useAnalytics';
import Tank from './Tank';

const useStyles = makeStyles(() => ({
    searchInput: {
        height: '45px',
        width: '300px',
        '& input': {
            color: '#636364',
        },
    },
    create: {
        backgroundColor: '#00AEEF',
        color: '#fff',
        width: '206px',
        height: '45px',
    },
    sectionHeader: {
        fontSize: '16px',
        fontWeight: 'bold',
        letterSpacing: '-0.38px',
        lineHeight: '21px',
        marginBottom: '20px',
    },
    mapContainer: {
        height: '100%',
        width: '100%',
        position: 'relative',
        overflow: 'hidden',
        borderRadius: '10px',
    },
    LastUpdateContainer: {
        marginBottom: '-20px',
        marginTop: '30px',
    },
    unselected: {
        opacity: 0.5,
    },
}));

const dateRangeEquals = (first, second) => {
    return (
        first.startDate.getTime() === second.startDate.getTime() &&
        first.endDate.getTime() === second.endDate.getTime()
    );
};

const getDimensionsManhole = (surveyAnswers) => {
    const man2 = surveyAnswers.find((answer) => answer.surveyQuestionId === 'MAN2');
    const man4 = surveyAnswers.find((answer) => answer.surveyQuestionId === 'MAN4');
    const man5 = surveyAnswers.find((answer) => answer.surveyQuestionId === 'MAN5');
    const man6 = surveyAnswers.find((answer) => answer.surveyQuestionId === 'MAN6');

    let b = {};
    let i = {};

    b.value = +(man5?.value ?? 0) + +(man4?.value ?? 0) - +(man2?.value ?? 0);
    i.value = +(man6?.value ?? 0) + +(man4?.value ?? 0) - +(man2?.value ?? 0);

    return { b, i };
};

const getDimensionsGroundWaterTube = (surveyAnswers) => {
    let tubeDiameter = surveyAnswers.find((answer) => answer.surveyQuestionId === 'MAN1');
    let lengthAboveGround = surveyAnswers.find((answer) => answer.surveyQuestionId === 'MAN3');
    let distanceToBottom = surveyAnswers.find((answer) => answer.surveyQuestionId === 'MAN5');

    tubeDiameter = tubeDiameter?.value ?? 0;
    lengthAboveGround = lengthAboveGround?.value ?? 0;
    distanceToBottom = distanceToBottom?.value ?? 0;

    return { tubeDiameter, lengthAboveGround, distanceToBottom };
};

const getDimensionsTank = (surveyAnswers) => {
    let distanceToSensor = surveyAnswers.find((answer) => answer.surveyQuestionId === 'TANK1');
    let distanceToMaxLevel = surveyAnswers.find((answer) => answer.surveyQuestionId === 'TANK2');
    let zeroMark = surveyAnswers.find((answer) => answer.surveyQuestionId === 'TANK4');

    distanceToSensor = parseInt(distanceToSensor?.value ?? 0);
    distanceToMaxLevel = parseInt(distanceToMaxLevel?.value ?? 0);
    zeroMark = parseInt(zeroMark?.value ?? 0);
    return { distanceToSensor, distanceToMaxLevel, zeroMark };
};

const SiteDataMh = (props) => {
    const classes = useStyles();
    const { siteName, site, maxDepth, surveys } = props;
    const siteTypeId = props.site.siteTypeId;

    const [benching, setBenching] = React.useState(null);
    const [invert, setInvert] = React.useState(null);
    const [tubeDiameter, setTubeDiameter] = React.useState(null);
    const [lengthAboveGround, setLengthAboveGround] = React.useState(null);
    const [distanceToBottom, setDistanceToBottom] = React.useState(null);
    const [distanceToSensor, setDistanceToSensor] = React.useState(null);
    const [distanceToMaxLevel, setDistanceToMaxLevel] = React.useState(null);
    const [zeroMark, setZeroMark] = React.useState(null);
    const [dateRange, setDateRange] = React.useState({
        label: 'Last 30 days',
        startDate: new Date(new Date().setDate(new Date().getDate() - 30)),
        endDate: new Date(),
    });
    const [alarmRules, setAlarmRules] = React.useState([]);

    const {
        site: siteAnalytics,
        alarms,
        latestReading,
        readings,
        loading: loadingAnalytics,
        error,
    } = useAnalytics(props.id, dateRange.startDate, dateRange.endDate);
    const notify = useNotify();
    const {
        response,
        error: errorRules,
        isLoading: loadingRules,
    } = useFetch(`${process.env.REACT_APP_API_BASE_URL}/rules/site/${site.id}`, {
        method: 'GET',
    });

    const { data, isLoading: loadingSurveys } = useGetOne(
        'surveys',
        {
            id: surveys?.[0]?.id ?? null,
        },
        {
            enabled: !!surveys?.[0]?.id,
        },
    );

    React.useEffect(() => {
        if (data && data.surveyAnswers) {
            if (siteTypeId === 1) {
                const { b, i } = getDimensionsManhole(data.surveyAnswers);
                setBenching(b);
                setInvert(i);
            }
            if (siteTypeId === 2 || siteTypeId === 4) {
                const { tubeDiameter, lengthAboveGround, distanceToBottom } =
                    getDimensionsGroundWaterTube(data.surveyAnswers);
                setTubeDiameter(tubeDiameter);
                setLengthAboveGround(lengthAboveGround);
                setDistanceToBottom(distanceToBottom);
            }
            if (siteTypeId === 3) {
                const { distanceToSensor, distanceToMaxLevel, zeroMark } = getDimensionsTank(
                    data.surveyAnswers,
                );
                setDistanceToSensor(distanceToSensor);
                setDistanceToMaxLevel(distanceToMaxLevel);
                setZeroMark(zeroMark);
            }
        }
    }, [data, siteTypeId]);

    React.useEffect(() => {
        if (response) setAlarmRules(response);
    }, [response]);

    React.useEffect(() => {
        if (error) notify(error, { type: 'warning' });
        if (errorRules) notify(errorRules, { type: 'warning' });
    }, [error, errorRules, notify]);

    if (loadingAnalytics || loadingRules || loadingSurveys) {
        return (
            <Box className={classes.container} marginTop="16px">
                <LinearProgress />
            </Box>
        );
    }

    if (!siteAnalytics) {
        return (
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                margin="auto"
                height="100px"
            >
                <span>No Device Data</span>
            </Box>
        );
    }

    if (error) {
        return null;
    }

    // maxDepth is 0 when a site is deleted.
    let monitoringDepth = maxDepth;
    if (maxDepth === 0 && invert && benching)
        monitoringDepth = site.measureFromInvert ? +invert.value : +benching.value;

    return (
        <>
            <Box display="flex" flexDirection="column" marginTop="16px">
                <Box>
                    <Box height="216px" mb="15px" display="flex">
                        <Box width="50%" height="100%" mr="30px">
                            <Card
                                style={{
                                    height: '450px',
                                    boxSizing: 'content-box',
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <SiteDeviceDetails
                                    site={{ ...siteAnalytics }}
                                    device={site.devices ? site.devices[0] : undefined}
                                />
                                <Box className={classes.mapContainer} display="flex" flex={1.4}>
                                    <Map
                                        center={{
                                            lat: +siteAnalytics.latitude,
                                            lng: +siteAnalytics.longitude,
                                        }}
                                        zoom={15}
                                        withLocationMem={false}
                                    >
                                        {siteAnalytics.latitude && (
                                            <Marker
                                                position={{
                                                    lat: +siteAnalytics.latitude,
                                                    lng: +siteAnalytics.longitude,
                                                }}
                                            />
                                        )}
                                    </Map>
                                </Box>
                                <Box className={classes.LastUpdateContainer} display="flex">
                                    <LastUpdate
                                        latestReading={
                                            latestReading ? latestReading?.timestamp : undefined
                                        }
                                    />
                                </Box>
                            </Card>
                        </Box>
                        <Box width="50%" height="450px">
                            <Card
                                display="flex"
                                style={{
                                    height: '450px',
                                    boxSizing: 'content-box',
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                {siteTypeId === 1 && (
                                    <Manhole
                                        data={[
                                            {
                                                name: 'Remaining',
                                                y: monitoringDepth - (latestReading?.level ?? 0),
                                                className: classes.unselected,
                                            },
                                            {
                                                name: 'WaterLevel',
                                                y: latestReading?.level ?? 0,
                                            },
                                        ]}
                                        value={latestReading?.level ?? 0}
                                        measureFromInvert={site.measureFromInvert}
                                        invertToSensor={invert?.value ?? 0}
                                        benchingToSensor={benching?.value ?? 0}
                                        maxDepth={monitoringDepth}
                                        rules={alarmRules}
                                    />
                                )}
                                {(siteTypeId === 2 || siteTypeId === 4) && (
                                    <GroundWater
                                        data={[
                                            {
                                                name: 'Remaining',
                                                y: monitoringDepth - (latestReading?.level ?? 0),
                                                className: classes.unselected,
                                            },
                                            {
                                                name: 'WaterLevel',
                                                y: latestReading?.level ?? 0,
                                            },
                                        ]}
                                        value={latestReading?.level ?? 0}
                                        maxDepth={monitoringDepth}
                                        rules={alarmRules}
                                        tubeDiameter={tubeDiameter}
                                        lengthAboveGround={lengthAboveGround}
                                        distanceToBottom={distanceToBottom}
                                    />
                                )}
                                {siteTypeId === 3 && (
                                    <Tank
                                        latestReading={latestReading}
                                        rules={alarmRules}
                                        distanceToSensor={distanceToSensor}
                                        distanceToMaxLevel={distanceToMaxLevel}
                                        zeroMark={zeroMark}
                                    />
                                )}
                            </Card>
                        </Box>
                    </Box>
                    <Box height="419px" width="100%" mt="290px" display="flex">
                        <Box width="100%" position="relative">
                            <Box position="relative">
                                <DateRangeSummary
                                    range={dateRange}
                                    onUpdate={(range) => {
                                        if (!dateRangeEquals(range, dateRange)) {
                                            setDateRange(range);
                                        }
                                    }}
                                />
                            </Box>
                            <Card style={{ height: '440px', width: '100%' }}>
                                <WaterLevel
                                    data={readings}
                                    title={siteName}
                                    allowExport={readings && readings.length > 0}
                                    dateRange={dateRange}
                                    maxDepth={monitoringDepth}
                                    rules={alarmRules}
                                />
                            </Card>
                        </Box>
                    </Box>

                    {alarms && alarms.length > 0 && (
                        <Box>
                            <Card style={{ height: '360px', width: '100%', marginTop: '45px' }}>
                                <Typography variant="body1" className={classes.sectionHeader}>
                                    Recent last 10 Active Alarms
                                </Typography>
                                <SiteAlarms alarms={alarms} />
                            </Card>
                        </Box>
                    )}
                </Box>
            </Box>
        </>
    );
};

export default SiteDataMh;
