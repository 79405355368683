/* eslint-disable security/detect-object-injection */
/* global google */
import React from 'react';
import { LinearProgress } from '@mui/material';
import { useGetOne } from 'react-admin';
import { makeStyles } from '@mui/styles';
import { InfoWindow, Marker, MarkerClusterer, useGoogleMap } from '@react-google-maps/api';
import SiteDetails from './components/SiteDetails';
import useSessionStorage from '../hooks/useSessionStorage';
import sorter from '../utils/sorter';
import { useAppContext } from '../context/AppContext';

const useStyles = makeStyles(() => ({
    card: {
        display: 'flex',
        flexDirection: 'column',
        width: '263px',
        height: '140px',
        borderRadius: '4px',
        marginBottom: '14px',
        padding: '24px',
    },
    tabContainer: {
        marginTop: '30px',
    },
    numericValue: {
        fontSize: '40px',
        lineHeight: '53px',
    },
    numericLabel: {
        fontSize: '16px',
        lineHeight: '21px',
        color: '#747474',
    },
    mapContainer: {
        width: '100%',
        height: '59vh',
    },
    infoWindow: {
        height: '230px',
        width: '210px',
        padding: '5px',
    },
}));

const DashboardMap = (props) => {
    const { dashboard } = props;
    const classes = useStyles();
    const map = useGoogleMap();
    var [location] = useSessionStorage('location', { zoom: props.zoom, center: undefined });
    var [refreshDashboardMap, setRefreshDashboardMap] = useSessionStorage('refreshDashboardMap', {
        forceRefresh: false,
    });
    const [selectedSite, setSelectedSite] = React.useState();
    const { data: siteDetails, isLoading: siteLoading } = useGetOne(
        'sites',
        { id: selectedSite },
        {
            enabled: selectedSite !== undefined,
        },
    );
    const [appStore] = useAppContext();
    const organisationId =
        appStore.organisationId && appStore.organisationId !== '-' ? appStore.organisationId : '';
    const fitMarkers = (sites) => {
        if (location.center && !refreshDashboardMap.forceRefresh) return;
        if (!map) return;
        if (!sites || sites.length === 0) {
            // Set map to NZ center if there are no sites
            map.setCenter({ lat: -40.9006, lng: 174.886 });
            map.setZoom(5);
            return;
        }
        const markers = sites.map((marker) => {
            return { lat: +marker.latitude, lng: +marker.longitude };
        });
        const sortedMarkers = markers.sort(sorter('lat')).sort(sorter('lng'));
        var bounds = new google.maps.LatLngBounds();
        for (var i = 0; i < sortedMarkers.length; i++) {
            bounds.extend(sortedMarkers[parseInt(i)]);
        }
        const mapCenter = bounds.getCenter();
        map.setCenter(mapCenter);
        map.panToBounds(bounds);
        map.fitBounds(bounds, 0);
    };

    React.useEffect(() => {
        fitMarkers(sitesWithAlarms);
        setRefreshDashboardMap({
            forceRefresh: false,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        if (map) {
            if (!dashboard || dashboard.sites.length === 0) return;
            fitMarkers(sitesWithAlarms);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [map, organisationId]);

    const greenMarker = {
        path: 'M19.1284167,18.0450833 L14.5318333,22.6416667 C13.6858745,23.4867725 12.3152088,23.4867725 11.46925,22.6416667 L6.87158333,18.0450833 C3.48716779,14.6604934 3.48726204,9.17314475 6.87179386,5.78867106 C10.2563257,2.40419738 15.7436743,2.40419738 19.1282061,5.78867106 C22.512738,9.17314475 22.5128322,14.6604934 19.1284167,18.0450833 L19.1284167,18.0450833 Z',
        fillColor: '#5CECBF',
        fillOpacity: 1,
        strokeColor: '#28C494',
    };

    const redMarker = {
        path: 'M19.1284167,18.0450833 L14.5318333,22.6416667 C13.6858745,23.4867725 12.3152088,23.4867725 11.46925,22.6416667 L6.87158333,18.0450833 C3.48716779,14.6604934 3.48726204,9.17314475 6.87179386,5.78867106 C10.2563257,2.40419738 15.7436743,2.40419738 19.1282061,5.78867106 C22.512738,9.17314475 22.5128322,14.6604934 19.1284167,18.0450833 L19.1284167,18.0450833 Z',
        fillColor: 'red',
        fillOpacity: 1,
        strokeColor: 'black',
    };

    const greyMarker = {
        path: 'M14.5318333,22.6416667 C13.6858745,23.4867725 12.3152088,23.4867725 11.46925,22.6416667 L6.87158333,18.0450833 C3.48716779,14.6604934 3.48726204,9.17314475 6.87179386,5.78867106 C10.2563257,2.40419738 15.7436743,2.40419738 19.1282061,5.78867106 C22.512738,9.17314475 22.5128322,14.6604934 19.1284167,18.0450833 L14.5318333,22.6416667 Z',
        fillColor: 'grey',
        fillOpacity: 1,
        strokeColor: 'black',
    };

    const orangeMarker = {
        path: 'M19.1284167,18.0450833 L14.5318333,22.6416667 C13.6858745,23.4867725 12.3152088,23.4867725 11.46925,22.6416667 L6.87158333,18.0450833 C3.48716779,14.6604934 3.48726204,9.17314475 6.87179386,5.78867106 C10.2563257,2.40419738 15.7436743,2.40419738 19.1282061,5.78867106 C22.512738,9.17314475 22.5128322,14.6604934 19.1284167,18.0450833 L19.1284167,18.0450833 Z',
        fillColor: 'orange',
        fillOpacity: 1,
        strokeColor: 'black',
    };

    const cluster = {
        url: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m1.png',
        height: 53,
        width: 53,
    };

    const getAlarms = (sites) => {
        for (let i = 0; i < sites.length; i++) {
            sites[i].alarm = 'none';
            sites[i].markerColor = greenMarker;
            if (sites[i].tamperAlarmsCount && sites[i].tamperAlarmsCount != '0') {
                sites[i].alarm = 'tamper';
                sites[i].markerColor = redMarker;
            } else if (sites[i].inactiveAlarmsCount && sites[i].inactiveAlarmsCount != '0') {
                sites[i].alarm = 'inactive';
                sites[i].markerColor = greyMarker;
            } else if (sites[i].levelAlarmsCount && sites[i].levelAlarmsCount != '0') {
                sites[i].alarm = 'level';
                sites[i].markerColor = orangeMarker;
            }
        }
        return sites;
    };

    const sitesWithAlarms = getAlarms(dashboard.sites);

    return (
        <>
            {sitesWithAlarms && (
                <MarkerClusterer maxZoom={18} gridSize={15} styles={[cluster]}>
                    {(clusterer) =>
                        sitesWithAlarms.map((site) => {
                            return (
                                <Marker
                                    key={site.id}
                                    position={{
                                        lat: +site.latitude,
                                        lng: +site.longitude,
                                    }}
                                    clusterer={clusterer}
                                    onClick={() => setSelectedSite(site.id)}
                                    icon={site.markerColor}
                                >
                                    {selectedSite && selectedSite === site.id && (
                                        <InfoWindow
                                            position={{
                                                lat: +site.latitude,
                                                lng: +site.longitude,
                                            }}
                                            onLoad={() => {
                                                if (map)
                                                    map.setOptions({
                                                        scrollwheel: false,
                                                    });
                                            }}
                                            onCloseClick={() => {
                                                setSelectedSite(undefined);
                                                if (map)
                                                    map.setOptions({
                                                        scrollwheel: true,
                                                    });
                                            }}
                                        >
                                            <div className={classes.infoWindow}>
                                                {siteLoading && <LinearProgress />}
                                                {!siteLoading && siteDetails && (
                                                    <SiteDetails
                                                        {...siteDetails}
                                                        dashboardSite={
                                                            dashboard.sites.filter(
                                                                (s) => s.id == site.id,
                                                            )[0]
                                                        }
                                                    />
                                                )}
                                            </div>
                                        </InfoWindow>
                                    )}
                                </Marker>
                            );
                        })
                    }
                </MarkerClusterer>
            )}
        </>
    );
};

export default DashboardMap;
