const validateWaterThreshold = (lvl, maxDepth) => {
    let isValid = true;
    let error = '';
    if (lvl.valueUnit === '%') {
        if (lvl.value < 1 || lvl.value > 99) {
            isValid = false;
            error += 'Threshold % can only be between 1-99%. ';
        }
    }

    if (lvl.valueUnit === 'mm') {
        if (lvl.value < 1) {
            isValid = false;
            error += 'Threshold value is required. ';
        }
        if (maxDepth && lvl.value >= maxDepth) {
            isValid = false;
            error += 'Threshold value must be less than max height. ';
        }
    }

    if (lvl.triggerEvents < 1 || lvl.triggerEvents > 5) {
        isValid = false;
        error += 'Trigger events are required with a maximum of 5. ';
    }

    return [isValid, error];
};

export { validateWaterThreshold };
