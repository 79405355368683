import React from 'react';
import { Box, Switch, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Label from '../components/Label';
import InputMask from 'react-input-mask';
import UnitInput from '../components/UnitInput';

const useStyles = makeStyles(() => ({
    validation: {
        marginTop: '10px',
        fontSize: '11px',
        color: 'red',
    },
}));

const InactiveThreshold = ({ checked, value, valueUnit, onChange, showMode }) => {
    const classes = useStyles();
    const [check, setCheck] = React.useState(false);
    let numericValue = parseInt(value);
    React.useEffect(() => {
        if (checked !== check) setCheck(checked);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checked]);

    const [error, setError] = React.useState(false);
    const [validation, setValidation] = React.useState('');

    const [unit, setUnit] = React.useState(valueUnit !== undefined ? valueUnit : 'events');
    const [unitValue, setUnitValue] = React.useState(
        numericValue > 0 ? numericValue.toString() : '2',
    );
    React.useEffect(() => {
        if (numericValue > 0 && numericValue.toString() !== unitValue)
            setUnitValue(numericValue.toString());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [numericValue]);
    React.useEffect(() => {
        if (valueUnit !== undefined && valueUnit !== unit) setUnit(valueUnit);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [valueUnit]);
    React.useEffect(() => {
        setError(false);
        setValidation('');

        if (numericValue < 1) {
            setError(true);
            setValidation('Trigger value is required.');
            return;
        }

        if (unit === 'hrs') {
            if (unitValue < 1 || unitValue > 24) {
                setError(true);
                setValidation('Trigger can be up to 24hrs long.');
            }
        }

        if (unit === 'events') {
            if (unitValue < 1 || unitValue > 5) {
                setError(true);
                setValidation('Trigger can be up to 5 events long.');
            }
        }

        if (onChange) onChange(checked, unitValue, unit);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [unitValue, unit]);

    const handleChange = (event, checkedValue) => {
        setCheck(checkedValue);
        if (onChange) onChange(checkedValue, unitValue, unit);
    };

    return (
        <Box display="flex" flexDirection="column" pt="10px" pb="40px">
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Label sub>Inactive</Label>
                <Tooltip title="Will raise an alarm if the device stops sending readings." arrow>
                    <Switch
                        checked={check}
                        onChange={handleChange}
                        color="primary"
                        name="tamper"
                        disabled={showMode}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                </Tooltip>
            </Box>
            {check === true && (
                <Box display="flex" flexDirection="row-reverse">
                    <Box display="flex" flexDirection="column" width="200px">
                        <Label>Trigger after</Label>
                        <InputMask
                            mask="99"
                            maskPlaceholder={null}
                            onChange={(evnt) => {
                                if (evnt.target.value !== unitValue)
                                    setUnitValue(evnt.target.value);
                            }}
                            value={unitValue}
                            disabled={showMode}
                        >
                            <UnitInput
                                units={['hrs', 'events']}
                                error={error}
                                selectedUnit={unit}
                                onChangeUnit={(unitValue) => {
                                    if (unitValue !== unit) setUnit(unitValue);
                                }}
                            />
                        </InputMask>
                        {error === true && <span className={classes.validation}>{validation}</span>}
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default InactiveThreshold;
