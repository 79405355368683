import React, { useEffect, useState } from 'react';
import { useGetPermissions, Confirm, useShowController } from 'react-admin';
import { makeStyles } from '@mui/styles';
import MeasurementSection from './MeasurementSection';
import SurveyOtherInfo from './SurveyOtherInfo';
import DisplayField from '../../components/DisplayFiled';
import { Grid, LinearProgress, Tab, Tabs } from '@mui/material';
import SurveyAttachments from './SurveyAttachments';
import SurveyComments from './SurveyComments';
import DeviceHistory from './DeviceHistory';
import { getUserInfo } from '../../auth/auth0Functions';
import Layout from '../../components/Layout';
import TabPanel from '../../components/TabPanel';
import ROOT from '../../theme/root';
import { getDateString } from '../utils';
import { getSiteType } from '../components/SiteTypes';

// TODO: Create a gap in the border to match the design. This method is not working very will >>

const useStyles = makeStyles(() => ({
    container: {
        background: 'transparent',
        '& hr': {
            height: '0px',
        },
        '& .MuiTabs-fixed > .MuiTabs-indicator': {
            backgroundColor: ROOT.COLORS.SECONDARY,
            top: '0px',
            bottom: 'unset',
            borderLeft: 'unset',
            borderRight: 'unset',
        },
        '& .MuiTabs-fixed > .MuiTabs-flexContainer': {
            borderBottom: 'unset',
        },
        '& [class*="MuiTab-root"][class*="MuiTab-textColorPrimary"][class*="Mui-selected"]': {
            backgroundColor: 'white',
            overflow: 'visible',
            borderLeft: ROOT.BORDERS.BORDER,
            borderRight: ROOT.BORDERS.BORDER,
            '&::after': {
                content: '""',
                width: '100%',
                height: '12px',
                backgroundColor: 'white',
                display: 'block',
                position: 'absolute',
                bottom: '0px',
                zIndex: '9000',
            },
        },
    },
    tabPanel: {
        border: '1px solid #D8D8D8',
        paddingTop: '16px',
        paddingLeft: '24px',
        borderRadius: '4px',
        paddingRight: '24px',
        paddingBottom: '24px',
        backgroundColor: 'white',
    },
    tabContainer: {
        marginLeft: '0px',
    },
}));

const SurveySection = (props) => {
    const { resource, id, siteTypeId } = props;
    const classes = useStyles();
    const { record, isFetching } = useShowController({ resource, id });
    const [isEditMode, setIsEditMode] = React.useState(false);
    const [permissions, setPermissions] = useState([]);
    const getPermissions = useGetPermissions();
    const [userId, setUserId] = useState('');
    const setNewSurvey = useState(record)[1];
    const [tabIndex, setTabIndex] = useState(0);
    const [confirmNavigate, setConfirmNavigate] = useState(false);
    const [siteStatus] = React.useState(props.siteStatus);
    const [tempTabIndex, setTempTabIndex] = React.useState(0);

    useEffect(() => {
        async function getUser() {
            const user = await getUserInfo();
            if (user) setUserId(user.externalUserId);
        }
        getUser();
    }, []);

    useEffect(() => {
        getPermissions().then((permissions) => setPermissions(permissions));
    }, [getPermissions]);

    const handleTabChange = (event, newValue) => {
        if (isEditMode) {
            setTempTabIndex(newValue);
            setConfirmNavigate(true);
        } else {
            setTabIndex(newValue);
        }
    };

    const handleConfirmTabChange = () => {
        setIsEditMode(false);
        setConfirmNavigate(false);
        setTabIndex(tempTabIndex);
    };

    if (!record) return <LinearProgress />;
    const photoPaths = props.photoPaths ?? [record.photoPath];
    const siteTypeName = getSiteType(siteTypeId);
    const isRaftSensor = siteTypeId === 5;
    const indexAux = isRaftSensor ? 1 : 0;
    return (
        <Layout classes={classes.tabContainer}>
            <Tabs value={tabIndex} onChange={handleTabChange} className={classes.container}>
                <Tab label="Overview" index={0} />
                {!isRaftSensor && <Tab label={`${siteTypeName} Measurements`} index={1} />}
                <Tab label="OTHER INFO" index={2 - indexAux} />
                <Tab label="Uploaded Photos" index={3 - indexAux} />
                <Tab label="Comments" index={4 - indexAux} />
                <Tab label="History" index={5 - indexAux} />
            </Tabs>
            <TabPanel label="Overview" className={classes.tabPanel} value={tabIndex} index={0}>
                <Grid container spacing={4}>
                    <Grid item xs={6}>
                        <DisplayField label="Site Name" value={props.name} />
                        <DisplayField label="Name of Inspector" value={record?.inspectorName} />
                    </Grid>
                    <Grid item xs={6}>
                        <DisplayField
                            label="Address (Recorded at location of survey)"
                            value={record?.address}
                        />
                        <DisplayField
                            label="Date of Inspection"
                            value={getDateString(record?.inspectionDate)}
                        />
                    </Grid>
                </Grid>
            </TabPanel>

            {!isRaftSensor && (
                <TabPanel
                    label="Manhole Measurements"
                    className={classes.tabPanel}
                    value={tabIndex}
                    index={1}
                >
                    {isFetching && <LinearProgress />}
                    {!isFetching && (
                        <MeasurementSection
                            record={record}
                            permissions={permissions}
                            userId={userId}
                            setIsEditMode={setIsEditMode}
                            isEditMode={isEditMode}
                            setNewSurvey={setNewSurvey}
                            siteStatus={siteStatus}
                            siteTypeId={siteTypeId}
                        />
                    )}
                </TabPanel>
            )}

            <TabPanel
                label="OTHER INFO"
                className={classes.tabPanel}
                value={tabIndex}
                index={2 - indexAux}
            >
                {isFetching && <LinearProgress />}
                {!isFetching && (
                    <SurveyOtherInfo
                        record={record}
                        permissions={permissions}
                        userId={userId}
                        setIsEditMode={setIsEditMode}
                        isEditMode={isEditMode}
                        setNewSurvey={setNewSurvey}
                        siteStatus={siteStatus}
                        siteTypeId={siteTypeId}
                    />
                )}
            </TabPanel>

            <TabPanel
                label="Uploaded Photos"
                className={classes.tabPanel}
                value={tabIndex}
                index={3 - indexAux}
            >
                {photoPaths && (
                    <SurveyAttachments paths={photoPaths} siteStatus={siteStatus} record={record} />
                )}
            </TabPanel>
            <TabPanel
                label="Comments"
                className={classes.tabPanel}
                value={tabIndex}
                index={4 - indexAux}
            >
                <SurveyComments siteId={record.siteId} />
            </TabPanel>
            <TabPanel
                label="History"
                className={classes.tabPanel}
                value={tabIndex}
                index={5 - indexAux}
            >
                <DeviceHistory siteId={record.siteId} />
            </TabPanel>
            <Confirm
                isOpen={confirmNavigate}
                title="Navigate Tab"
                content="All unsaved changes will be lost when navigating to a different tab."
                onConfirm={() => handleConfirmTabChange()}
                onClose={() => setConfirmNavigate(false)}
            />
        </Layout>
    );
};

export default SurveySection;
