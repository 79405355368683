import React from 'react';
import { required, minLength, maxLength, Confirm } from 'react-admin';
import { makeStyles } from '@mui/styles';
import DisplayField from '../../components/DisplayFiled';
import { Grid, Button, TextField, Box, Select, MenuItem } from '@mui/material';
import ROOT from '../../theme/root';
import { validateText } from '../../utils/validation';
import GROUPS from '../../auth/groups';
import { updateSurveyData } from './EditSurvey';
import Loading from '../../components/Loading';
import { getTankMaterial } from '../../surveys';

// TODO: Create a gap in the border to match the design. This method is not working very will >>

const useStyles = makeStyles(() => ({
    label: {
        color: '#636364',
        fontSize: '12px',
        letterSpacing: '-0.34px',
        lineHeight: '19px',
    },
    buttonRight: {
        display: 'flex',
        float: 'right',
    },
    validation: {
        marginTop: '10px',
        fontSize: '11px',
        color: 'red',
    },
    gridColumn: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '10px',
    },
}));

const signalStrength = {
    0: 'Limited',
    1: 'Average',
    2: 'Good',
    3: 'Excellent',
    4: 'N/A',
    5: 'No Reading Provided',
    6: 'No Coverage',
};

const renderSignalStrength = (man11, man12) => {
    const response = signalStrength[`${man11}`] || 'Unknown';

    return (
        <span>
            {response}
            {man12 === 'true' && (
                <span style={{ fontSize: '14px', marginLeft: '4px', color: ROOT.COLORS.RED }}>
                    (Manual)
                </span>
            )}
        </span>
    );
};
const validText =
    (message = 'Invalid text') =>
    (value) => {
        if (value && value.length > 3) {
            if (!validateText(value)) {
                return message;
            } else {
                return '';
            }
        }
        return '';
    };

// Values per siteTypeId
const ValuesManhole = (props) => {
    const { man7, man8, man9, man10, man11, man12 } = props;

    return (
        <Grid container>
            <Grid item xs={6}>
                <DisplayField
                    label="Is it hinged?"
                    value={<span>{man7 === 'true' ? 'Yes' : 'No'}</span>}
                />
                <DisplayField label="Manufacturer (if available)" value={<span>{man9}</span>} />
                <DisplayField
                    label="Sigfox Signal Strength"
                    value={renderSignalStrength(man11, man12)}
                />
            </Grid>
            <Grid item xs={6} paddingLeft="32px">
                <DisplayField
                    label="Is the cover installed on a slope/angle or is it horizontal?"
                    value={<span>{man8 === 'true' ? 'Yes' : 'No'}</span>}
                />
                <DisplayField label="Material (if available)" value={<span>{man10}</span>} />
            </Grid>
        </Grid>
    );
};
const ValuesGroundwater = (props) => {
    const { man11, man12 } = props;

    return (
        <Grid container>
            <Grid item xs={6}>
                <DisplayField
                    label="Sigfox Signal Strength"
                    value={renderSignalStrength(man11, man12)}
                />
            </Grid>
            <Grid item xs={6} paddingLeft="32px"></Grid>
        </Grid>
    );
};
const ValuesWaterTank = (props) => {
    const { tank3 } = props;
    return (
        <Grid container>
            <Grid item xs={6}>
                <DisplayField
                    label="Tank material"
                    value={<span>{getTankMaterial(parseInt(tank3))}</span>}
                />
            </Grid>
            <Grid item xs={6} paddingLeft="32px"></Grid>
        </Grid>
    );
};

// Value edits per siteTypeId
const ValueEditsManhole = (props) => {
    const { man7, man8, man9, man10, man11, man12, setValue, comment, error, validation } = props;
    const classes = useStyles();
    return (
        <Grid container>
            <Grid item xs={6} className={classes.gridColumn}>
                <Box>
                    <span className={classes.label}>Is it hinged?</span>
                    <Select
                        id="MAN7"
                        name="MAN7"
                        labelId="MAN7"
                        variant="outlined"
                        value={man7}
                        onChange={(e) => {
                            setValue(e.target.name, e.target.value);
                        }}
                        style={{ width: '100%' }}
                        label="Manufacturer (if available)"
                    >
                        <MenuItem key="0" value="true">
                            Yes
                        </MenuItem>
                        <MenuItem key="0" value="false">
                            No
                        </MenuItem>
                    </Select>
                </Box>
                <Box>
                    <span className={classes.label}>Manufacturer (if available)</span>
                    <TextField
                        label=""
                        value={man9}
                        name={'MAN9'}
                        onChange={(e) => {
                            setValue(e.target.name, e.target.value);
                        }}
                        fullWidth
                        validate={[required(), minLength(2), maxLength(200), validText()]}
                        variant="outlined"
                        margin="0px"
                    />
                </Box>
                <Box>
                    <span className={classes.label}>Sigfox Signal Strength - value</span>
                    <Select
                        id="MAN11"
                        name="MAN11"
                        labelId="MAN11"
                        variant="outlined"
                        value={man11}
                        onChange={(e) => {
                            setValue(e.target.name, e.target.value);
                        }}
                        style={{ width: '100%' }}
                        label="Sigfox Signal Strength"
                    >
                        <MenuItem key="0" value="4">
                            N/A
                        </MenuItem>
                        <MenuItem key="0" value="6">
                            No Coverage
                        </MenuItem>
                        <MenuItem key="0" value="0">
                            Limited
                        </MenuItem>
                        <MenuItem key="0" value="1">
                            Average
                        </MenuItem>
                        <MenuItem key="0" value="2">
                            Good
                        </MenuItem>
                        <MenuItem key="0" value="3">
                            Excellent
                        </MenuItem>
                    </Select>
                </Box>
                <Box>
                    <Box>
                        <span className={classes.label}>
                            Sigfox Signal Strength - is it Manual?
                        </span>
                        <Select
                            id="MAN12"
                            name="MAN12"
                            labelId="MAN12"
                            variant="outlined"
                            value={man12}
                            onChange={(e) => {
                                setValue(e.target.name, e.target.value);
                            }}
                            style={{ width: '100%' }}
                            label="Manufacturer (if available)"
                        >
                            <MenuItem key="0" value="true">
                                Yes
                            </MenuItem>
                            <MenuItem key="0" value="false">
                                No
                            </MenuItem>
                        </Select>
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={6} paddingLeft="32px" className={classes.gridColumn}>
                <Box>
                    <span className={classes.label}>
                        Is the cover installed on a slope/angle or is it horizontal?
                    </span>
                    <Select
                        id="MAN8"
                        name="MAN8"
                        labelId="MAN8"
                        variant="outlined"
                        value={man8}
                        onChange={(e) => {
                            setValue(e.target.name, e.target.value);
                        }}
                        style={{ width: '100%' }}
                        label="Is the cover installed on a slope/angle or is it horizontal?"
                    >
                        <MenuItem key="0" value="true">
                            Yes
                        </MenuItem>
                        <MenuItem key="0" value="false">
                            No
                        </MenuItem>
                    </Select>
                </Box>
                <Box>
                    <span className={classes.label}>Material (if available)</span>
                    <TextField
                        label=""
                        value={man10}
                        name={'MAN10'}
                        onChange={(e) => {
                            setValue(e.target.name, e.target.value);
                        }}
                        fullWidth
                        variant="outlined"
                        margin="0px"
                    />
                </Box>
                <Box>
                    <span className={classes.label}>Comment</span>
                    <TextField
                        label=""
                        value={comment}
                        name="COMMENT"
                        variant="outlined"
                        onChange={(e) => {
                            setValue(e.target.name, e.target.value);
                        }}
                        error={error === true}
                        helperText={error === true && validation}
                        fullWidth={true}
                        margin="0px"
                    />
                </Box>
            </Grid>
        </Grid>
    );
};
const ValueEditsGroundwater = (props) => {
    const { man11, man12, setValue, comment, error, validation } = props;
    const classes = useStyles();
    return (
        <Grid container>
            <Grid item xs={6} className={classes.gridColumn}>
                <Box>
                    <span className={classes.label}>Sigfox Signal Strength - value</span>
                    <Select
                        id="MAN11"
                        name="MAN11"
                        labelId="MAN11"
                        variant="outlined"
                        value={man11}
                        onChange={(e) => {
                            setValue(e.target.name, e.target.value);
                        }}
                        style={{ width: '100%' }}
                        label="Sigfox Signal Strength"
                    >
                        <MenuItem key="0" value="4">
                            N/A
                        </MenuItem>
                        <MenuItem key="0" value="6">
                            No Coverage
                        </MenuItem>
                        <MenuItem key="0" value="0">
                            Limited
                        </MenuItem>
                        <MenuItem key="0" value="1">
                            Average
                        </MenuItem>
                        <MenuItem key="0" value="2">
                            Good
                        </MenuItem>
                        <MenuItem key="0" value="3">
                            Excellent
                        </MenuItem>
                    </Select>
                </Box>
                <Box>
                    <span className={classes.label}>Sigfox Signal Strength - is it Manual?</span>
                    <Select
                        id="MAN12"
                        name="MAN12"
                        labelId="MAN12"
                        variant="outlined"
                        value={man12}
                        onChange={(e) => {
                            setValue(e.target.name, e.target.value);
                        }}
                        style={{ width: '100%' }}
                        label="Manufacturer (if available)"
                    >
                        <MenuItem key="0" value="true">
                            Yes
                        </MenuItem>
                        <MenuItem key="0" value="false">
                            No
                        </MenuItem>
                    </Select>
                </Box>
            </Grid>
            <Grid item xs={6} paddingLeft="32px" className={classes.gridColumn}>
                <Box>
                    <span className={classes.label}>Comment</span>
                    <TextField
                        label=""
                        value={comment}
                        name="COMMENT"
                        variant="outlined"
                        onChange={(e) => {
                            setValue(e.target.name, e.target.value);
                        }}
                        error={error === true}
                        helperText={error === true && validation}
                        fullWidth={true}
                        margin="0px"
                    />
                </Box>
            </Grid>
        </Grid>
    );
};
const ValueEditsWaterTank = (props) => {
    const { tank3, setValue, comment, error, validation } = props;
    const classes = useStyles();
    return (
        <Grid container>
            <Grid item xs={6} className={classes.gridColumn}>
                <Box>
                    <span className={classes.label}>Tank material</span>
                    <Select
                        id="TANK3"
                        name="TANK3"
                        labelId="TANK3"
                        variant="outlined"
                        value={tank3}
                        onChange={(e) => {
                            setValue(e.target.name, e.target.value);
                        }}
                        style={{ width: '100%' }}
                        label=""
                    >
                        <MenuItem key="0" value="0">
                            Plastic
                        </MenuItem>
                        <MenuItem key="1" value="1">
                            Wood
                        </MenuItem>
                        <MenuItem key="2" value="2">
                            Concrete
                        </MenuItem>
                    </Select>
                </Box>
            </Grid>
            <Grid item xs={6} paddingLeft="32px" className={classes.gridColumn}>
                <Box>
                    <span className={classes.label}>Comment</span>
                    <TextField
                        label=""
                        value={comment}
                        name="COMMENT"
                        variant="outlined"
                        onChange={(e) => {
                            setValue(e.target.name, e.target.value);
                        }}
                        error={error === true}
                        helperText={error === true && validation}
                        fullWidth={true}
                        margin="0px"
                    />
                </Box>
            </Grid>
        </Grid>
    );
};

// Create survey answers per siteTypeId
const createSurveyAnswersManhole = (surveyAnswers, answerValues) => {
    const newAnswers = [...surveyAnswers];
    const { man7, man8, man9, man10, man11, man12 } = answerValues;

    for (const answer of newAnswers) {
        if (answer.surveyQuestionId === 'MAN7') answer.value = man7;
        if (answer.surveyQuestionId === 'MAN8') answer.value = man8;
        if (answer.surveyQuestionId === 'MAN9') answer.value = man9;
        if (answer.surveyQuestionId === 'MAN10') answer.value = man10;
        if (answer.surveyQuestionId === 'MAN11') answer.value = man11;
        if (answer.surveyQuestionId === 'MAN12') answer.value = man12;
    }
    return newAnswers;
};
const createSurveyAnswersGroundwater = (surveyAnswers, answerValues) => {
    const newAnswers = [...surveyAnswers];
    const { man11, man12 } = answerValues;

    for (const answer of newAnswers) {
        if (answer.surveyQuestionId === 'MAN11') answer.value = man11;
        if (answer.surveyQuestionId === 'MAN12') answer.value = man12;
    }
    return newAnswers;
};
const createSurveyAnswersWatertank = (surveyAnswers, answerValues) => {
    const newAnswers = [...surveyAnswers];
    const { tank3 } = answerValues;

    for (const answer of newAnswers) {
        if (answer.surveyQuestionId === 'TANK3') answer.value = tank3;
    }
    return newAnswers;
};

const siteTypes = {
    1: {
        ValueEditsComponent: ValueEditsManhole,
        ValuesComponent: ValuesManhole,
        createSurveyAnswers: createSurveyAnswersManhole,
    },
    2: {
        ValueEditsComponent: ValueEditsGroundwater,
        ValuesComponent: ValuesGroundwater,
        createSurveyAnswers: createSurveyAnswersGroundwater,
    },
    3: {
        ValueEditsComponent: ValueEditsWaterTank,
        ValuesComponent: ValuesWaterTank,
        createSurveyAnswers: createSurveyAnswersWatertank,
    },
    4: {
        ValueEditsComponent: ValueEditsGroundwater,
        ValuesComponent: ValuesGroundwater,
        createSurveyAnswers: createSurveyAnswersGroundwater,
    },
};

const getSurveyAnswers = (siteTypeId, man7, man8, man9, man10, man11, man12, tank3) => {
    const intTank3 = parseInt(tank3);

    // Answers per siteTypeId
    const answers = {
        1: {
            man7: man7 || '',
            man8: man8 || '',
            man9: man9 || '',
            man10: man10 || '',
            man11: man11 || '',
            man12: man12 || '',
        },
        2: { man11: man11 || '', man12: man12 || '' },
        3: { tank3: intTank3 },
        4: { man11: man11 || '', man12: man12 || '' },
    };

    if (!answers[`${siteTypeId}`]) return null;

    return answers[`${siteTypeId}`];
};

const renderAnswer = (record, id) => {
    if (record) {
        const answers = record.surveyAnswers;
        let value;
        for (const answer of answers) {
            if (answer.surveyQuestionId === id) {
                value = answer.value ? answer.value : '';
            }
        }
        return value;
    }
};

const SurveyOtherInfo = (props) => {
    const { record, siteStatus, userId, isEditMode, permissions, siteTypeId, setIsEditMode } =
        props;
    const [comment, setComment] = React.useState('');
    const [validation, setValidation] = React.useState('');
    const [error, setError] = React.useState(false);
    const [confirmOpen, setConfirmOpen] = React.useState(false);
    const [newSurvey, setNewSurvey] = React.useState(record);
    const [loading, setLoading] = React.useState(false);

    const [man7, setMan7] = React.useState(renderAnswer(record, 'MAN7'));
    const [man8, setMan8] = React.useState(renderAnswer(record, 'MAN8'));
    const [man9, setMan9] = React.useState(renderAnswer(record, 'MAN9'));
    const [man10, setMan10] = React.useState(renderAnswer(record, 'MAN10'));
    const [man11, setMan11] = React.useState(renderAnswer(record, 'MAN11'));
    const [man12, setMan12] = React.useState(renderAnswer(record, 'MAN12'));
    const [tank3, setTank3] = React.useState(renderAnswer(record, 'TANK3'));

    const isValidStatus = siteStatus == 1 || siteStatus == 2 || siteStatus == 4;
    const displayEditButton =
        !isEditMode && permissions.includes(GROUPS.HSW_ADMIN) && isValidStatus;

    const { ValueEditsComponent, ValuesComponent, createSurveyAnswers } =
        siteTypes[`${siteTypeId}`];
    const surveyAnswers = getSurveyAnswers(
        siteTypeId,
        man7,
        man8,
        man9,
        man10,
        man11,
        man12,
        tank3,
    );

    React.useEffect(() => {
        if (record) {
            setMan7(renderAnswer(record, 'MAN7'));
            setMan8(renderAnswer(record, 'MAN8'));
            setMan9(renderAnswer(record, 'MAN9'));
            setMan10(renderAnswer(record, 'MAN10'));
            setMan11(renderAnswer(record, 'MAN11'));
            setMan12(renderAnswer(record, 'MAN12'));
            setTank3(renderAnswer(record, 'TANK3'));
            setNewSurvey(record);
        }
    }, [record]);

    const setValue = (q, value) => {
        if (q === 'MAN7') setMan7(value);
        if (q === 'MAN8') setMan8(value);
        if (q === 'MAN9') setMan9(value);
        if (q === 'MAN10') setMan10(value);
        if (q === 'MAN11') setMan11(value);
        if (q === 'MAN12') setMan12(value);
        if (q === 'TANK3') setTank3(value);
        if (q === 'COMMENT') {
            setComment(value);
        }
    };

    React.useEffect(() => {
        if (!comment?.length) {
            setValidation('comments are required');
            setError(true);
            return false;
        } else {
            setValidation('');
            setError(false);
            return true;
        }
    }, [comment]);

    const handleUpdate = async () => {
        const updatedSurvey = newSurvey;
        updatedSurvey.surveyAnswers = createSurveyAnswers(newSurvey.surveyAnswers, surveyAnswers);
        updatedSurvey.comment = comment;
        updatedSurvey.userId = userId;
        setNewSurvey(updatedSurvey);

        setLoading(true);
        await updateSurveyData(updatedSurvey);
        setLoading(false);
        handleCancel();
        setConfirmOpen(false);
    };

    const handleCancel = () => {
        setIsEditMode(false);
        setMan7(renderAnswer(record, 'MAN7'));
        setMan8(renderAnswer(record, 'MAN8'));
        setMan9(renderAnswer(record, 'MAN9'));
        setMan10(renderAnswer(record, 'MAN10'));
        setMan11(renderAnswer(record, 'MAN11'));
        setMan12(renderAnswer(record, 'MAN12'));
        setTank3(renderAnswer(record, 'TANK3'));
        setComment('');
    };

    if (loading) return <Loading />;

    return (
        <Box display="flex" flexDirection="column" justifyContent="stretch">
            {displayEditButton && (
                <Box display="flex" flexDirection="row-reverse">
                    <Button onClick={() => setIsEditMode(true)} color="primary">
                        Edit
                    </Button>
                </Box>
            )}

            {!isEditMode && <ValuesComponent {...surveyAnswers} />}
            {isEditMode && permissions.includes(GROUPS.HSW_ADMIN) && (
                <ValueEditsComponent
                    setValue={setValue}
                    comment={comment}
                    error={error}
                    validation={validation}
                    {...surveyAnswers}
                />
            )}
            {isEditMode && permissions.includes(GROUPS.HSW_ADMIN) && (
                <Box alignSelf="flex-end">
                    <Button onClick={() => handleCancel()} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={() => setConfirmOpen(true)} color="primary" disabled={error}>
                        Save
                    </Button>
                </Box>
            )}

            <Confirm
                isOpen={confirmOpen}
                loading={false}
                title="Update Survey"
                content="Are you sure you want to update survey info?"
                onConfirm={() => handleUpdate()}
                onClose={() => setConfirmOpen(false)}
            />
        </Box>
    );
};

export default SurveyOtherInfo;
