import React from 'react';
import { useRecordContext } from 'react-admin';
import ROOT from '../../theme/root';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    container: {
        borderRadius: ROOT.BORDERS.RADIUS,
        width: '122px',
        minWidth: '70px',
        maxWidth: '132px',
        height: '26px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0px 8px',
        fontWeight: 'bold',
    },
}));

const styles = {
    pendingReview: {
        color: '#363637',
        backgroundColor: '#D8D8D8',
    },
    approved: {
        color: ROOT.COLORS.PRIMARY,
        backgroundColor: ROOT.COLORS.PRIMARY_LIGHT,
    },
    pendingSurvey: {
        color: '#414564',
        backgroundColor: '#E1E3EE',
    },
    pendingInstall: {
        color: ROOT.COLORS.GREEN,
        backgroundColor: ROOT.COLORS.GREEN_LIGHT,
    },
    rejected: {
        color: ROOT.COLORS.RED,
        backgroundColor: ROOT.COLORS.RED_LIGHT,
    },
    catchAll: {
        color: '#363637',
        backgroundColor: '#D8D8D8',
    },
};

const statuses = [
    { id: 0, name: 'Pending Survey', style: styles.pendingSurvey },
    { id: 1, name: 'Survey Submitted', style: styles.pendingReview },
    { id: 2, name: 'Approved', style: styles.approved },
    { id: 3, name: 'Rejected', style: styles.rejected },
    { id: 4, name: 'Installed', style: styles.pendingInstall },
    { id: 5, name: 'Deleted', style: styles.catchAll },
];

const SiteStatusField = (props) => {
    const classes = useStyles();
    const { source } = props;
    const record = useRecordContext(props);
    if (!record) return null;
    const status = statuses.find((x) => x.id === record[`${source}`]);
    return (
        <div className={classes.container} style={status.style}>
            {status.name}
        </div>
    );
};

export default SiteStatusField;
