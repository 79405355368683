import React, { useState, useEffect, useRef } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';
import Exporting from 'highcharts/modules/exporting';
import ExportingData from 'highcharts/modules/export-data';
import Boost from 'highcharts/modules/boost';
import Error from '../../components/Error';
import { formatLocalTimezone, formatXaxisLabel } from '../utils';
import ROOT from '../../theme/root';

Exporting(Highcharts);
ExportingData(Highcharts);
Boost(Highcharts);

const colors = [ROOT.COLORS.PRIMARY, '#2FBD68', '#FFA30D', '#555555', '#F84008'];

const defaultOptions = {
    title: {
        text: 'Line Chart',
        align: 'left',
        style: {
            fontWeight: 600,
        },
    },
    subtitle: {
        verticalAlign: 'bottom',
        useHTML: true,
        y: 20,
        text: '<b>From:</b>    ---    <b>To: </b>',
    },
    chart: {
        type: 'line',
        zoomType: 'x',
        panKey: 'shift',
        panning: true,
        events: {
            render: function () {
                updateSubtitle.apply(this);
            },
        },
        style: {
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontWeight: 500,
        },
        animation: false,
    },
    plotOptions: {
        line: {
            marker: {
                enabled: true,
            },
        },
    },
    accessibility: {
        enabled: false,
    },
    colors: colors,
    legend: {
        enabled: true,
        layout: 'horizontal',
        verticalAlign: 'top',
    },
    tooltip: {
        valueSuffix: ' mm',
        split: true,
    },
    navigator: {
        height: 22,
        buttonOptions: {
            enabled: true,
        },
        stickToMax: true,
        xAxis: {
            type: 'datetime',
            ordinal: false,
            gridLineWidth: 1,
            labels: {
                formatter: function () {
                    return formatXaxisLabel(new Date(this.value));
                },
            },
        },
    },
    rangeSelector: {
        inputEnabled: false,
        buttons: [
            {
                type: 'all',
                text: 'All',
                title: 'View all',
            },
        ],
    },
    yAxis: [
        {
            title: {
                text: 'mm',
            },
            opposite: false,
            min: 0,
            lineWidth: 1,
            labels: {
                format: '{value:.0f}',
            },
            tickAmount: 10,
        },
    ],
    xAxis: [
        {
            type: 'datetime',
            ordinal: false,
            gridLineWidth: 1,
            labels: {
                formatter: function () {
                    return formatXaxisLabel(new Date(this.value));
                },
            },
        },
    ],
    exporting: {
        enabled: true,
        menuItemDefinitions: {
            viewFullscreen: {
                text: 'Fullscreen',
            },
        },
        buttons: {
            contextButton: {
                menuItems: [
                    'viewFullscreen',
                    'separator',
                    {
                        text: 'Download CSV',
                        onclick() {
                            this.downloadCSV();
                        },
                    },
                ],
            },
        },
    },
    credits: {
        enabled: false,
    },
    time: {
        useUTC: false,
    },
    noData: {
        style: {
            color: 'rgba(0,0,0,0.37)',
            fontSize: '16px',
        },
    },
    boost: {
        enabled: true,
    },
};

function updateSubtitle() {
    const minExtreme = this.xAxis[0].getExtremes().userMin || this.xAxis[0].getExtremes().min;
    const maxExtreme = this.xAxis[0].getExtremes().userMax || this.xAxis[0].getExtremes().max;
    let text = '';
    if (minExtreme > 0 && maxExtreme > 0) {
        text =
            '<b>From:</b> ' +
            formatLocalTimezone(new Date(minExtreme), true) +
            '   ---   ' +
            ' <b>To: </b>' +
            formatLocalTimezone(new Date(maxExtreme), true);
    }
    this.setSubtitle({
        text,
    });
}

const LineChart = (props) => {
    const { chartOptions, series, loading, error, min, max } = props;
    const chartComponent = useRef(null);
    const [options, setOptions] = useState({
        ...defaultOptions,
        ...chartOptions,
    });

    useEffect(() => {
        if (series && series.length > 0) {
            setOptions({
                series,
            });
        }
    }, [series]);

    useEffect(() => {
        if (!chartComponent.current || !chartComponent.current.chart || !min || !max) return;
        const chart = chartComponent.current.chart;
        chart.xAxis[0].setExtremes(min.getTime(), max.getTime());
    }, [min, max]);

    useEffect(() => {
        if (!chartComponent.current || !chartComponent.current.chart) return;
        const chart = chartComponent.current.chart;

        if (loading) {
            chart.hideNoData();
            chart.showLoading();
        } else {
            chart.hideLoading();
        }
    }, [loading]);

    return (
        <>
            {error && <Error message={`Chart cannot be loaded. Error details: ${error}.`} />}
            {!error && (
                <HighchartsReact
                    highcharts={Highcharts}
                    options={options}
                    constructorType={'stockChart'}
                    ref={chartComponent}
                    containerProps={{ style: { width: '100%' } }}
                />
            )}
        </>
    );
};

export default React.memo(LineChart);
