import { format } from 'date-fns';

export const formatLocalTimezone = (date, withSeconds) => {
    if (date) {
        return format(date, 'd MMM yy, HH:mm' + (withSeconds ? ':ss' : ''));
    }
};

export const formatXaxisLabel = (date) => {
    const time = formatLocalTimezone(date).split(',');
    return `${time[0]} <br />${time[1]}`;
};
