import React, { useCallback, useState } from 'react';
import { Box, Divider, Fab } from '@mui/material';
import { makeStyles } from '@mui/styles';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { addHours } from 'date-fns';
import Controls from '../components/Controls';
import { Battery, DistanceAmplitude, Humidity, Level, Tampering, Temperature } from './charts';
import LinkQualityIndicator from '../charts/LinkQualityIndicator';
import {
    LevelDataContextProvider,
    MiscDataContextProvider,
    TamperingDataContextProvider,
    ReadingsDataContextProvider,
} from './dataproviders';
import { LqiDataContextProvider } from '../dataproviders/LqiDataContext';
import ScrollTop from '../components/ScrollTop';
import ReadingsSummary from './components/ReadingsSummary';

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        gap: '30px',
    },
    splitContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'stretch',
        gap: '20px',
    },
    splitItem: {
        minHeight: '400px',
        flexBasis: '50%',
    },
    fullRow: {
        minHeight: '400px',
    },
}));

const DevicesDataMH = ({ deviceId, createdAt }) => {
    const classes = useStyles();
    const [from, setFrom] = useState(addHours(new Date(), -24));
    const [to, setTo] = useState(new Date());

    const updateDates = useCallback(
        (fromDate, toDate) => {
            if (fromDate) setFrom(fromDate);
            if (toDate) setTo(toDate);
        },
        [setFrom, setTo],
    );

    return (
        <>
            <Box className={classes.container}>
                <Controls updateDates={updateDates} initialFrom={from} initialTo={to} />
                <Divider />

                <LevelDataContextProvider deviceId={deviceId} from={from} to={to}>
                    <Box className={classes.fullRow}>
                        <Level />
                    </Box>
                    <Divider />
                    <Box className={classes.fullRow}>
                        <DistanceAmplitude />
                    </Box>
                </LevelDataContextProvider>

                <Divider />

                <MiscDataContextProvider deviceId={deviceId} from={from} to={to}>
                    <Box className={classes.splitContainer}>
                        <Box className={classes.splitItem}>
                            <Humidity />
                        </Box>
                        <Divider orientation="vertical" flexItem={true} />
                        <Box className={classes.splitItem}>
                            <Temperature />
                        </Box>
                    </Box>
                    <Divider />
                    <Box className={classes.splitContainer}>
                        <Box className={classes.splitItem}>
                            <Battery />
                        </Box>
                        <Divider orientation="vertical" flexItem={true} />
                        <Box className={classes.splitItem}>
                            <LqiDataContextProvider
                                deviceId={deviceId}
                                from={from}
                                to={to}
                                createdAt={createdAt}
                            >
                                <LinkQualityIndicator />
                            </LqiDataContextProvider>
                        </Box>
                    </Box>
                </MiscDataContextProvider>

                <Divider />

                <Box className={classes.splitContainer}>
                    <Box className={classes.splitItem}>
                        <ReadingsDataContextProvider deviceId={deviceId} from={from} to={to}>
                            <ReadingsSummary />
                        </ReadingsDataContextProvider>
                    </Box>
                    <Divider orientation="vertical" flexItem={true} />
                    <Box className={classes.splitItem}>
                        <TamperingDataContextProvider deviceId={deviceId} from={from} to={to}>
                            <Tampering />
                        </TamperingDataContextProvider>
                    </Box>
                </Box>
            </Box>
            <ScrollTop>
                <Fab color="secondary" size="small" aria-label="scroll back to top">
                    <KeyboardArrowUpIcon />
                </Fab>
            </ScrollTop>
        </>
    );
};

export default DevicesDataMH;
