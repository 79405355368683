import * as React from 'react';
import {
    Form,
    TextInput,
    SaveButton,
    required,
    minLength,
    maxLength,
    useRefresh,
    useNotify,
    useRedirect,
    Confirm,
    useDelete,
    usePermissions,
    EditContextProvider,
    useGetRecordId,
    useGetList,
    useDataProvider,
    SaveContextProvider,
} from 'react-admin';
import { Paper, Box, Typography, LinearProgress, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useAppContext } from '../context/AppContext';
import Layout from '../components/Layout';
import DeleteIcon from '@mui/icons-material/Delete';
import GROUPS from '../auth/groups';
import { validateText } from '../utils/validation';

const useStyles = makeStyles(() => ({
    createForm: {
        marginRight: '20px',
        padding: '15px',
        width: '50%',
    },
}));

const OrganisationEdit = () => {
    const classes = useStyles();
    // eslint-disable-next-line no-unused-vars
    const [appStore, setAppStore] = useAppContext();
    const [open, setOpen] = React.useState(false);
    const [saving, setSaving] = React.useState(false);
    const [organisation, setOrganisation] = React.useState();
    const refresh = useRefresh();
    const notify = useNotify();
    const redirect = useRedirect();
    const [deleteOne, { isLoading: loading }] = useDelete();
    const { permissions } = usePermissions();
    const dataProvider = useDataProvider();
    const orgIdPath = useGetRecordId();

    const {
        data: organisations,
        isLoading,
        isFetching,
        refetch,
        error,
    } = useGetList(
        'organisations',
        { pagination: { page: 1, perPage: 1000 } },
        { enabled: !!orgIdPath },
    );

    React.useEffect(() => {
        if (!organisations) {
            return;
        }
        const org = organisations.find((o) => o.id === orgIdPath);
        setOrganisation(org);
    }, [organisations, setOrganisation, orgIdPath]);

    if (!!permissions && !permissions.includes(GROUPS.HSW_ADMIN)) {
        redirect('/');
    }
    const canDelete = !!permissions && permissions.includes(GROUPS.HSW_ADMIN);

    if (!organisation) return <LinearProgress />;

    const handleAcknowledge = async (table, id, previous) => {
        try {
            await deleteOne(table, { id, previousData: previous });
            setTimeout(() => {
                notify('Customer removed.', { type: 'success' });
                redirect('list', 'organisations');
                refresh(true);
            }, 600);
            setAppStore({ ...appStore, organisationId: '-' });
        } catch (err) {
            notify('Customer could not be deleted.', { type: 'warning' });
        }
        setOpen(false);
    };

    const saveChanges = async (props) => {
        setSaving(true);

        await dataProvider.update('organisations', {
            id: organisation.id,
            data: {
                ...props,
            },
        });
        setSaving(false);
        redirect('list', 'organisations');
        notify('Organisation saved');
    };

    const overrides = {
        organisations: 'Customers',
    };
    overrides[organisation.id] = organisation.name;

    const validText =
        (message = 'Invalid text') =>
        (value) => {
            if (value && value.length > 3) {
                if (!validateText(value)) {
                    return message;
                } else {
                    return '';
                }
            }
            return '';
        };

    return (
        <EditContextProvider
            value={{
                error,
                defaultTitle: 'Customer',
                isFetching,
                isLoading,
                record: organisation,
                refetch,
                redirect: 'list',
                resource: 'organisations',
            }}
        >
            <SaveContextProvider value={{ save: saveChanges, saving, mutationMode: 'optimistic' }}>
                <Layout breadcrumb overrides={overrides} title="Edit Customer">
                    <Form>
                        <Box display="flex" flexDirection="column">
                            <Box display="flex">
                                <Paper className={classes.createForm} elevation={0}>
                                    <Typography variant="h6" gutterBottom>
                                        Customer Details
                                    </Typography>
                                    <TextInput
                                        source="name"
                                        label="Customer Name"
                                        variant="outlined"
                                        fullWidth
                                        validate={[
                                            required(),
                                            minLength(2),
                                            maxLength(200),
                                            validText(),
                                        ]}
                                    />
                                    <TextInput
                                        source="accountNo"
                                        label="Account Number"
                                        variant="outlined"
                                        fullWidth
                                        validate={[
                                            required(),
                                            minLength(2),
                                            maxLength(100),
                                            validText(),
                                        ]}
                                    />
                                    <TextInput
                                        source="address.line1"
                                        label="Address"
                                        variant="outlined"
                                        fullWidth
                                        validate={[
                                            required(),
                                            minLength(2),
                                            maxLength(200),
                                            validText(),
                                        ]}
                                    />
                                    <TextInput
                                        source="address.line2"
                                        label="Address Line 2"
                                        variant="outlined"
                                        validate={[validText()]}
                                        fullWidth
                                    />
                                    <TextInput
                                        source="address.city"
                                        label="City"
                                        variant="outlined"
                                        fullWidth
                                        validate={[
                                            required(),
                                            minLength(2),
                                            maxLength(200),
                                            validText(),
                                        ]}
                                    />
                                    <TextInput
                                        source="address.country"
                                        label="Country"
                                        variant="outlined"
                                        fullWidth
                                        validate={[
                                            required(),
                                            minLength(2),
                                            maxLength(200),
                                            validText(),
                                        ]}
                                    />
                                </Paper>
                            </Box>
                            <Box display="flex" width="100%" flexDirection="column">
                                <Box
                                    display="flex"
                                    width="50%"
                                    justifyContent="space-between"
                                    mt="20px"
                                >
                                    <SaveButton saving={saving} disabled={saving} />
                                    {canDelete && (
                                        <Button
                                            onClick={() => setOpen(true)}
                                            startIcon={<DeleteIcon />}
                                            color="secondary"
                                            style={{ color: '#f44336' }}
                                        >
                                            Delete
                                        </Button>
                                    )}

                                    <Confirm
                                        isOpen={open}
                                        loading={loading}
                                        title="Delete customer"
                                        content="Are you sure you want to delete this customer? This will delete all users associated with this customer aswell."
                                        onConfirm={() =>
                                            handleAcknowledge(
                                                'organisations',
                                                organisation.id,
                                                organisation,
                                            )
                                        }
                                        onClose={() => setOpen(false)}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Form>
                </Layout>
            </SaveContextProvider>
        </EditContextProvider>
    );
};

export default OrganisationEdit;
