const waterLevelDuplicateValidation = (levels) => {
    let isValid = true;
    let error = '';
    if (levels.length > 1) {
        let allLevels = [];

        // conditions are either in level.conditions or level is condition
        let conditions = levels
            .map((p) => {
                return p.conditions ?? [];
            })
            .reduce((a, b) => {
                return a.concat(b);
            }, []);
        conditions = conditions.concat(levels.filter((l) => !l.conditions));

        for (var i = 0; i < conditions.length; i++) {
            // eslint-disable-next-line security/detect-object-injection
            var level = conditions[i];

            if (level.value > 2 && allLevels.length > 0) {
                const excists = allLevels.find(
                    // eslint-disable-next-line security/detect-object-injection
                    (l) =>
                        l.value === level.value &&
                        l.valueUnit === level.valueUnit &&
                        l.operator === level.operator,
                );
                if (!excists) {
                    // eslint-disable-next-line security/detect-object-injection
                    allLevels.push(level);
                } else {
                    isValid = false;
                    error += 'The water threshold already exists. ';
                }
            } else {
                // eslint-disable-next-line security/detect-object-injection
                allLevels.push(level);
            }
        }
    }
    return [isValid, error];
};

export { waterLevelDuplicateValidation };
