import React from 'react';
import { LinearProgress } from '@mui/material';
import Layout from './Layout';

const Loading = () => {
    return (
        <Layout>
            <LinearProgress />
        </Layout>
    );
};

export default Loading;
