import GROUPS from '../auth/groups';
import jwtDecode from 'jwt-decode';
import {
    AUTH0_TOKEN_CONTEXT,
    AZURE_AD_TOKEN_CONTEXT,
    getUserInfo,
    getAccessToken,
} from '../auth/auth0Functions';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from '../auth/azureADConfig';

const auth0_audience = process.env.REACT_APP_AUTH0_AUDIENCE;
const auth0_clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
const auth0_domain = process.env.REACT_APP_AUTH0_DOMAIN;
const auth0_connection = process.env.REACT_APP_AUTH0_CONNECTION;
const password_valid_days = process.env.REACT_APP_PASSWORD_EXPIRY_DAYS;

const login = async ({ username, password }) => {
    try {
        return await loginAuth0(username, password);
    } catch (err) {
        return Promise.reject(err ?? 'Error while signing in. Please try again.');
    }
};

const getPermissions = async () => {
    try {
        const userInfo = await getUserInfo();
        return userInfo.roles ? Promise.resolve(userInfo.roles) : Promise.resolve('guest');
    } catch (error) {
        console.error(error);
        return Promise.resolve('guest');
    }
};

const checkAuth = async () => {
    try {
        const isAzureADToken = localStorage.getItem('hsw_isAzureAD')?.toLowerCase() === 'true';
        const isAuth = isAzureADToken ? await checkAuth_AzureAD() : await checkAuth_Auth0();
        if (isAuth) return Promise.resolve();
        else {
            return Promise.reject();
        }
    } catch (error) {
        console.log('error check');
    }
    return Promise.reject();
};

async function checkAuth_Auth0() {
    try {
        var access_token = (await getAccessToken())?.replace('Bearer ', '');
        if (!access_token) {
            throw 'unable to retrieve access token';
        }
        const decodedToken = access_token ? jwtDecode(access_token) : {};

        const roles = decodedToken[AUTH0_TOKEN_CONTEXT.ROLES];
        const expiry_unix = decodedToken[AUTH0_TOKEN_CONTEXT.EXPIRY];

        var expiry = new Date(expiry_unix * 1000);
        const now = new Date();

        let response = false;

        if (roles && roles.length > 0) {
            //check expiry
            if (expiry > now) {
                //check roles - these roles can access the portal
                if (
                    roles.includes(GROUPS.HSW_ADMIN) ||
                    roles.includes(GROUPS.HSW_USER) ||
                    roles.includes(GROUPS.PORTALACCESS) ||
                    roles.includes(GROUPS.CUSTOMER_ADMIN) ||
                    roles.includes(GROUPS.CUSTOMER_USER)
                ) {
                    return true;
                }
            }
        } else {
            return response;
        }
    } catch {
        return false;
    }
}

async function checkAuth_AzureAD() {
    try {
        var access_token = (await getAccessToken())?.replace('Bearer ', '');
        if (!access_token) {
            throw 'unable to retrieve access token';
        }
        const decodedToken = access_token ? jwtDecode(access_token) : {};
        const expiry_unix = decodedToken[AZURE_AD_TOKEN_CONTEXT.EXPIRY];

        var expiry = new Date(expiry_unix * 1000);
        const now = new Date();

        let response = false;

        const userInfo = await getUserInfo();
        if (userInfo?.roles && userInfo.roles.length > 0) {
            //check expiry
            if (expiry > now) {
                //check roles - these roles can access the portal
                if (
                    userInfo.roles.includes(GROUPS.HSW_ADMIN) ||
                    userInfo.roles.includes(GROUPS.HSW_USER) ||
                    userInfo.roles.includes(GROUPS.PORTALACCESS) ||
                    userInfo.roles.includes(GROUPS.CUSTOMER_ADMIN) ||
                    userInfo.roles.includes(GROUPS.CUSTOMER_USER)
                ) {
                    return true;
                }
            }
        } else {
            return response;
        }
    } catch {
        return false;
    }
}

async function loginAuth0(username, password) {
    const body = {
        grant_type: 'http://auth0.com/oauth/grant-type/password-realm',
        audience: auth0_audience,
        client_id: auth0_clientId,
        scope: 'openid profile email offline_access',
        username: username,
        password: password,
        realm: auth0_connection,
    };
    let options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    };
    const url = `${auth0_domain}/oauth/token`;

    const res = await fetch(url, options);
    if (res.status === 403) {
        throw new Error('Invalid username or password');
    }
    if (res.status === 429) {
        throw new Error(
            'You have exceeded the maximum number of login attempts. Please unblock your account and try again.',
        );
    }
    if (res.status !== 200) {
        throw new Error('Error while signing in. Please try again.');
    }
    const json = await res.json();

    if (res.ok && json['access_token']) {
        const access_token = json['access_token'];
        const refresh_token = json['refresh_token'];
        const decoded_token = jwtDecode(access_token);

        //check password expiry
        let last_password_reset = new Date('2024-04-18T12:00:00Z'); // Default to now to support cases when last_password_reset is not present AQUA-478
        if (decoded_token[AUTH0_TOKEN_CONTEXT.LAST_PASSWORD_RESET]) {
            last_password_reset = new Date(decoded_token[AUTH0_TOKEN_CONTEXT.LAST_PASSWORD_RESET]);
        }

        const now = new Date();
        const passwordExpiryDays = password_valid_days;
        if ((now - last_password_reset) / (1000 * 60 * 60 * 24) >= passwordExpiryDays) {
            throw new Error('password expired');
        }

        localStorage.setItem('hsw_auth', access_token);
        localStorage.setItem('hsw_token', refresh_token);
        localStorage.setItem('hsw_isAzureAD', false);
        return Promise.resolve(decoded_token);
    } else {
        throw new Error('Error while signing in. Please try again.');
    }
}

async function revoke_refreshToken(refresh_token) {
    const body = {
        client_id: auth0_clientId,
        token: refresh_token,
    };
    let options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    };
    const url = `${auth0_domain}/oauth/revoke`;

    try {
        await fetch(url, options);
    } catch (err) {
        console.log(err);
    }
}

async function logoutAzureAD() {
    const instance = new PublicClientApplication(msalConfig);
    const accounts = instance.getAllAccounts();
    const account = accounts.find((a) => a?.tenantId === process.env.REACT_APP_AZURE_AD_TENANT_ID);
    instance.logoutRedirect({
        account: account,
        postLogoutRedirectUri: process.env.REACT_APP_AZURE_AD_LOGOUT_URI,
    });
}

const logout = async () => {
    const isAzureADToken = localStorage.getItem('hsw_isAzureAD')?.toLowerCase() === 'true';

    localStorage.removeItem('hsw_auth');
    localStorage.removeItem('app');
    const refresh_token = localStorage.getItem('hsw_token');
    if (refresh_token && !isAzureADToken) {
        await revoke_refreshToken(refresh_token);
    }
    localStorage.removeItem('hsw_token');
    localStorage.removeItem('hsw_isAzureAD');
    sessionStorage.removeItem('hsw_user');

    if (isAzureADToken) {
        await logoutAzureAD();
    }
};

const authProvider = {
    login,
    checkError: () => Promise.resolve(),
    checkAuth,
    logout,
    getIdentity: () => Promise.resolve(),
    handleCallback: () => Promise.resolve(),
    getPermissions,
};

export default authProvider;
