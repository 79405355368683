import React, { useEffect, useState } from 'react';
import {
    SimpleShowLayout,
    EditButton,
    useShowController,
    usePermissions,
    useRedirect,
    TextField as TextFieldRa,
    ReferenceField,
} from 'react-admin';
import ProjectSites from '../organisations/Sites';
import Typography from '@mui/material/Typography';
import {
    Box,
    Card,
    ButtonGroup,
    Button,
    TextField,
    LinearProgress,
    Tab,
    Tabs,
} from '@mui/material';
import Breadcrumb from '../menu/Breadcrumb';
import { makeStyles } from '@mui/styles';
import Layout from '../components/Layout';
import Label from '../components/Label';
import TabPanel from '../components/TabPanel';
import ROOT from '../theme/root';
import AlarmSettings from '../alarms/AlarmSettings';
import GROUPS from '../auth/groups';
import { Features } from '../constant';
import { useFeature } from '../context/FeatureFlags';

const useStyles = makeStyles(() => ({
    panelContent: {
        padding: '16px 0px',
    },
    projectTitle: {
        fontSize: '32px',
        fontWeight: 'bold',
        lineHeight: '43px',
    },
    tabs: {
        '& [class*="MuiTabs-indicator"]': {
            backgroundColor: ROOT.COLORS.PRIMARY,
            top: 'unset',
            bottom: '0px',
            borderLeft: 'none',
            borderRight: 'none',
        },
        '& [class*="MuiTab-textColorInherit"][class*="Mui-selected"]': {
            backgroundColor: 'transparent',
            color: ROOT.COLORS.PRIMARY,
            borderLeft: 'none',
            borderRight: 'none',
        },
        '& [class*="MuiTabs-flexContainer"]': {
            borderBottom: ROOT.BORDERS.BORDER,
        },

        backgroundColor: 'transparent',
    },
    tabPanel: {
        minHeight: 'unset',
        maxHeight: '80vh',
        overflowX: 'hidden',
        overflowY: 'scroll',
        '-ms-overflow-style': 'none',
        'scrollbar-width': 'none',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },
}));

const ProjectTitle = ({ record }) => {
    return <span>Project {record ? `"${record.name}"` : ''}</span>;
};

const ProjectShowActions = ({ data: project }) => {
    const classes = useStyles();

    return (
        <Box mt={2}>
            <Typography variant="h5" gutterBottom className={classes.projectTitle}>
                <ProjectTitle record={project} />
            </Typography>
        </Box>
    );
};

const CardLayout = ({ record: project }) => {
    const { permissions } = usePermissions();
    const isHyndsAdmin = !!permissions && permissions.includes(GROUPS.HSW_ADMIN);
    const isCustomerAdminAnalytics =
        !!permissions &&
        permissions.includes(GROUPS.CUSTOMER_ADMIN) &&
        permissions.includes(GROUPS.ANALYTICS);
    const isAnalyticsAllowed = isHyndsAdmin || isCustomerAdminAnalytics;
    const canEdit = isHyndsAdmin || isCustomerAdminAnalytics;

    return (
        <Box marginBottom={5}>
            <Card>
                <Box display="flex" justifyContent="space-between">
                    <Typography variant="h6" gutterBottom>
                        Project Details
                    </Typography>
                    {canEdit && <EditButton record={project} icon={null} />}
                </Box>

                <Box display="flex" flexDirection="column" rowGap="20px">
                    <Box display="flex">
                        <Box width="50%">
                            <Label>Project Name</Label>
                            <TextField
                                id="project-name"
                                variant="outlined"
                                sx={{ width: '95%' }}
                                disabled
                                defaultValue={project.name}
                            />
                        </Box>
                        <Box>
                            <Label>Status</Label>
                            <ButtonGroup
                                color="primary"
                                aria-label="outlined primary button group"
                                size="large"
                                disabled
                                sx={{ marginTop: '8.5px', height: '45px' }}
                            >
                                <Button
                                    color={project.projectStatus === 0 ? 'primary' : 'inherit'}
                                    style={{
                                        color: project.projectStatus === 0 ? '#fff' : 'inherit',
                                    }}
                                >
                                    Active
                                </Button>
                                <Button
                                    color={project.projectStatus === 1 ? 'primary' : 'inherit'}
                                    style={{
                                        color: project.projectStatus === 1 ? '#fff' : 'inherit',
                                    }}
                                >
                                    Inactive
                                </Button>
                            </ButtonGroup>
                        </Box>
                    </Box>
                    {isAnalyticsAllowed && (
                        <Box display="flex">
                            <Box width="50%">
                                <Label>Rainfall Site</Label>
                                <Box
                                    sx={{
                                        backgroundColor: 'white',
                                        border: ROOT.BORDERS.BORDER,
                                        borderRadius: ROOT.BORDERS.RADIUS,
                                        color: ROOT.INPUTS.COLOR,
                                        padding: '0px 12px',
                                        height: ROOT.INPUTS.HEIGHT,
                                        display: 'flex',
                                        alignItems: 'center',
                                        marginBottom: '4px',
                                        marginTop: '8.5px',
                                        minWidth: '160px',
                                        width: '95%',
                                    }}
                                >
                                    <ReferenceField
                                        source="rainfallSiteId"
                                        reference="rainfallSites"
                                        link={(record, reference) =>
                                            `/${reference}/${record.rainfallSiteId}/show`
                                        }
                                        emptyText="Not selected"
                                    >
                                        <TextFieldRa source="name" id="rainfall-site-id" />
                                    </ReferenceField>
                                </Box>
                            </Box>
                            {isHyndsAdmin && (
                                <Box>
                                    <Label>Automatic Survey Approval</Label>
                                    <TextField
                                        id="survey-approval"
                                        variant="outlined"
                                        sx={{ width: '100%' }}
                                        disabled
                                        defaultValue={
                                            project.automaticSurveyApproval ? 'True' : 'False'
                                        }
                                    />
                                </Box>
                            )}
                        </Box>
                    )}
                </Box>
            </Card>
        </Box>
    );
};

const ShowProject = () => {
    const classes = useStyles();
    const controller = useShowController();
    const hasRules = useFeature(Features.rules);
    const [tabIndex, setTabIndex] = useState(0);

    const project = controller.record;
    const overrides = {};
    const redirect = useRedirect();
    const { permissions } = usePermissions();
    const isHyndsAdmin = !!permissions && permissions.includes(GROUPS.HSW_ADMIN);
    const isHyndsUser = !!permissions && permissions.includes(GROUPS.HSW_USER);
    const isCustomerAdmin = !!permissions && permissions.includes(GROUPS.CUSTOMER_ADMIN);

    useEffect(() => {
        if (!isHyndsAdmin && !isHyndsUser && !isCustomerAdmin) {
            redirect('/');
        }
    }, [redirect, isHyndsAdmin, isHyndsUser, isCustomerAdmin]);

    const handleChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    const setIndex = (index) => {
        setTabIndex(index);
        setTabIndex(1);
    };

    if (project && project.id) {
        overrides[project.id] = project.name;
    }

    const canSeeSettings = (permissions && permissions.includes('hsw_admin')) === true;

    if (!controller.record) {
        return (
            <Layout>
                <LinearProgress />
            </Layout>
        );
    }

    return (
        <Layout>
            <Breadcrumb overrides={overrides} />
            <ProjectShowActions data={controller.record} resource="projects" />
            <Tabs value={tabIndex} onChange={handleChange} className={classes.tabs}>
                <Tab label="Overview" classes={classes.tab} />
                {hasRules && canSeeSettings && <Tab label="Settings" classes={classes.tab} />}
            </Tabs>

            <TabPanel value={tabIndex} index={0} className={classes.tabPanel}>
                <SimpleShowLayout record={controller.record} className={classes.panelContent}>
                    <CardLayout record={controller.record} />
                    {project && <ProjectSites />}
                </SimpleShowLayout>
            </TabPanel>
            {hasRules && canSeeSettings && (
                <TabPanel value={tabIndex} index={1} className={classes.tabPanel}>
                    <AlarmSettings
                        thresholdUnits={['%', 'mm']}
                        hierarchy="project"
                        id={project.id}
                        reloadAlarms={setIndex}
                    />
                </TabPanel>
            )}
        </Layout>
    );
};

export default ShowProject;
