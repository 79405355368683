import ROOT from './root';

const raOverrides = {
    RaTabbedShowLayout: {
        styleOverrides: {
            root: {
                '& .RaTabbedShowLayout-content': {
                    backgroundColor: 'white',
                    border: ROOT.BORDERS.BORDER,
                    borderRadius: ROOT.BORDERS.RADIUS,
                    paddingTop: '16px',
                    paddingLeft: '24px',
                    paddingRight: '24px',
                    paddingBottom: '24px',
                },
            },
        },
    },
    RaLayout: {
        styleOverrides: {
            root: {
                '& .RaLayout-appFrame': {
                    marginTop: '0 !important',
                },
                '& .RaLayout-content': {
                    padding: '8px 24px 24px 5px',
                },
            },
        },
    },
    RaLabeled: {
        styleOverrides: {
            root: {
                '& .RaLabeled-label': {
                    color: ROOT.LABELS.COLOR,
                    fontSize: ROOT.LABELS.FONT_SIZE,
                    marginBottom: '4px',
                },
                '& .RaLabeled-label + span.MuiTypography-body2': {
                    backgroundColor: 'white',
                    border: ROOT.BORDERS.BORDER,
                    borderRadius: ROOT.BORDERS.RADIUS,
                    color: ROOT.INPUTS.COLOR,
                    padding: '0px 12px',
                    height: ROOT.INPUTS.HEIGHT,
                    display: 'flex',
                    alignItems: 'center',
                    width: 'auto',
                    marginBottom: '4px',
                    minWidth: '160px',
                },
            },
        },
    },
    RaListToolbar: {
        styleOverrides: {
            root: {
                '& .RaListToolbar-toolbar': {
                    alignItems: 'center',
                    marginBottom: '30px',
                },
            },
        },
    },
    RaList: {
        styleOverrides: {
            root: {
                '& .RaList-content': {
                    marginTop: '30px',
                    padding: '16px',
                    border: ROOT.BORDERS.BORDER,
                },
            },
        },
    },
    RaSidebar: {
        styleOverrides: {
            root: {
                '& .RaSidebar-fixed': {
                    position: 'relative',
                    overflowX: 'visible',
                    height: '100%',
                },
            },
        },
    },
    RaToolbar: {
        styleOverrides: {
            root: {
                '& .RaToolbar-toolbar': {
                    backgroundColor: 'transparent',
                },
                '&.RaToolbar-desktopToolbar': {
                    backgroundColor: 'transparent',
                },
            },
        },
    },
    RaPaginationActions: {
        styleOverrides: {
            root: {
                '& .RaPaginationActions-currentPageButton': {
                    backgroundColor: 'transparent',
                },
            },
        },
    },
    RaMenuItemLink: {
        styleOverrides: {
            root: {
                height: '55px',
                color: '#fff',
                textOverflow: 'ellipsis',
                '&:hover': {
                    backgroundColor: '#363637',
                },
                '& div': {
                    verticalAlign: 'text-bottom',
                    display: 'inline-block',
                },
                '&.RaMenuItemLink-active:not(.Mui-disabled)': {
                    borderRight: 'solid #00AEEF 4px',
                    color: '#fff',
                    backgroundColor: '#363637',
                },
            },
        },
    },
    RaSimpleShowLayout: {
        styleOverrides: {
            root: {
                '& .MuiTypography-root': {
                    marginBottom: '0px',
                },
            },
        },
    },
};

export default raOverrides;
