import React from 'react';
import { Box, Button, DialogTitle } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { DateRangePicker } from 'materialui-daterange-picker';
import { makeStyles } from '@mui/styles';
import { differenceInDays } from 'date-fns';
import { useNotify } from 'react-admin';

const useStyles = makeStyles(() => ({
    dialog: {
        minHeight: '55vh',
        maxHeight: '55vh',
        minWidth: '740px',
        maxWidth: '740px',
    },
}));

const DateRangeDialog = ({ initialRange, isOpen, onClose }) => {
    const limitInDays = 1095;
    const minDate = new Date(2021, 10, 1);
    const notify = useNotify();
    const classes = useStyles();
    const [open, setOpen] = React.useState(isOpen ?? false);
    const handleClose = (dates) => {
        setOpen(false);
        if (onClose) onClose(dates);
    };
    const onChangeHandler = (range) => {
        const result = differenceInDays(range.endDate, range.startDate);
        if (result > limitInDays) {
            notify(`Max limit is ${limitInDays} days.`, { type: 'warning' });
            return;
        }
        setDateRange(range);
    };
    const [dateRange, setDateRange] = React.useState(initialRange);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    React.useEffect(() => handleClose(dateRange), [dateRange]);
    React.useEffect(() => {
        setOpen(isOpen);
    }, [isOpen]);
    return (
        <Dialog
            open={open}
            onClose={() => handleClose(dateRange)}
            onBackdropClick={() => handleClose(dateRange)}
            aria-labelledby="date-range"
            maxWidth="sm"
            fullWidth
            classes={{ paper: classes.dialog }}
            scroll="paper"
        >
            <DialogTitle id="customer-selector">Select a date range</DialogTitle>
            <DialogContent>
                <DateRangePicker
                    initialDateRange={dateRange}
                    open={true}
                    onChange={onChangeHandler}
                    toggle={() => handleClose(dateRange)}
                    maxDate={new Date(new Date().setDate(new Date().getDate() + 31))}
                    minDate={minDate}
                />
            </DialogContent>
        </Dialog>
    );
};

const formatDateRange = (range) => {
    if (!range || !range.startDate || !range.endDate) return 'Today';
    if (range.label) return range.label;
    return `${range.startDate.toDateString()} - ${range.endDate.toDateString()}`;
};

const setTodayDate = (range) => {
    const start = new Date();
    start.setHours(0, 0, 0, 0);
    range.startDate = start;
    const end = new Date();
    end.setHours(23, 59, 59, 999);
    range.endDate = end;
    return range;
};

const setYesterdayDate = (range) => {
    const start = new Date(new Date().setDate(new Date().getDate() - 1));
    start.setHours(0, 0, 0, 0);
    range.startDate = start;
    const end = new Date(start);
    end.setHours(23, 59, 59, 999);
    range.endDate = end;
    return range;
};

const setMonthDate = (range) => {
    var date = new Date();
    var start = new Date(date.getFullYear(), date.getMonth(), 1);
    var end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    end.setHours(23, 59, 59, 999);
    range.startDate = start;
    range.endDate = end;
    return range;
};

const setEndTime = (range) => {
    range.endDate.setHours(23, 59, 59, 999);
    return range;
};

const DateRangeSummary = ({ range, onUpdate, formatter = formatDateRange }) => {
    const [openDatePicker, setOpenDatePicker] = React.useState(false);
    const [dateRange, setDateRange] = React.useState(
        range ?? {
            label: 'Last 30 days',
            startDate: new Date(new Date().setDate(new Date().getDate() - 30)),
            endDate: new Date(new Date().setDate(new Date().getDate() + 1)),
        },
    );
    React.useEffect(() => {
        if (onUpdate) onUpdate(dateRange);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateRange]);
    return (
        <Box position="absolute" right="50px" margin="auto" top="10px" height="40px" zIndex={1}>
            <DateRangeDialog
                initialRange={dateRange}
                isOpen={openDatePicker}
                onClose={(range) => {
                    if (range.label === 'Today') range = setTodayDate(range);
                    if (range.label === 'Yesterday') range = setYesterdayDate(range);
                    if (range.label === 'This Month') range = setMonthDate(range);
                    if (!range.label) range = setEndTime(range);
                    setDateRange(range);
                    setOpenDatePicker(false);
                }}
            />
            <Button
                onClick={() => setOpenDatePicker(true)}
                color="primary"
                style={{ maxWidth: '300px', backgroundColor: '#00AEEF', color: '#fff' }}
            >
                {formatter(dateRange)}
            </Button>
        </Box>
    );
};

export default DateRangeSummary;
