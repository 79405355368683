import React from 'react';
import SiteDataRaft from '../raft/SiteDataRaft';
import SiteDataMh from '../mh/SiteDataMh';
import { Box } from '@mui/material';

const SiteData = (props) => {
    const { record, hasSurvey, maxDepth, surveys } = props;
    if (!record) return null;

    const showRaftData = record.siteTypeId === 5 && (record.deviceId || record.status === 5);
    const showMhData =
        record.siteTypeId !== 5 && (record.deviceId || (record.status === 5 && hasSurvey));
    const showEmptyData = !showRaftData && !showMhData;

    return (
        <>
            {showRaftData && <SiteDataRaft id={record.id} siteName={record.name} site={record} />}
            {showMhData && (
                <SiteDataMh
                    id={record.id}
                    siteName={record.name}
                    site={record}
                    maxDepth={maxDepth}
                    surveys={surveys}
                />
            )}
            {showEmptyData && (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    margin="auto"
                    height="100px"
                >
                    <span>No Device Data</span>
                </Box>
            )}
        </>
    );
};

export default SiteData;
