import React from 'react';
import { Box, Button, List, ListItem, IconButton, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Label from '../components/Label';
import InputMask from 'react-input-mask';
import UnitInput from '../components/UnitInput';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import { validateWaterThreshold } from './waterLevelValidation';
import { waterLevelDuplicateValidation } from './waterLevelDuplicateValidation';
import { levelAlarmOperator } from './alarm';

const useStyles = makeStyles(() => ({
    delete: {
        marginTop: '15px',
    },
    item: {
        paddingLeft: 0,
        paddingRight: 0,
        marginBottom: '10px',
    },
    validation: {
        marginTop: '10px',
        fontSize: '11px',
        color: 'red',
    },
    alarmType: {
        fontSize: '0.8em',
        flexBasis: '45px',
        display: 'flex',
        alignItems: 'center',
        fontWeight: '500',
    },
}));

const createThreshold = (operator = levelAlarmOperator.gt) => {
    return {
        value: 1,
        valueUnit: '%',
        triggerEvents: 2,
        operator,
    };
};

const WaterLevelThresholds = ({
    thresholdUnits = ['%', 'mm'],
    thresholds,
    onSetThresholds,
    showMode,
    title = 'Water level',
    maxDepth,
}) => {
    const classes = useStyles();
    const [levels, setLevels] = React.useState([]);
    React.useEffect(() => {
        if (onSetThresholds) onSetThresholds(levels);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [levels]);
    React.useEffect(() => {
        if (
            thresholds &&
            Object.keys(thresholds).length > 0 &&
            JSON.stringify(thresholds) !== JSON.stringify(levels)
        ) {
            setLevels(thresholds);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [thresholds]);

    const handleDelete = (idx) => {
        if (idx === -1) return;
        const current = levels;
        current.splice(idx, 1);
        setLevels([...current]);
    };

    var [isNotDuplicate, duplicateValidation] = waterLevelDuplicateValidation(levels);

    return (
        <Box>
            <Label sub>{title}</Label>
            <List component="nav">
                {levels &&
                    levels.length > 0 &&
                    levels.map((level, index) => {
                        var [isValid, validation] = validateWaterThreshold(level, maxDepth);
                        const operator = level?.operator || 0;
                        if (
                            level.conditions &&
                            level.conditions.length > 0 &&
                            level.conditions[0].value === 999999999
                        ) {
                            //Ignore this fake rule
                            return (
                                <React.Fragment key={`lvl-${index}`}>
                                    <br />
                                </React.Fragment>
                            );
                        }
                        return (
                            <ListItem
                                role={undefined}
                                dense
                                key={`lvl-${index}`}
                                className={classes.item}
                            >
                                <Box display="flex" flexDirection="column" width="100%">
                                    <Box
                                        display="flex"
                                        width="100%"
                                        alignItems="stretch"
                                        justifyContent="space-between"
                                    >
                                        <Box
                                            paddingRight="4px"
                                            display="flex"
                                            flexDirection="column"
                                            marginBottom="4px"
                                            justifyContent="space-between"
                                        >
                                            <Label>Alarm type</Label>
                                            {operator === 0 && (
                                                <Box className={classes.alarmType}>
                                                    <span>Rising alarm</span>
                                                </Box>
                                            )}
                                            {operator === 2 && (
                                                <Box className={classes.alarmType}>
                                                    <span>Falling alarm</span>
                                                </Box>
                                            )}
                                        </Box>
                                        <Box width="170px" minWidth="170px">
                                            <Label>Value</Label>
                                            <InputMask
                                                mask={level.valueUnit === '%' ? '999' : '99999'}
                                                maskPlaceholder={null}
                                                value={level.value ? `${level.value}` : '1'}
                                                disabled={showMode === true}
                                                onChange={(evnt) => {
                                                    setLevels(
                                                        levels.map((level, idx) => {
                                                            if (idx !== index) return level;
                                                            return {
                                                                ...level,
                                                                value: +evnt.target.value,
                                                            };
                                                        }),
                                                    );
                                                }}
                                            >
                                                <UnitInput
                                                    units={thresholdUnits}
                                                    selectedUnit={level.valueUnit}
                                                    onChangeUnit={(unitValue) => {
                                                        setLevels(
                                                            levels.map((level, idx) => {
                                                                if (idx !== index) return level;
                                                                return {
                                                                    ...level,
                                                                    valueUnit: unitValue,
                                                                };
                                                            }),
                                                        );
                                                    }}
                                                />
                                            </InputMask>
                                        </Box>
                                        <Box width="170px">
                                            <Label>Trigger after</Label>
                                            <InputMask
                                                mask="99"
                                                maskPlaceholder={null}
                                                disabled={showMode}
                                                value={
                                                    level.triggerEvents
                                                        ? `${level.triggerEvents}`
                                                        : '2'
                                                }
                                                onChange={(evnt) => {
                                                    setLevels(
                                                        levels.map((level, idx) => {
                                                            if (idx !== index) return level;
                                                            return {
                                                                ...level,
                                                                triggerEvents: +evnt.target.value,
                                                            };
                                                        }),
                                                    );
                                                }}
                                            >
                                                <UnitInput units={['events']} />
                                            </InputMask>
                                        </Box>
                                        {!showMode && (
                                            <Box display="flex" alignItems="center" alignSelf="end">
                                                <Tooltip title="Remove threshold." arrow>
                                                    <IconButton
                                                        aria-label="delete"
                                                        onClick={() => handleDelete(index)}
                                                        className={classes.delete}
                                                        disabled={showMode}
                                                        sx={{
                                                            minHeight: '45px',
                                                            margin: '0px 0px 4px 0px',
                                                        }}
                                                    >
                                                        <CloseIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>
                                        )}
                                    </Box>
                                    {!isValid && !showMode && (
                                        <span className={classes.validation}>{validation}</span>
                                    )}
                                </Box>
                            </ListItem>
                        );
                    })}
            </List>
            {!isNotDuplicate && <span className={classes.validation}>{duplicateValidation}</span>}
            {!showMode && (
                <Box>
                    <Tooltip title="Add threshold for rising alarm" arrow>
                        <Button
                            color="primary"
                            startIcon={<AddIcon />}
                            onClick={() => {
                                const current = Array.isArray(levels)
                                    ? [...levels, createThreshold()]
                                    : [createThreshold()];
                                setLevels(current);
                            }}
                            disabled={levels.length > 9}
                        >
                            Add threshold for rising alarm
                        </Button>
                    </Tooltip>
                    <Tooltip title="Add threshold for falling alarm" arrow>
                        <Button
                            color="primary"
                            startIcon={<AddIcon />}
                            onClick={() => {
                                const current = Array.isArray(levels)
                                    ? [...levels, createThreshold(levelAlarmOperator.lt)]
                                    : [createThreshold(levelAlarmOperator.lt)];
                                setLevels(current);
                            }}
                            disabled={levels.length > 9}
                        >
                            Add threshold for falling alarm
                        </Button>
                    </Tooltip>
                </Box>
            )}
        </Box>
    );
};

export default WaterLevelThresholds;
