import React, { useEffect, useRef, useState } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';
import Exporting from 'highcharts/modules/exporting';
import ExportingData from 'highcharts/modules/export-data';
import NoDataDisplay from 'highcharts/modules/no-data-to-display';
import { formatLocalTimezone, formatXaxisLabel } from '../../utils';
import ChartError from '../../components/ChartError';
Exporting(Highcharts);
ExportingData(Highcharts);
NoDataDisplay(Highcharts);

const colors = ['#2FBD68', '#FFA30D', '#555555', '#F84008'];

const updateSubtitle = (chart) => {
    // Show min values from chart or default to min and max date picker
    const minExtreme = chart.xAxis[0].getExtremes().userMin || chart.xAxis[0].getExtremes().min;
    const maxExtreme = chart.xAxis[0].getExtremes().userMax || chart.xAxis[0].getExtremes().max;
    let text = '';
    if (minExtreme > 0 && maxExtreme > 0) {
        text =
            '<b>From:</b> ' +
            formatLocalTimezone(new Date(minExtreme), true) +
            '   ---   ' +
            ' <b>To: </b>' +
            formatLocalTimezone(new Date(maxExtreme), true);
    }
    chart.setSubtitle({
        text,
    });
};

export const defaultChartOptions = {
    title: {
        text: 'Line Chart',
        align: 'left',
        style: {
            fontWeight: 600,
        },
    },
    subtitle: {
        verticalAlign: 'bottom',
        align: 'center',
        useHTML: true,
        text: '',
    },
    chart: {
        type: 'line',
        zoomType: 'x',
        panKey: 'shift',
        panning: true,
        events: {
            render: function () {
                updateSubtitle(this);
            },
        },
        style: {
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontWeight: 500,
        },
        animation: false,
        spacingBottom: 10,
    },
    accessibility: {
        enabled: false,
    },
    colors: colors,
    legend: {
        enabled: true,
        verticalAlign: 'top',
        align: 'center',
        y: -3,
        floating: true,
    },
    navigator: {
        height: 22,
        buttonOptions: {
            enabled: true,
        },
        stickToMax: true,
        xAxis: {
            type: 'datetime',
            ordinal: false,
            gridLineWidth: 1,
            labels: {
                formatter: function () {
                    return formatXaxisLabel(new Date(this.value));
                },
            },
        },
    },
    rangeSelector: {
        inputEnabled: false,
        buttons: [
            {
                type: 'all',
                text: 'All',
                title: 'View all',
            },
        ],
        floating: true,
        y: 32,
        verticalAlign: 'bottom',
    },
    yAxis: [
        {
            title: {
                text: 'mm',
            },
            opposite: false,
            min: 0,
            lineWidth: 1,
            labels: {
                format: '{value:.0f}',
            },
            tickAmount: 10,
        },
    ],
    xAxis: [
        {
            type: 'datetime',
            ordinal: false,
            gridLineWidth: 1,
            labels: {
                formatter: function () {
                    return formatXaxisLabel(new Date(this.value));
                },
            },
        },
    ],
    exporting: {
        enabled: true,
        menuItemDefinitions: {
            viewFullscreen: {
                text: 'Fullscreen',
            },
        },
        buttons: {
            contextButton: {
                menuItems: [
                    'viewFullscreen',
                    'separator',
                    {
                        text: 'Download CSV',
                        onclick() {
                            this.downloadCSV();
                        },
                    },
                ],
            },
        },
    },
    credits: {
        enabled: false,
    },
    time: {
        useUTC: false,
    },
    noData: {
        style: {
            color: 'rgba(0,0,0,0.37)',
            fontSize: '16px',
        },
    },
};

export const defaultSeries = {
    dataGrouping: {
        enabled: false,
    },
    showInNavigator: true,
    yAxis: 0,
};

const LineChart = (props) => {
    const { chartOptions, loading, error, min, max, series } = props;
    const chartComponent = useRef(null);
    const [options, setOptions] = useState({
        ...defaultChartOptions,
        ...chartOptions,
    });

    useEffect(() => {
        const chart = chartComponent.current?.chart;
        if (!chart) return;

        // Update xAxis (min, max), remove series, zoom out, hide Nodata label, show loading
        function handleTimeChange() {
            let cancelEffect = false;
            setOptions((opts) => {
                const firstxAxis = opts.xAxis[0];
                const shouldChange =
                    firstxAxis.min !== min.getTime() || firstxAxis.max !== max.getTime();
                if (!shouldChange) return opts;

                let newOpts = {
                    ...opts,
                    xAxis: [
                        {
                            ...firstxAxis,
                            min: min.getTime(),
                            max: max.getTime(),
                        },
                    ],
                };
                cancelEffect = true;
                if (opts.series) {
                    const series = opts.series.map((s) => ({
                        ...s,
                        data: [],
                    }));
                    newOpts.series = series;
                }

                return newOpts;
            });

            if (cancelEffect) {
                chart.zoomOut();
                chart.hideNoData();
                chart.showLoading();
            }
            return cancelEffect;
        }

        // Display/hide loading label and hide noData label
        function handleLoadingChange() {
            if (loading) {
                chart.showLoading();
                chart.hideNoData();
                return true;
            } else {
                chart.hideLoading();
            }
            return false;
        }

        // Updates series in the chart
        function handleSeriesChange() {
            setOptions((opts) => ({
                ...opts,
                series,
            }));
        }

        let cancelEffect = false;

        cancelEffect = handleTimeChange();
        if (cancelEffect) return;

        cancelEffect = handleLoadingChange();
        if (cancelEffect) return;

        handleSeriesChange();
    }, [series, loading, min, max]);

    return (
        <>
            {error && <ChartError error={error} title={options.title?.text} />}
            {!error && (
                <HighchartsReact
                    highcharts={Highcharts}
                    options={options}
                    constructorType={'stockChart'}
                    ref={chartComponent}
                    containerProps={{ style: { width: '100%' } }}
                />
            )}
        </>
    );
};

export default LineChart;
