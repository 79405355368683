import React, { useState, useEffect } from 'react';
import { useShowController, TabbedShowLayout, useRedirect, usePermissions } from 'react-admin';
import { LinearProgress } from '@mui/material';
import Breadcrumb from '../menu/Breadcrumb';
import SurveySection from './surveys/SurveySection';
import Layout from '../components/Layout';
import { makeStyles } from '@mui/styles';
import GROUPS from '../auth/groups';
import SiteDetails from './components/SiteDetails';
import ROOT from '../theme/root';
import AlarmSettings from '../alarms/AlarmSettings';
import { Features } from '../constant';
import { useFeature } from '../context/FeatureFlags';
import useMaxDepth from './hooks/useMaxDepth';
import ShowActions from './components/ShowActions';
import Analytics from './analytics';
import TrendAnalysis from './trend';
import SiteData from './components/SiteData';

const useStyles = makeStyles(() => ({
    tabs: {
        '& .MuiTabs-indicator': {
            backgroundColor: ROOT.COLORS.PRIMARY,
            top: 'unset',
            bottom: '0px',
            borderLeft: 'none',
            borderRight: 'none',
        },
        '& [class*="MuiTab-textColorInherit"][class*="Mui-selected"]': {
            backgroundColor: 'transparent',
            color: ROOT.COLORS.PRIMARY,
            borderLeft: 'none',
            borderRight: 'none',
        },
        '& [class*="MuiTabs-flexContainer"]': {
            borderBottom: ROOT.BORDERS.BORDER,
        },
        '& [class*="RaTabbedShowLayout-content"]': {
            backgroundColor: 'transparent',
            border: 'none',
            margin: 0,
            padding: 0,
        },
    },
}));

function compare(a, b) {
    if (a.id < b.id) {
        return 1;
    }
    if (a.id > b.id) {
        return -1;
    }
    return 0;
}

const ShowSite = () => {
    const classes = useStyles();
    const controller = useShowController();
    const redirect = useRedirect();
    const [record, setRecord] = useState(null);
    const { permissions } = usePermissions();
    const [surveys, setSurveys] = useState(() => {
        if (controller?.record?.surveys) {
            return controller.record.surveys.sort(compare);
        }
        return null;
    });
    const maxDepth = useMaxDepth(controller.record);
    const showRules = useFeature(Features.rules) && permissions.includes(GROUPS.HSW_ADMIN);

    const showAnalytics =
        record?.deviceId &&
        (permissions.includes(GROUPS.HSW_ADMIN) || permissions.includes(GROUPS.ANALYTICS)) &&
        record?.siteTypeId !== 3 &&
        record?.siteTypeId !== 5;
    const hasSurvey = !!surveys && surveys.length > 0;
    const showSettings = showRules && record?.siteTypeId !== 5;
    const overrides = !!controller?.record?.id && {
        [`${controller.record.id}`]: controller.record.name,
    };
    const rainfallSiteId = record?.rainfallSiteId || record?.project?.rainfallSiteId || null;

    const handleStatusChange = (newStatus) => {
        let upsert = { ...record };
        upsert.status = newStatus;
        setRecord(upsert);
    };
    const reloadSettings = () => {
        redirect(`/sites/${record.id}/Show/settings`);
    };

    useEffect(() => {
        if (controller.record?.surveys && controller.record?.surveys.length > 0) {
            const sorted = controller.record.surveys.sort(compare);
            setSurveys(sorted);
        }
        setRecord(controller.record);
    }, [controller.record]);

    if (!record) return <LinearProgress />;

    return (
        <Layout>
            <Breadcrumb overrides={overrides} />
            <ShowActions data={record} reloadData={handleStatusChange} resource="sites" />
            <TabbedShowLayout record={record} className={classes.tabs}>
                <TabbedShowLayout.Tab label="Overview">
                    <SiteDetails record={record} label={false} />
                    {hasSurvey && (
                        <SurveySection
                            label={false}
                            resource="surveys"
                            id={surveys[0].id}
                            name={record.name}
                            photoPaths={surveys?.map((survey) => survey.photoPath)}
                            feedback={surveys?.map((survey) => survey.feedback).flat(1)}
                            permissions={permissions}
                            siteStatus={record.status}
                            siteTypeId={record.siteTypeId}
                        />
                    )}
                </TabbedShowLayout.Tab>
                {showSettings && (
                    <TabbedShowLayout.Tab label="Settings" path="settings">
                        <AlarmSettings
                            hierarchy="site"
                            id={record.id}
                            siteDepth={maxDepth}
                            site={record}
                            reloadAlarms={reloadSettings}
                        />
                    </TabbedShowLayout.Tab>
                )}
                <TabbedShowLayout.Tab label="Data" path="data">
                    <SiteData
                        record={record}
                        hasSurvey={hasSurvey}
                        maxDepth={maxDepth}
                        surveys={surveys}
                    />
                </TabbedShowLayout.Tab>
                {showAnalytics && (
                    <TabbedShowLayout.Tab label="Analytics" path="analytics">
                        <Analytics siteId={record.id} rainfallSiteId={rainfallSiteId} />
                    </TabbedShowLayout.Tab>
                )}
                {showAnalytics && (
                    <TabbedShowLayout.Tab label="Trend" path="trend">
                        <TrendAnalysis siteId={record.id} rainfallSiteId={rainfallSiteId} />
                    </TabbedShowLayout.Tab>
                )}
            </TabbedShowLayout>
        </Layout>
    );
};

export default ShowSite;
