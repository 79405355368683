import { UpdateWithPromise } from '../components/UpdateWithPromise';
import { useLogout } from 'react-admin';

const setRules = async (type, id, rules) => {
    if (!type || !id) return;
    let body;
    if (type === 'project') {
        rules.forEach((element) => {
            element.projectId = id;
            element.siteId = null;
        });
        body = { rules: rules };
        body.projectId = id;
    }
    if (type === 'site') {
        rules.forEach((element) => {
            element.siteId = id;
            element.projectId = null;
        });
        body = { rules: rules };
        body.siteId = id;
    }

    var [status, error] =
        (await UpdateWithPromise('rules', body, 'POST').then((response) => {
            status = response[0];
            error = response[1] === undefined ? '' : response[1];
            if (status === 404) {
                window.sessionStorage.clear();
                useLogout();
            }
            if (error) {
                console.log(error);
            } else {
                return true;
            }
        })) ?? [];
};

const asMerge = (thresholds) => {
    if (!thresholds || !thresholds.length) return {};
    let levels = [];
    for (const t of thresholds) {
        if (t.conditions) {
            for (let index = 0; index < t.conditions.length; index++) {
                const condition = t.conditions[parseInt(index)];
                if (condition.value !== 999999999) {
                    condition.valueUnit = condition.isPercent === true ? '%' : 'mm';
                    const trigger = t.triggers.length > 0 ? t.triggers[parseInt(index)] : null;
                    if (t.triggers.length > 0) {
                        trigger.triggerEvents = trigger.value;
                    } else {
                        trigger.triggerEvents = 2;
                    }
                    const level = { ...trigger, ...condition };
                    level.siteId = t.siteId;
                    level.projectId = t.projectId;
                    levels.push(level);
                }
            }
        }
    }
    return levels;
};

const ruleTypes = {
    inactive: 0,
    tampering: 1,
    reading: 2,
};

const ruleActionStatus = {
    inactive: 0,
    active: 1,
};

const ruleActionTypes = {
    dashboard: 'Dashboard',
    email: 'Email',
    sms: 'SMS',
};

export { setRules, asMerge, ruleTypes, ruleActionStatus, ruleActionTypes };
