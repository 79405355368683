import React, { useEffect, useRef, useState } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import funnel from 'highcharts/modules/funnel.js';
import { makeStyles } from '@mui/styles';
import { useSidebarState } from 'react-admin';
import { removeSVGFromChart } from '../utils';
import { levelAlarmOperator } from '../../alarms/alarm';

funnel(Highcharts);
const colors = ['#eeeeee', '#00AEEF'];
const SENSOR = ['M242.37 15H262L272 19.375V25H232V19.375L242.37 15Z'];
const LID_TOP = ['M317 19H190V21.5H317V19Z'];
const TOP_LINE = ['M0.5 29.5H117'];
const BUTTOM_OF_INVERT = ['M117 259H0'];
const BUTTOM_OF_BENCHING = ['M53 229H117'];
const INVERT_AND_BENCHING = [
    'M126 229L223.5 238C225.5 246.5 232.5 260 252 260C265.5 260 275.5 252.5 278.5 238L377 229.5',
];
const BUTTOM_WITH_HALF_CIRCLE = [
    'M223.5 238L126 229V284H252L377 284.114V229.5L278.5 238C275.5 252.5 265.5 260 252 260C232.5 260 225.5 246.5 223.5 238Z',
];
const OUTSIDE_COVER = [
    'M343.341 284H160.562H148.317H126V45.5H204V19H117V48V284L102.5 290.172V303H402V290L386 284V48V19H299V45.5H377V284H355.134H343.341Z',
];
const GRAY_OUT = [
    'M224 237.5L126.5 228.5L376.5 229L278 237.5C276 249 268 259.5 252 259.5C234 259.5 227 248.5 224 237.5Z',
];
const ARROW_FOR_INVERT = [
    'M39 30L36.1132 35H41.8868L39 30ZM39 259L41.8868 254H36.1132L39 259ZM38.5 34.5L38.5 254.5H39.5L39.5 34.5H38.5Z',
];
const ARROW_FOR_BENCHING = [
    'M86 30L83.1132 35H88.8868L86 30ZM86 228L88.8868 223H83.1132L86 228ZM85.5 34.5L85.5 223.5H86.5L86.5 34.5H85.5Z',
];

const renderLevelThresholdsText = (chart, thresholds, measureFromInvert, thresholdsGroup) => {
    const textCSS = {
        fontFamily: 'Arial, Helvetica, sans-serif',
        fontWeight: 'bolder',
        fontSize: '90%',
    };

    const X_BASE = 390; // starting point of X axis to draw the text
    const Y_BASE = 29.5; // starting point of Y axis to draw the text
    const Y_TOTAL = measureFromInvert ? 259 - 29.5 : 229 - 29.5;

    let thresholdTexts = thresholds.map((t) => {
        const { percent: threshold, mm, operator } = t;
        const type = operator === levelAlarmOperator.lt ? arrowDown : arrowUp;
        const y = Y_BASE + (Y_TOTAL * (100 - threshold)) / 100 + 4;
        const text = mm !== null ? `${mm}mm ${type}` : `${threshold}% ${type}`;
        return {
            percent: threshold,
            mm,
            text,
            x: X_BASE,
            y,
        };
    });

    adjustDistances(thresholdTexts);

    thresholdTexts.forEach(({ text, x, y }) =>
        chart.renderer.text(text, x, y).css(textCSS).add(thresholdsGroup),
    );
};

function adjustDistances(objects) {
    // Sort objects by distance in ascending order
    objects.sort((a, b) => {
        if (a.y === b.y && a.mm && b.mm) return b.mm - a.mm;
        return a.y - b.y;
    });

    for (let i = 1; i < objects.length; i++) {
        const previousDistance = objects[`${i - 1}`].y;
        const currentDistance = objects[`${i}`].y;

        if (currentDistance - previousDistance < 10) {
            // Adjust the current object's distance to ensure a minimum distance of 10
            objects[`${i}`].y = previousDistance + 10;
        }
    }
}

const renderLevelThresholdsDashLines = (
    chart,
    levelPercent,
    thresholds,
    measureFromInvert,
    thresholdsGroup,
) => {
    let attrs = {
        'stroke-width': 1,
        'stroke-dasharray': '8,4',
        stroke: 'grey',
    };

    const Y_BASE = 29.5; // starting point of Y axis to draw the line
    const Y_TOTAL = measureFromInvert ? 259 - 29.5 : 229 - 29.5;

    thresholds.forEach((t) => {
        let cmd;
        const { percent: threshold } = t;
        const y = Y_BASE + (Y_TOTAL * (100 - threshold)) / 100;
        if (y <= 45) {
            cmd = 'M 299 ' + `${y}` + 'H 204';
        } else {
            cmd = 'M 375 ' + `${y}` + 'H 125';
        }

        if (threshold <= levelPercent) {
            attrs.stroke = 'Gainsboro';
        } else {
            attrs.stroke = 'grey';
        }

        chart.renderer.path([cmd]).attr(attrs).add(thresholdsGroup);
    });
};

const calculateLevelThresholds = (rules, maxDepth) => {
    let thresholds = [];

    if (!!rules && rules.length > 0) {
        for (const rule of rules) {
            if (rule.conditions) {
                for (const condition of rule.conditions) {
                    if (condition.fact === 'level' && maxDepth) {
                        const percent = condition.isPercent
                            ? condition.value
                            : Math.round((condition.value * 100) / maxDepth);
                        const mm = !condition.isPercent ? condition.value : null;
                        thresholds.push({ percent, mm, operator: condition.operator });
                    }
                }
            }
        }
    }

    return thresholds;
};

const adjustDataForLowPercentage = (data) => {
    let remainder = data[0];
    let level = data[1];
    const total = level.y + remainder.y;

    if (level.y !== 0 && level.y / total < 0.01) {
        level.y = total * 0.01;
        remainder.y = total - level.y;
    }

    return [remainder, level];
};

const useStyles = makeStyles((theme) => ({
    ChartWrapper: {
        position: 'relative',
        height: '100%',
        width: '100%',
        display: 'flex',
        flex: 1,
        textTransform: 'none',
    },
    ManholeContainer: {
        position: 'absolute',
        minHeight: '310px',
        minWidth: '600px',
        backgroundColor: 'transparent',
        transform: (props) =>
            props.open ? 'translate(4%, 16%) scale(1.08)' : 'translate(12%, 16%) scale(1.08)',
        [theme.breakpoints.up('xl')]: {
            height: '310px',
            width: '600px',
            transform: (props) =>
                props.open ? 'translate(25%, 23%) scale(1.3)' : 'translate(36%, 23%) scale(1.3)',
        },
    },
    title: {
        position: 'absolute',
        fontSize: '20px',
        marginLeft: '5px',
        height: '100%',
        width: '100%',
    },
    WaterLevel: {
        position: 'absolute',
        fontSize: '20px',
        fontWeight: 'bold',
        color: 'black',
        marginLeft: '250px',
        height: '100%',
        width: '100%',
    },
}));

const chartOptions = {
    chart: {
        type: 'funnel',
        backgroundColor: 'transparent',
    },
    title: {
        text: '  ',
    },
    subtitle: {
        text: '- ',
    },
    xAxis: {
        visible: false,
    },
    colors: colors,
    tooltip: {
        zIndex: 99999,
        useHTML: true,
        borderWidth: 3,
        shadow: false,
        marker: {
            symbol: 'circle',
            radius: 18, // set the radius of the color point
        },
        headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        pointFormat:
            '<span style="color:{point.color}">\u2B24</span> {point.name}: <b> {point.percentage:.0f}%</b> of total<br/>',
        backgroundColor: 'white',
    },
    credits: {
        enabled: false,
    },
    exporting: { enabled: false },
    plotOptions: {
        tooltip: {
            pointFormat:
                '<tr><td style="color:{series.color};padding:0">{456}: </td>' +
                '<td style="padding:0"><b>{789} mm</b></td></tr>',
            useHTML: true,

            followPointer: true,
            followTouchMove: true,
        },
        series: {
            center: ['42%', '29%'],
            neckWidth: '45%',
            width: '45%',
            height: '69%',
            dataLabels: {
                enabled: false,
                format: '<b>{point.name}</b> {point.y:.0f}mm',
                softConnector: true,
            },
        },
    },
    accessibility: {
        enabled: false,
    },
    legend: {
        enabled: false,
    },
    responsive: {
        rules: [
            {
                condition: {
                    maxWidth: 600,
                    maxHeight: 100,
                },
            },
        ],
    },
};

const arrowUp = '&#x25B2;';
const arrowDown = '&#x25BC;';

const Manhole = (props) => {
    const { data, value, measureFromInvert, invertToSensor, benchingToSensor, maxDepth, rules } =
        props;
    const thresholds = calculateLevelThresholds(rules, maxDepth);
    const chartComponent = useRef(null);
    const [open] = useSidebarState();
    const classes = useStyles({ open });
    const [options, setOptions] = useState({
        ...chartOptions,
        chart: {
            ...chartOptions.chart,
            events: {
                load: function () {
                    var chart = this;
                    drawBackground(chart);
                },
            },
        },
    });

    // Redraw SVG background and metadata
    useEffect(() => {
        const chart = chartComponent.current?.chart;
        if (!chart) return;
        drawDimensions(chart, benchingToSensor, invertToSensor, measureFromInvert);
        drawLevelText(chart, value);
        drawThresholds(chart, value, maxDepth, thresholds, measureFromInvert);
    }, [value, maxDepth, thresholds, measureFromInvert, invertToSensor, benchingToSensor]);

    // Redraw level
    useEffect(() => {
        const adjustedData = adjustDataForLowPercentage(data);

        setOptions({
            series: [
                {
                    name: 'Latest Water Level',
                    data: adjustedData,
                },
            ],
        });
    }, [data]);

    return (
        <div>
            <div className={classes.ChartWrapper}>
                <span className={classes.title}>Latest Water Level (mm)</span>
                <div className={classes.ManholeContainer}>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={options}
                        ref={chartComponent}
                    />
                </div>
            </div>
        </div>
    );
};

function drawBackground(chart) {
    // Remove to avoid drawing same elements multiple times
    removeSVGFromChart(chart, 'mh-2d-background');

    // Group for all static elements
    const background = chart.renderer
        .g('mh-2d-background-group')
        .attr({ id: 'mh-2d-background' })
        .add()
        .toFront();

    // bottom with half circle
    chart.renderer
        .path(BUTTOM_WITH_HALF_CIRCLE)
        .attr({
            'stroke-width': 1,
            fill: '#D9D9D9',
            stroke: 'black',
        })
        .add(background)
        .toFront();
    //Sensor
    chart.renderer
        .path(SENSOR)
        .attr({
            'stroke-width': 1,
            fill: '#828282',
            stroke: 'black',
        })
        .add(background);

    //outside
    chart.renderer
        .path(OUTSIDE_COVER)
        .attr({
            'stroke-width': 1,
            fill: '#D9D9D9',
            stroke: 'black',
        })
        .add(background);

    //Lid top
    chart.renderer
        .path(LID_TOP)
        .attr({
            'stroke-width': 1,
            fill: '#414040',
        })
        .add(background);

    chart.renderer
        .path(INVERT_AND_BENCHING)
        .attr({
            'stroke-width': 1,
            stroke: '#949393',
        })
        .add(background);

    //arrow for invert
    chart.renderer
        .path(ARROW_FOR_INVERT)
        .attr({
            'stroke-width': 1,
            fill: 'black',
        })
        .add(background);

    //arrow for brenching
    chart.renderer
        .path(ARROW_FOR_BENCHING)
        .attr({
            'stroke-width': 1,
            fill: 'black',
        })
        .add(background);

    //Top line for both
    chart.renderer
        .path(TOP_LINE)
        .attr({
            'stroke-width': 1,
            stroke: 'black',
        })
        .add(background);

    chart.renderer
        .path(BUTTOM_OF_INVERT)
        .attr({
            'stroke-width': 1,
            stroke: 'black',
        })
        .add(background);

    chart.renderer
        .path(BUTTOM_OF_BENCHING)
        .attr({
            'stroke-width': 1,
            stroke: 'black',
        })
        .add(background);

    chart.renderer
        .text('B = Top of benching to sensor', 115, 320)
        .css({
            fontFamily: 'Arial, Helvetica, sans-serif',
            fontWeight: 'bolder',
            fontSize: '80%',
        })
        .add(background);

    chart.renderer
        .text('I = Invert to sensor', 295, 320)
        .css({
            fontFamily: 'Arial, Helvetica, sans-serif',
            fontWeight: 'bolder',
            fontSize: '80%',
        })
        .add(background);
}

function drawDimensions(chart, benchingToSensor, invertToSensor, measureFromInvert) {
    // Remove to avoid drawing same elements multiple times
    removeSVGFromChart(chart, 'mh-2d-dimensions');

    // Group for dimensions related elements
    const dimensions = chart.renderer
        .g('mh-2d-dimensions-group')
        .attr({ id: 'mh-2d-dimensions' })
        .add()
        .toFront();

    // gray out
    if (!measureFromInvert) {
        chart.renderer
            .path(GRAY_OUT)
            .attr({
                fill: '#D9D9D9',
                'stroke-width': 0,
                'stroke-linejoin': 'round',
            })
            .add(dimensions);
    }
    chart.renderer
        .text('B = ' + benchingToSensor + 'mm', 81, 115)
        .attr({
            rotation: -90,
        })
        .css({
            fontFamily: 'Arial, Helvetica, sans-serif',
            fontWeight: 'bold',
            fontSize: '80%',
        })
        .add(dimensions);

    chart.renderer
        .text('I = ' + invertToSensor + 'mm', 35, 115)
        .attr({
            rotation: -90,
        })
        .css({
            fontFamily: 'Arial, Helvetica, sans-serif',
            fontWeight: 'bold',
            fontSize: '80%',
        })
        .add(dimensions);
}

function drawThresholds(chart, value, maxDepth, thresholds, measureFromInvert) {
    // Remove to avoid drawing same elements multiple times
    removeSVGFromChart(chart, 'mh-2d-thresholds');

    // Group for thresholds
    const thresholdsGroup = chart.renderer
        .g('mh-2d-thresholds-group')
        .attr({ id: 'mh-2d-thresholds' })
        .add()
        .toFront();

    // dash lines for level thresholds
    renderLevelThresholdsDashLines(
        chart,
        (value * 100) / maxDepth,
        thresholds,
        measureFromInvert,
        thresholdsGroup,
    );
    // text for level thresholds
    renderLevelThresholdsText(chart, thresholds, measureFromInvert, thresholdsGroup);
}

function drawLevelText(chart, value) {
    // Remove to avoid drawing same elements multiple times
    removeSVGFromChart(chart, 'mh-2d-level-text');

    chart.renderer
        .text(value + 'mm', value > 999 ? 165 : 195, 160)
        .attr({ id: 'mh-2d-level-text' })
        .css({
            fontWeight: 'bold',
            fontSize: '40px',
            color: '#666666',
        })
        .add();
}

export default Manhole;
