import ListProjects from './List';
import ShowProject from './Show';
import CreateProject from './Create';
import EditProject from './Edit';
import EditProjectRestricted from './EditRestricted';
import GROUPS from '../auth/groups';

const projects = (permissions) => {
    const project = {
        list: ListProjects,
        show: ShowProject,
        create: null,
        edit: null,
    };

    if (!permissions) {
        return project;
    } else if (permissions.includes(GROUPS.HSW_ADMIN)) {
        project.create = CreateProject;
        project.edit = EditProject;
    } else if (
        permissions.includes(GROUPS.CUSTOMER_ADMIN) &&
        permissions.includes(GROUPS.ANALYTICS)
    ) {
        project.edit = EditProjectRestricted;
    }

    return project;
};
export default projects;
