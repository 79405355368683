import SurveyList from './List';
import SurveyShow from './Show';
import SurveyCreate from './Create';

export const surveyTypes = [
    { id: 1, name: 'Manhole' },
    { id: 2, name: 'Pump' },
    { id: 3, name: 'Raft' },
    { id: 4, name: 'Groundwater' },
    { id: 5, name: 'Raingarden' },
    { id: 6, name: 'Tank' },
];

export const tankMaterials = [
    { id: 0, name: 'Plastic' },
    { id: 1, name: 'Wood' },
    { id: 2, name: 'Concrete' },
];

export const getTankMaterial = (id) => tankMaterials.find((tm) => tm.id === id)?.name || null;

const surveys = {
    list: SurveyList,
    show: SurveyShow,
    create: SurveyCreate,
};

export default surveys;
