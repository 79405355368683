import { useGetList } from 'react-admin';
import React, { createContext, useContext } from 'react';

const AlarmAnalyticsContext = createContext();

const AlarmAnalyticsContextProvider = ({ children, siteId, fromDate, toDate }) => {
    const fromTimestamp = fromDate && fromDate.getTime();
    const toTimestamp = toDate && toDate.getTime();

    const { isLoading, data, error } = useGetList(
        'alarms',
        {
            filter: {
                siteId,
                createdAtFrom: fromTimestamp,
                createdAtTo: toTimestamp,
            },
            pagination: { page: 1, perPage: 2000 },
        },
        {
            enabled: siteId && fromDate && toDate ? true : false,
        },
    );

    const general =
        data && !error
            ? data
                  .filter((a) => a.alarmType === 0)
                  .sort((a1, a2) => new Date(a2.createdAt) - new Date(a1.createdAt))
            : [];
    const inactive = data && !error ? data.filter((a) => a.alarmType === 2) : [];
    const tamper = data && !error ? data.filter((a) => a.alarmType === 1) : [];

    return (
        <AlarmAnalyticsContext.Provider value={{ general, inactive, tamper, isLoading, error }}>
            {children}
        </AlarmAnalyticsContext.Provider>
    );
};

const useAlarmAnalyticsContext = () => {
    const context = useContext(AlarmAnalyticsContext);
    if (!context) {
        throw new Error(
            'useAlarmAnalyticsContext must be used within a AlarmAnalyticsContextProvider',
        );
    }
    return context;
};

export { useAlarmAnalyticsContext, AlarmAnalyticsContextProvider };
