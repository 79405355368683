import React, { useEffect } from 'react';
import { useListContext, useRemoveFromStore, useStore } from 'react-admin';
import { Alert, Box, Card, Chip, Stack, Typography } from '@mui/material';
import CompareButton from './CompareButton';

function convertNumberToWords(num) {
    const numbersWord = [
        'zero',
        'one',
        'two',
        'three',
        'four',
        'five',
        'six',
        'seven',
        'eight',
        'nine',
    ];
    return numbersWord[`${num}`];
}

const AsideList = ({ maxSitesToCompare }) => {
    const { data, selectedIds, onToggleItem, isLoading } = useListContext();
    const [selectedSites, setSelectedSites] = useStore('sites.list.selected', []);
    const remove = useRemoveFromStore('sites.list.selected');

    useEffect(() => {
        if (isLoading) return;

        // Get differences between 'RaStore.sites.selectedIds' and 'sites.list.selected'
        const selectedSitesIds = selectedSites.map((site) => site.id);
        const toRemove = selectedSitesIds.filter((id) => !selectedIds.includes(id));
        const toAdd = selectedIds.filter((id) => !selectedSitesIds.includes(id));

        if (toRemove.length === 0 && toAdd.length === 0) return;

        // Create updated list
        let newList = selectedSites.filter((site) => !toRemove.includes(site.id));
        newList = newList.concat(data.filter((site) => toAdd.indexOf(site.id) >= 0));

        // Update local storage
        newList.length === 0 ? remove() : setSelectedSites(newList);
    }, [data, selectedIds, selectedSites, setSelectedSites, isLoading, remove]);

    const handleDelete = (id) => {
        onToggleItem(id);
    };

    if (!data || selectedIds.length === 0) return <></>;

    return (
        <Card sx={{ margin: '94px 10px 52px 10px', flex: '0 0 15%', maxWidth: '215px' }}>
            <Typography variant="h6">Selected sites</Typography>
            <Box display="flex" flexDirection="column" justifyContent="space-between">
                <Stack direction="column" spacing={1} marginTop="30px">
                    {selectedSites.map((site) => {
                        return (
                            <Chip
                                key={site.id}
                                label={site.name}
                                variant="outlined"
                                color="primary"
                                style={{ justifyContent: 'space-between' }}
                                onDelete={() => handleDelete(site.id)}
                            />
                        );
                    })}
                </Stack>
                {selectedIds && selectedIds.length >= maxSitesToCompare && (
                    <Box marginTop="25px">
                        <Alert severity="info" icon={false}>
                            You can only compare up to {convertNumberToWords(maxSitesToCompare)}{' '}
                            sites at a time
                        </Alert>
                    </Box>
                )}
                <Stack direction="column" spacing={1} marginTop="30px">
                    <CompareButton sites={selectedSites} />
                </Stack>
            </Box>
        </Card>
    );
};

export default AsideList;
