import React from 'react';
import { Alert, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    title: {
        color: 'rgb(51, 51, 51)',
        fontSize: 16,
        fontWeight: 600,
        margin: 0,
        padding: '5px 0 0 10px',
    },
    errorContainer: {
        display: 'flex',
        flexDirection: 'column',
        flexBasis: '50%',
        justifyContent: 'center',
        alignItems: 'center',
        fontStyle: 'italic',
    },
}));

const ChartError = (props) => {
    const { error, title } = props;
    const classes = useStyles();
    return (
        <Box className={classes.container}>
            <p className={classes.title}>{title || 'Line Chart'}</p>
            <Box className={classes.errorContainer}>
                <h5>Chart unavailable</h5>
                {error && <Alert severity="error">{error}</Alert>}
            </Box>
        </Box>
    );
};

export default ChartError;
