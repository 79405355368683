import UserList from './List';
import ShowUser from './Show';
import CreateUser from './Create';
import EditUser from './Edit';

const users = {
    list: UserList,
    show: ShowUser,
    create: CreateUser,
    edit: EditUser,
};
export default users;
