import Home from './screens/Home';
import Create from './screens/Create';
import List from './screens/List';

export const Downlink = () => {
    return {
        create: Create,
        list: List,
    };
};

export default Home;
