import React from 'react';
import {
    List,
    TextField,
    DateField,
    TopToolbar,
    CreateButton,
    usePermissions,
    useRedirect,
    useGetList,
    useRecordContext,
} from 'react-admin';
import { useMediaQuery } from '@mui/material';
import { useAppContext } from '../context/AppContext';
import GROUPS from '../auth/groups';
import Layout from '../components/Layout';
import SearchFilter from '../components/SearchFilter';
import DatagridWrapper from '../components/DatagridWrapper';

const UserList = () => {
    const [appStore] = useAppContext();
    const redirect = useRedirect();
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const { permissions } = usePermissions();

    const { data: organisations } = useGetList('organisations', {
        filter: { status: 0 },
        pagination: { page: 1, perPage: 1000 },
    });
    if (
        !!permissions &&
        !permissions.includes(GROUPS.HSW_ADMIN) &&
        !permissions.includes(GROUPS.CUSTOMER_ADMIN)
    ) {
        redirect('/');
    }

    const OrganisationField = (props) => {
        const record = useRecordContext();
        let org;
        if (record && record.organisationId && organisations) {
            org = organisations.find((o) => o.id === record.organisationId);
        }
        return <TextField {...props} record={org} />;
    };
    const ListActions = () => {
        return (
            <TopToolbar>
                <CreateButton label="Create New User" icon={null} />
            </TopToolbar>
        );
    };

    return (
        <Layout title="User Management">
            <List
                filters={[
                    <SearchFilter
                        key="filter-query"
                        placeholder="Search by name"
                        alwaysOn
                        source="query-users"
                    />,
                ]}
                actions={<ListActions />}
                filter={
                    appStore.organisationId !== '-'
                        ? { organisationId: appStore.organisationId }
                        : null
                }
                sort={{ field: 'name', order: 'ASC' }}
            >
                <DatagridWrapper rowClick="show" bulkActionButtons={false}>
                    <TextField source="name" label="Full Name" />
                    {!!permissions &&
                        (permissions.includes(GROUPS.HSW_ADMIN) ||
                            permissions.includes(GROUPS.HSW_USER)) && (
                            // <ReferenceField
                            //     label="Customer"
                            //     source="organisationId"
                            //     reference="organisations"
                            //     link="show"
                            // >
                            //     <TextField source="name" />
                            // </ReferenceField>
                            <OrganisationField label="Customer" source="name" emptyText="" />
                        )}

                    <TextField source="emailAddress" label="Email" />
                    {!isSmall && <TextField source="phone" label="Mobile" />}
                    {!isSmall && <DateField source="onboardingDate" label="Date Created" />}
                </DatagridWrapper>
            </List>
        </Layout>
    );
};

export default UserList;
