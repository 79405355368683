import React from 'react';
import { makeStyles } from '@mui/styles';
import { Button, Box, Typography, CircularProgress } from '@mui/material';
import { useRefresh, useNotify } from 'react-admin';
import { getDeviceCommStatus, installDevice } from './DeviceOp';
import InputMask from 'react-input-mask';

const useStyles = makeStyles(() => ({
    valueContainer: {
        backgroundColor: '#F5F5F5',
        border: '1px solid #D8D8D8',
        borderRadius: '4px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '44px',
        fontWeight: 'bold',
        width: 'auto',
        padding: '0px 8px',
    },
    valueOverlayWrapper: {
        position: 'absolute',
        height: '100%',
        width: '100%',
        left: '0px',
        top: '0px',
    },
    EditlabelDescription: {
        fontWeight: 'bold',
        fontSize: '16px',
        marginRight: '16px',
    },
    EditValueContainer: {
        backgroundColor: '#F5F5F5',
        border: '1px solid #D8D8D8',
        borderRadius: '4px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '44px',
        fontWeight: 'bold',
        width: '79px',
        padding: '0px 8px',
        fontSize: '16px',
        boxSizing: 'content-box',
    },
    CommentContainer: {
        backgroundColor: '#F5F5F5',
        border: '1px solid #D8D8D8',
        borderRadius: '4px',
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'left',
        height: '44px',
        fontWeight: 'bold',
        width: '100%',
        padding: '0px 8px',
    },
    buttonRight: {
        display: 'flex',
        justifyContent: 'flex-end',
        float: 'right',
    },
}));

const ValueEdit = ({ setValue, left, top, label, value, name, loading, className }) => {
    const classes = useStyles();
    const mask = '******';

    const validateInput = (value) => {
        const isValid = /^[0-9A-Fa-f]{0,6}$/.test(value);
        if (isValid) setValue(value.toUpperCase());
    };

    return (
        <div style={{ position: 'absolute', left: left, top: top }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div className={classes.EditlabelDescription}>{label}</div>
                <InputMask
                    type="text"
                    mask={mask}
                    maskPlaceholder={null}
                    spellCheck={false}
                    disabled={loading}
                    className={className}
                    value={value}
                    name={name}
                    onChange={(e) => {
                        validateInput(e.target.value);
                    }}
                />
            </div>
        </div>
    );
};

const CommStatus = {
    DEAD: 1,
    OFF_CONTRACT: 2,
    DISABLED: 3,
    DELETED: 5,
    SUSPENDED: 6,
    NOT_ACTIVABLE: 7,
    NOT_FOUND: 10,
    NOT_ENABLED: 11,
    ENABLED: 12,
    INSTALLED: 13,
    NOT_AVAILABLE: 14,
};

const checkDeviceStatus = async (deviceId) => {
    let res = await getDeviceCommStatus(deviceId);

    res.valid = false;

    if (!res.httpStatus || !res.commStatus || res.httpStatus !== 200) {
        res.statusMsg = 'API ERROR';
        res.message = 'Service Error.';
        return res;
    }

    switch (res.commStatus.status) {
        // Not Found
        case CommStatus.NOT_FOUND:
            res.statusMsg = 'NOT FOUND';
            res.message = 'Device does not exist.';
            break;

        // Not Enabled
        case CommStatus.NOT_ENABLED:
            res.statusMsg = 'NOT ENABLED';
            res.message =
                'Please ensure that device is powered on and that it has connectivity to the Sigfox network.';
            break;

        // Enabled
        case CommStatus.ENABLED:
            res.valid = true;
            res.statusMsg = 'ENABLED';
            res.message = 'Device last comm: ' + new Date(res.commStatus.lastComm).toLocaleString();
            break;

        // Installed
        case CommStatus.INSTALLED:
            res.statusMsg = 'INSTALLED';
            res.message = 'Device has already been installed to site: ' + res.commStatus.siteName;
            break;

        case CommStatus.NOT_AVAILABLE:
            res.statusMsg = 'NOT AVAILABLE';
            res.message = 'Service not available. Please retry later.';
            break;

        default:
            res.statusMsg = 'OFF CONTRACT';
            res.message = 'Device off contract. Please check device status or token.';
            break;
    }

    return res;
};

const InstallDevice = (props) => {
    const { site, title } = props;
    const classes = useStyles();
    const mountedRef = React.useRef(true);
    const [error, setError] = React.useState(false);
    const [validation, setValidation] = React.useState('');
    const [statusMsg, setStatusMsg] = React.useState('');

    const [deviceId, setDeviceId] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [installing, setInstalling] = React.useState(false);
    const notify = useNotify();
    const refresh = useRefresh();

    const handleInstall = async () => {
        if (installing) return;

        let deviceApplication = 2; // SWLS
        const isGpsSensor = site.siteTypeId === 5 && site.status === 4;
        const isRaftSensor = site.siteTypeId === 5;
        if (isGpsSensor) {
            deviceApplication = 7;
        } else if (isRaftSensor) {
            deviceApplication = 4;
        }

        const siteDevice = {
            siteId: site.id,
            siteDevice: {
                deviceId: deviceId, // device ID
                deviceType: 0, // commercial
                deviceApplication,
            },
        };

        setInstalling(true);

        await installDevice(siteDevice);

        handleCancel();
        refresh();
        notify('Site device installed.', { type: 'success' });
    };

    React.useEffect(() => {
        const validate = async (id) => {
            if (id.length < 6) {
                setError(true);
                return false;
            }

            setStatusMsg('');
            setValidation('');
            setLoading(true);

            const res = await checkDeviceStatus(id);

            if (!mountedRef.current) return;

            setLoading(false);
            setValidation(res.message);
            setStatusMsg(res.statusMsg);
            setError(!res.valid);

            return true;
        };

        validate(deviceId);
    }, [deviceId]);

    React.useEffect(() => {
        return () => {
            mountedRef.current = false;
        };
    }, []);

    const handleCancel = () => {
        props.onClose();
    };

    const deviceInstallButtons = () => {
        return (
            <div>
                <div className={classes.valueOverlayWrapper}>
                    <div
                        style={{
                            position: 'relative',
                            left: '20%',
                            top: '280px',
                            width: '100%',
                        }}
                    >
                        <Box display="flex" gap="64px" flexGrow="1" flexDirection="row">
                            <Button
                                onClick={() => handleInstall()}
                                color="primary"
                                disabled={error || installing}
                                className={classes.buttonRight}
                                style={{ fontSize: '16px', height: '48px' }}
                                startIcon={installing && <CircularProgress size={20} />}
                            >
                                Install
                            </Button>
                            <Button
                                onClick={() => handleCancel()}
                                color="primary"
                                disabled={installing}
                                className={classes.buttonRight}
                                style={{ fontSize: '16px', height: '48px' }}
                            >
                                Cancel
                            </Button>
                        </Box>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div>
            <Box display="flex" justifyContent="space-between" width="100%">
                <Typography variant="h6" gutterBottom>
                    {title}
                </Typography>
            </Box>
            <br></br>
            <br></br>
            <div style={{ position: 'relative' }}>
                <ValueEdit
                    label="Enter Device Id"
                    name="deviceId"
                    value={deviceId}
                    setValue={setDeviceId}
                    left={'20%'}
                    top="20px"
                    loading={loading}
                    className={classes.EditValueContainer}
                />
            </div>
            <div style={{ position: 'relative' }}>
                {statusMsg && (
                    <div style={{ position: 'absolute', left: '20%', top: '100px', width: '55%' }}>
                        <Button
                            disabled={true}
                            variant="outlined"
                            color="primary"
                            style={{
                                fontSize: '14px',
                                color: 'white',
                                backgroundColor: error ? 'grey' : '',
                            }}
                        >
                            {statusMsg}
                        </Button>
                    </div>
                )}
                <br></br>
                <div
                    style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        position: 'absolute',
                        left: '20%',
                        top: '160px',
                        width: '55%',
                    }}
                >
                    <Box>
                        <span style={{ fontSize: '14px', color: error ? 'red' : 'black' }}>
                            {validation}
                        </span>
                    </Box>
                </div>
                {loading && (
                    <div style={{ position: 'absolute', left: '40%', top: '100px' }}>
                        <CircularProgress size={50} color="primary" thickness={2} />
                    </div>
                )}
                <>{deviceInstallButtons()}</>
            </div>
        </div>
    );
};

export default InstallDevice;
