import React from 'react';
import { makeStyles } from '@mui/styles';
import { Card, CardContent, Typography, Box, LinearProgress } from '@mui/material';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
    dashboardCard: {
        display: 'flex',
        alignItems: 'center',
        width: 230,
        height: 135,
        padding: theme.spacing(2),
        border: '1px solid #ddd',
        borderRadius: theme.spacing(1),
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    },
    icon: {
        backgroundColor: '#f0f0f0',
        borderRadius: '50%',
        width: 50,
        height: 50,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minWidth: 50,
    },
    title: {
        fontSize: '16px',
        fontWeight: 'bold',
    },
    value: {
        fontSize: '20px',
    },
}));

function DashboardCard({ title, value, icon, className, loading, error }) {
    const classes = useStyles();

    return (
        <Card className={classNames(classes.dashboardCard, className)}>
            <Box className={classes.icon}>{icon}</Box>
            <CardContent>
                <Typography variant="h5" component="h3" className={classes.title}>
                    {title}
                </Typography>
                {loading && <LinearProgress />}
                {!loading && (
                    <Typography variant="h4" component="p" className={classes.value}>
                        {!error && value}
                        {error && 'Not found'}
                    </Typography>
                )}
            </CardContent>
        </Card>
    );
}

export default DashboardCard;
