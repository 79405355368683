import React from 'react';
import { Button, Card, Typography, Link, Breadcrumbs } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useLocation } from 'react-router-dom';
import Error from '../../components/Error';
import ROOT from '../../theme/root';
import Layout from '../../components/Layout';
import PendingCommands from '../components/PendingCommands';
import { useRedirect } from 'react-admin';
import CommandsHistory from '../components/CommandsHistory';

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
    },
    cancel: {
        color: ROOT.COLORS.RED,
        fontWeight: 600,
        fontSize: 14,
    },
    card: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        alignSelf: 'center',
    },
    errorContainer: {
        margin: 0,
    },
    siteTitle: {
        opacity: 0.6,
    },
}));

/**
 * @returns Pending Commands or Commands History depending on the state received in the location (react-router)
 */
const List = () => {
    const { state } = useLocation();
    const classes = useStyles();
    const redirect = useRedirect();
    const cancelHandler = () => redirect(callbackUrl);

    const callbackUrl =
        state && state.from
            ? state.from
            : state && state.deviceUuid
            ? `/devices/${state.deviceUuid}/show/3`
            : '/devices';

    const error =
        !state || !state.deviceId || !state.deviceUuid || !state.status
            ? 'Error loading deviceId from the state'
            : null;

    let title = '';
    if (state && state.status) {
        title =
            state.status === 'pending'
                ? 'Pending Commands'
                : state.status === 'all'
                ? 'Commands History'
                : '';
    }

    if (error) {
        return (
            <Layout classes={classes.container}>
                <Card className={classes.card}>
                    <Error
                        classes={classes.errorContainer}
                        message={`${error}. Please cancel and start over`}
                    />
                    <Button
                        onClick={cancelHandler}
                        variant="text"
                        className={classes.cancel}
                        aria-label="CANCEL"
                    >
                        CANCEL
                    </Button>
                </Card>
            </Layout>
        );
    }

    return (
        <Layout classes={classes.container}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link color="primary" underline="hover" href="/devices">
                    Devices
                </Link>
                <Link underline="hover" color="primary" href={`/devices/${state.deviceUuid}/show`}>
                    {state.deviceId}
                </Link>
                <Link underline="hover" color="primary" href={`${callbackUrl}`}>
                    Downlink
                </Link>
                <Typography color="inherit">{title}</Typography>
            </Breadcrumbs>
            <Typography variant="h5" gutterBottom>
                {title}
            </Typography>
            <Typography variant="h6" className={classes.siteTitle}>
                Device {state.deviceId}
            </Typography>
            {state && state.status === 'pending' && (
                <PendingCommands
                    callbackUrl={callbackUrl}
                    deviceId={state.deviceId}
                    deviceUuid={state.deviceUuid}
                />
            )}
            {state && state.status === 'all' && (
                <CommandsHistory
                    callbackUrl={callbackUrl}
                    deviceId={state.deviceId}
                    deviceUuid={state.deviceUuid}
                />
            )}
        </Layout>
    );
};

export default List;
