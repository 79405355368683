// Root theme variables

const ROOT = {
    COLORS: {
        PRIMARY: '#00AEEF',
        PRIMARY_LIGHT: '#ECF3FC',
        SECONDARY: '#0B0B0D',
        RED: '#EA2F2F',
        RED_LIGHT: '#FFE9E9',
        GREEN: '#1BB922',
        GREEN_LIGHT: '#F2FAE8',
        ORANGE: '#FF6600',
    },
    BORDERS: {
        BORDER: '1px solid #D8D8D8',
        RADIUS: '4px',
        COLOR: '#D8D8D8',
    },
    INPUTS: {
        HEIGHT: '45px',
        COLOR: '#0B0B0D',
    },
    LABELS: {
        FONT_SIZE: '12px',
        COLOR: '#636364',
        FONT_WEIGHT: 'normal',
    },
    STATUSCOLOR: {
        OK: '#5CECBF',
        INFO: '#949495',
        WARNING: '#F7AF61',
        ERROR: '#EA2F2F',
        INACTIVE: '#D8D8D8',
    },
};

export default ROOT;
