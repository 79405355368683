import React, { useEffect, useState } from 'react';
import {
    TopToolbar,
    useNotify,
    useCreate,
    useDelete,
    useGetPermissions,
    Confirm,
    useRedirect,
    useRefresh,
} from 'react-admin';
import { Box, Button, Typography, LinearProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import SurveyFeedback from '../surveys/SurveyFeedback';
import SiteSurvey from '../surveys/SiteSurvey';
import SiteOnboard from '../onboarding/SiteOnboard';
import StatusSelector from '../components/StatusSelector';
import { getUserInfo } from '../../auth/auth0Functions';
import GROUPS from '../../auth/groups';

const useStyles = makeStyles(() => ({
    remove: {
        fontSize: '13px',
    },
    siteTitle: {
        fontSize: '32px',
        fontWeight: 'bold',
        lineHeight: '43px',
    },
}));

const ShowActions = ({ data, reloadData }) => {
    const classes = useStyles();

    const [showFeedback, setShowFeedback] = useState(false);
    const [userId, setUserId] = useState('');
    const [permissions, setPermissions] = useState([]);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [approveConfirmOpen, setApproveConfirmOpen] = useState(false);
    const [status, setStatus] = useState(data?.status ?? 0);

    const getPermissions = useGetPermissions();
    const notify = useNotify();
    const redirect = useRedirect();
    const refresh = useRefresh();
    const [deleteOne, { data: deleted, isLoading: loading, error }] = useDelete();
    const [create, { isLoading: createLoading }] = useCreate();

    useEffect(() => {
        async function getUser() {
            const user = await getUserInfo();
            if (user) setUserId(user.externalUserId);
        }
        getUser();
    }, []);

    useEffect(() => {
        if (error) {
            notify('Site cannot be removed with an active device attached.', { type: 'error' });
            return;
        }

        if (deleted) {
            setTimeout(() => {
                refresh();
                notify('Site removed.', { type: 'success' });
                redirect('list', 'sites');
            }, 900);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleted, error]);

    useEffect(() => {
        getPermissions().then((permissions) => setPermissions(permissions));
        if (!data) return;
        if (data?.status) setStatus(data.status);
    }, [data, getPermissions]);

    const handleChangeStatus = (event) => {
        if (!event?.target?.value) return;
        if (event.target.value === 2) setApproveConfirmOpen(true);
        if (event.target.value === 3) setShowFeedback(true);
    };

    const handleApproveSite = () => {
        create('surveyStatus', {
            data: {
                siteId: data.id,
                status: 2,
                userId: userId,
            },
        });
        setStatus(2);
        setApproveConfirmOpen(false);
        notify('Site approved.', { type: 'success' });
        reloadData(2);
    };

    const handleSurveyFeedback = (feedback) => {
        create('surveyStatus', {
            data: {
                siteId: data.id,
                status: 3,
                comments: feedback,
                userId: userId,
            },
        });
        setStatus(3);
        notify('Site rejected.', { type: 'success' });
        reloadData(3);
    };

    const handleRemoveSite = async () => {
        deleteOne('sites', { id: data.id, previousData: data });
        setConfirmOpen(false);
    };

    if (!data) {
        return <LinearProgress />;
    }

    if (!permissions.includes(GROUPS.HSW_ADMIN)) return null;

    const activeGpsDevice =
        data.devices && data.devices.find((d) => d.deviceApplication === 7 && d.toDate === null);
    const allowSiteSurvey =
        data.status === 0 &&
        (data.siteTypeId === 2 || data.siteTypeId === 3 || data.siteTypeId === 4);
    const allowSiteOnboard =
        data.status === 2 &&
        (data.siteTypeId === 2 ||
            data.siteTypeId === 3 ||
            data.siteTypeId === 4 ||
            data.siteTypeId === 5);
    const allowInstallGps = data.siteTypeId === 5 && data.status === 4 && !activeGpsDevice; // Only raft installed without GPS devices

    return (
        <TopToolbar>
            <SurveyFeedback
                isOpen={showFeedback}
                onClose={() => setShowFeedback(false)}
                onSave={(feedback) => handleSurveyFeedback(feedback)}
            />
            <Box display="flex" width="100%" alignItems="center" marginBottom="16px">
                <Typography variant="h5" className={classes.siteTitle}>
                    {data?.name}
                </Typography>
                <Box ml="29px">
                    <StatusSelector
                        status={status}
                        onChange={(event) => handleChangeStatus(event)}
                    />
                </Box>

                <Box display="flex" gap="16px" flexGrow="1" flexDirection="row-reverse">
                    {data.status !== 5 && (
                        <Button
                            className={classes.remove}
                            onClick={() => setConfirmOpen(true)}
                            variant="outlined"
                        >
                            Remove this site
                        </Button>
                    )}
                    {allowSiteSurvey && <SiteSurvey site={data} />}
                    {allowSiteOnboard && <SiteOnboard site={data} />}
                    {allowInstallGps && <SiteOnboard site={data} title="Install GPS" />}
                </Box>
            </Box>
            <Confirm
                isOpen={confirmOpen}
                loading={loading}
                title="Remove site"
                content="Are you sure you want to remove this site?"
                onConfirm={() => handleRemoveSite()}
                onClose={() => setConfirmOpen(false)}
            />
            <Confirm
                isOpen={approveConfirmOpen}
                loading={createLoading}
                title="Approve site"
                content="Are you sure you want to approve this site?"
                onConfirm={() => handleApproveSite()}
                onClose={() => setApproveConfirmOpen(false)}
            />
        </TopToolbar>
    );
};

export default ShowActions;
