import React from 'react';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Breadcrumb from '../menu/Breadcrumb';
import className from 'classnames';

const useStyles = makeStyles(() => ({
    layout: {
        marginLeft: '34px',
        marginTop: '40px',
    },
    header: {
        marginTop: '27px',
        marginBottom: '30px',
        color: '#0B0B0D',
        fontSize: '32px',
        fontWeight: 'bold',
        letterSpacing: '-0.77px',
        lineHeight: '43px',
    },
}));

const Layout = (props) => {
    const classes = useStyles();
    return (
        <Box className={className(classes.layout, props.classes)}>
            {props.breadcrumb && <Breadcrumb overrides={props.overrides} />}
            {props.title && (
                <Typography variant="h5" gutterBottom className={props.bold ? classes.header : ''}>
                    {props.title}
                </Typography>
            )}
            {props.children}
        </Box>
    );
};

export default Layout;
