import { Box, Card, TextField, Typography } from '@mui/material';
import React from 'react';
import { EditButton } from 'react-admin';
import { deviceStatus, getDeviceType } from '../device';
import { getSiteType } from '../../sites/components/SiteTypes';
import Label from '../../components/Label';

const getDateString = (date) => {
    const options = {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: false,
    };
    return date ? new Date(date).toLocaleDateString('en-US', options) : '';
};

/**
 * Form to display and edit device details
 * @param {*} props Device record
 * @returns Device Details view
 */
const DeviceDetails = (props) => {
    const { device } = props;
    const canEdit = !device.siteId && device.status !== 0;
    return (
        <Card>
            <Box display="flex" justifyContent="space-between">
                <Typography variant="h6" gutterBottom>
                    Device Details
                </Typography>
                {canEdit && device.status !== 0 && <EditButton record={device} icon={null} />}
            </Box>

            <Box display="flex" flexWrap="wrap" pt="16px" style={{ gap: '16px' }}>
                <Box width="48%">
                    <Label>Device ID</Label>
                    <TextField
                        id="project-name"
                        variant="outlined"
                        fullWidth
                        disabled
                        defaultValue={device.deviceId}
                    />
                </Box>
                <Box width="48%">
                    <Label>Registration date</Label>
                    <TextField
                        id="device-registration"
                        variant="outlined"
                        fullWidth
                        disabled
                        defaultValue={getDateString(new Date(device.fromDate))}
                    />
                </Box>
                <Box width="48%">
                    <Label>Firmware</Label>
                    <TextField
                        id="device-firmware"
                        variant="outlined"
                        fullWidth
                        disabled
                        defaultValue={device.firmware}
                    />
                </Box>
                <Box width="48%">
                    <Label>Current site</Label>
                    <TextField
                        id="site-name"
                        variant="outlined"
                        fullWidth
                        disabled
                        defaultValue={device.site?.name ?? ''}
                    />
                </Box>

                <Box width="48%">
                    <Label>Device type</Label>
                    <TextField
                        id="device-firmware"
                        variant="outlined"
                        fullWidth
                        disabled
                        defaultValue={getDeviceType(device)}
                    />
                </Box>
                <Box width="48%">
                    <Label>Site type</Label>
                    <TextField
                        id="site-type"
                        variant="outlined"
                        fullWidth
                        disabled
                        defaultValue={getSiteType(device.site?.siteTypeId)}
                    />
                </Box>
                <Box width="48%">
                    <Label>Storage location</Label>
                    <TextField
                        id="device-storagelocation"
                        variant="outlined"
                        fullWidth
                        disabled
                        defaultValue={device.storageLocation}
                    />
                </Box>
                <Box width="48%">
                    <Label>Last comms</Label>
                    <TextField
                        id="device-storagelocation"
                        variant="outlined"
                        fullWidth
                        disabled
                        defaultValue={undefined}
                    />
                </Box>
                <Box width="48%">
                    <Label>Onboarding status</Label>
                    <TextField
                        id="device-onboard"
                        variant="outlined"
                        fullWidth
                        disabled
                        defaultValue={undefined}
                    />
                </Box>
                <Box width="48%">
                    <Label>Device status</Label>
                    <TextField
                        id="device-onboard"
                        variant="outlined"
                        fullWidth
                        disabled
                        defaultValue={deviceStatus[device.status]}
                    />
                </Box>
            </Box>
        </Card>
    );
};

export default DeviceDetails;
