import React from 'react';
import { Box } from '@mui/material';
import { useWatch } from 'react-hook-form';
import { NumberInput, required } from 'react-admin';
import { ReactComponent as TankImage } from '../images/tank.svg';
import HelperLabel from './HelperLabel';

const MeasurementsInput = () => {
    const surveyTypeId = useWatch({ name: 'surveyTypeId' });
    return (
        <Box display="flex" justifyContent="stretch">
            <Box display="flex" flex="1" justifyContent="center">
                {surveyTypeId === 6 && (
                    <Box display="flex" alignItems="center" position="relative" paddingRight="40px">
                        <TankImage style={{ width: '100%' }} />
                        <NumberInput
                            label="a"
                            source="questions.Distance to sensor"
                            validate={[required()]}
                            step={1}
                            min={0}
                            max={10000}
                            variant="outlined"
                            sx={{
                                position: 'absolute',
                                left: '25px',
                                top: '150px',
                                width: '90px',
                                '& .MuiInputBase-root ': {
                                    height: '35px',
                                },
                            }}
                        />
                        <NumberInput
                            label="b"
                            source="questions.Distance to max level"
                            validate={[required()]}
                            step={1}
                            min={0}
                            max={10000}
                            variant="outlined"
                            sx={{
                                position: 'absolute',
                                left: '135px',
                                top: '185px',
                                width: '90px',
                                '& .MuiInputBase-root ': {
                                    height: '35px',
                                },
                            }}
                        />
                        <NumberInput
                            label="C"
                            source="questions.Zero mark"
                            validate={[required()]}
                            step={1}
                            min={0}
                            max={10000}
                            variant="outlined"
                            sx={{
                                position: 'absolute',
                                left: '245px',
                                top: '300px',
                                width: '80px',
                                '& .MuiInputBase-root ': {
                                    height: '35px',
                                },
                            }}
                        />
                    </Box>
                )}
            </Box>

            <Box>
                {surveyTypeId === 6 && (
                    <Box
                        sx={{
                            backgroundColor: '#F5F5F5',
                            padding: '28px',
                        }}
                    >
                        <HelperLabel label="a" description="Distance to sensor" />
                        <HelperLabel label="b" description="Distance to max level" />
                        <HelperLabel label="c" description="Zero mark" />
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default MeasurementsInput;
