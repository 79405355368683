import { Box, LinearProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import Error from '../../components/Error';
import ROOT from '../../theme/root';

const useStyles = makeStyles({
    container: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        rowGap: 8,
    },
    loading: {
        minWidth: 150,
        display: 'flex',
        alignItems: 'center',
    },
    loadingBar: {
        width: '100%',
    },
    error: {
        margin: 0,
        display: 'flex',
        color: ROOT.COLORS.RED,
        alignItems: 'center',
    },
    errorIcon: {
        width: '0.8em',
        height: '0.8em',
        marginRight: 5,
    },
});

const PendingCommandsCounter = (props) => {
    const classes = useStyles();
    const { count, loading, error } = props;

    // Loading & Error
    if (loading) {
        return (
            <Box className={classes.loading}>
                <LinearProgress className={classes.loadingBar} />
            </Box>
        );
    }

    if (error) {
        return (
            <Error
                message={error}
                icon={true}
                iconClass={classes.errorIcon}
                classes={classes.error}
            />
        );
    }

    if (isNaN(count)) {
        return 'Pending commands not found';
    }

    return <Box className={classes.container}>{count}</Box>;
};

PendingCommandsCounter.propTypes = {
    count: PropTypes.number,
    loading: PropTypes.bool,
    error: PropTypes.string,
};

export default PendingCommandsCounter;
