import React from 'react';
import {
    TextField,
    DateField,
    List,
    ReferenceField,
    usePermissions,
    useLocale,
    Datagrid,
    FunctionField,
    Link,
} from 'react-admin';
import { useAppContext } from '../context/AppContext';
import SiteStatusField from '../sites/components/Status';
import GROUPS from '../auth/groups';

const DashboardSites = (props) => {
    const [appStore] = useAppContext();
    const locale = useLocale();
    const { permissions } = usePermissions();
    const siteFilter = {
        organisationId: appStore.organisationId != '-' ? appStore.organisationId : undefined,
        status: 4,
    };
    if (props.query) siteFilter.query = props.query;

    const hasProjectPermissions =
        !!permissions &&
        (permissions.includes(GROUPS.HSW_ADMIN) ||
            permissions.includes(GROUPS.HSW_USER) ||
            permissions.includes(GROUPS.CUSTOMER_ADMIN));

    const stopPropagation = (e) => e.stopPropagation();

    return (
        <>
            <List
                resource="sites"
                title="Sites"
                filter={siteFilter}
                actions={null}
                filters={null}
                sort={{ field: 'createdAt', order: 'DESC' }}
                disableSyncWithLocation
            >
                <Datagrid
                    rowClick={(id) => `/sites/${id}/Show/data`}
                    rowStyle={() => ({
                        height: 50,
                        boxSizing: 'content-box',
                    })}
                    bulkActionButtons={false}
                >
                    <TextField source="name" />
                    <ReferenceField
                        label="Project"
                        source="projectId"
                        reference="projects"
                        link={hasProjectPermissions ? 'show' : false}
                        filters={{ organisationId: appStore.organisationId }}
                    >
                        <TextField source="name" />
                    </ReferenceField>
                    {appStore.organisationId && (
                        <FunctionField
                            source="project.organisation"
                            label="Customer"
                            sortBy="projectId"
                            render={(record) => {
                                if (record && record.project && record.project.organisation) {
                                    const value = `organisations/${record.project.organisation.id}/show`;

                                    return (
                                        <Link to={value} onClick={stopPropagation}>
                                            {record.project.organisation.name}
                                        </Link>
                                    );
                                }
                                return '';
                            }}
                        />
                    )}
                    <TextField source="deviceId" label="Device ID" />
                    <SiteStatusField source="status" />
                    <TextField source="address" />
                    <DateField
                        source="createdAt"
                        label="Date Created"
                        options={{
                            day: 'numeric',
                            month: 'short',
                            year: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric',
                            second: 'numeric',
                            hour12: false,
                        }}
                        locales={locale}
                        showTime
                    />
                </Datagrid>
            </List>
        </>
    );
};

export default DashboardSites;
