import React from 'react';
import { Box, Button, Typography, Tooltip } from '@mui/material';
import InactiveThreshold from './InactiveThreshold';
import TamperThreshold from './TamperThreshold';
import WaterLevelThresholds from './WaterLevelThresholds';
import { asMerge, ruleTypes } from './rules';
import { useNotify } from 'ra-core';
import { makeStyles } from '@mui/styles';
import { useDelete, useRefresh } from 'react-admin';
import { validateWaterThreshold } from './waterLevelValidation';
import { waterLevelDuplicateValidation } from './waterLevelDuplicateValidation';

const useStyles = makeStyles(() => ({
    wideButton: {
        position: 'initial',
        display: 'initial',
    },
    inactive: {
        paddingBottom: '70px',
    },
}));

const asRule = (hierarchy, id, levelThresholds) => {
    const levelRule = {
        siteId: hierarchy !== 'project' ? id : undefined,
        projectId: hierarchy === 'project' ? id : undefined,
        ruleType: ruleTypes.reading,
        triggers:
            levelThresholds?.map((threshold) => {
                return { value: threshold.triggerEvents, unit: 1 };
            }) ?? [],
        conditions:
            levelThresholds?.map((threshold) => {
                return {
                    operator: threshold.operator || 0,
                    value: threshold.value,
                    fact: 'level',
                    isPercent: threshold.valueUnit === '%',
                };
            }) ?? [],
    };
    return levelRule;
};

let siteThresholds = (threshold) =>
    threshold?.filter((t) => t.projectId === null || t.projectId === undefined) ?? {};
const projectThresholds = (threshold) =>
    threshold?.filter((t) => t.projectId !== null && t.projectId !== undefined) ?? {};
if (siteThresholds.length === 0) {
    siteThresholds = projectThresholds;
}

const AlarmThresholds = ({
    onSave,
    onCancel,
    thresholdUnits = ['%', 'mm'],
    thresholds,
    tampering,
    tamperingProject,
    inactivity,
    edit,
    onEdit,
    permissions,
    hierarchy = 'project',
    id,
    siteStatus = 0,
    siteDepth,
    refreshData,
}) => {
    const [inactive, setInactive] = React.useState(
        inactivity ?? {
            checked: false,
            value: '2',
            unit: 'events',
        },
    );
    const [inactiveValue, setInactiveValue] = React.useState();
    const [inactiveUnit, setInactiveUnit] = React.useState();
    const [tamper, setTamper] = React.useState(tampering ?? false);
    const setTamperProject = React.useState(tamperingProject ?? false)[1];
    const [levelThresholds, setLevelThresholds] = React.useState();
    React.useEffect(() => setLevelThresholds(thresholds), [thresholds, setLevelThresholds]);
    React.useEffect(() => setTamperProject(tampering), [tampering, setTamperProject]);
    const [Delete, { data: deleted, error }] = useDelete();
    const notify = useNotify();
    const canEdit = (permissions && permissions.includes('hsw_admin')) === true ? true : false;
    const classes = useStyles();
    const refresh = useRefresh();

    React.useEffect(() => {
        if (inactivity) {
            setInactive(inactivity);
            const value = inactivity && inactive.triggers ? inactivity?.triggers[0].value : '';
            const unit = inactivity && inactive.triggers ? inactivity?.triggers[0].unit : '';
            setInactiveValue(value);
            setInactiveUnit(unit);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inactivity]);

    React.useEffect(() => {
        if (tampering && tampering !== tamper) setTamper(tampering);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tampering]);

    React.useEffect(() => {
        if (error) {
            notify('Site settings could not be deleted.', { type: 'warning' });
        }
        if (!deleted) return;
        const response = deleted;
        if (response.errors) {
            notify(response.errors.join(' '), { type: 'warning' });
            return;
        }
        notify('Site alarm settings deleted.', {
            type: 'success',
            messageArgs: { smart_count: 1 },
        });
        setLevelThresholds(projectThresholds(levelThresholds)); // may not be needed
        refreshData();
        refresh(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleted, error]);

    const handleCancel = (event) => {
        event.preventDefault();
        if (onCancel) onCancel();
    };

    let siteLevelThresholds = siteThresholds(levelThresholds);
    const projectLevelThresholds = projectThresholds(levelThresholds);
    if (siteLevelThresholds.length === 0) {
        siteLevelThresholds = projectLevelThresholds;
    }

    const handleSave = async (actionLevel) => {
        if (onSave) {
            const rules = [];
            const thresholds =
                actionLevel === 'project'
                    ? projectThresholds(levelThresholds)
                    : siteThresholds(levelThresholds);
            if (thresholds) {
                for (const threshold of thresholds) {
                    var [isValid, validation] = validateWaterThreshold(threshold, siteDepth);
                    if (!isValid) {
                        notify(validation);
                        return;
                    }
                }
                var [isNotDuplicate, duplicateValidation] =
                    waterLevelDuplicateValidation(thresholds);
                if (!isNotDuplicate) {
                    notify(duplicateValidation);
                    return;
                }

                // modified water level thresholds
                const triggers = thresholds.filter((t) => t.triggerEvents !== undefined);
                for (const trigger of triggers) {
                    const triggerRule = asRule(actionLevel, id, [trigger]);
                    rules.push(triggerRule);
                }

                // existing water level thresholds
                const ruleThresholds = thresholds.filter((t) => t.triggerEvents === undefined);
                for (const ruleThreshold of ruleThresholds) {
                    rules.push(ruleThreshold);
                }
            }
            if (tamper) {
                rules.push({
                    ruleType: ruleTypes.tampering,
                });
            }
            if (inactive) {
                if ('checked' in inactive) {
                    let isValid = true;
                    let error = '';
                    if (inactive.checked === true) {
                        if (inactive.value === '0') {
                            isValid = false;
                            error += 'The Inactive value cannot be 0. ';
                        }
                        if (inactive.unit === 'events') {
                            if (inactive.value < 1 || inactive.value > 5) {
                                isValid = false;
                                error += 'Trigger can be up to 5 events long.';
                            }
                        }
                        if (!isValid) {
                            notify('Could not save inactive alarm trigger - ' + error, {
                                type: 'warning',
                            });
                            return;
                        } else {
                            rules.push({
                                ruleType: ruleTypes.inactive,
                                triggers: [
                                    {
                                        value: inactive.value,
                                        unit: inactive.unit === 'events' ? 1 : 0,
                                    },
                                ],
                            });
                        }
                    }
                } else {
                    rules.push({
                        ruleType: ruleTypes.inactive,
                        triggers: [
                            {
                                value: inactive.triggers[0].value,
                                unit: inactive.triggers[0].unit,
                            },
                        ],
                    });
                }
            }
            // cater for the case where we delete project rules
            if (rules.length === 0) {
                if (actionLevel === 'site') {
                    rules.push({
                        triggers: [],
                        conditions: [
                            {
                                value: 999999999,
                                isPercent: true,
                            },
                        ],
                        siteId: id,
                    });
                }
            }
            onSave(rules);
        }
    };

    const handleSaveProject = async (event) => {
        event.preventDefault();
        if (hierarchy === 'site') {
            const rulestoDelete = siteThresholds(levelThresholds);
            await Delete('rules', { id, previousData: rulestoDelete });
            refresh(true);
        } else {
            await handleSave('project');
        }
    };

    const handleSaveSite = async (event) => {
        event.preventDefault();
        await handleSave('site');
        setLevelThresholds(siteThresholds(levelThresholds));
        refresh(true);
    };

    return (
        <Box minHeight="300px" position="relative">
            <Box display="flex" justifyContent="space-between" width="100%">
                <Typography variant="h6" gutterBottom>
                    Alarm Thresholds
                </Typography>
                {edit === true && canEdit && (
                    <Tooltip title="Clear current changes." arrow>
                        <Button onClick={handleCancel}>Cancel</Button>
                    </Tooltip>
                )}
                {!edit && canEdit && (hierarchy !== 'site' || siteStatus !== 5) && (
                    <Button
                        onClick={() => {
                            if (onEdit) onEdit();
                        }}
                    >
                        Edit
                    </Button>
                )}
            </Box>
            {projectLevelThresholds && hierarchy !== 'site' && (
                <WaterLevelThresholds
                    thresholds={asMerge(projectLevelThresholds)}
                    thresholdUnits={thresholdUnits}
                    onSetThresholds={(levels) => {
                        if (hierarchy !== 'project') return;
                        for (const level of levels) {
                            level.projectId = id;
                            level.siteId = null;
                        }
                        setLevelThresholds(levels);
                    }}
                    title="Project water Levels"
                    showMode={hierarchy === 'site' || edit === false}
                />
            )}

            {hierarchy === 'site' && (
                <WaterLevelThresholds
                    thresholds={asMerge(siteLevelThresholds)}
                    thresholdUnits={thresholdUnits}
                    onSetThresholds={(levels) => {
                        for (const level of levels) {
                            level.siteId = id;
                            level.projectId = null;
                        }
                        setLevelThresholds(levels);
                    }}
                    showMode={edit === false}
                    title="Site water Levels"
                    maxDepth={siteDepth}
                />
            )}

            <TamperThreshold
                checked={tamper}
                onChange={(val) => setTamper(val)}
                showMode={!edit} /* || tamperProject === true*/
            />
            <InactiveThreshold
                checked={'checked' in inactive ? inactive.checked : !!inactive}
                value={inactiveValue}
                valueUnit={inactiveUnit === 0 ? 'hrs' : 'events'}
                onChange={(check, val, unit) => {
                    setInactive({ checked: check, value: val, unit: unit });
                }}
                showMode={!edit}
                className={classes.inactive}
            />
            {edit === true && (
                <Box position="initial" bottom={0}>
                    <Button
                        onClick={handleSaveProject}
                        color="primary"
                        className={classes.wideButton}
                    >
                        {hierarchy === 'project' ? 'Save' : 'Inherit'} Project settings
                    </Button>
                    {hierarchy === 'site' && (
                        <Button
                            onClick={handleSaveSite}
                            color="primary"
                            className={classes.wideButton}
                        >
                            Override Project settings
                        </Button>
                    )}
                </Box>
            )}
        </Box>
    );
};

export default AlarmThresholds;
