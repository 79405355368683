import React, { useState } from 'react';
import { DeviceThermostat, ElectricBolt, Battery60, Speed } from '@mui/icons-material';
import { Box, Card, Divider, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Marker } from '@react-google-maps/api';
import { addDays } from 'date-fns';
import Map from '../../components/Map';
import SiteDeviceDetails from '../components/SiteDeviceDetails';
import LastUpdate from '../components/LastUpdate';
import useRaftData from './useRaftData';
import Controls from '../components/Controls';
import Temperature from './Temperature';
import DissolvedOxygen from './DissolvedOxygen';
import Turbidity from './Turbidity';
import Conductivity from './Conductivity';
import Ph from './Ph';
import Battery from './Battery';
import DashboardCard from './DashboardCard';
import { ReactComponent as OxygenIcon } from '../../icons/oxygen.svg';
import { ReactComponent as TurbidityIcon } from '../../icons/turbidity.svg';
import useRaftLatest from './useRaftLatest';
import GpsLocations from './GpsLocations';
import useGpsData from './useGpsData';

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 16,
        rowGap: 20,
    },
    dividerContainer: {
        flex: 1,
    },
    divider: {
        height: '100%',
        alignSelf: 'center',
    },
    dividerHorizontal: {
        height: 10,
    },
    data: {
        display: 'flex',
        flexDirection: 'row',
        columnGap: 20,
        minHeight: 450,
    },
    column: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        rowGap: 15,
        padding: 20,
    },
    latestReading: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    latestReadingData: {
        display: 'flex',
        flexDirection: 'row',
        rowGap: 25,
        columnGap: 15,
        flexWrap: 'wrap',
        justifyContent: 'center',
    },
    charts: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: 15,
        justifyContent: 'stretch',
        position: 'relative',
        overflow: 'visible',
    },
    chartRow: {
        display: 'flex',
        flexDirection: 'row',
        columnGap: 5,
        justifyContent: 'stretch',
        position: 'relative',
        height: 490,
    },
    mapContainer: {
        flex: 4,
        width: '100%',
        position: 'relative',
        overflow: 'hidden',
        borderRadius: '10px',
    },
    temperatureCard: {
        backgroundColor: COLORS.temperature.secondary,
        color: COLORS.temperature.primary,
    },
    dOxCard: {
        backgroundColor: COLORS.dissolvedOxygen.secondary,
        color: COLORS.dissolvedOxygen.primary,
        paddingLeft: 8,
        paddingRight: 8,
    },
    turbidityCard: {
        backgroundColor: COLORS.turbidity.secondary,
        color: COLORS.turbidity.primary,
    },
    conductivityCard: {
        backgroundColor: COLORS.conductivity.secondary,
        color: COLORS.conductivity.primary,
    },
    phCard: {
        backgroundColor: COLORS.pH.secondary,
        color: COLORS.pH.primary,
    },
    batteryCard: {
        backgroundColor: COLORS.battery.secondary,
        color: COLORS.battery.primary,
    },
}));

const COLORS = {
    temperature: {
        primary: '#B71C1C',
        secondary: '#FFCDD2',
    },
    turbidity: {
        primary: '#0D47A1',
        secondary: '#E1F5FE',
    },
    dissolvedOxygen: {
        primary: '#6A1B9A',
        secondary: '#E1BEE7',
    },
    conductivity: {
        primary: '#F57F17',
        secondary: '#FFF9C4',
    },
    pH: {
        primary: '#E65100',
        secondary: '#FFE0B2',
    },
    battery: {
        primary: '#1B5E20',
        secondary: '#E8F5E9',
    },
};

const SiteDataRaft = (props) => {
    const classes = useStyles();
    const { site } = props;
    const [fromDate, setFromDate] = useState(addDays(new Date(), -30));
    const [toDate, setToDate] = useState(new Date());

    const updateDates = (from, to) => {
        setFromDate(from);
        setToDate(to);
    };

    const { isLoading, error, data } = useRaftData(site.id, fromDate, toDate);
    const {
        isLoading: isLoadingLatest,
        error: errorLatest,
        data: latestReadingResult,
    } = useRaftLatest(site.id);
    const {
        isLoading: isLoadingGps,
        error: errorGps,
        data: dataGps,
    } = useGpsData(site.id, fromDate, toDate);

    const latestReading = {
        timestamp: '',
        temp: '',
        do: '',
        tur: '',
        cond: '',
        ph: '',
        bat: '',
    };

    let temperature = [];
    let dOx = [];
    let tur = [];
    let cond = [];
    let ph = [];
    let bat = [];

    if (data) {
        for (const reading of data) {
            const tempValue = reading.temp !== null ? reading.temp / 10 : reading.temp;
            const dOxValue = reading.do !== null ? reading.do / 10 : reading.do;
            const turValue = reading.tur !== null ? reading.tur / 10 : reading.tur;
            const phValue = reading.ph !== null ? reading.ph / 10 : reading.ph;
            temperature.push([reading.timestamp, tempValue]);
            dOx.push([reading.timestamp, dOxValue]);
            tur.push([reading.timestamp, turValue]);
            cond.push([reading.timestamp, reading.cond]);
            ph.push([reading.timestamp, phValue]);
            bat.push([reading.timestamp, reading.bat]);
        }
    }

    if (latestReadingResult) {
        latestReading.timestamp = latestReadingResult.timestamp;
        latestReading.temp = latestReadingResult.temp / 10 + ' ° C';
        latestReading.do = latestReadingResult.do / 10 + ' %';
        latestReading.tur = latestReadingResult.tur / 10 + ' NTU';
        latestReading.cond = latestReadingResult.cond + ' μS/cm';
        latestReading.ph = latestReadingResult.ph / 10;
        latestReading.bat = latestReadingResult.bat + ' %';
    }
    return (
        <Box className={classes.container}>
            <Card className={classes.data}>
                <Box className={classes.column}>
                    <SiteDeviceDetails
                        site={site}
                        device={site.devices ? site.devices[0] : undefined}
                    />
                    <Box className={classes.mapContainer}>
                        <Map
                            center={{
                                lat: +site.latitude,
                                lng: +site.longitude,
                            }}
                            zoom={15}
                            withLocationMem={false}
                        >
                            {site.latitude && (
                                <Marker
                                    position={{
                                        lat: +site.latitude,
                                        lng: +site.longitude,
                                    }}
                                />
                            )}
                        </Map>
                    </Box>
                </Box>
                <Box>
                    <Divider orientation="vertical" flexItem={true} className={classes.divider} />
                </Box>
                <Box className={classes.column}>
                    <Typography component="h6" variant="h6" mb="20px" mt="10px">
                        Latest reading information
                    </Typography>
                    <Box className={classes.latestReadingData}>
                        <DashboardCard
                            title="Temperature"
                            value={latestReading.temp}
                            className={classes.temperatureCard}
                            icon={<DeviceThermostat />}
                            loading={isLoadingLatest}
                            error={errorLatest}
                        />
                        <DashboardCard
                            title="Dissolved Oxygen Saturation"
                            value={latestReading.do}
                            className={classes.dOxCard}
                            icon={
                                <OxygenIcon
                                    width="24px"
                                    height="24px"
                                    fill={COLORS.dissolvedOxygen.primary}
                                />
                            }
                            loading={isLoadingLatest}
                            error={errorLatest}
                        />
                        <DashboardCard
                            title="Turbidity"
                            value={latestReading.tur}
                            className={classes.turbidityCard}
                            icon={
                                <TurbidityIcon
                                    width="24px"
                                    height="24px"
                                    fill={COLORS.turbidity.primary}
                                />
                            }
                            loading={isLoadingLatest}
                            error={errorLatest}
                        />
                        <DashboardCard
                            title="Conductivity"
                            value={latestReading.cond}
                            className={classes.conductivityCard}
                            icon={<ElectricBolt />}
                            loading={isLoadingLatest}
                            error={errorLatest}
                        />
                        <DashboardCard
                            title="pH"
                            value={latestReading.ph}
                            className={classes.phCard}
                            icon={<Speed />}
                            loading={isLoadingLatest}
                            error={errorLatest}
                        />
                        <DashboardCard
                            title="Battery"
                            value={latestReading.bat}
                            className={classes.batteryCard}
                            icon={<Battery60 />}
                            loading={isLoadingLatest}
                            error={errorLatest}
                        />
                    </Box>
                    <LastUpdate
                        latestReading={latestReading ? latestReading?.timestamp : undefined}
                    />
                </Box>
            </Card>
            <Card className={classes.charts}>
                <Controls
                    fromDate={fromDate}
                    toDate={toDate}
                    updateDates={updateDates}
                    useBorders={false}
                />
                <Box className={classes.chartRow}>
                    <Temperature
                        isLoading={isLoading}
                        error={error}
                        data={temperature}
                        color={COLORS.temperature.primary}
                        fromDate={fromDate}
                        toDate={toDate}
                    />
                    <Divider orientation="vertical" flexItem={true} className={classes.divider} />
                    <DissolvedOxygen
                        isLoading={isLoading}
                        error={error}
                        data={dOx}
                        color={COLORS.dissolvedOxygen.primary}
                        fromDate={fromDate}
                        toDate={toDate}
                    />
                </Box>

                <Divider flexItem={true} className={classes.dividerHorizontal} />

                <Box className={classes.chartRow}>
                    <Turbidity
                        isLoading={isLoading}
                        error={error}
                        data={tur}
                        color={COLORS.turbidity.primary}
                        fromDate={fromDate}
                        toDate={toDate}
                    />
                    <Divider orientation="vertical" flexItem={true} className={classes.divider} />
                    <Conductivity
                        isLoading={isLoading}
                        error={error}
                        data={cond}
                        color={COLORS.conductivity.primary}
                        fromDate={fromDate}
                        toDate={toDate}
                    />
                </Box>

                <Divider flexItem={true} className={classes.dividerHorizontal} />

                <Box className={classes.chartRow}>
                    <Ph
                        isLoading={isLoading}
                        error={error}
                        data={ph}
                        color={COLORS.pH.primary}
                        fromDate={fromDate}
                        toDate={toDate}
                    />
                    <Divider orientation="vertical" flexItem={true} className={classes.divider} />
                    <Battery
                        isLoading={isLoading}
                        error={error}
                        data={bat}
                        color={COLORS.battery.primary}
                        fromDate={fromDate}
                        toDate={toDate}
                    />
                </Box>

                <Divider flexItem={true} className={classes.dividerHorizontal} />

                <Box className={classes.chartRow}>
                    <GpsLocations isLoading={isLoadingGps} error={errorGps} data={dataGps} />
                    <Divider orientation="vertical" flexItem={true} className={classes.divider} />
                </Box>
            </Card>
        </Box>
    );
};

export default SiteDataRaft;
