import React from 'react';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { NavLink } from 'react-router-dom';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    breadcrumb: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    link: {
        fontSize: '14px',
        lineHeight: '19px',
        marginLeft: '2px',
        marginRight: '2px',
        fontWeight: 'bold',
        '& > a': {
            textDecoration: 'none',
            color: 'black',
        },
    },
}));

const Breadcrumb = (props) => {
    const classes = useStyles();
    const breadcrumbs = useBreadcrumbs();
    if (
        breadcrumbs.length > 0 &&
        breadcrumbs[breadcrumbs.length - 1].key.toLowerCase().endsWith('show')
    ) {
        breadcrumbs.pop();
    }

    return (
        <div className={classes.root}>
            {breadcrumbs
                .filter((b) => b.key !== '/')
                .map(({ match, breadcrumb }, index) => {
                    let replace = false;
                    let crumbLink = '';

                    if (props.overrides) {
                        const keys = Object.keys(props.overrides).map((k) => k.toLowerCase());
                        if (
                            breadcrumb.props.children &&
                            keys.includes(
                                breadcrumb.props.children.toLowerCase().replaceAll(' ', '-'),
                            )
                        ) {
                            replace = true;
                            crumbLink =
                                props.overrides[
                                    breadcrumb.props.children.toLowerCase().replaceAll(' ', '-')
                                ];
                        }
                    }

                    return (
                        <React.Fragment key={`nav-link-${index}`}>
                            {index > 0 && (
                                <span key={`link-${index}`} className={classes.link}>
                                    {'>'}
                                </span>
                            )}
                            <span key={match.pathname} className={classes.link}>
                                <NavLink key={`nav-${match.pathname}`} to={match.pathname}>
                                    {replace ? crumbLink : breadcrumb}
                                </NavLink>
                            </span>
                        </React.Fragment>
                    );
                })}
        </div>
    );
};

export default Breadcrumb;
