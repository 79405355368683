import {
    Box,
    Card,
    LinearProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { DateField, useLocale } from 'react-admin';
import Layout from '../../components/Layout';
import useFetch from '../../hooks/useFetch';
import ROOT from '../../theme/root';

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 0,
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    error: {
        color: ROOT.COLORS.RED,
        fontSize: 14,
    },
    completed: {
        color: ROOT.COLORS.GREEN,
        textTransform: 'capitalize',
    },
    cancelled: {
        color: ROOT.COLORS.RED,
        textTransform: 'capitalize',
    },
    pending: {
        color: ROOT.COLORS.ORANGE,
        textTransform: 'capitalize',
    },
}));

// Fetch and display all commands history
const CommandsHistory = ({ deviceId }) => {
    const classes = useStyles();
    const locale = useLocale();

    // Give color to command status
    const getStatusClass = (command) => {
        if (command) {
            switch (command.status) {
                case 'completed':
                    return classes.completed;
                case 'cancelled':
                    return classes.cancelled;
                case 'pending':
                    return classes.pending;
                default:
                    return null;
            }
        }
        return null;
    };

    // Fetch commands
    const filter = encodeURI(
        JSON.stringify({
            device_id: deviceId,
        }),
    );

    const {
        response: data,
        error: errorFetch,
        isLoading: loading,
    } = useFetch(`${process.env.REACT_APP_API_BASE_URL}/downlinkCommands?filter=${filter}`, {
        method: 'GET',
    });

    return (
        <Layout classes={classes.container}>
            <Box marginBottom={5}>
                <Card>
                    <Box className={classes.header}>
                        {errorFetch && (
                            <span className={classes.error}>Unable to fetch commands history</span>
                        )}
                    </Box>

                    {loading && <LinearProgress aria-label="pending-commands-loading" />}
                    {!loading && data && data.length > 0 && (
                        <TableContainer component={Paper}>
                            <Table aria-label="Commands History Grid" size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Command ID</TableCell>
                                        <TableCell>Parameter</TableCell>
                                        <TableCell>Value</TableCell>
                                        <TableCell>Received Time</TableCell>
                                        <TableCell>Processed time</TableCell>
                                        <TableCell>Status</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data.map((row) => {
                                        const statusClass = getStatusClass(row);

                                        return (
                                            <TableRow key={row.createdTime}>
                                                <TableCell component="th" scope="row">
                                                    {row.commandId}
                                                </TableCell>
                                                <TableCell>{row.name}</TableCell>
                                                <TableCell>{`${row.value} (${row.unit})`}</TableCell>

                                                <TableCell>
                                                    <DateField
                                                        record={row}
                                                        source="createdTime"
                                                        options={{
                                                            day: 'numeric',
                                                            month: 'short',
                                                            year: 'numeric',
                                                            hour: 'numeric',
                                                            minute: 'numeric',
                                                            second: 'numeric',
                                                            hourCycle: 'h24',
                                                        }}
                                                        locales={locale}
                                                        showTime
                                                        sortable={false}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <DateField
                                                        record={row}
                                                        source="completedTime"
                                                        options={{
                                                            day: 'numeric',
                                                            month: 'short',
                                                            year: 'numeric',
                                                            hour: 'numeric',
                                                            minute: 'numeric',
                                                            second: 'numeric',
                                                            hourCycle: 'h24',
                                                        }}
                                                        locales={locale}
                                                        showTime
                                                        sortable={false}
                                                    />
                                                </TableCell>
                                                <TableCell className={statusClass}>
                                                    {row.status}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}

                    {!loading && (!data || data.length === 0) && (
                        <p role="status" aria-label="downlink-history-not-found">
                            No downlink commands for device {deviceId || ''} yet
                        </p>
                    )}
                </Card>
            </Box>
        </Layout>
    );
};
export default CommandsHistory;
