import React from 'react';
import { Box, Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import InstallDevice from './InstallDevice';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    dialog: {
        minHeight: '500px',
        maxHeight: '500px',
        minWidth: '480px',
        maxWidth: '480px',
    },
}));

const SiteOnboardingDialog = ({ site, isOpen, onClose, title }) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(isOpen ?? false);
    const handleClose = () => {
        setOpen(false);
        if (onClose) onClose();
    };

    React.useEffect(() => {
        setOpen(isOpen);
    }, [isOpen]);

    return (
        <Dialog
            open={open}
            onClose={() => handleClose()}
            onBackdropClick={() => handleClose()}
            aria-labelledby="install-device"
            maxWidth="sm"
            fullWidth
            classes={{ paper: classes.dialog }}
        >
            <DialogContent style={{ overflow: 'hidden' }}>
                <InstallDevice site={site} onClose={() => handleClose()} title={title} />
            </DialogContent>
        </Dialog>
    );
};

const SiteOnboard = ({ site, title = 'Install Device' }) => {
    const [openSiteOnboard, setOpenSiteOnboard] = React.useState(false);

    return (
        <Box>
            <SiteOnboardingDialog
                site={site}
                isOpen={openSiteOnboard}
                onClose={() => {
                    setOpenSiteOnboard(false);
                }}
                title={title}
            />
            <Button
                onClick={() => setOpenSiteOnboard(true)}
                color="primary"
                style={{
                    fontSize: '13px',
                    maxWidth: '300px',
                    backgroundColor: '#00AEEF',
                    color: '#fff',
                }}
            >
                {title}
            </Button>
        </Box>
    );
};

export default SiteOnboard;
