import React from 'react';
import { Layout, useAuthState, useLogout } from 'react-admin';
import Menu from './menu/Menu';
import { useAppContext } from './context/AppContext';
import GROUPS from './auth/groups';
import { getUserInfo } from './auth/auth0Functions';
import PortalSidebar from './menu/Sidebar';

const PortalLayout = (props) => {
    const { authenticated } = useAuthState();
    const [appStore, setAppStore] = useAppContext();
    const handleLogout = useLogout();

    const getIdentity = async () => {
        try {
            const userInfo = await getUserInfo();
            const groups = userInfo.roles;

            if (!appStore.userId || userInfo.externalUserId !== appStore.userId) {
                setAppStore({
                    organisationId:
                        groups.includes(GROUPS.HSW_USER) || groups.includes(GROUPS.HSW_ADMIN)
                            ? appStore.organisationId
                            : userInfo.organisationId,
                    userId: userInfo.externalUserId,
                    email: userInfo.email ?? '',
                });
            }
        } catch (err) {
            handleLogout();
            return;
        }
    };

    if (authenticated) getIdentity();
    return <Layout {...props} menu={Menu} appBar={() => <></>} sidebar={PortalSidebar} />;
};

export default PortalLayout;
