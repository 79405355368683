import React from 'react';
import { Box } from '@mui/material';
import { useRedirect, useGetOne } from 'react-admin';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    name: {
        fontSize: '24px',
        fontWeight: 'bold',
        letterSpacing: '-0.58px',
        lineHeight: '32px',
        textDecoration: 'underline',
        maxWidth: '115px',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflowX: 'hidden',
    },
    label: {
        fontSize: '14px',
        letterSpacing: '-0.34px',
        lineHeight: '19px',
        color: '#636364;',
    },
    field: {
        color: '#0B0B0D',
        fontSize: '16px',
    },
    status: {
        backgroundColor: '#5CECBF',
        color: '#0B0B0D',
        borderRadius: '4px',
        height: '27px',
        minWidth: '66px',
    },
}));

const getStatus = (statusId) => {
    switch (statusId) {
        case 0:
            return 'Pending Survey';
        case 1:
            return 'Survey Submitted';
        case 2:
            return 'Approved';
        case 3:
            return 'Rejected';
        case 4:
            return 'Installed';
        case 5:
            return 'Deleted';
        default:
            break;
    }
};

const getAlarm = (dashboardSite) => {
    if (dashboardSite != undefined) {
        let activeAlarms = [];
        if (dashboardSite.tamperAlarmsCount != '0') {
            activeAlarms.push('Tamper');
        }
        if (dashboardSite.inactiveAlarmsCount != '0') {
            activeAlarms.push('Inactive');
        }
        if (dashboardSite.levelAlarmsCount != '0') {
            activeAlarms.push('Level');
        }
        if (!activeAlarms.length) {
            activeAlarms.push('None');
        }
        return activeAlarms.join(', ');
    }
    return 'None';
};

const SiteDetails = (props) => {
    const classes = useStyles();
    const redirect = useRedirect();
    const handleSelectSite = (id) => {
        if (props.deviceId) redirect(`/sites/${id}/Show/data`);
    };
    const { data } = useGetOne('sites', { id: props.id });
    const AlarmType = getAlarm(props.dashboardSite);
    return (
        <Box display="flex" flexDirection="column">
            <Box mb="5px">
                <Box
                    display="flex"
                    width="100%"
                    onClick={() => handleSelectSite(props.id)}
                    style={{ cursor: props.deviceId ? 'pointer' : 'inherit' }}
                >
                    <span className={classes.name}>{props.name}</span>
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        ml="12px"
                        className={classes.status}
                    >
                        {getStatus(props.status)}
                    </Box>
                </Box>
                <Box display="flex" flexDirection="column" width="100%" mt="8px">
                    <span className={classes.label}>Owner </span>
                    {data.project.organisation.name}
                </Box>
            </Box>
            <Box display="flex" flexDirection="column" mb="5px" mt="8px" width="100%">
                <span className={classes.label}>Address </span> {props.address}
            </Box>
            <Box display="flex" flexDirection="column" mb="5px" mt="8px" width="100%">
                <span className={classes.label}>Active Device ID </span> {props.deviceId}
            </Box>
            <Box display="flex" flexDirection="column" mb="5px" mt="8px" width="100%">
                <span className={classes.label}>Alarm </span> {AlarmType}
            </Box>
        </Box>
    );
};

export default SiteDetails;
