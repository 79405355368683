import * as React from 'react';
import { useState } from 'react';
import { useNotify, useRedirect } from 'react-admin';
import { ThemeProvider, makeStyles } from '@mui/styles';
import { createTheme } from '@mui/material/styles';
import { Box, Card, TextField, FormControl, Button, Link, CircularProgress } from '@mui/material';
import Theme from '../theme/index';
import { ReactComponent as Logo } from '../img/hynds-aquanect-logo-grey.svg';
import { forgotPassword } from '../auth/auth0Functions';

const useStyles = makeStyles((theme) => ({
    formControl: {
        marginTop: theme.spacing(3),
        minWidth: 120,
        width: '100%',
    },
    label: {
        color: '#636364',
        fontSize: '14px',
        letterSpacing: '-0.34px',
        lineHeight: '19px',
        marginBottom: '4px',
    },
    submit: {
        color: '#fff',
        backgroundColor: '#00AEEF',
        height: '45px',
        width: '100%',
        marginTop: '32px',
        marginBottom: '24px',
    },
}));

const ForgotPassword = () => {
    const classes = useStyles();
    const [email, setEmail] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const redirect = useRedirect();
    const notify = useNotify();

    const submit = async (e) => {
        e.preventDefault();
        if (!email || !email.length) {
            notify('Email is required.', { type: 'error' });
            return;
        }

        setSubmitting(true);
        try {
            await forgotPassword(email)
                .then(() => {
                    setSubmitting(false);
                    notify('Reset password link has been sent.', { type: 'success' });
                    return;
                })
                .catch(() => {
                    notify('Something went wrong, please try again', { type: 'error' });
                    setSubmitting(false);
                });
        } catch (error) {
            setSubmitting(false);
            notify('Something went wrong, please try again', { type: 'error' });
            return;
        }
    };

    return (
        <ThemeProvider theme={createTheme(Theme)}>
            <Box
                display="flex"
                width="100%"
                alignItems="center"
                flexDirection="column"
                justifyContent="center"
                height="100vh"
                style={{ backgroundColor: '#f5f5f8' }}
            >
                <Logo height="100px" />
                <Card style={{ padding: '16px', maxWidth: '360px', textAlign: 'center' }}>
                    <h2>Forgot Password</h2>
                    <form onSubmit={submit} style={{ textAlign: 'left' }}>
                        <span className={classes.label}>
                            Please enter your email to request a reset password link.
                        </span>
                        <FormControl className={classes.formControl}>
                            <span className={classes.label}>Email</span>
                            <TextField
                                name="email"
                                type="email"
                                variant="outlined"
                                fullWidth
                                value={email}
                                onChange={(e) => setEmail(e.target.value.trim())}
                            />
                        </FormControl>
                        <Button
                            type="submit"
                            onClick={submit}
                            color="primary"
                            disabled={submitting}
                            className={classes.submit}
                        >
                            Send reset password link
                            {submitting && (
                                <CircularProgress
                                    style={{ marginLeft: '8px' }}
                                    size={18}
                                    color="inherit"
                                />
                            )}
                        </Button>
                    </form>
                    <Link style={{ cursor: 'pointer' }} onClick={() => redirect('/login')}>
                        Back to Login
                    </Link>
                </Card>
            </Box>
        </ThemeProvider>
    );
};

export default ForgotPassword;
