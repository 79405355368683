import React, { useEffect, useState } from 'react';
import { useLevelDataContext } from '../dataproviders';
import LineChart, { defaultChartOptions, defaultSeries } from './LineChart';

const chartOptions = {
    title: {
        ...defaultChartOptions.title,
        text: 'Distance and amplitude',
    },
    yAxis: [
        {
            ...defaultChartOptions.yAxis[0],
            title: {
                text: 'distance (mm)',
            },
        },
        {
            ...defaultChartOptions.yAxis[0],
            title: {
                text: 'amplitude',
            },
            opposite: true,
            tickAmount: undefined,
        },
    ],
};

const createSeries = (data, name, property, yAxis = 0) => {
    const series = {
        ...defaultSeries,
        name,
        data: (data && data[`${property}`]) || [],
        yAxis,
    };
    return series;
};

const DistanceAmplitude = () => {
    const { distAmp, from, to } = useLevelDataContext();
    const [series, setSeries] = useState();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(!!distAmp.loading);
        if (distAmp.loading == true) return;
        const a1 = createSeries(distAmp.data, 'a1', 'a1', 1);
        const a2 = createSeries(distAmp.data, 'a2', 'a2', 1);
        const d1 = createSeries(distAmp.data, 'd1', 'd1');
        const d2 = createSeries(distAmp.data, 'd2', 'd2');
        setSeries([a1, a2, d1, d2]);
    }, [distAmp]);

    return (
        <LineChart
            chartOptions={chartOptions}
            series={series}
            loading={isLoading}
            error={distAmp.error?.message}
            min={from}
            max={to}
        />
    );
};

export default DistanceAmplitude;
