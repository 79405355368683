import { Box, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useState, useEffect } from 'react';
import {
    Confirm,
    useDelete,
    useNotify,
    useRedirect,
    useRefresh,
    useShowContext,
    useUnselectAll,
} from 'react-admin';
import GROUPS from '../auth/groups';

const useStyles = makeStyles(() => ({
    remove: {
        color: '#D0021B',
        fontSize: '14px',
    },
}));

const UnlinkDevice = (props) => {
    // Props
    const { permissions } = props;

    // Hooks
    const { record: device } = useShowContext();
    const classes = useStyles();
    const [confirmOpen, setConfirmOpen] = useState(false);
    const notify = useNotify();
    const [deleteOne, { data: deleted, isLoading: loading, error }] = useDelete();
    const refresh = useRefresh();
    const redirect = useRedirect();
    const unselectAll = useUnselectAll('sites');

    // Effect Hooks
    useEffect(() => {
        if (error) {
            notify('Device could not be unlinked.', { type: 'error' });
            return;
        }

        if (deleted) {
            setTimeout(() => {
                unselectAll();
                refresh();
                notify('Device unlinked.', { type: 'success' });
                redirect('list', 'devices');
            }, 900);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error, deleted]);

    // Event Handlers
    const handleRemoveSite = async () => {
        deleteOne('devices', { id: device.id, previousData: device });
        setConfirmOpen(false);
    };

    // Hide Button if we don't have the info needed
    if (!device || !permissions) return <></>;

    return (
        <Box display="flex" flexGrow="1" flexDirection="row-reverse">
            {device.siteId && permissions.indexOf(GROUPS.HSW_ADMIN) >= 0 && (
                <Button
                    disabled={loading}
                    className={classes.remove}
                    onClick={() => setConfirmOpen(true)}
                    variant="secondary"
                >
                    Unlink this device
                </Button>
            )}
            <Confirm
                isOpen={confirmOpen}
                loading={loading}
                title="Unlink device"
                content="Are you sure you want to unlink this device?"
                onConfirm={() => handleRemoveSite()}
                onClose={() => setConfirmOpen(false)}
            />
        </Box>
    );
};

export default UnlinkDevice;
