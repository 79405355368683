import React from 'react';
import { useRecordContext } from 'react-admin';
import ROOT from '../theme/root';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    container: {
        borderRadius: ROOT.BORDERS.RADIUS,
        width: 'fit-content',
        minWidth: '70px',
        height: '26px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0px 8px',
        fontWeight: 'bold',
    },
}));

const styles = {
    inactive: {
        color: '#fff',
        backgroundColor: '#949495',
    },
    level: {
        color: '#fff',
        backgroundColor: ROOT.COLORS.PRIMARY,
    },
    tampering: {
        color: '#fff',
        backgroundColor: ROOT.COLORS.RED,
    },
    catchAll: {
        color: '#363637',
        backgroundColor: '#D8D8D8',
    },
};

const statuses = [
    { id: 0, name: 'Level', style: styles.level },
    { id: 1, name: 'Tampering', style: styles.tampering },
    { id: 2, name: 'Inactive', style: styles.inactive },
];

const AlarmTypeField = (props) => {
    const { source } = props;
    const record = useRecordContext(props);
    const status = statuses.find((x) => x.id === record[`${source}`]);
    const classes = useStyles();

    return (
        <div className={classes.container} style={status.style}>
            {status.name}
        </div>
    );
};

export default AlarmTypeField;
