const GoogleAutoComplete = async (text, mapOptions) =>
    new Promise((resolve, reject) => {
        if (!text) {
            return reject('Need valid text input');
        }
        // for use in things like GatsbyJS where the html is generated first
        if (typeof window === 'undefined') {
            return reject('Need valid window object');
        }
        try {
            new window.google.maps.places.AutocompleteService(text, mapOptions).getPlacePredictions(
                {
                    input: text,
                    componentRestrictions: { country: ['nz', 'au'] },
                    fields: ['address_components', 'geometry'],
                },
                resolve,
            );
        } catch (e) {
            reject(e);
        }
    });
export default GoogleAutoComplete;
