import { useGetManyReference } from 'react-admin';

const useGetPendingCommands = (deviceId, last = true) => {
    // pagination and sorting are not needed here. Only adding for react-admin
    const {
        data,
        isLoading: loading,
        error,
        refetch,
    } = useGetManyReference('downlinkCommands', {
        target: 'device_id',
        id: deviceId,
        pagination: { page: 1, perPage: 25 },
        sort: { field: 'id', order: 'DESC' },
        filter: { last, status: 'pending' },
    });

    const count = (data && data.length) || 0;
    const ids = data && data.map((records) => records.id);
    return {
        loading,
        error: (!loading && !data) || error ? "Couldn't load pending commands" : null,
        count,
        ids,
        data,
        refetch,
    };
};

export default useGetPendingCommands;
