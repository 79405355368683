import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box, LinearProgress, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useAlarmAnalyticsContext } from '../dataproviders';
import LevelAlarmStatus from './LevelAlarmStatus';
import { statuses } from '../../../alarms/alarm';
import { getDateString } from '../../utils';
import ROOT from '../../../theme/root';
import Error from '../../../components/Error';

const getAlarmAckStatus = (statusNumber) => {
    const alarmStatus = statuses.find((status) => status.id === statusNumber);
    return (alarmStatus && alarmStatus.name) || '';
};

const columns = [
    {
        field: 'deviceId',
        headerName: 'Device ID',
        sortable: false,
        maxWidth: 150,
        flex: 1,
    },
    {
        field: 'description',
        headerName: 'Details',
        sortable: false,
        maxWidth: 300,
        flex: 1,
    },
    {
        field: 'alarmStatus',
        headerName: 'Alarm Status',
        sortable: true,
        valueFormatter: ({ value }) => getAlarmAckStatus(value),
        maxWidth: 200,
        flex: 1,
    },
    {
        field: 'triggerStatus',
        headerName: 'Level Alarm',
        sortable: true,
        renderCell: LevelAlarmStatus,
        maxWidth: 150,
        flex: 1,
    },
    {
        field: 'createdAt',
        headerName: 'Date Created',
        sortable: true,
        valueFormatter: ({ value }) => getDateString(value),
        maxWidth: 220,
        flex: 1,
    },
    {
        field: 'endDate',
        headerName: 'End Time',
        sortable: true,
        valueFormatter: ({ value }) => getDateString(value),
        maxWidth: 220,
        flex: 1,
    },
    {
        field: 'duration',
        headerName: 'Duration',
        sortable: false,
        flex: 1,
    },
];

const useStyles = makeStyles(() => ({
    container: {
        backgroundColor: '#FFFFFF',
        border: ROOT.BORDERS.BORDER,
        borderRadius: '4px',
        padding: '16px',
        width: '100%',
    },
}));

const LevelAlarmsList = () => {
    const classes = useStyles();
    const { general: data, isLoading, error } = useAlarmAnalyticsContext();

    return (
        <Box className={classes.container}>
            <Typography variant="h6" component="h6" mb="20px" mt="10px">
                Level alarms
            </Typography>
            {isLoading && (
                <Box>
                    <LinearProgress />
                </Box>
            )}
            {error && <Error message={`Cannot load level alarms. Error details: ${error}.`} />}

            {!isLoading && !error && (
                <Box sx={{ height: 400, width: '100%' }} mt={'20px'}>
                    <DataGrid
                        headerHeight={40}
                        rows={data}
                        columns={columns}
                        pageSize={100}
                        rowsPerPageOptions={[100]}
                        disableSelectionOnClick
                        disableColumnFilter
                        disableColumnMenu
                        isRowSelectable={() => false}
                        loading={false}
                        hideFooter={false}
                        disableVirtualization={false}
                        sx={{
                            border: 'none',
                            '& .MuiDataGrid-columnHeaders': {
                                border: 'none',
                            },
                            '& .MuiDataGrid-columnHeader': {
                                backgroundColor: '#F2F3F5',
                                borderRadius: '4px',
                                height: '40px',
                                borderBottom: 'none',
                            },

                            '& .MuiDataGrid-columnSeparator': {
                                display: 'none',
                            },

                            '& .MuiDataGrid-columnHeaderTitle': {
                                fontWeight: 'bold',
                                color: ROOT.LABELS.COLOR,
                            },
                        }}
                    />
                </Box>
            )}
        </Box>
    );
};

export default LevelAlarmsList;
