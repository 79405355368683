import React, { useState } from 'react';
import { TextField, InputAdornment, IconButton } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const PasswordField = (props) => {
    const { name, variant, fullWidth, value, onChange, placeholder } = props;

    const [show, setShow] = useState(false);

    const handleToggle = () => {
        setShow((prev) => !prev);
    };

    return (
        <TextField
            name={name}
            type={show ? 'text' : 'password'}
            variant={variant}
            fullWidth={fullWidth}
            value={value}
            onChange={(event) => onChange(event.target.value)}
            placeholder={placeholder}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleToggle}
                            //onMouseDown={handleMouseDownPassword}
                        >
                            {show ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                ),
            }}
        />
    );
};

export default PasswordField;
