import ListAlarms from './List';

const statuses = [
    { id: 0, name: 'Acknowledged' },
    { id: 1, name: 'Unacknowledged' },
    { id: 2, name: 'Deleted' },
];

const alarmTypes = [
    { id: 0, name: 'Level' },
    { id: 1, name: 'Tampering' },
    { id: 2, name: 'Inactive' },
];

const alarms = {
    list: ListAlarms,
};

export default alarms;
export { statuses, alarmTypes };
