import React from 'react';
import ROOT from '../theme/root';

const Label = (props) => {
    const { sub } = props;
    return (
        <label
            style={{
                color: sub ? '#0B0B0D' : ROOT.LABELS.COLOR,
                fontSize: sub ? '14px' : '12px',
                fontWeight: sub ? 'bold' : ROOT.LABELS.FONT_WEIGHT,
                marginBottom: '4px',
                display: 'block',
            }}
        >
            <span>{props.children}</span>
        </label>
    );
};

export default Label;

// TODO: This could use a tidy up
