import React from 'react';
import {
    Confirm,
    useNotify,
    useRefresh,
    useRedirect,
    useUpdate,
    useRecordContext,
} from 'react-admin';
import { Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const DeleteButton = () => {
    const notify = useNotify();
    const redirect = useRedirect();
    const refresh = useRefresh();
    const [openConfirm, setOpenConfirm] = React.useState(false);
    const [update, { data: deleted, isLoading: loading, error }] = useUpdate();
    const record = useRecordContext();
    const handleDelete = async (event) => {
        event.preventDefault();
        const device = record;
        device.toDate = null;
        device.status = 0;
        await update('devices', { id: record.id, data: device });
        notify('Device deleted.', { type: 'success', messageArgs: { smart_count: 1 } });
        redirect('/devices');
        refresh();
    };
    React.useEffect(() => {
        if (error) {
            notify('Device could not be deleted.', { type: 'warning' });
        }
        if (!deleted) return;
        const response = deleted;
        if (response.errors) {
            notify(response.errors.join(' '), { type: 'warning' });
            return;
        }
        notify('Device deleted.', { type: 'success', messageArgs: { smart_count: 1 } });
        // redirect('/devices');
        // setTimeout(refresh, 500);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleted, error]);

    if (error) return <p>Could not remove device.</p>;
    return (
        <>
            <Button
                disabled={loading}
                onClick={() => setOpenConfirm(true)}
                startIcon={<DeleteIcon />}
                color="secondary"
                style={{ color: '#f44336' }}
            >
                Delete
            </Button>

            <Confirm
                isOpen={openConfirm}
                loading={loading}
                title="Delete device"
                content="Are you sure you want to delete this device?"
                onConfirm={(evnt) => handleDelete(evnt)}
                onClose={() => setOpenConfirm(false)}
            />
        </>
    );
};

export default DeleteButton;
