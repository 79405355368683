/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Box, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { Confirm, usePermissions } from 'react-admin';
import { uploadSurveyImage, deleteSurveyImage, fetchImages } from './EditSurvey';
import Loading from '../../components/Loading';
import GROUPS from '../../auth/groups';

const useStyles = makeStyles(() => ({
    image: {
        borderRadius: '4px',
        height: '130px',
        width: '130px',
        marginBottom: '24px',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    imagePreview: {
        display: 'inline-block',
        maxWidth: '60px',
        maxHeight: '60px',
        margin: '2px',
        padding: '5px',
    },
    remove: {
        color: '#D0021B',
        fontSize: '14px',
    },
    buttonRight: {
        display: 'flex',
        float: 'right',
    },
}));

const SurveyAttachments = (props) => {
    const [images, setImages] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedImage, setSelectedImage] = React.useState('');
    const [imageIndex, setImageIndex] = useState(0);
    const [isEditMode, setIsEditMode] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [siteStatus] = React.useState(props.siteStatus);
    const [confirmDelete, setConfirmDelete] = React.useState(false);
    const classes = useStyles();
    const { permissions } = usePermissions();
    const isHyndsAdmin = !!permissions && permissions.includes(GROUPS.HSW_ADMIN);

    const isValidStatus = siteStatus == 1 || siteStatus == 2 || siteStatus == 4;

    const toggleOpen = (event, key) => {
        setIsOpen(!isOpen);
        setImageIndex(key);
    };

    const next = () => {
        setImageIndex((imageIndex + 1) % images.length);
    };

    const prev = () => {
        setImageIndex((imageIndex + images.length - 1) % images.length);
    };

    useEffect(async () => {
        if (props.paths) await fetchSurveyImages();
    }, [props.paths]);

    const fetchSurveyImages = async () => {
        setIsUploading(true);
        var [images] =
            (await fetchImages(props.record.siteId).then((response) => {
                images = response[2] === undefined ? '' : response[2];
                setImages(images);
            })) ?? [];
        setIsUploading(false);
    };

    const handleDelete = async () => {
        setIsUploading(true);
        setConfirmDelete(false);
        await deleteSurveyImage(props.record.id, selectedImage);
        await fetchSurveyImages();
    };

    const onFileChange = async (event) => {
        var file = event.target.files[0];
        setIsUploading(true);
        await uploadSurveyImage(file, props.record.id);
        await fetchSurveyImages();
    };

    const deleteImage = async (src) => {
        const arrSrc = src.split('/');
        const key = arrSrc[3] + '/' + arrSrc[4] + '/' + arrSrc[5].split('?')[0];
        setSelectedImage(key);
        setConfirmDelete(true);
    };

    if (isUploading) return <Loading />;

    if (!images || images.length === 0)
        return (
            <div>
                {!isEditMode && isValidStatus && isHyndsAdmin && (
                    <div style={{ minHeight: '24px' }}>
                        <Button
                            onClick={() => setIsEditMode(true)}
                            color="primary"
                            className={classes.buttonRight}
                        >
                            Edit
                        </Button>
                    </div>
                )}
                {isEditMode && (
                    <div>
                        <Button variant="contained" component="label" onChange={onFileChange}>
                            Choose File
                            <input type="file" hidden />
                        </Button>
                        <Button onClick={() => setIsEditMode(false)} color="primary">
                            Cancel
                        </Button>
                    </div>
                )}
            </div>
        );

    return (
        <div>
            {!isEditMode && isValidStatus && isHyndsAdmin && (
                <Button
                    onClick={() => setIsEditMode(true)}
                    color="primary"
                    className={classes.buttonRight}
                >
                    Edit
                </Button>
            )}
            <div style={{ marginBottom: '24px' }}>{`Attachments (${images.length})`}</div>
            <Box display="flex" flexWrap="wrap">
                {images.map((img, key) => (
                    <Box marginRight={1} key={key}>
                        {isEditMode && (
                            <div>
                                <Button className={classes.remove} onClick={() => deleteImage(img)}>
                                    Remove image
                                </Button>
                            </div>
                        )}
                        <img
                            className={classes.image}
                            onClick={(event) => toggleOpen(event, key)}
                            src={img}
                        />
                    </Box>
                ))}
            </Box>

            {isOpen && (
                <Lightbox
                    mainSrc={images[parseInt(imageIndex)]}
                    nextSrc={images[(imageIndex + 1) % images.length]}
                    prevSrc={images[(imageIndex + images.length - 1) % images.length]}
                    onMoveNextRequest={next}
                    onMovePrevRequest={prev}
                    onCloseRequest={toggleOpen}
                />
            )}
            <br></br>
            {isEditMode && (
                <div>
                    <Button variant="contained" component="label">
                        Choose File to upload
                        <input type="file" hidden onChange={onFileChange} />
                    </Button>
                    &nbsp;&nbsp;&nbsp;
                    <Button onClick={() => setIsEditMode(false)} color="primary">
                        View
                    </Button>
                </div>
            )}
            <Confirm
                isOpen={confirmDelete}
                loading={false}
                title="Deleting survey photo"
                content="Are you sure? This action cannot be undone. But you can always re-upload the image"
                onConfirm={() => handleDelete()}
                onClose={() => setConfirmDelete(false)}
            />
        </div>
    );
};

export default SurveyAttachments;
