import {
    Box,
    LinearProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import DatetimeIntl from '../../components/DatetimeIntl';
import Error from '../../components/Error';
import ROOT from '../../theme/root';

const useStyles = makeStyles({
    container: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        rowGap: 8,
    },
    loading: {
        minWidth: 150,
        display: 'flex',
        alignItems: 'center',
    },
    loadingBar: {
        width: '100%',
    },
    error: {
        margin: 0,
        display: 'flex',
        color: ROOT.COLORS.RED,
        alignItems: 'center',
    },
    errorIcon: {
        width: '0.8em',
        height: '0.8em',
        marginRight: 5,
    },
    dataTitle: {
        fontWeight: 300,
        width: 150,
    },
    status: {
        textTransform: 'capitalize',
    },
    completed: {
        color: ROOT.COLORS.GREEN,
    },
    cancelled: {
        color: ROOT.COLORS.RED,
    },
    pending: {
        color: ROOT.COLORS.ORANGE,
    },
    cell: {
        border: 0,
        fontSize: 16,
    },
});

const getStatusClass = (command, classes) => {
    let statusClass = null;
    if (command) {
        switch (command.status) {
            case 'completed':
                statusClass = classes.completed;
                break;
            case 'cancelled':
                statusClass = classes.cancelled;
                break;
            case 'pending':
                statusClass = classes.pending;
                break;
            default:
                statusClass = null;
                break;
        }
    }
    return classNames(classes.status, statusClass);
};

// Display last downlink command,
const LastDownlinkCommand = (props) => {
    const classes = useStyles();
    const { command, loading, error } = props;

    const statusClass = getStatusClass(command, classes);

    // Loading & Error
    if (loading) {
        return (
            <Box className={classes.loading}>
                <LinearProgress className={classes.loadingBar} />
            </Box>
        );
    }

    if (error) {
        return (
            <Error
                message={error}
                icon={true}
                iconClass={classes.errorIcon}
                classes={classes.error}
            />
        );
    }

    if (!command) {
        return <Box className={classes.container}>No commands found</Box>;
    }

    return (
        <Box className={classes.container}>
            <TableContainer>
                <Table padding="none" aria-label="last command table">
                    <TableBody>
                        <TableRow>
                            <TableCell className={classNames(classes.dataTitle, classes.cell)}>
                                Command ID:
                            </TableCell>
                            <TableCell className={classes.cell}>{command.commandId}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={classNames(classes.dataTitle, classes.cell)}>
                                Parameter:
                            </TableCell>
                            <TableCell className={classes.cell}>
                                {command.name} ({command.unit})
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={classNames(classes.dataTitle, classes.cell)}>
                                Value:
                            </TableCell>
                            <TableCell className={classes.cell}>{command.value}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={classNames(classes.dataTitle, classes.cell)}>
                                Received time:
                            </TableCell>
                            <TableCell className={classes.cell}>
                                <DatetimeIntl
                                    timestampMilliseconds={command.createdTime}
                                    ariaLabel="Created Time"
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={classNames(classes.dataTitle, classes.cell)}>
                                Processed time:
                            </TableCell>
                            <TableCell className={classes.cell}>
                                {command.completedTime && (
                                    <DatetimeIntl
                                        timestampMilliseconds={command.completedTime}
                                        ariaLabel="Completed Time"
                                    />
                                )}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={classNames(classes.dataTitle, classes.cell)}>
                                Status:
                            </TableCell>
                            <TableCell className={classNames(statusClass, classes.cell)}>
                                {command.status}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

LastDownlinkCommand.propTypes = {
    command: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        unit: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
        createdTime: PropTypes.number.isRequired,
        completedTime: PropTypes.number,
        status: PropTypes.string,
    }),
    loading: PropTypes.bool,
    error: PropTypes.string,
};

export default LastDownlinkCommand;
