import { Box, Grid } from '@mui/material';
import React from 'react';
import { Loading, useShowContext } from 'react-admin';
import Error from '../../components/Error';
import DeviceDetails from './DeviceDetails';
import DeviceSite from './DeviceSite';

/**
 * Device overview information
 * Use context to get Device information
 * @returns Overview container
 */
const DeviceOverview = () => {
    const { record: device, isLoading: loading } = useShowContext();

    // Loading & Error
    if (loading) return <Loading />;
    if (!loading && !device) {
        return <Error message="Could not load device" />;
    }

    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item sm={12} md={6}>
                    <DeviceDetails device={device} />
                </Grid>
                <Grid item sm={12} md={6}>
                    {device.site && <DeviceSite device={device} />}
                </Grid>
            </Grid>
        </Box>
    );
};

export default DeviceOverview;
