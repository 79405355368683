import React, { useEffect } from 'react';
import {
    List,
    TextField,
    DateField,
    TopToolbar,
    CreateButton,
    SelectField,
    useLocale,
    useRedirect,
    useRecordContext,
    usePermissions,
} from 'react-admin';
import { useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useAppContext } from '../context/AppContext';
import GROUPS from '../auth/groups';
import Layout from '../components/Layout';
import SearchFilter from '../components/SearchFilter';
import DatagridWrapper from '../components/DatagridWrapper';
import { Link } from 'react-router-dom';

const ListProjects = () => {
    const { permissions } = usePermissions();
    const locale = useLocale();
    const [appStore] = useAppContext();
    const redirect = useRedirect();
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const organisationId =
        appStore.organisationId && appStore.organisationId !== '-' ? appStore.organisationId : '';

    const isHyndsAdmin = !!permissions && permissions.includes(GROUPS.HSW_ADMIN);
    const isHyndsUser = !!permissions && permissions.includes(GROUPS.HSW_USER);
    const isCustomerAdmin = !!permissions && permissions.includes(GROUPS.CUSTOMER_ADMIN);

    const canCreate = isHyndsAdmin && organisationId !== '';
    const isHynds = isHyndsAdmin || isHyndsUser;

    useEffect(() => {
        if (!isHyndsAdmin && !isHyndsUser && !isCustomerAdmin) {
            redirect('/');
        }
    }, [redirect, isHyndsAdmin, isHyndsUser, isCustomerAdmin]);

    const ListActions = () => {
        return (
            <TopToolbar>
                {canCreate && <CreateButton label="Create New Project" icon={null} />}
            </TopToolbar>
        );
    };

    return (
        <Layout title="Projects">
            <List
                filters={[
                    <SearchFilter
                        key="filter-query"
                        placeholder="Search by name"
                        alwaysOn
                        source="query-projects"
                    />,
                ]}
                filter={{
                    organisationId: organisationId,
                    status: isHynds ? undefined : 0,
                    projectStatus: 0,
                }}
                actions={<ListActions />}
                sort={{ field: 'name', order: 'ASC' }}
            >
                <DatagridWrapper
                    bulkActionButtons={false}
                    rowStyle={() => ({
                        height: 50,
                        boxSizing: 'content-box',
                    })}
                >
                    <NameField source="name" />
                    {organisationId === '' && isHynds && <OrganisationNameField label="Customer" />}
                    <SelectField
                        source="projectStatus"
                        label="Project Status"
                        choices={[
                            { id: 0, name: 'Active' },
                            { id: 1, name: 'Inactive' },
                        ]}
                    />
                    <TextField source="siteCount" label="Sites" sortable={false} />
                    {!isSmall && (
                        <DateField
                            source="createdAt"
                            label="Date Created"
                            options={{
                                day: 'numeric',
                                month: 'short',
                                year: 'numeric',
                                hour: 'numeric',
                                minute: 'numeric',
                                second: 'numeric',
                                hour12: false,
                            }}
                            locales={locale}
                            showTime
                        />
                    )}
                </DatagridWrapper>
            </List>
        </Layout>
    );
};

const OrganisationNameField = () => {
    const post = useRecordContext();
    const siteShowPage = `/organisations/${post.organisationId}/show`;
    const useStyles = makeStyles(() => ({
        linkUrl: {
            color: '#00AEEF',
            textDecoration: 'none',
        },
    }));
    const classes = useStyles();

    return (
        <Link to={siteShowPage} className={classes.linkUrl}>
            {post.organisation?.name}
        </Link>
    );
};

const NameField = () => {
    const post = useRecordContext();
    const siteShowPage = `/projects/${post.id}/show`;
    const useStyles = makeStyles(() => ({
        linkUrl: {
            color: '#00AEEF',
            textDecoration: 'none',
        },
    }));
    const classes = useStyles();

    return (
        <Link to={siteShowPage} className={classes.linkUrl}>
            {post.name}
        </Link>
    );
};

export default ListProjects;
