import React from 'react';
import { deviceTypes, deviceStatuses } from './device';
import { siteTypes, applicationTypes as siteApplicationTypes } from '../sites/components/SiteTypes';
import { SelectInput, SearchInput } from 'react-admin';
import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment } from '@mui/material';

const getFilters = (organisationId, projects, classes) => {
    const listFilters = [
        <SearchInput
            key="filter-search"
            source="query"
            variant="outlined"
            placeholder="Search by device ID"
            className={classes.searchInput}
            alwaysOn
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <SearchIcon style={{ opacity: '0.5' }} />
                    </InputAdornment>
                ),
            }}
        />,
        <SelectInput
            choices={projects}
            key="filter-project"
            source="projectId"
            value="id"
            label="Project"
            variant="outlined"
            initialValue="All"
            emptyText="All"
            emptyValue="All"
            alwaysOn
        />,
        <SelectInput
            choices={deviceTypes}
            key="filter-devicetype"
            source="deviceType"
            value="id"
            label="Device Type"
            variant="outlined"
            initialValue="All"
            emptyText="All"
            emptyValue="All"
            alwaysOn
        />,
        <SelectInput
            choices={siteTypes}
            key="filter-siteType"
            source="siteType"
            value="id"
            label="Site Type"
            variant="outlined"
            initialValue="All"
            emptyText="All"
            emptyValue="All"
            alwaysOn
        />,
        <SelectInput
            choices={siteApplicationTypes}
            key="filter-applicationType"
            source="applicationType"
            value="id"
            label="Site Application"
            variant="outlined"
            initialValue="All"
            emptyText="All"
            emptyValue="All"
            alwaysOn
        />,
    ];
    if (organisationId === '-') {
        const statusFilter = (
            <SelectInput
                choices={deviceStatuses}
                key="filter-status"
                source="status"
                value="id"
                label="Status"
                variant="outlined"
                initialValue="All"
                emptyText="All"
                emptyValue="All"
                alwaysOn
            />
        );
        listFilters.push(statusFilter);
    }
    return listFilters;
};

export default getFilters;
