import React from 'react';
import {
    Edit,
    SimpleForm,
    TextInput,
    ReferenceInput,
    SelectInput,
    required,
    email,
    number,
    minLength,
    maxLength,
    useEditController,
    SaveButton,
    Toolbar,
    usePermissions,
    useRedirect,
    useEditContext,
    useNotify,
    useRefresh,
} from 'react-admin';
import { Box, Grid } from '@mui/material';
import Layout from '../components/Layout';
import { useAppContext } from '../context/AppContext';
import GROUPS from '../auth/groups';
import Loading from '../components/Loading';
import DeleteButton from './Delete';
import { validateText } from '../utils/validation';

const UserEditToolbar = (props) => {
    const { userEmail } = props;
    const { isLoading, permissions } = usePermissions();
    const { record } = useEditContext();
    if (isLoading) return <Loading />;
    const hasDeletePermission = (permission) => {
        return permission.includes(GROUPS.HSW_ADMIN) || permission.includes(GROUPS.CUSTOMER_ADMIN);
    };
    const isCurrentUser = record.emailAddress.toLowerCase() === userEmail.toLowerCase();
    const canDelete =
        record.primaryContact === false && !isCurrentUser && hasDeletePermission(permissions);
    return (
        <Toolbar>
            <Box display="flex" justifyContent="space-between" width="100%">
                <SaveButton type="button" />
                {canDelete && <DeleteButton />}
            </Box>
        </Toolbar>
    );
};

const validate = (values) => {
    const errors = {};
    if (!values.name) errors.name = 'The name is required.';
    return errors;
};

const EditUser = () => {
    const [appStore] = useAppContext();
    const controller = useEditController();
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();
    const { permissions } = usePermissions();

    const onSuccess = () => {
        notify('Changes saved', { type: 'success' });
        redirect('list', 'users');
        refresh();
    };

    if (!controller.record) return <Loading />;

    if (
        !!permissions &&
        !permissions.includes(GROUPS.HSW_ADMIN) &&
        !permissions.includes(GROUPS.CUSTOMER_ADMIN)
    ) {
        redirect('list', 'users');
    }

    const overrides = {};
    overrides[controller.record.id] = controller.record.name;

    const validName =
        (message = 'Invalid text') =>
        (value) => {
            if (value && value.length > 3) {
                if (!validateText(value)) {
                    return message;
                } else {
                    return '';
                }
            }
            return '';
        };

    return (
        <Layout breadcrumb overrides={overrides} title={controller.record.name}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6}>
                    <Edit actions={<></>} mutationMode="optimistic" mutationOptions={{ onSuccess }}>
                        <SimpleForm
                            validate={validate}
                            toolbar={<UserEditToolbar userEmail={appStore.email} />}
                        >
                            <ReferenceInput
                                source="organisationId"
                                reference="organisations"
                                defaultValue={
                                    appStore.organisationId !== '-' ? appStore.organisationId : null
                                }
                                filter={
                                    appStore.organisationId !== '-'
                                        ? { id: appStore.organisationId, status: 0 }
                                        : { status: 0 }
                                }
                                perPage={1000}
                                isRequired
                            >
                                <SelectInput
                                    optionText="name"
                                    value="id"
                                    label="Customer"
                                    variant="outlined"
                                    fullWidth
                                    validate={[required()]}
                                    disabled={true}
                                />
                            </ReferenceInput>
                            <TextInput
                                source="name"
                                label="Full name"
                                variant="outlined"
                                validate={[required(), minLength(2), maxLength(200), validName()]}
                                fullWidth
                                disabled={controller.record.isAzureADUser}
                            />
                            <TextInput
                                source="emailAddress"
                                label="Email"
                                variant="outlined"
                                validate={[required(), email(), minLength(2), maxLength(200)]}
                                fullWidth
                                disabled
                            />
                            <TextInput
                                source="phone"
                                label="Mobile"
                                variant="outlined"
                                validate={[
                                    minLength(6),
                                    maxLength(12),
                                    number('Only numbers allowed'),
                                ]}
                                fullWidth
                            />
                            <TextInput
                                source="accountNo"
                                label="Account reference"
                                variant="outlined"
                                validate={[minLength(2), maxLength(200)]}
                                fullWidth
                            />
                        </SimpleForm>
                    </Edit>
                </Grid>
            </Grid>
        </Layout>
    );
};

export default EditUser;
