import React from 'react';

const FeatureFlags = React.createContext(null);

export function FeatureProvider({ features = null, children }) {
    if (features === null || typeof features !== 'object') {
        throw new TypeError('The features prop must be an object or an array.');
    }
    return <FeatureFlags.Provider value={features}>{children}</FeatureFlags.Provider>;
}

export function useFeature(name) {
    const features = React.useContext(FeatureFlags);
    if (features === null) {
        throw new Error('You must wrap your components in a FeatureProvider.');
    }
    return Array.isArray(features) ? features.includes(name) : features[`${name}`];
}
