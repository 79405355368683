import React, { useEffect } from 'react';
import {
    SimpleShowLayout,
    useShowController,
    usePermissions,
    useRedirect,
    useGetOne,
} from 'react-admin';
import Typography from '@mui/material/Typography';
import { Box, Card, TextField, LinearProgress } from '@mui/material';
import Breadcrumb from '../menu/Breadcrumb';
import { makeStyles } from '@mui/styles';
import Layout from '../components/Layout';
import Label from '../components/Label';
import GROUPS from '../auth/groups';
import RainfallNetworkShowLayout from '../rainfallNetwork/components/ShowLayout';

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        gap: '20px',
    },
    panelContent: {
        padding: '16px 0px',
    },
    title: {
        fontSize: '32px',
        fontWeight: 'bold',
        lineHeight: '43px',
    },
}));

const ShowRainfallSite = () => {
    const classes = useStyles();
    const { permissions } = usePermissions();
    const redirect = useRedirect();
    const isCustomerAdminAnalytics =
        !!permissions &&
        permissions.includes(GROUPS.CUSTOMER_ADMIN) &&
        permissions.includes(GROUPS.ANALYTICS);
    const isHyndsAdmin = !!permissions && permissions.includes(GROUPS.HSW_ADMIN);
    const isCustomerAnalytics =
        !!permissions &&
        permissions.includes(GROUPS.PORTALACCESS) &&
        permissions.includes(GROUPS.ANALYTICS);

    useEffect(() => {
        if (!isCustomerAdminAnalytics && !isHyndsAdmin && !isCustomerAnalytics) {
            redirect('/');
        }
    }, [redirect, isCustomerAdminAnalytics, isHyndsAdmin, isCustomerAnalytics]);

    const {
        record: rainfallSite,
        isLoading,
        error,
    } = useShowController({
        queryOptions: {
            enabled: isCustomerAdminAnalytics || isHyndsAdmin || isCustomerAnalytics,
        },
    });
    const {
        data: rainfallNetwork,
        isLoading: isLoadingRainfallNetwork,
        error: errorRainfallNetwork,
    } = useGetOne(
        'rainfallNetworks',
        { id: rainfallSite?.rainfallNetworkId },
        {
            enabled:
                !!rainfallSite &&
                !!rainfallSite.rainfallNetworkId &&
                (isCustomerAdminAnalytics || isHyndsAdmin),
        },
    );
    const overrides = {};
    if (rainfallSite && rainfallSite.id) {
        overrides[rainfallSite.id] = rainfallSite.name;
    }

    if (isLoading || !rainfallSite) {
        return (
            <Layout>
                <LinearProgress />
            </Layout>
        );
    }

    if (error) {
        return (
            <Box>
                <Typography variant="body2">Could not load Rainfall Site</Typography>
            </Box>
        );
    }

    return (
        <Layout>
            <Breadcrumb overrides={overrides} />
            <Box mt={2}>
                <Typography variant="h5" gutterBottom className={classes.title}>
                    <span>Rainfall Site {rainfallSite ? `"${rainfallSite.name}"` : ''}</span>
                </Typography>
            </Box>

            <Box className={classes.container}>
                <SimpleShowLayout record={rainfallSite} className={classes.panelContent}>
                    <Card>
                        <Typography variant="h6" gutterBottom>
                            Rainfall Site Details
                        </Typography>
                        <Box
                            display="flex"
                            flexDirection="column"
                            rowGap="20px"
                            justifyContent="stretch"
                        >
                            <Box className={classes.formItem}>
                                <Label>Name</Label>
                                <TextField
                                    id="rainfall-site-name"
                                    variant="outlined"
                                    disabled
                                    defaultValue={rainfallSite.name}
                                    fullWidth={true}
                                />
                            </Box>
                            <Box className={classes.formItem}>
                                <Label>Rain Station Id</Label>
                                <TextField
                                    id="rainfall-site-rainstation"
                                    variant="outlined"
                                    disabled
                                    defaultValue={rainfallSite.rainStationId}
                                    fullWidth={true}
                                />
                            </Box>
                            <Box className={classes.formItem}>
                                <Label>Coordinates - Longitude</Label>
                                <TextField
                                    id="rainfall-site-longitude"
                                    variant="outlined"
                                    disabled
                                    defaultValue={rainfallSite.longitude}
                                    fullWidth={true}
                                />
                            </Box>
                            <Box className={classes.formItem}>
                                <Label>Coordinates - Latitude</Label>
                                <TextField
                                    id="rainfall-site-latitude"
                                    variant="outlined"
                                    disabled
                                    defaultValue={rainfallSite.latitude}
                                    fullWidth={true}
                                />
                            </Box>
                        </Box>
                    </Card>
                </SimpleShowLayout>
                {isHyndsAdmin ||
                    (isCustomerAdminAnalytics && (
                        <RainfallNetworkShowLayout
                            record={rainfallNetwork}
                            isLoading={isLoadingRainfallNetwork}
                            error={errorRainfallNetwork}
                        />
                    ))}
            </Box>
        </Layout>
    );
};

export default ShowRainfallSite;
