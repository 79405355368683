import React from 'react';
import { makeStyles } from '@mui/styles';
import ROOT from '../../../theme/root';
import { levelAlarmStatus } from '../../../alarms/alarm';

const useStyles = makeStyles(() => ({
    container: {
        borderRadius: ROOT.BORDERS.RADIUS,
        width: 'fit-content',
        minWidth: '70px',
        height: '26px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0px 8px',
        fontWeight: 'bold',
    },
}));
const LevelAlarmStatus = (props) => {
    const classes = useStyles();
    const status = levelAlarmStatus.find((status) => status.id === props.value);
    let style = {
        color: '#fff',
    };
    if (status && status.id === 0) style.backgroundColor = ROOT.STATUSCOLOR.INFO;
    else if (status && status.id === 1) style.backgroundColor = ROOT.STATUSCOLOR.ERROR;

    return (
        <div className={classes.container} style={style}>
            {status?.name || ''}
        </div>
    );
};

export default LevelAlarmStatus;
