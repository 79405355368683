import React from 'react';
import { useCreateContext } from 'react-admin';

/**
 * Wrapper to add extra functionality to react-admin input
 * Uses react admin createContext
 */
const InputWrapper = ({ children, ...rest }) => {
    const { saving } = useCreateContext(rest);
    const oldProps = children.props;
    const newProps = {
        ...oldProps,
        disabled: saving,
    };
    return React.cloneElement(children, newProps);
};

export default InputWrapper;
