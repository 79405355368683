import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Button, Link, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { addDays, addHours, addMonths } from 'date-fns';

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '20px',
        backgroundColor: 'white',
        position: 'sticky',
        color: 'transparent',
        top: 0,
        left: 'auto',
        right: 0,
        zIndex: 1100,
        borderTop: (props) => (props.useBorders ? '1px solid #D8D8D8' : 'none'),
        borderLeft: (props) => (props.useBorders ? '1px solid #D8D8D8' : 'none'),
        borderRight: (props) => (props.useBorders ? '1px solid #D8D8D8' : 'none'),
        borderBottom: '1px solid #D8D8D8',
        borderRadius: (props) => (props.useBorders ? 4 : 0),
    },
    quickrange: {
        flexBasis: '400px',
        display: 'flex',
    },
    list: {
        listStyleType: 'none',
    },
});

const Controls = ({ updateDates, fromDate, toDate, useBorders = true }) => {
    const [fromDatePicker, setFromDatePicker] = useState(fromDate);
    const [toDatePicker, setToDatePicker] = useState(toDate);
    const [error, setError] = useState(null);
    const updateButton = useRef(null);

    const classes = useStyles({ useBorders });

    const updateHandler = useCallback(() => {
        updateDates(fromDatePicker, toDatePicker);
    }, [fromDatePicker, toDatePicker, updateDates]);

    const quickHourHandler = useCallback(
        (hours) => {
            const now = new Date();
            const from = addHours(now, hours);
            setFromDatePicker(from);
            setToDatePicker(now);
            setTimeout(() => updateButton.current.click());
        },
        [setFromDatePicker, setToDatePicker],
    );

    const quickDayHandler = (days) => {
        const now = new Date();
        const from = addDays(now, days);
        setFromDatePicker(from);
        setToDatePicker(now);
        setTimeout(() => updateButton.current.click());
    };
    const quickMonthHandler = (months) => {
        const now = new Date();
        const from = addMonths(now, months);
        setFromDatePicker(from);
        setToDatePicker(now);
        setTimeout(() => updateButton.current.click());
    };

    useEffect(() => {
        if (updateButton.current != null) {
            updateButton.current.click();
        }
    }, []);

    return (
        <Box className={classes.container}>
            <Box className={classes.quickrange}>
                <ul className={classes.list}>
                    <li>
                        <Link
                            component="button"
                            onClick={() => quickHourHandler(-1)}
                            underline="none"
                        >
                            Last hour
                        </Link>
                    </li>
                    <li>
                        <Link
                            component="button"
                            onClick={() => quickHourHandler(-24)}
                            underline="none"
                        >
                            Last 24 hours
                        </Link>
                    </li>
                </ul>
                <ul className={classes.list}>
                    <li>
                        <Link
                            component="button"
                            onClick={() => quickDayHandler(-7)}
                            underline="none"
                        >
                            Last 7 days
                        </Link>
                    </li>
                    <li>
                        <Link
                            component="button"
                            onClick={() => quickDayHandler(-30)}
                            underline="none"
                        >
                            Last 30 days
                        </Link>
                    </li>
                </ul>
                <ul className={classes.list}>
                    <li>
                        <Link
                            component="button"
                            onClick={() => quickMonthHandler(-3)}
                            underline="none"
                        >
                            Last 3 months
                        </Link>
                    </li>
                    <li>
                        <Link
                            component="button"
                            onClick={() => quickMonthHandler(-6)}
                            underline="none"
                        >
                            Last 6 months
                        </Link>
                    </li>
                </ul>
            </Box>

            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                    value={fromDatePicker}
                    onChange={setFromDatePicker}
                    disableFuture={true}
                    inputFormat="dd/MM/yyyy HH:mm"
                    label="From"
                    renderInput={(props) => (
                        <TextField id="device-data-from" variant="outlined" {...props} />
                    )}
                    onError={(newError) => setError(newError)}
                />
                <DateTimePicker
                    value={toDatePicker}
                    onChange={setToDatePicker}
                    minDateTime={fromDatePicker}
                    inputFormat="dd/MM/yyyy HH:mm"
                    label="To"
                    renderInput={(props) => (
                        <TextField id="device-data-to" variant="outlined" {...props} />
                    )}
                    componentsProps={{
                        actionBar: {
                            actions: ['today'],
                        },
                    }}
                    onError={(newError) => setError(newError)}
                />
            </LocalizationProvider>
            <Button
                variant="contained"
                color="primary"
                onClick={updateHandler}
                ref={updateButton}
                disabled={!!error}
            >
                Update
            </Button>
        </Box>
    );
};

export default Controls;
