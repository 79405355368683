import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import SearchIcon from '@mui/icons-material/Search';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Button, TextField, InputAdornment, debounce, Switch, Tooltip } from '@mui/material';
import { Marker } from '@react-google-maps/api';
import Map from '../../components/Map';
import GoogleAutoComplete from '../components/GoogleAutoComplete';
import Autocomplete from '@mui/lab/Autocomplete';
import Label from '../../components/Label';

const useStyles = makeStyles(() => ({
    dialog: {
        minHeight: '60vh',
        maxHeight: '60vh',
    },
    mapContainer: {
        width: '100%',
        height: '300px',
    },
}));

const lib = ['places', 'drawing', 'geometry'];

const SiteLocationEdit = ({ isOpen, onOpen, onClose, siteAddress, location, onSave }) => {
    const classes = useStyles();

    const [open, setOpen] = useState(isOpen ?? false);
    const [address, setAddress] = useState(siteAddress);
    const [geolocation, setGeolocation] = useState(location);
    const [googleMapsGeocoder, setGoogleMapsGeocoder] = useState({});
    const [isValid] = React.useState(true);
    const [isLocationValid] = React.useState(true);
    const [isAddressSearch, setIsAddressSearch] = React.useState(true);
    const [addresses, setAddresses] = useState([]);
    const [isMarkerChange, setIsMarkerChange] = React.useState(false);

    const center = { lat: -40.9006, lng: 174.886 };
    const defaultBounds = {
        north: center.lat + 1,
        south: center.lat - 1,
        east: center.lng + 1,
        west: center.lng - 1,
    };
    const mapOptions = {
        bounds: defaultBounds,
        componentRestrictions: { country: ['nz', 'au'] },
        fields: ['address_components', 'geometry'],
        strictBounds: false,
        //types: ["establishment"],
    };
    useEffect(() => setOpen(isOpen), [isOpen]);

    useEffect(() => {
        if (!address) return;
        const isGPS = Number.isNaN(
            Number(
                address
                    ?.replace('+', '')
                    .replace('-', '')
                    .replace(' ', '')
                    .replace(',', '')
                    .replace('.', ''),
            ),
        );
        setIsAddressSearch(isGPS);
    }, [address, siteAddress]);

    useEffect(() => {
        if (isOpen) {
            setAddress(siteAddress);
        }
    }, [isOpen, onOpen, siteAddress]);

    React.useEffect(() => {
        if (isMarkerChange) {
            setIsMarkerChange(false);
            return;
        }
        if (!googleMapsGeocoder || !address) return;
        const geocode = () => {
            if (googleMapsGeocoder.geocode) {
                if (isAddressSearch) {
                    googleMapsGeocoder
                        .geocode({
                            address: address,
                        })
                        .then((code) => {
                            if (!code.results) return;
                            const newAddress = code.results[0];
                            const loc = newAddress.geometry.location;
                            setGeolocation({ latitude: loc.lat(), longitude: loc.lng() });
                        });
                } else {
                    const latlng = address.replace(' ', '').split(',');
                    if (latlng.length === 2 && !isNaN(latlng[0]) && !isNaN(latlng[1])) {
                        setGeolocation({ latitude: latlng[0], longitude: latlng[1] });
                    } else {
                        setGeolocation({
                            latitude: -36.86796905907407,
                            longitude: 174.75143230389588,
                        });
                    }
                }
            }
        };
        debounce(geocode(), 1000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [address, googleMapsGeocoder, isAddressSearch]);

    const handleClose = () => {
        setOpen(false);
        if (onClose) onClose();
    };

    const handleSave = () => {
        setOpen(false);
        if (onSave) onSave({ address: address, location: geolocation });
        if (onClose) onClose();
    };

    const initMaps = React.useCallback(() => {
        const maps = window?.google?.maps;
        if (!maps) return;
        const gmGeocoder = new maps.Geocoder();
        setGoogleMapsGeocoder(gmGeocoder);
    }, []);

    useEffect(() => {
        setTimeout(() => {
            initMaps();
        }, 500);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleAddressChange = (event) => {
        if (event.key === 'Enter') {
            setAddress(event.target.value);
        }
    };

    const handleMarkerChange = (e) => {
        if (e && e.latLng) {
            const markerSearch = () => {
                const loc = e.latLng;
                setGeolocation({ latitude: loc.lat(), longitude: loc.lng() });
                googleMapsGeocoder
                    .geocode({
                        location: {
                            lat: loc.lat(),
                            lng: loc.lng(),
                        },
                    })
                    .then((code) => {
                        if (!code || !code.results) return;
                        const newAddress = code.results[0]['formatted_address'];
                        if (newAddress) {
                            setIsMarkerChange(true);
                            if (isAddressSearch !== true) {
                                setAddress(loc.lat() + ', ' + loc.lng());
                            } else {
                                setAddress(newAddress);
                            }
                        }
                    });
            };
            debounce(markerSearch(), 1000);
        }
    };

    const handleChangeAddress = async (target) => {
        if (isAddressSearch) {
            const results = await GoogleAutoComplete(target.value, mapOptions);
            if (results && results.length > 0) {
                setAddresses(results);
            }
        }
    };

    const handleChange = (event, checkedValue) => {
        setIsAddressSearch(checkedValue);
        setAddress('');
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="site-location"
            maxWidth="sm"
            fullWidth
            classes={{ paper: classes.dialog }}
            scroll="paper"
        >
            <DialogTitle id="customer-selector">Select a Site location</DialogTitle>
            <DialogContent>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    {isAddressSearch && <Label sub>Address lookup</Label>}
                    {!isAddressSearch && <Label sub>longitude and latitude</Label>}
                    <Tooltip title="Will use a different input." arrow>
                        <Switch
                            checked={isAddressSearch}
                            onChange={handleChange}
                            color="primary"
                            name="tamper"
                            //disabled={showMode}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                    </Tooltip>
                </Box>
                {!isAddressSearch && (
                    <TextField
                        autoFocus
                        margin="dense"
                        id="location"
                        type="text"
                        placeholder="Search"
                        variant="outlined"
                        onKeyDown={(event) => handleAddressChange(event)}
                        onChange={(event) => setAddress(event.target.value)}
                        value={address}
                        error={!isValid && !isLocationValid}
                        fullWidth
                        helperText={!isLocationValid ? 'Please enter a location' : undefined}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                )}
                {isAddressSearch && (
                    <Autocomplete
                        options={addresses.map((option) => option.description)}
                        onChange={(event, newValue) => {
                            setAddress(newValue);
                        }}
                        value={address}
                        autoComplete={false}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Site Address"
                                name="siteAddress"
                                onChange={(event) => {
                                    debounce(handleChangeAddress(event.target), 1000);
                                }}
                                variant="outlined"
                            />
                        )}
                    />
                )}
                <br></br>
                <br></br>

                <Box className={classes.mapContainer}>
                    <Map
                        center={{ lat: +geolocation.latitude, lng: +geolocation.longitude }}
                        zoom={15}
                        withLocationMem={false}
                        lib={lib}
                    >
                        <Marker
                            position={{ lat: +geolocation.latitude, lng: +geolocation.longitude }}
                            onDragEnd={(e) => handleMarkerChange(e)}
                            draggable
                        />
                    </Map>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleSave} color="primary" disabled={!address}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default SiteLocationEdit;
