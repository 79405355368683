import React, { Children, isValidElement, useCallback } from 'react';
import { TableHead, TableRow, TableCell } from '@mui/material';
import {
    DatagridHeaderCell,
    DatagridClasses,
    useResourceContext,
    useListContext,
} from 'react-admin';
import clsx from 'clsx';

// Component similar to ra-ui-materialui/src/list/datagrid/DatagridHeader.tsx
// The only difference is that does not contain checkbox in the header to select/unselect all rows
const DatagridHeaderNoSelectAll = ({ children, ...props }) => {
    const resource = useResourceContext(props);
    const { sort, setSort } = useListContext(props);
    const updateSortCallback = useCallback(
        (event) => {
            event.stopPropagation();
            const newField = event.currentTarget.dataset.field;
            const newOrder =
                sort.field === newField
                    ? sort.order === 'ASC'
                        ? 'DESC'
                        : 'ASC'
                    : event.currentTarget.dataset.order;

            setSort({ field: newField, order: newOrder });
        },
        [sort.field, sort.order, setSort],
    );
    const updateSort = setSort ? updateSortCallback : null;

    return (
        <TableHead>
            <TableRow>
                <TableCell></TableCell>
                {Children.map(children, (field, index) =>
                    isValidElement(field) ? (
                        <DatagridHeaderCell
                            className={clsx(
                                DatagridClasses.headerCell,
                                `column-${field.props.source}`,
                            )}
                            sort={sort}
                            field={field}
                            isSorting={sort.field === (field.props.sortBy || field.props.source)}
                            key={field.props.source || index}
                            resource={resource}
                            updateSort={updateSort}
                        />
                    ) : null,
                )}
            </TableRow>
        </TableHead>
    );
};

export default DatagridHeaderNoSelectAll;
