import React from 'react';
import { createTheme } from '@mui/material/styles';
import { Box, Card, ThemeProvider, Link } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Theme from '../theme/index';
import { ReactComponent as Logo } from '../img/hynds-aquanect-logo-grey.svg';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';

const useStyles = makeStyles((theme) => ({
    formControl: {
        marginTop: theme.spacing(3),
        minWidth: 120,
        width: '100%',
        textAlign: 'left',
    },
    label: {
        color: '#636364',
        fontSize: '14px',
        lineHeight: '20px',
        marginBottom: '4px',
    },
    submit: {
        color: '#fff',
        backgroundColor: '#00AEEF',
        height: '45px',
        width: '100%',
        marginTop: '32px',
        '&:hover': {
            color: 'white',
            border: '1px solid #00AEEF',
        },
    },
    snackbar: {
        backgroundColor: '#EA2F2F',
        color: 'white',
        borderRadius: '8px',
        padding: '4px 4px',
    },
}));

function getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&'); //eslint-disable-line
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'), //eslint-disable-line
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

const AccountUnblocked = () => {
    const classes = useStyles();
    const message = getParameterByName('message');
    const isSuccess = getParameterByName('success');

    return (
        <ThemeProvider theme={createTheme(Theme)}>
            <Box
                display="flex"
                width="100%"
                alignItems="center"
                flexDirection="column"
                justifyContent="center"
                height="100vh"
                style={{ backgroundColor: '#f5f5f8' }}
            >
                <Logo height="100px" />
                <Card
                    style={{
                        padding: '24px',
                        width: '360px',
                        textAlign: 'center',
                    }}
                >
                    <div
                        style={{
                            marginBottom: '24px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        {isSuccess === 'true' ? (
                            <CheckCircleIcon style={{ width: '1.5em', height: '1.5em' }} />
                        ) : (
                            <ErrorIcon style={{ width: '1.5em', height: '1.5em' }} />
                        )}
                        <div
                            style={{
                                marginLeft: '8px',
                                fontSize: '1.25em',
                                fontWeight: '550',
                            }}
                        >
                            {isSuccess === 'true' ? 'Account Unblocked' : 'Invalid link'}
                        </div>
                    </div>
                    <span className={classes.label}>
                        {`${message}${
                            isSuccess === 'true'
                                ? ' If you are a mobile user please use your app to login to your account. If you are a portal user please use the login link below to login.'
                                : '.'
                        }`}
                    </span>
                    <div style={{ marginTop: '16px' }}>
                        <Link
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                window.location.replace('/login');
                            }}
                        >
                            Go to Login
                        </Link>
                    </div>
                </Card>
            </Box>
        </ThemeProvider>
    );
};

export default AccountUnblocked;
