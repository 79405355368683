import React from 'react';
import { Box, Switch, Tooltip } from '@mui/material';
import Label from '../components/Label';

const TamperThreshold = ({ checked, onChange, showMode }) => {
    const [check, setCheck] = React.useState(false);
    React.useEffect(() => {
        if (checked !== check) setCheck(checked);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checked]);
    const handleChange = (event, checkedValue) => {
        setCheck(checkedValue);
        if (onChange) onChange(checkedValue);
    };
    return (
        <Box display="flex" justifyContent="space-between" alignItems="center" pt="10px" pb="10px">
            <Label sub>Tamper</Label>

            <Tooltip title="Will raise an alarm if the device is tampered with." arrow>
                <Switch
                    checked={check}
                    onChange={handleChange}
                    //color={check ? 'red' : 'primary'}
                    name="tamper"
                    disabled={showMode}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                />
            </Tooltip>
        </Box>
    );
};

export default TamperThreshold;
