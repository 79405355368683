import * as React from 'react';
import PropTypes from 'prop-types';
import { Sidebar } from 'react-admin';

const PortalSidebar = (props) => (
    <Sidebar
        sx={{
            height: 'unset',
            backgroundColor: 'secondary.main',
            '& .RaSidebar-paper': {
                overflow: 'visible',
            },
        }}
        {...props}
    />
);

PortalSidebar.propTypes = {
    children: PropTypes.node.isRequired,
};

export default PortalSidebar;
