import { zonedTimeToUtc } from 'date-fns-tz';

function transformDescription(description) {
    const prefix = 'last reading: ';

    if (description.startsWith(prefix) && description.indexOf('no reading') === -1) {
        let time = description.slice(prefix.length);
        let tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
        return prefix + convertToLocalTime(time, tz);
    }

    return description;
}

// convert NZ time to local time
function convertToLocalTime(time, tz) {
    /* eslint-disable */
    const regex = new RegExp(
        /(?<day>\d*)\/(?<month>\d*)\/(?<year>\d*), (?<hour>\d*):(?<minute>\d*):(?<seconds>\d*) (?<am>am|pm)/,
    );
    const result = time.match(regex);

    const day = result[1];
    // month should be array index based. transform to 0-11 format
    const month = parseInt(result[2]) - 1;
    const year = result[3];
    // transform into 24 hours format
    const hour = parseInt(result[4]) + (result[7] === 'pm' ? 12 : 0);
    const minute = result[5];
    const seconds = result[6];
    const ms = 0; // default value

    // Create date object. This date depends on the local timezone.
    const zonedDate = new Date(year, month, day, hour, minute, seconds, ms);

    // Tranform UTC. This UTC date does not depend on the local timezone.
    const utcDate = zonedTimeToUtc(zonedDate, 'Pacific/Auckland');

    const options = getTimeOptions(tz);

    // Transform into local time
    const local = utcDate.toLocaleString('en-US', options);

    return local;
}

// common date and time display options
function getTimeOptions(timezone) {
    let displayOpts = {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: false,
        timeZone: timezone,
    };

    return displayOpts;
}

export { transformDescription, convertToLocalTime };
