import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';
import React from 'react';

const useStyles = makeStyles(() => ({
    label: {
        fontSize: '12px',
        color: '#636364',
    },
    value: {
        height: '48px',
        display: 'flex',
        alignItems: 'center',
        border: '1px solid #D8D8D8',
        borderRadius: '4px',
        fontSize: '16px',
        color: '#0B0B0D',
        paddingLeft: '12px',
        marginTop: '4px',
        marginBottom: '16px',
    },
}));

const DisplayField = (props) => {
    const classes = useStyles();

    return (
        <Box width="100%">
            <label className={classes.label}>{props.label}</label>
            <div className={classes.value}>{props.value}</div>
        </Box>
    );
};

export default DisplayField;
