import React, { useEffect, useState } from 'react';
import Map from '../../components/Map';
import {
    InfoWindowF,
    MarkerF,
    PolylineF,
    MarkerClustererF,
    useJsApiLoader,
} from '@react-google-maps/api';
import secondaryPinIcon from '../../icons/maps-pin-secondary.svg';
import { Box, LinearProgress, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Error from '../../components/Error';
import { formatLocalTimezone } from '../utils';

const po = {
    geodesic: true,
    strokeColor: '#E65100',
    strokeOpacity: 1.0,
    strokeWeight: 2,
};

const infoWindowStyle = {
    background: 'white',
    border: '1px solid ##CCC',
};

const cluster = {
    url: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m1.png',
    height: 53,
    width: 53,
};

const useStyles = makeStyles(() => ({
    container: {
        padding: '16px',
        flexBasis: '50%',
        display: 'flex',
        flexDirection: 'column',
    },
    mapContainer: {
        borderRadius: 10,
        flex: 1,
        position: 'relative',
        overflow: 'hidden',
    },
}));
const lib = ['places', 'drawing', 'geometry'];

const GpsLocations = (props) => {
    const { data, isLoading, error } = props;
    const classes = useStyles();
    const [selectedMarker, setSelectedMarker] = useState(null);
    const [center, setCenter] = useState(null);
    const { isLoaded, loadError } = useJsApiLoader({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
        region: 'AU',
        preventGoogleFontsLoading: false,
        libraries: lib,
    });

    useEffect(() => {
        if (!data || data.length === 0) return;

        const lastDatapoint = data[data.length - 1];
        if (!center || lastDatapoint.lat !== center.lat || lastDatapoint.long !== center.lng) {
            setCenter({
                lat: data[data.length - 1].lat,
                lng: data[data.length - 1].long,
            });
        }
    }, [data, center]);

    const handleMarkerClick = (marker) => {
        if (!selectedMarker || marker.timestamp !== selectedMarker.timestamp) {
            setSelectedMarker(marker);
        }
    };

    const closeInfoWindow = () => {
        setSelectedMarker(null);
    };

    let locations = null;
    let latestLocation = null;
    let previousLocations = [];
    let path = [];

    if (data && data.length > 0) {
        locations = data.map((record, idx) => {
            path.push({
                lat: record.lat,
                lng: record.long,
            });

            // Format for Google Maps
            const dataPoint = {
                location: { lat: record.lat, lng: record.long },
                timestamp: record.timestamp,
                idx,
            };

            if (idx === data.length - 1) latestLocation = dataPoint;
            else previousLocations.push(dataPoint);

            return dataPoint;
        });
    }

    return (
        <Box className={classes.container}>
            <Typography component="h6" variant="h6" mb="20px" mt="10px">
                Location History
            </Typography>
            {(isLoading || !isLoaded) && <LinearProgress />}
            {isLoaded && loadError && <Error message="Error loading Map" />}
            {!isLoading && error && <Error message="Error loading GPS locations" />}
            {!isLoading && !error && !locations && <Box>No locations found</Box>}
            {!isLoading && isLoaded && !error && locations && (
                <Box className={classes.mapContainer}>
                    <Map center={center} zoom={12} withLocationMem={false}>
                        <PolylineF path={path} options={po} />
                        <MarkerClustererF maxZoom={18} gridSize={15} styles={[cluster]}>
                            {(clusterer) =>
                                previousLocations.map((gps, idx) => (
                                    <MarkerF
                                        position={gps.location}
                                        icon={secondaryPinIcon}
                                        key={idx}
                                        clusterer={clusterer}
                                        onClick={() => handleMarkerClick(gps)}
                                    ></MarkerF>
                                ))
                            }
                        </MarkerClustererF>

                        {latestLocation && (
                            <MarkerF
                                position={latestLocation.location}
                                onClick={() => handleMarkerClick(latestLocation)}
                                zIndex={100}
                            ></MarkerF>
                        )}
                        {selectedMarker !== null && (
                            <InfoWindowF
                                position={selectedMarker.location}
                                onCloseClick={closeInfoWindow}
                            >
                                <div style={infoWindowStyle}>
                                    <p>
                                        {formatLocalTimezone(
                                            new Date(selectedMarker.timestamp),
                                            false,
                                        )}
                                    </p>
                                    <p>
                                        {`${selectedMarker.location.lat}, ${selectedMarker.location.lng}`}
                                    </p>
                                </div>
                            </InfoWindowF>
                        )}
                    </Map>
                </Box>
            )}
        </Box>
    );
};

export default React.memo(GpsLocations);
