import ROOT from './root';

const muiOverrides = {
    MuiInputBase: {
        styleOverrides: {
            input: {
                height: ROOT.INPUTS.HEIGHT,
                '&:-webkit-autofill': {
                    paddingTop: '0px',
                    paddingBottom: '0px',
                },
            },
        },
    },
    MuiOutlinedInput: {
        styleOverrides: {
            root: {
                height: ROOT.INPUTS.HEIGHT,
                color: ROOT.INPUTS.COLOR,
                backgroundColor: 'white',
            },
        },
    },
    MuiTableRow: {
        styleOverrides: {
            root: {
                '&[class*="MuiTableRow-hover"]:hover': {
                    backgroundColor: '#D8D8D8 !important',
                },
            },
        },
    },
    MuiTableCell: {
        styleOverrides: {
            root: {
                '&[class*="MuiTableCell-head"]': {
                    backgroundColor: '#F2F3F5',
                    borderRadius: ROOT.BORDERS.RADIUS,
                    height: '40px',
                    borderBottom: 'none',
                    fontWeight: 'bold',
                    color: '#636364',
                },
            },
            head: {
                fontWeight: 'bold',
                boxShadow: '0 2px 1px -1px rgba(0,0,0,0.08)',
            },
            sizeSmall: {
                height: '51px',
            },
        },
    },
    MuiButton: {
        styleOverrides: {
            root: {
                letterSpacing: 'unset',
            },

            textPrimary: {
                color: '#000000',
                '&:hover': {
                    backgroundColor: ROOT.COLORS.PRIMARY,
                    boxShadow: '0 6px 12px 0 rgba(0,174,239,0.25)',
                },
            },
            outlined: {
                borderColor: ROOT.BORDERS.COLOR,
                '&:hover': {
                    backgroundColor: 'inherit',
                },
            },
            outlinedPrimary: {
                borderColor: ROOT.COLORS.PRIMARY,
                backgroundColor: ROOT.COLORS.PRIMARY,
                color: 'white',
                '&:hover': {
                    backgroundColor: ROOT.COLORS.PRIMARY,
                },
            },
        },
    },
    MuiTabs: {
        styleOverrides: {
            root: {
                backgroundColor: 'transparent',
                '& .MuiTabs-indicator': {
                    bottom: 'unset',
                    top: '0px',
                    height: '3px',
                },
                '& .MuiButtonBase-root': {
                    minWidth: '160px',
                },
            },
        },
    },
    MuiTab: {
        styleOverrides: {
            root: {
                backgroundColor: 'transparent',
                '&[class*="MuiTab-textColorInherit"][class*="Mui-selected"]': {
                    backgroundColor: 'white',
                    color: ROOT.COLORS.PRIMARY,
                    borderLeft: ROOT.BORDERS.BORDER,
                    borderRight: ROOT.BORDERS.BORDER,
                },
            },
        },
    },
    MuiCard: {
        styleOverrides: {
            root: {
                borderRadius: ROOT.BORDERS.RADIUS,
                boxShadow: 'none',
                border: ROOT.BORDERS.BORDER,
                padding: '16px',
            },
        },
    },
    MuiButtonGroup: {
        styleOverrides: {
            root: {
                backgroundColor: '#F5F5F5',
                borderColor: ROOT.BORDERS.COLOR,
                height: '47px',
            },
            groupedOutlinedPrimary: {
                '&:hover': {
                    borderColor: ROOT.BORDERS.COLOR,
                },
            },
        },
    },
    MuiTypography: {
        styleOverrides: {
            subtitle2: {
                color: ROOT.LABELS.COLOR,
                fontSize: ROOT.LABELS.FONT_SIZE,
                fontWeight: ROOT.LABELS.FONT_WEIGHT,
            },
            h6: {
                color: ROOT.INPUTS.COLOR,
                fontWeight: 'bold',
                fontSize: '16px',
            },
        },
    },
    MuiFormHelperText: {
        styleOverrides: {
            contained: {
                marginLeft: '0px',
            },
        },
    },
};

export default muiOverrides;
