import React, { useEffect, useState } from 'react';
import { Alert, Box, LinearProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useReadingsDataContext } from '../dataproviders';
import Card from '../../components/Card';

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        margin: '5px 10px',
        gap: 20,
    },
    title: {
        color: 'rgb(51, 51, 51)',
        fontSize: '16px',
        fontWeight: 600,
        margin: 0,
    },
    summary: {
        display: 'flex',
        gap: 40,
        alignItems: 'center',
    },
    errorContainer: {
        margin: 0,
        fontStyle: 'italic',
    },
}));

const ReadingsSummary = () => {
    const classes = useStyles();
    const [errorMessage, setErrorMessage] = useState();
    const { data, isLoading, error } = useReadingsDataContext();

    useEffect(() => {
        if (error) {
            try {
                const details = JSON.parse(error.message);
                setErrorMessage(details.message);
            } catch {
                setErrorMessage(error.message);
            }
        } else {
            setErrorMessage(undefined);
        }
    }, [error]);

    return (
        <Box className={classes.container}>
            <h3 className={classes.title}>Readings Summary</h3>
            {isLoading && <LinearProgress />}
            {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
            {!isLoading && !errorMessage && (
                <Box className={classes.summary}>
                    <Card title="Expected" text={data?.expected} />
                    <Card title="Actual" text={data?.actual} />
                    <Card title="Lost" text={data?.lost} />
                </Box>
            )}
        </Box>
    );
};

export default ReadingsSummary;
