import { useEffect, useState } from 'react';
import { usePermissions, useRedirect } from 'react-admin';
import GROUPS from '../../auth/groups';
import deviceParams from '../device-parameters';
import { useLazyGetNextWindow } from './useGetNextWindow';

const useCreateCommand = (state) => {
    const initialRecord = {
        deviceId: state && state.deviceId,
        commandId: 1,
        commandValue: 0,
    };
    const error = !state || !state.deviceId ? 'Error loading deviceId from the state' : null;
    const deviceParamsArray = Object.keys(deviceParams).map((id) => deviceParams[`${id}`]);
    const callbackUrl =
        state && state.from
            ? state.from
            : state && state.deviceUuid
            ? `/devices/${state.deviceUuid}/show/3`
            : '/devices';

    const [selectedParam, setSelectedParam] = useState(deviceParams[1]);
    const { permissions } = usePermissions();
    const redirect = useRedirect();
    const [
        promise,
        {
            loading: loadingNextWindow,
            loaded: loadedNextWindow,
            error: errorNextWindow,
            nextWindow,
            nextWindowDistance,
            waitingFirstMessage,
        },
    ] = useLazyGetNextWindow();

    const validateValue = (value, allValues) => {
        const { validate, hint } = deviceParams[allValues.commandId];
        const result = validate(value);
        if (!result) {
            return hint;
        }
    };
    const onChangeSelectHandler = (event) => {
        const id = event.target.value;
        if (id) {
            setSelectedParam(deviceParams[`${id}`]);
        }
    };

    useEffect(() => {
        const getNextWindow = async (deviceId) => {
            await promise(deviceId);
        };
        if (
            state &&
            state.deviceId &&
            !loadingNextWindow &&
            !loadedNextWindow &&
            !error &&
            nextWindow === undefined
        ) {
            getNextWindow(state.deviceId);
        }
    }, [state, promise, loadingNextWindow, loadedNextWindow, error, nextWindow]);

    if (!!permissions && !permissions.includes(GROUPS.HSW_ADMIN)) {
        redirect('/');
    }

    return {
        deviceId: state && state.deviceId,
        initialRecord,
        callbackUrl,
        deviceParamsArray,
        selectedParam,
        nextWindow: {
            loading: loadingNextWindow,
            error: errorNextWindow,
            nextWindow: nextWindow || 0,
            nextWindowDistance: nextWindowDistance || '',
            waitingFirstMessage: !!waitingFirstMessage,
        },
        error,
        validateValue,
        onChangeSelectHandler,
    };
};

export default useCreateCommand;
