import { useEffect, useState } from 'react';
import { useDataProvider } from 'react-admin';

const getMaxDepth = (site) => {
    const survey = site && site.surveys && site.surveys.length > 0 ? site.surveys[0] : {};
    let maxDepth;
    if (site?.siteTypeId === 3) {
        maxDepth = 0;
    } else {
        const man2 = survey?.surveyAnswers?.find((answer) => answer.surveyQuestionId === 'MAN2');
        const man4 = survey?.surveyAnswers?.find((answer) => answer.surveyQuestionId === 'MAN4');
        const man6 = survey?.surveyAnswers?.find((answer) => answer.surveyQuestionId === 'MAN6');

        // max depth = f+d-b
        maxDepth = +(man6?.value ?? 0) + +(man4?.value ?? 0) - +(man2?.value ?? 0);
    }

    return maxDepth;
};

const useMaxDepth = (site) => {
    const dataProvider = useDataProvider();
    const deviceId = site && site.deviceId;
    const surveyId = site && site.surveys && site.surveys[0] && site.surveys[0].id;
    const fallbackMaxDepth = getMaxDepth(site);
    const isRaftSensor = site?.siteTypeId === 5;

    const [maxDepth, setMaxDepth] = useState(fallbackMaxDepth);

    useEffect(() => {
        const getDeviceParametersSite = async () => {
            try {
                const { data } = await dataProvider.getManyReference('deviceParametersSite', {
                    id: deviceId,
                    target: 'device_id',
                    pagination: { page: 1, perPage: 25 },
                    sort: { field: 'id', order: 'DESC' },
                });
                const siteMonitoringDepth =
                    data &&
                    data.length > 0 &&
                    data.find((param) => param.id === 'site-monitoring-depth');
                if (siteMonitoringDepth && siteMonitoringDepth.value > 0) {
                    setMaxDepth(siteMonitoringDepth.value);
                } else {
                    setMaxDepth(fallbackMaxDepth);
                }
            } catch (err) {
                setMaxDepth(fallbackMaxDepth);
                console.error(err);
            }
        };
        const getSurveyAnswers = async () => {
            try {
                const { data } = await dataProvider.getOne('surveys', {
                    id: surveyId,
                });

                const distanceToMaxLevel =
                    data &&
                    data.surveyAnswers &&
                    data.surveyAnswers.find((sa) => sa.surveyQuestionId === 'TANK2');
                if (distanceToMaxLevel && distanceToMaxLevel.value) {
                    setMaxDepth(parseInt(distanceToMaxLevel.value));
                } else {
                    setMaxDepth(fallbackMaxDepth);
                }
            } catch (err) {
                setMaxDepth(fallbackMaxDepth);
                console.error(err);
            }
        };
        if (site?.siteTypeId === 3 && surveyId) {
            getSurveyAnswers();
        } else if (deviceId && !isRaftSensor) {
            getDeviceParametersSite();
        }
    }, [deviceId, dataProvider, fallbackMaxDepth, isRaftSensor, site?.siteTypeId, surveyId]);
    return maxDepth;
};

export default useMaxDepth;
