import { format } from 'date-fns';
import { validateText } from '../utils/validation';

export const formatLocalTimezone = (date, withSeconds) => {
    if (date) {
        return format(date, 'd MMM yy, HH:mm' + (withSeconds ? ':ss' : ''));
    }
};

export const formatXaxisLabel = (date) => {
    const time = formatLocalTimezone(date).split(',');
    return `${time[0]} <br />${time[1]}`;
};

export const getDateString = (date, emptyValue = '') => {
    const options = {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: false,
    };
    return date ? new Date(date).toLocaleDateString('en-US', options) : emptyValue;
};

export const validText =
    (message = 'Invalid text') =>
    (value) => {
        if (value && value.length > 3) {
            if (!validateText(value)) {
                return message;
            } else {
                return '';
            }
        }
        return '';
    };

export const removeSVGFromChart = (chart, id) => {
    const svgElement = chart.renderer.box.getElementById(id);
    if (svgElement) {
        chart.renderer.box.removeChild(svgElement);
    }
};
