import React from 'react';
import {
    TextField,
    DateField,
    List,
    useGetList,
    SelectField,
    FunctionField,
    useLocale,
    useRedirect,
    usePermissions,
    useRecordContext,
    TopToolbar,
} from 'react-admin';
import { useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useAppContext } from '../context/AppContext';
import Layout from '../components/Layout';
import Loading from '../components/Loading';
import getListFilters from './filters';
import { deviceTypes, deviceApplicationTypes, deviceStatus as status } from './device';
import { siteTypes, applicationTypes as siteApplicationTypes } from '../sites/components/SiteTypes';
import GROUPS from '../auth/groups';
import DatagridWrapper from '../components/DatagridWrapper';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(() => ({
    container: {
        marginLeft: '34px',
        marginTop: '40px',
    },
    searchInput: {
        marginTop: '4px',
        width: '300px',
        '& input': {
            color: '#636364',
        },
    },
    referenceInput: {
        '& div[class^="RaLabeled"]': {
            marginBottom: '4px',
            minWidth: '160px',
        },
    },
}));

const ListDevices = () => {
    const classes = useStyles();
    const locale = useLocale();
    const [appStore] = useAppContext();
    const redirect = useRedirect();
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const isMed = useMediaQuery((theme) => theme.breakpoints.between('md', 'lg'));
    const { permissions } = usePermissions();
    const organisation = appStore.organisationId !== '-' ? appStore.organisationId : null;
    const { data: projects, isLoading: loading } = useGetList('projects', {
        pagination: { page: 1, perPage: 100 },
        sort: { field: 'name', order: 'ASC' },
        filter: {
            organisationId: organisation,
            projectStatus: 0,
        },
    });

    React.useEffect(() => {
        if (!!permissions && !permissions.includes(GROUPS.HSW_ADMIN)) {
            redirect('/');
        }
    });

    if (loading) return <Loading />;
    return (
        <Layout title="Devices">
            <List
                title="Devices"
                filters={getListFilters(appStore.organisationId, projects, classes)}
                filter={{ organisationId: organisation }}
                sort={{ field: 'createdAt', order: 'DESC' }}
                exporter={false}
                actions={<TopToolbar />}
            >
                <DatagridWrapper
                    bulkActionButtons={false}
                    rowStyle={() => ({
                        height: 50,
                        boxSizing: 'content-box',
                    })}
                >
                    <NameField label="Device ID" />
                    <SiteNameField label="Site"></SiteNameField>
                    {!isSmall && !isMed && <TextField source="firmware" label="Firmware" />}
                    {!isSmall && (
                        <SelectField
                            source="deviceApplication"
                            label="Device Application"
                            choices={deviceApplicationTypes}
                        />
                    )}
                    {!isSmall && !isMed && (
                        <SelectField
                            source="deviceType"
                            label="Device Type"
                            choices={deviceTypes}
                        />
                    )}
                    <FunctionField
                        label="Status"
                        source="status"
                        fullWidth
                        render={(record) => status[record.status]}
                    />
                    {!isSmall && !isMed && (
                        <SelectField
                            source="site.siteTypeId"
                            label="Site Type"
                            choices={siteTypes}
                            sortable={false}
                        />
                    )}
                    {!isSmall && !isMed && (
                        <SelectField
                            source="site.applicationTypeId"
                            label="Site Application"
                            choices={siteApplicationTypes}
                            sortable={false}
                        />
                    )}
                    {!isSmall && (
                        <DateField
                            source="createdAt"
                            label="Date Created"
                            options={{
                                day: 'numeric',
                                month: 'short',
                                year: 'numeric',
                                hour: 'numeric',
                                minute: 'numeric',
                                second: 'numeric',
                                hour12: false,
                            }}
                            locales={locale}
                            showTime
                        />
                    )}
                </DatagridWrapper>
            </List>
        </Layout>
    );
};

const SiteNameField = () => {
    const post = useRecordContext();
    const siteShowPage = `/sites/${post.siteId}/show`;
    const useStyles = makeStyles(() => ({
        linkUrl: {
            color: '#00AEEF',
            textDecoration: 'none',
        },
    }));
    const classes = useStyles();

    if (!post.siteId) {
        return null;
    }

    return (
        <Link to={siteShowPage} className={classes.linkUrl}>
            {post.site?.name}
        </Link>
    );
};

const NameField = () => {
    const post = useRecordContext();
    const siteShowPage = `/devices/${post.id}/show`;
    const useStyles = makeStyles(() => ({
        linkUrl: {
            color: '#00AEEF',
            textDecoration: 'none',
        },
    }));
    const classes = useStyles();

    return (
        <Link to={siteShowPage} className={classes.linkUrl}>
            {post.deviceId}
        </Link>
    );
};

export default ListDevices;
