import React from 'react';
import { Box, FormControl, InputLabel, Select, MenuItem, Button, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import InputMask from 'react-input-mask';

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '20px',
        backgroundColor: 'white',
        position: 'sticky',
        color: 'transparent',
        top: 0,
        left: 'auto',
        right: 0,
        zIndex: 1100,
        borderTop: (props) => (props.useBorders ? '1px solid #D8D8D8' : 'none'),
        borderLeft: (props) => (props.useBorders ? '1px solid #D8D8D8' : 'none'),
        borderRight: (props) => (props.useBorders ? '1px solid #D8D8D8' : 'none'),
        borderBottom: '1px solid #D8D8D8',
        borderRadius: (props) => (props.useBorders ? 4 : 0),
    },
});

let TrendAlgorithms = [{ id: 'sma', name: 'Moving Average' }];

const AlgorithmSelector = ({ algorithm, onApply, useBorders = true }) => {
    const [selectedValue, setSelectedValue] = React.useState(algorithm.id);

    // params for known SMA
    const [period, setPeriod] = React.useState(algorithm.param.toString());

    const classes = useStyles({ useBorders });

    let options = TrendAlgorithms;

    const handleChange = (event) => {
        setSelectedValue(event.target.value);
    };

    const handlePeriodChange = (event) => {
        setPeriod(event.target.value);
    };

    const handleApply = () => {
        let param;
        let selectedOption = options.find((option) => option.id === selectedValue);
        if (selectedOption.id === 'sma') param = parseInt(period);

        let newAlgorithm = {
            id: selectedOption.id,
            name: selectedOption.name,
            param: param,
        };

        if (param > 0) onApply(newAlgorithm);
    };

    return (
        <Box className={classes.container}>
            <Box>
                <InputLabel style={{ color: 'black', marginLeft: '70px', fontSize: '15px' }}>
                    Select an algorithm:
                </InputLabel>
                <FormControl style={{ marginLeft: '70px', marginRight: '10px', marginTop: '0px' }}>
                    <Select
                        style={{ color: '#00AEEF' }}
                        variant="outlined"
                        value={selectedValue}
                        onChange={handleChange}
                    >
                        {options.map((option) => (
                            <MenuItem
                                style={{ color: '#00AEEF' }}
                                key={option.id}
                                value={option.id}
                            >
                                {option.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
            {selectedValue && (
                <div style={{ marginLeft: '10px', marginRight: '10px', maxWidth: '150px' }}>
                    <InputLabel style={{ color: 'black', fontSize: '15px' }}>
                        Period (days):
                    </InputLabel>
                    <InputMask
                        mask="999"
                        maskPlaceholder={null}
                        value={period}
                        name="param"
                        style={{ fontSize: '15px', width: '100px', marginTop: '0px' }}
                        onChange={handlePeriodChange}
                    >
                        <TextField id="outlined-basic" variant="outlined" />
                    </InputMask>
                </div>
            )}
            <Button
                variant="contained"
                color="primary"
                onClick={handleApply}
                style={{ marginTop: '8px', marginLeft: '10px', width: '80px' }}
            >
                Apply
            </Button>
        </Box>
    );
};

export default AlgorithmSelector;
