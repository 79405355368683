import React from 'react';
import { List, Datagrid, TextField, TopToolbar, CreateButton } from 'react-admin';
import { StatusField } from './components';

const ListActions = () => (
    <TopToolbar>
        <CreateButton label="Create New Survey" icon={null} />
    </TopToolbar>
);

const SurveyList = () => {
    return (
        <List title="Surveys" actions={<ListActions />}>
            <Datagrid rowClick="show">
                <TextField source="name" />
                <StatusField source="status" />
                <TextField source="address" />
            </Datagrid>
        </List>
    );
};

export default SurveyList;
