import React from 'react';
import { Box, Button, Typography, Tooltip } from '@mui/material';
import MeasureThreshold from './MeasureThreshold';
import { makeStyles } from '@mui/styles';
import { useRefresh } from 'react-admin';

const useStyles = makeStyles(() => ({
    wideButton: {
        position: 'initial',
        display: 'initial',
    },
    measure: {
        paddingBottom: '70px',
    },
}));

const MeasurementType = ({
    onSave,
    onCancel,
    measureFromInvert,
    edit,
    onEdit,
    permissions,
    siteStatus,
}) => {
    const [measure, setMeasure] = React.useState(measureFromInvert ?? false);
    const canEdit = (permissions && permissions.includes('hsw_admin')) === true ? true : false;
    const classes = useStyles();
    const refresh = useRefresh();

    React.useEffect(() => {
        setMeasure((prevMeasure) =>
            measureFromInvert !== prevMeasure ? measureFromInvert : prevMeasure,
        );
    }, [measureFromInvert]);

    const handleCancel = (event) => {
        event.preventDefault();
        setMeasure(measureFromInvert);
        if (onCancel) onCancel();
    };

    const handleSaveMeasure = (event) => {
        event.preventDefault();
        if (onSave) onSave(measure);
        refresh(true);
    };

    return (
        <Box minHeight="300px" position="relative">
            <Box display="flex" justifyContent="space-between" width="100%" marginBottom="16px">
                <Typography variant="h6" gutterBottom>
                    Measurement Configuration
                </Typography>
                {edit === true && canEdit && (
                    <Tooltip title="Clear current changes." arrow>
                        <Button onClick={handleCancel}>Cancel</Button>
                    </Tooltip>
                )}
                {!edit && canEdit && siteStatus !== 5 && (
                    <Button
                        onClick={() => {
                            if (onEdit) onEdit();
                        }}
                    >
                        Edit
                    </Button>
                )}
            </Box>

            <MeasureThreshold
                checked={measure}
                onChange={(val) => setMeasure(val)}
                showMode={!edit}
            />

            {edit === true && (
                <Box display="flex" justifyContent="flex-end" alignItems="flex-end" pt="70px">
                    <Button
                        onClick={handleSaveMeasure}
                        color="primary"
                        className={classes.wideButton}
                    >
                        Save
                    </Button>
                </Box>
            )}
        </Box>
    );
};

export default MeasurementType;
