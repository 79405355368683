import React from 'react';
import { ReactComponent as Tampering } from '../../icons/dash-1.svg';
import { ReactComponent as General } from '../../icons/dash-2.svg';
import { ReactComponent as Inactive } from '../../icons/dash-3.svg';
import { ReactComponent as Active } from '../../icons/dash-4.svg';

const cards = [
    {
        id: 0,
        title: 'Tamper alarms',
        value: '0',
        colour: '#EA2F2F',
        icon: <Tampering />,
        href: `/alarms?filter=${JSON.stringify({ alarmType: 1, alarmStatus: 1 })}`,
    },
    {
        id: 1,
        title: 'General alarms',
        value: '0',
        colour: '#F7AF61',
        icon: <General />,
        href: `/alarms?filter=${JSON.stringify({ alarmType: 0 })}`,
    },
    {
        id: 2,
        title: 'Inactive',
        value: '0',
        colour: 'Grey',
        icon: <Inactive />,
        href: `/alarms?filter=${JSON.stringify({ alarmType: 2 })}`,
    },
    {
        id: 3,
        title: 'Active',
        value: '0',
        colour: '#5CECBF',
        icon: <Active />,
        href: `/sites?filter=${JSON.stringify({ status: 4 })}`,
    },
];

const calculateCards = (cards, dashboard) => {
    for (const card of cards) {
        if (card.id === 0) card.value = dashboard?.tamperAlarms ?? 0;
        if (card.id === 1) card.value = dashboard?.generalAlarms ?? 0;
        if (card.id === 2) card.value = dashboard?.inactiveSites ?? 0;
        if (card.id === 3) card.value = dashboard?.activeSites ?? 0;
    }
    return cards;
};

export { cards, calculateCards };
