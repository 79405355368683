import React from 'react';
import { Box, TextField, InputAdornment } from '@mui/material';
import Layout from '../components/Layout';
import { useAppContext } from '../context/AppContext';
import SearchIcon from '@mui/icons-material/Search';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DashboardSites from './DashboardSites';
import DashboardMap from './DashboardMap';
import QuickCards from './components/QuickCards';
import Map from '../components/Map';
import { makeStyles } from '@mui/styles';
import Loading from '../components/Loading';
import { cards, calculateCards } from './components/cards';
import { useFeature } from '../context/FeatureFlags';
import { Features } from '../constant';
import { getAccessToken } from '../auth/auth0Functions';

const lib = ['places', 'drawing', 'geometry'];
const useStyles = makeStyles(() => ({
    layout: {
        marginTop: '-10px !important',
    },
    card: {
        display: 'flex',
        flexDirection: 'column',
        width: '263px',
        height: '140px',
        borderRadius: '4px',
        marginBottom: '14px',
        padding: '24px',
    },
    tabContainer: {
        marginTop: '30px',
    },
    numericValue: {
        fontSize: '40px',
        lineHeight: '53px',
    },
    numericLabel: {
        fontSize: '16px',
        lineHeight: '21px',
        color: '#747474',
    },
    mapContainer: {
        width: '100%',
        height: '59vh',
    },
    infoWindow: {
        height: '230px',
        width: '210px',
        padding: '5px',
    },
}));

const Dashboard = () => {
    const classes = useStyles();
    const [appStore] = useAppContext();
    const [mapView, setMapView] = React.useState(true);
    const [loading, setIsLoading] = React.useState(false);
    const [query, setQuery] = React.useState();

    const getDashboard = async () => {
        setIsLoading(true);
        const token = await getAccessToken();
        const url =
            process.env.REACT_APP_API_BASE_URL +
            `/dashboard/${appStore.organisationId ?? '-'}?query=${query ? query : ''}`;
        const options = {
            headers: new Headers({
                Accept: 'application/json',
                Authorization: token,
            }),
        };
        const res = await fetch(url, options);
        const json = await res.json();
        setDashboard(json.data);
        setIsLoading(false);
    };

    React.useEffect(() => {
        setIsLoading(true);
        let dashboardQuery = window.sessionStorage.getItem('dashboardQuery');
        dashboardQuery = dashboardQuery ? dashboardQuery : '';
        setQuery(dashboardQuery);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [dashboard, setDashboard] = React.useState();
    const hasAlarms = useFeature(Features.alarms);
    const handleMapviewChange = (event) => {
        setMapView(!event.target.checked);
        setIsLoading(true);
    };

    React.useEffect(() => {
        const t = setTimeout(() => {
            window.sessionStorage.setItem('dashboardQuery', query);
            if (mapView) {
                getDashboard();
            } else {
                setIsLoading(false);
            }
        }, 1000);
        return () => {
            clearTimeout(t);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query, appStore.organisationId, mapView]);

    if (loading) return <Loading />;

    return (
        <Layout className={classes.layout}>
            <QuickCards cards={calculateCards(cards, dashboard)} enableLinks={hasAlarms} />
            <Box display="flex" justifyContent="space-between" alignItems="center" mb="16px">
                <Box>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="location"
                        type="text"
                        placeholder="Search by site, device ID or address"
                        variant="outlined"
                        fullWidth
                        value={query}
                        onChange={(e) => setQuery(e.target.value)}
                        style={{ width: '360px' }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                        autoComplete="off"
                    />
                </Box>
                <Box display="inline">
                    <FormGroup style={{ display: 'inline' }}>
                        <span style={{ marginRight: '10px' }}>Map view</span>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={!mapView}
                                    onChange={handleMapviewChange}
                                    color="secondary"
                                />
                            }
                            label="List view"
                        />
                    </FormGroup>
                </Box>
            </Box>
            {!mapView && dashboard?.sites && (
                <DashboardSites
                    key={`sites=${query}`}
                    sites={dashboard.sites ?? []}
                    query={query}
                />
            )}
            {mapView && (
                <Box display="flex" width="100%" height="100vh" className={classes.mapContainer}>
                    <Map maxZoom={17} zoom={10} lib={lib} withLocationMem>
                        {dashboard && <DashboardMap dashboard={dashboard} query={query} />}
                    </Map>
                </Box>
            )}
        </Layout>
    );
};

export default Dashboard;
