import React from 'react';
import {
    Button,
    Confirm,
    TextField,
    FunctionField,
    DateField,
    List,
    SelectField,
    SelectInput,
    useUpdate,
    useNotify,
    useUnselectAll,
    useLocale,
    useLogout,
    useRecordContext,
    usePermissions,
} from 'react-admin';
import { useAppContext } from '../context/AppContext';
import { makeStyles } from '@mui/styles';
import SearchFilter from '../components/SearchFilter';
import AlarmTypeField from '../components/AlarmTypeField';
import Layout from '../components/Layout';
import { alarmTypes, statuses } from './alarm';
import DatagridWrapper from '../components/DatagridWrapper';
import GROUPS from '../auth/groups';
import { transformDescription } from '../utils/alarmDescription';
import { UpdateWithPromise } from '../components/UpdateWithPromise';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(() => ({
    linkUrl: {
        color: '#00AEEF',
        textDecoration: 'none',
    },
    hidden: {
        display: 'none',
    },
}));

const ListAlarms = () => {
    const classes = useStyles();
    const locale = useLocale();
    const [appStore] = useAppContext();
    const { isLoading: loading } = useUpdate()[1];
    //const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll('alarms');
    const [open, setOpen] = React.useState(false);
    const { permissions } = usePermissions();
    const canEdit =
        !!permissions &&
        permissions.includes(GROUPS.HSW_ADMIN, GROUPS.HSW_USER, GROUPS.CUSTOMER_ADMIN);
    const customerCanAck = !!permissions && permissions.includes('hsw_customeradmin');
    const listFilters = [
        <SearchFilter
            key="filter-query"
            placeholder="Search by description, device"
            alwaysOn
            source="query-alarms"
        />,
        <SelectInput
            alwaysOn
            key="filter-type"
            source="alarmType"
            value="status"
            label="Alarm Type"
            variant="outlined"
            choices={alarmTypes}
            className={classes.status}
            emptyText="All"
            emptyValue="All"
            initialValue="All"
        />,
        <SelectInput
            alwaysOn
            key="filter-status"
            source="alarmStatus"
            value="status"
            label="Status"
            variant="outlined"
            choices={statuses}
            className={classes.status}
            emptyText="All"
            emptyValue="All"
            initialValue="All"
        />,
    ];
    const handleLogout = useLogout();
    React.useEffect(() => {
        unselectAll();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleAcknowledge = async (table, ids) => {
        let idsToAcknowledge = [];
        for (let i = 0; i < ids.length; i++) {
            // eslint-disable-next-line security/detect-object-injection
            idsToAcknowledge.push({ id: ids[i] });
        }

        var [status, error] =
            (await UpdateWithPromise(
                'alarms',
                {
                    alarmStatus: 0,
                    ids: idsToAcknowledge,
                },
                'PATCH',
            ).then((response) => {
                status = response[0];
                error = response[1];
                if (status === 404) {
                    window.sessionStorage.clear();
                    handleLogout();
                }
                if (error) {
                    console.log(error);
                    notify('Alarms could not be acknowledged.', { type: 'warning' });
                } else {
                    notify('Alarms acknowledged.', { type: 'success' });
                    setTimeout(() => {
                        window.location.reload(false);
                    }, 3000);
                }
            })) ?? [];

        setOpen(false);
    };

    const BulkActionButton = ({ resource, selectedIds }) => {
        return (
            <>
                <Button color="secondary" label="Acknowledge" onClick={() => setOpen(true)} />
                <Confirm
                    isOpen={open}
                    loading={loading}
                    title="Acknowledge Alarms"
                    content="Are you sure you want to acknowledge these alarms?"
                    onConfirm={() => handleAcknowledge(resource, selectedIds)}
                    onClose={() => setOpen(false)}
                />
            </>
        );
    };

    return (
        <Layout title="Alarms">
            <pre className={classes.hidden}>{permissions}</pre>
            <List
                title="Alarms"
                filters={listFilters}
                filter={{
                    organisationId:
                        appStore.organisationId !== '-' ? appStore.organisationId : null,
                }}
                exporter={false}
                sort={{ field: 'createdAt', order: 'DESC' }}
                actions={null}
            >
                <DatagridWrapper
                    isRowSelectable={(record) =>
                        record.alarmStatus === 1 && (canEdit || customerCanAck)
                    }
                    bulkActionButtons={<BulkActionButton />}
                    rowStyle={() => ({
                        height: 50,
                        boxSizing: 'content-box',
                    })}
                >
                    <SiteNameField label="Site" />
                    <TextField source="deviceId" label="Device ID" />
                    <FunctionField
                        source="description"
                        label="Details"
                        render={(record) => transformDescription(record.description)}
                    />
                    <AlarmTypeField source="alarmType" />
                    <SelectField source="alarmStatus" choices={statuses} />
                    <DateField
                        source="createdAt"
                        label="Date Created"
                        options={{
                            day: 'numeric',
                            month: 'short',
                            year: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric',
                            second: 'numeric',
                            hour12: false,
                        }}
                        locales={locale}
                        showTime
                    />
                </DatagridWrapper>
            </List>
        </Layout>
    );
};

const SiteNameField = () => {
    const post = useRecordContext();
    const siteShowPage = `/sites/${post.siteId}/show`;
    const useStyles = makeStyles(() => ({
        linkUrl: {
            color: '#00AEEF',
            textDecoration: 'none',
        },
    }));
    const classes = useStyles();

    return (
        <Link to={siteShowPage} className={classes.linkUrl}>
            {post.site?.name}
        </Link>
    );
};

export default ListAlarms;
