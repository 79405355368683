import React from 'react';
import { LinearProgress } from 'react-admin';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import useSessionStorage from '../hooks/useSessionStorage';
import { debounce } from '@mui/material';

const mapStyle = [
    { featureType: 'poi', elementType: 'labels', stylers: [{ visibility: 'off' }] },
    { featureType: 'transit.station', elementType: 'labels', stylers: [{ visibility: 'off' }] },
];

const lib = ['places', 'drawing', 'geometry'];

const Map = (props) => {
    const withLocationMem = props.withLocationMem ?? false;
    const mapRef = React.useRef(null);
    const { isLoaded, loadError } = useJsApiLoader({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
        region: 'AU',
        preventGoogleFontsLoading: false,
        libraries: props.lib ?? lib,
    });
    const [mapOptions] = React.useState({
        mapTypeControlOptions: {
            mapTypeIds: [],
        },
        zoomControl: false,
        zoomControlOptions: {
            position: 6,
        },
        disableDefaultUI: true,
        mapTypeId: 'roadmap',
        mapTypeControl: false,
        minZoom: 3,
        //maxZoom: 16,
        styles: mapStyle,
    });
    React.useEffect(() => {}, []);
    const [location, setLocation] = useSessionStorage('location', {
        zoom: props.zoom,
        center: undefined,
    });

    const handleCenterChange = () => {
        const map = mapRef.current;
        if (!map) return;
        const mapControl = map.state.map;
        if (mapControl && withLocationMem) {
            const mapZoom = mapControl.getZoom();
            const center = mapControl.getCenter();
            setLocation({ center: center, zoom: mapZoom });
        }
    };

    if (loadError) return <span>Could not load map</span>;
    if (!isLoaded) return <LinearProgress />;
    return (
        <GoogleMap
            ref={mapRef}
            options={mapOptions}
            center={
                props.center ??
                location.center ?? {
                    lat: -36.839948067403256,
                    lng: 174.76516616157053,
                }
            }
            onCenterChanged={debounce(handleCenterChange, 100)}
            zoom={withLocationMem ? location.zoom : props.zoom ?? location.zoom}
            mapContainerStyle={{
                height: '100%',
                width: '100%',
            }}
            onLoad={(map) => {
                if (props.onLoad) props.onLoad(map);
            }}
        >
            {props.children}
        </GoogleMap>
    );
};

export default Map;
