import { Box, LinearProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import DatetimeIntl from '../../components/DatetimeIntl';
import Error from '../../components/Error';
import ROOT from '../../theme/root';

const useStyles = makeStyles({
    container: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        rowGap: 8,
    },
    loading: {
        minWidth: 150,
        display: 'flex',
        alignItems: 'center',
    },
    loadingBar: {
        width: '100%',
    },
    error: {
        margin: 0,
        display: 'flex',
        color: ROOT.COLORS.RED,
        alignItems: 'center',
    },
    errorIcon: {
        width: '0.8em',
        height: '0.8em',
        marginRight: 5,
    },
});

const NextWindow = (props) => {
    const classes = useStyles();
    const { nextWindow, nextWindowDistance, waitingFirstMessage, loading, error } = props;
    // Loading & Error
    if (loading) {
        return (
            <Box className={classes.loading}>
                <LinearProgress className={classes.loadingBar} aria-label="next-window-loading" />
            </Box>
        );
    }

    if (error) {
        return (
            <Error
                message={error}
                icon={true}
                iconClass={classes.errorIcon}
                classes={classes.error}
            />
        );
    }

    if (waitingFirstMessage) {
        return (
            <Box id="next-window" className={classNames(classes.container, props.className)}>
                Waiting for first message...
            </Box>
        );
    } else {
        return (
            <Box id="next-window" className={classNames(classes.container, props.className)}>
                {nextWindow > 0 && (
                    <span id="nextWindowTime">
                        <DatetimeIntl
                            timestampMilliseconds={nextWindow}
                            ariaLabel="Next Window Time"
                        />{' '}
                        {nextWindowDistance && nextWindowDistance != 0
                            ? `(${nextWindowDistance})`
                            : ''}
                    </span>
                )}
            </Box>
        );
    }
};

NextWindow.propTypes = {
    nextWindow: PropTypes.number,
    nextWindowDistance: PropTypes.string,
    waitingFirstMessage: PropTypes.bool,
    loading: PropTypes.bool,
    error: PropTypes.string,
};

export default NextWindow;
