import GROUPS from '../auth/groups';
import ShowRainfallSite from './Show';

const RainfallSites = (permissions) => {
    const rainfallSite = {
        list: null,
        show: null,
        create: null,
        edit: null,
    };
    if (
        permissions &&
        (permissions.includes(GROUPS.HSW_ADMIN) || permissions.includes(GROUPS.ANALYTICS))
    ) {
        rainfallSite.show = ShowRainfallSite;
    }
    return rainfallSite;
};

export default RainfallSites;
