import { validateText } from '../utils/validation';

export const validText =
    (message = 'Invalid text') =>
    (value) => {
        if (value && value.length > 2) {
            if (!validateText(value)) {
                return message;
            } else {
                return '';
            }
        }
        return '';
    };
