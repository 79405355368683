import React from 'react';
import {
    CreateButton,
    TextField,
    List,
    SelectField,
    usePermissions,
    useRedirect,
    useRecordContext,
    useListContext,
} from 'react-admin';
import SearchIcon from '@mui/icons-material/Search';
import {
    Card,
    InputAdornment,
    Box,
    Typography,
    Select,
    MenuItem,
    TextField as MuiTextField,
    LinearProgress,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import SiteStatusField from '../sites/components/Status';
import GROUPS from '../auth/groups';
import { useAppContext } from '../context/AppContext';
import DatagridWrapper from '../components/DatagridWrapper';
import { siteTypes, applicationTypes } from '../sites/components/SiteTypes';

const useStyles = makeStyles(() => ({
    statusFilter: {
        marginLeft: '10px',
    },
    siteContainer: {},
    content: {
        padding: '0px',
        border: 'none',
    },
}));

const siteStatuses = [
    { id: -1, name: 'All' },
    { id: 0, name: 'Pending Survey' },
    { id: 1, name: 'Survey Submitted' },
    { id: 2, name: 'Approved' },
    { id: 3, name: 'Rejected' },
    { id: 4, name: 'Installed' },
    { id: 5, name: 'Deleted' },
];

const InsideListComponent = ({ onLoadingComplete }) => {
    const { isLoading, total } = useListContext();
    const wasLoading = React.useRef(true);
    const prevTotal = React.useRef(total);

    React.useEffect(() => {
        if ((wasLoading.current && !isLoading) || prevTotal.current !== total) {
            onLoadingComplete(total);
        }

        wasLoading.current = isLoading;
        prevTotal.current = total;
    }, [isLoading, onLoadingComplete, total]);

    return <></>;
};

const ProjectSites = () => {
    const classes = useStyles();
    const [appStore] = useAppContext();
    const [query, setQuery] = React.useState('');
    const [loading, setLoading] = React.useState(true);
    const [total, setTotal] = React.useState(0);
    const redirect = useRedirect();
    const [statusFilter, setStatusFilter] = React.useState(-1);
    const { permissions } = usePermissions();
    const record = useRecordContext();
    const canCreate =
        !!permissions &&
        permissions.includes(GROUPS.HSW_ADMIN) &&
        record &&
        record.projectStatus === 0 &&
        appStore.organisationId !== '-';

    if (!record) return <LinearProgress />;

    const handleLoadingComplete = (t) => {
        setLoading(false);
        setTotal(t ?? 0);
    };

    const siteFilter = {
        projectId: record?.id,
        query: query,
        status: statusFilter,
    };

    const search = (
        <InputAdornment position="end">
            <SearchIcon />
        </InputAdornment>
    );
    return (
        <>
            <Box>
                <Card margin={4}>
                    <Box display="flex" flexDirection="column" marginBottom={2}>
                        <Box display="flex">
                            <Typography variant="h6" gutterBottom>
                                {loading && 'Sites'}
                                {!loading && `Sites (${total})`}
                            </Typography>
                        </Box>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <MuiTextField
                                id="q"
                                variant="outlined"
                                placeholder="Search by name or address"
                                style={{ width: '300px' }}
                                InputProps={{
                                    endAdornment: search,
                                }}
                                value={query}
                                onChange={(event) => setQuery(event.target.value)}
                            />
                            <Box>
                                <span>Status:</span>
                                <Select
                                    id="site-status"
                                    value={statusFilter}
                                    variant="outlined"
                                    onChange={(event) => setStatusFilter(event.target.value)}
                                    style={{ minWidth: '150px', marginLeft: '10px' }}
                                >
                                    {siteStatuses.map((status) => (
                                        <MenuItem key={status.id} value={status.id}>
                                            {status.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Box>
                            {canCreate && (
                                <CreateButton
                                    label="Add"
                                    icon={null}
                                    to={{
                                        pathname: '/sites/create',
                                        search: `?projectid=${record.id}`,
                                        state: { record: { projectId: record.id } },
                                    }}
                                />
                            )}
                        </Box>
                    </Box>
                    <List
                        resource="sites"
                        title="Sites"
                        actions={null}
                        filter={siteFilter}
                        sort={{ field: 'createdAt', order: 'DESC' }}
                        component="div"
                        classes={{ content: classes.content }}
                    >
                        <InsideListComponent onLoadingComplete={handleLoadingComplete} />
                        <DatagridWrapper
                            bulkActionButtons={false}
                            rowClick={(id) => redirect('show', 'sites', id)}
                            rowStyle={() => ({
                                height: 50,
                                boxSizing: 'content-box',
                            })}
                        >
                            <TextField source="name" label="Site Name" />
                            <SiteStatusField source="status" />
                            <SelectField
                                source="siteTypeId"
                                label="Site Type"
                                choices={siteTypes}
                            />
                            <SelectField
                                source="applicationTypeId"
                                label="Application Type"
                                choices={applicationTypes}
                            />
                            <TextField source="address" />
                            <TextField source="deviceId" label="Device ID" />
                        </DatagridWrapper>
                    </List>
                </Card>
            </Box>
        </>
    );
};

export default ProjectSites;
