import React, { useEffect, useState } from 'react';
import LineChart, { defaultChartOptions, defaultSeries } from './LineChart';
import { useMiscDataContext } from '../dataproviders';

const chartOptions = {
    title: {
        ...defaultChartOptions.title,
        text: 'Battery Level',
    },
    yAxis: [
        {
            ...defaultChartOptions.yAxis[0],
            title: {
                text: 'mAh',
            },
        },
    ],
};

const Battery = () => {
    const { data, isLoading: loadingContext, error, from, to } = useMiscDataContext();
    const [series, setSeries] = useState();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(!!loadingContext);
        if (loadingContext == true) return;
        const battery = {
            ...defaultSeries,
            name: 'battery',
            data: data?.battery || [],
            showInLegend: false,
        };
        setSeries([battery]);
    }, [data, loadingContext]);

    return (
        <LineChart
            chartOptions={chartOptions}
            series={series}
            loading={isLoading}
            error={error?.message}
            min={from}
            max={to}
        />
    );
};

export default Battery;
