import React from 'react';
import Loading from '../../components/Loading';
import { fetchComments } from './EditSurvey';
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Card,
} from '@mui/material';
import { getDateString } from '../utils';

const SurveyComments = (props) => {
    const [loading, setLoading] = React.useState(false);
    const [feedback, setFeedback] = React.useState([]);

    React.useEffect(() => {
        async function getComments() {
            setLoading(true);
            var comments = await fetchComments(props.siteId);
            const flattedFeedback = comments?.map((survey) => survey.feedback).flat(1);
            const filteredComments = flattedFeedback?.filter((f) => f?.details?.length > 0);
            setFeedback(filteredComments);
            setLoading(false);
        }
        if (props.siteId) {
            getComments();
        }
    }, [props.siteId]);

    if (loading) return <Loading />;

    return (
        <>
            {feedback.length > 0 ? (
                <Box>
                    <Card>
                        <TableContainer component={Paper}>
                            <Table stickyHeader aria-label="device parameters">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left" size="small">
                                            Created at
                                        </TableCell>
                                        <TableCell align="left" size="small">
                                            Details
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {feedback &&
                                        feedback.map((f) => (
                                            <TableRow key={f.id}>
                                                <TableCell align="left" component="th" scope="id">
                                                    {getDateString(f.createdAt)}
                                                </TableCell>
                                                <TableCell align="left">{f.details}</TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Card>
                </Box>
            ) : (
                <span>No comments to show</span>
            )}
        </>
    );
};

export default SurveyComments;
