import GROUPS from '../auth/groups';
import ShowRainfallNetwork from './Show';

const RainfallNetworks = (permissions) => {
    const rainfallNetwork = {
        list: null,
        show: null,
        create: null,
        edit: null,
    };
    if (
        permissions &&
        (permissions.includes(GROUPS.HSW_ADMIN) || permissions.includes(GROUPS.ANALYTICS))
    ) {
        rainfallNetwork.show = ShowRainfallNetwork;
    }
    return rainfallNetwork;
};

export default RainfallNetworks;
