import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useNotify } from 'react-admin';
import { useLevelRainfallContext } from '../dataproviders';
import LineChart from '../../components/LineChart';
import ROOT from '../../../theme/root';

const levelSeries = {
    id: 'level',
    name: 'Level',
    data: [],
    dataGrouping: {
        enabled: false,
    },
    showInNavigator: true,
    yAxis: 0,
    color: ROOT.COLORS.PRIMARY,
};

const rainfallSeries = {
    id: 'rainfall',
    name: 'Rainfall',
    data: [],
    dataGrouping: {
        enabled: false,
    },
    showInNavigator: true,
    yAxis: 1,
    color: '#2FBD68',
};
const chartOptions = {
    title: {
        text: '',
        align: 'left',
        style: {
            fontWeight: 600,
        },
    },
    yAxis: [
        {
            title: {
                text: 'Level mm',
            },
            opposite: false,
            min: 0,
            lineWidth: 1,
            labels: {
                format: '{value:.0f}',
            },
            tickAmount: 10,
        },
        {
            title: {
                text: 'Rainfall mm',
            },
            opposite: true,
            reversed: true,
            min: 0,
            lineWidth: 1,
            labels: {
                format: '{value:.0f}',
            },
            tickAmount: 10,
        },
    ],
    series: [levelSeries, rainfallSeries],
};

const useStyles = makeStyles(() => ({
    container: {
        backgroundColor: '#FFFFFF',
        border: ROOT.BORDERS.BORDER,
        borderRadius: '4px',
        padding: '16px',
    },
}));

const LevelRainfall = () => {
    const { level, rainfallData, fromDate, toDate } = useLevelRainfallContext();
    const notify = useNotify();
    const [series, setSeries] = useState(() => {
        if (rainfallData.data.siteId === null) {
            return [levelSeries];
        }
        return [levelSeries, rainfallSeries];
    });
    const classes = useStyles();
    const [error, setError] = useState({ level: null, rainfallData: null });

    let chartTitle = 'Level and rainfall data';
    let options = {
        ...chartOptions,
    };
    if (rainfallData.data.siteId === null) {
        chartTitle = 'Level data';
        options.yAxis = [chartOptions.yAxis[0]];
        options.series = [chartOptions.series[0]];
    }
    useEffect(() => {
        const newSeries = [];
        let levelChanged = false;
        let rainfallChanged = false;
        const currentLevel = series.find((s) => s.id === 'level');
        const currentRainfall = series.find((s) => s.id === 'rainfall');

        // Level
        if (
            level?.data?.level &&
            level?.error === null &&
            currentLevel.data.length !== level.data.level.length
        ) {
            levelChanged = true;
            newSeries.push({
                ...levelSeries,
                data: level.data.level,
            });
        } else {
            newSeries.push(currentLevel);
        }

        // Rainfall
        if (
            rainfallData.data.siteId !== null &&
            rainfallData?.loading === false &&
            rainfallData?.error === null &&
            (currentRainfall.data.length !== rainfallData.data.rainfallData.length ||
                currentRainfall.name.indexOf(rainfallData.data.name) === -1)
        ) {
            rainfallChanged = true;
            newSeries.push({
                ...rainfallSeries,
                data: rainfallData.data.rainfallData,
                name: 'Rainfall - ' + rainfallData.data.name,
            });
        } else if (rainfallData.data.siteId !== null) {
            newSeries.push(currentRainfall);
        }

        // Update
        if (levelChanged || rainfallChanged) {
            setSeries(newSeries);
        }
    }, [level, rainfallData, series]);

    useEffect(() => {
        let errorChanged = false;
        if (level && level.error && level.error !== error.level) {
            notify(`Level data: ${level.error}`, { type: 'warning' });
            errorChanged = true;
        }
        if (rainfallData && rainfallData.error && rainfallData?.error !== error.rainfallData) {
            notify(`Rainfall data: ${rainfallData.error}`, { type: 'warning' });
            errorChanged = true;
        }
        if (errorChanged) {
            setError({
                level: level?.error || null,
                rainfallData: rainfallData?.error || null,
            });
        }
    }, [level, rainfallData, error, notify]);

    return (
        <Box className={classes.container}>
            <Typography component="h6" variant="h6" mb="20px" mt="10px">
                {chartTitle}
            </Typography>
            <LineChart
                chartOptions={options}
                series={series}
                loading={!!level.loading || !!rainfallData.loading}
                error={error.level && error.rainfall ? error : null}
                min={fromDate}
                max={toDate}
            />
        </Box>
    );
};
export default LevelRainfall;
