import React from 'react';
import { Resource } from 'react-admin';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { Route } from 'react-router-dom';
import Organisations from './organisations';
import Users from './users';
import Surveys from './surveys';
import Project from './projects';
import Sites from './sites';
import Devices from './devices';
import Alarms from './alarms';
import { Downlink } from './downlink';
import CommandRequests from './command-requests';
import CompareSites from './sites/compare';
import { ReactComponent as ProjectsIcon } from './icons/projects.svg';
import { ReactComponent as SitesIcon } from './icons/sites.svg';
import { ReactComponent as CustomersIcon } from './icons/customers.svg';
import { ReactComponent as DevicesIcon } from './icons/devices.svg';
import { ReactComponent as UsersIcon } from './icons/users.svg';
import { ReactComponent as AlarmsIcon } from './icons/alarms.svg';
import GROUPS from './auth/groups';
import RainfallSites from './rainfallSites';
import RainfallNetworks from './rainfallNetwork';

export default function Resources(permissions) {
    return [
        permissions.includes(GROUPS.HSW_USER) ||
        permissions.includes(GROUPS.HSW_ADMIN) ||
        permissions.includes(GROUPS.CUSTOMER_ADMIN) ||
        permissions.includes(GROUPS.CUSTOMER_USER) ||
        permissions.includes(GROUPS.PORTALACCESS) ? (
            <Resource
                key="projects"
                name="projects"
                {...Project(permissions)}
                icon={ProjectsIcon}
                options={{ label: 'Projects' }}
            />
        ) : null,

        <Resource
            key="customers"
            name="organisations"
            {...Organisations(permissions)}
            icon={CustomersIcon}
            options={{ label: 'Customers' }}
        />,

        permissions.includes(GROUPS.HSW_ADMIN) ||
        permissions.includes(GROUPS.HSW_USER) ||
        permissions.includes(GROUPS.CUSTOMER_ADMIN) ? (
            <Resource
                key="users-mgmt"
                name="users"
                {...Users}
                icon={UsersIcon}
                options={{ label: 'User Management' }}
            />
        ) : null,

        <Resource
            key="users"
            name="users"
            {...Users}
            icon={UsersIcon}
            options={{ label: 'User Management' }}
        />,

        <Resource
            key="surveys"
            name="surveys"
            {...Surveys}
            icon={AssignmentIcon}
            options={{ label: 'Site Surveys' }}
        />,
        <Resource
            key="sites"
            name="sites"
            {...Sites(permissions)}
            icon={SitesIcon}
            options={{ label: 'Sites' }}
        >
            <Route path="compare" element={<CompareSites />} />
        </Resource>,
        <Resource
            key="devices"
            name="devices"
            {...Devices(permissions)}
            icon={DevicesIcon}
            options={{ label: 'Devices' }}
        />,
        <Resource
            key="alarms"
            name="alarms"
            {...Alarms}
            icon={AlarmsIcon}
            options={{ label: 'Alarms' }}
        />,
        <Resource key="analytics" name="analytics" />,
        <Resource key="address" name="addresses" />,
        <Resource key="projectStatus" name="projectStatus" />,
        <Resource key="dashboard" name="dashboard" />,
        <Resource key="alarmscount" name="alarmscount" />,
        <Resource key="jobs" name="jobs" />,
        <Resource key="surveystatus" name="surveyStatus" />,
        <Resource key="rules" name="rules" />,
        <Resource key="ruleActions" name="ruleActions" />,
        <Resource key="deviceParametersSite" name="deviceParametersSite" />,
        permissions.includes(GROUPS.HSW_ADMIN) && (
            <Resource key="deviceParameters" name="deviceParameters" />
        ),
        permissions.includes(GROUPS.HSW_ADMIN) && (
            <Resource key="downlinkCommands" name="downlinkCommands" {...Downlink(permissions)} />
        ),
        permissions.includes(GROUPS.HSW_ADMIN) && (
            <Resource key="downlinkCommands/nextwindow" name="downlinkCommands/nextwindow" />
        ),
        <Resource key="commandRequests" name="commandRequests" {...CommandRequests(permissions)} />,
        <Resource key="rainfallSites" name="rainfallSites" {...RainfallSites(permissions)} />,
        <Resource
            key="rainfallNetworks"
            name="rainfallNetworks"
            {...RainfallNetworks(permissions)}
        />,
    ];
}
