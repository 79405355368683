import React from 'react';
import { Box, Typography } from '@mui/material';

const HelperLabel = (props) => {
    return (
        <Box display="flex" alignItems="center" marginBottom="10px" fontSize="14px">
            <Typography fontSize="16px" fontWeight="bold" color="#EA2F2F" marginRight="8px">
                {props.label}
            </Typography>
            {props.description}
        </Box>
    );
};

export default HelperLabel;
