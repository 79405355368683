import React, { useEffect } from 'react';
import { useShowController, usePermissions, useRedirect } from 'react-admin';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import Breadcrumb from '../menu/Breadcrumb';
import { makeStyles } from '@mui/styles';
import Layout from '../components/Layout';
import GROUPS from '../auth/groups';
import RainfallNetworkShowLayout from './components/ShowLayout';

const useStyles = makeStyles(() => ({
    title: {
        fontSize: '32px',
        fontWeight: 'bold',
        lineHeight: '43px',
    },
}));

const ShowRainfallNetwork = () => {
    const classes = useStyles();

    // Permissions
    const redirect = useRedirect();
    const { permissions } = usePermissions();
    const isCustomerAdminAnalytics =
        !!permissions &&
        permissions.includes(GROUPS.CUSTOMER_ADMIN) &&
        permissions.includes(GROUPS.ANALYTICS);
    const isHyndsAdmin = !!permissions && permissions.includes(GROUPS.HSW_ADMIN);
    useEffect(() => {
        if (!isHyndsAdmin && !isCustomerAdminAnalytics) {
            redirect('/');
        }
    }, [redirect, isCustomerAdminAnalytics, isHyndsAdmin]);

    // Data
    const {
        record: rainfallNetwork,
        isLoading,
        error,
    } = useShowController({
        queryOptions: {
            enabled: isHyndsAdmin || isCustomerAdminAnalytics,
        },
    });

    // Breadcrumbs
    const overrides = {};
    if (rainfallNetwork && rainfallNetwork.id) {
        overrides[rainfallNetwork.id] = rainfallNetwork.name;
    }

    return (
        <Layout>
            <Breadcrumb overrides={overrides} />
            <Box mt={2}>
                <Typography variant="h5" gutterBottom className={classes.title}>
                    <span>
                        Rainfall Network {rainfallNetwork ? `"${rainfallNetwork.name}"` : ''}
                    </span>
                </Typography>
            </Box>
            <RainfallNetworkShowLayout
                record={rainfallNetwork}
                isLoading={isLoading}
                error={error}
            />
        </Layout>
    );
};

export default ShowRainfallNetwork;
