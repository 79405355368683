import { getAccessToken } from '../auth/auth0Functions';

const setRulesActions = async (type, id, rules) => {
    if (!type || !id) return;
    const body = { ruleActions: rules };
    if (type === 'project') {
        body.projectId = id;
        rules.forEach((element) => {
            element.projectId = id;
        });
    }
    if (type === 'site') {
        body.siteId = id;
        rules.forEach((element) => {
            element.siteId = id;
        });
    }
    const token = await getAccessToken();
    await fetch(`${process.env.REACT_APP_API_BASE_URL}/ruleActions`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: token ?? '',
        },
        body: JSON.stringify(body),
    })
        .then((response) => {
            return response.json();
        })
        .catch((error) => {
            console.log(error);
        });
};

export { setRulesActions };
