import React from 'react';
import { RadioButtonGroupInput, required } from 'react-admin';
import { useWatch } from 'react-hook-form';
import { tankMaterials } from '..';

const QuestionsInput = () => {
    const surveyTypeId = useWatch({ name: 'surveyTypeId' });
    return (
        <>
            {surveyTypeId === 6 && (
                <RadioButtonGroupInput
                    source="questions.Tank material"
                    label="Tank Material"
                    validate={[required()]}
                    parse={(v) => parseInt(v)}
                    choices={tankMaterials}
                />
            )}
        </>
    );
};

export default QuestionsInput;
