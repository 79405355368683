import React from 'react';
import {
    List,
    FunctionField,
    TextField,
    DateField,
    TopToolbar,
    CreateButton,
    usePermissions,
    useRedirect,
} from 'react-admin';
import { useMediaQuery } from '@mui/material';
import Layout from '../components/Layout';
import SearchFilter from '../components/SearchFilter';
import GROUPS from '../auth/groups';
import DatagridWrapper from '../components/DatagridWrapper';

export default function OrganisationList() {
    const { permissions } = usePermissions();
    const redirect = useRedirect();
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    if (
        !!permissions &&
        !permissions.includes(GROUPS.HSW_ADMIN) &&
        !permissions.includes(GROUPS.HSW_USER)
    ) {
        redirect('/');
    }

    const ListActions = () => (
        <TopToolbar>
            <CreateButton label="Create New Customer" icon={null} />
        </TopToolbar>
    );
    return (
        <Layout title="Customers">
            <List
                title="Organisations"
                actions={<ListActions />}
                filters={[
                    <SearchFilter
                        key="filter-query"
                        placeholder="Search by name"
                        alwaysOn
                        source="query-organisations"
                    />,
                ]}
                sort={{ field: 'name', order: 'ASC' }}
            >
                <DatagridWrapper rowClick="show" bulkActionButtons={false}>
                    <TextField source="name" />
                    {!isSmall && <TextField source="accountNo" label="Account Number" />}
                    {!isSmall && (
                        <DateField source="createdAt" label="Date Created" showTime={true} />
                    )}
                    {!isSmall && (
                        <FunctionField
                            label="Address"
                            render={(customer) => {
                                if (!customer.address) return '';
                                return `${customer.address.line1}, ${customer.address.city}, ${customer.address.country}`;
                            }}
                        />
                    )}
                    <TextField source="projectCount" label="Projects" sortable={false} />
                    <TextField source="siteCount" label="Sites" sortable={false} />
                </DatagridWrapper>
            </List>
        </Layout>
    );
}
