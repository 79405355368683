import React from 'react';
import { useRecordContext } from 'react-admin';

const StatusField = (props) => {
    const { source } = props;
    const record = useRecordContext(props);
    let text;

    switch (record[`${source}`]) {
        case 0:
            text = 'Pending Survey';
            break;
        case 1:
            text = 'Pending Install';
            break;
        case 2:
            text = 'Rejected Survey';
            break;
    }

    return <span>{text}</span>;
};

export default StatusField;
