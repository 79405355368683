import React from 'react';
import { Typography, InputAdornment, Box, LinearProgress } from '@mui/material';
import {
    TextField,
    DateField,
    TopToolbar,
    CreateButton,
    List,
    SearchInput,
    SelectInput,
    useGetList,
    SelectField,
    useLocale,
    useRecordContext,
    usePermissions,
    DatagridHeader,
} from 'react-admin';
import { useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useAppContext } from '../context/AppContext';
import SearchIcon from '@mui/icons-material/Search';
import SiteStatusField from './components/Status';
import Layout from '../components/Layout';
import GROUPS from '../auth/groups';
import DatagridWrapper from '../components/DatagridWrapper';
import { Link } from 'react-router-dom';
import { siteTypes, applicationTypes } from './components/SiteTypes';
import AsideList from './components/AsideList';
import DatagridHeaderNoSelectAll from '../components/DatagridHeaderNoSelectAll';

const maxSitesToCompare = 4;

const useStyles = makeStyles(() => ({
    container: {
        marginLeft: '34px',
        marginTop: '40px',
    },
    searchInput: {
        marginTop: '4px',
        width: '300px',
        '& input': {
            color: '#636364',
        },
    },
    status: {
        marginLeft: '15px',
    },
    referenceInput: {
        '& > div:last-child()': {
            marginBottom: '4px',
            minWidth: '160px',
        },
    },
    list: {
        '& [class*="RaBulkActionsToolbar-toolbar"]': {
            display: 'none', // In Analytics role, hide default toolbar shown when we select items in the list
        },
    },
}));

export default function SiteList() {
    const classes = useStyles();
    const { permissions } = usePermissions();
    const locale = useLocale();
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const isMed = useMediaQuery((theme) => theme.breakpoints.between('md', 'lg'));
    const [appStore] = useAppContext();
    const organisationId =
        appStore.organisationId && appStore.organisationId !== '-' ? appStore.organisationId : null;

    const { data: projectRecords, isLoading: loading } = useGetList('projects', {
        pagination: { page: 1, perPage: 100 },
        sort: { field: 'name', order: 'ASC' },
        filter: {
            organisationId: appStore.organisationId !== '-' ? appStore.organisationId : null,
            projectStatus: 0,
        },
    });

    const isAdmin =
        !!permissions &&
        (permissions.includes(GROUPS.HSW_ADMIN) ||
            permissions.includes(GROUPS.HSW_USER) ||
            permissions.includes(GROUPS.CUSTOMER_ADMIN));

    const isHyndsAdmin =
        !!permissions &&
        (permissions.includes(GROUPS.HSW_ADMIN) || permissions.includes(GROUPS.HSW_USER));

    const showAnalytics =
        permissions.includes(GROUPS.HSW_ADMIN) || permissions.includes(GROUPS.ANALYTICS);

    const projects =
        projectRecords &&
        projectRecords
            .map((project) => {
                if (!project) return undefined;
                const pStatus =
                    project.projectStatus && project.projectStatus >= 0 ? project.projectStatus : 0;
                return {
                    id: project.id,
                    name: project.name,
                    status: isHyndsAdmin ? 0 : pStatus,
                };
            })
            .filter((p) => !!p && p.status === 0);

    const statuses = [
        { id: 0, name: 'Pending Survey' },
        { id: 1, name: 'Survey Submitted' },
        { id: 2, name: 'Approved' },
        { id: 3, name: 'Rejected' },
        { id: 4, name: 'Installed' },
        { id: 5, name: 'Deleted' },
    ];

    const ListActions = ({ permissions, organisationId }) => {
        const canCreate =
            !!permissions &&
            permissions.includes(GROUPS.HSW_ADMIN) &&
            organisationId !== '' &&
            organisationId !== '-';
        return (
            <TopToolbar>
                {canCreate && <CreateButton label="Create New Site" icon={null} />}
            </TopToolbar>
        );
    };

    if (loading) {
        return (
            <Layout>
                <LinearProgress />
            </Layout>
        );
    }

    const allListFilters = [
        <SearchInput
            key="filter-search"
            source="query"
            variant="outlined"
            placeholder="Search by name or address"
            className={classes.searchInput}
            alwaysOn
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <SearchIcon style={{ opacity: '0.5' }} />
                    </InputAdornment>
                ),
            }}
        />,
        isAdmin && (
            <SelectInput
                alwaysOn
                key="filter-project"
                source="projectId"
                value="id"
                label="Project"
                variant="outlined"
                initialValue="All"
                choices={projects}
                className={classes.status}
                emptyText="All"
                emptyValue="All"
            />
        ),
        isAdmin && (
            <SelectInput
                alwaysOn
                key="filter-status"
                source="status"
                value="status"
                label="Status"
                variant="outlined"
                initialValue="All"
                choices={statuses}
                className={classes.status}
                emptyText="All"
                emptyValue="All"
            />
        ),
        <SelectInput
            alwaysOn
            key="filter-site-type"
            source="siteTypeId"
            value="id"
            label="Site Type"
            variant="outlined"
            initialValue="All"
            choices={siteTypes}
            className={classes.status}
            emptyText="All"
            emptyValue="All"
        />,
        <SelectInput
            alwaysOn
            key="filter-application-type"
            source="applicationTypeId"
            value="id"
            label="Application Type"
            variant="outlined"
            initialValue="All"
            choices={applicationTypes}
            className={classes.status}
            emptyText="All"
            emptyValue="All"
        />,
    ];

    const listFilters = allListFilters.filter((x) => !!x);

    const permanentFilter = {
        organisationId: organisationId,
    };

    if (!isAdmin) {
        permanentFilter.status = 4; // Installed
    }

    return (
        <Box className={classes.container}>
            <Typography variant="h5" gutterBottom>
                Sites
            </Typography>
            <List
                title="Sites"
                actions={
                    <ListActions
                        permissions={permissions}
                        organisationId={appStore.organisationId}
                    />
                }
                filter={permanentFilter}
                filters={listFilters}
                sort={{ field: 'createdAt', order: 'DESC' }}
                aside={showAnalytics ? <AsideList maxSitesToCompare={maxSitesToCompare} /> : null}
                className={showAnalytics ? classes.list : null}
            >
                <DatagridWrapper
                    bulkActionButtons={showAnalytics && <></>}
                    header={showAnalytics ? <DatagridHeaderNoSelectAll /> : <DatagridHeader />}
                    rowStyle={() => ({
                        height: 50,
                        boxSizing: 'content-box',
                    })}
                    isRowSelectable={(record, selectedIds) => {
                        const maxReached = selectedIds.length >= maxSitesToCompare;
                        const isManholeSite = record.siteTypeId === 1;
                        const isWaterTank = record.siteTypeId === 3;
                        const enableCheckBox =
                            !maxReached || (maxReached && selectedIds.indexOf(record.id) >= 0);
                        return (
                            showAnalytics &&
                            (isManholeSite || isWaterTank) &&
                            enableCheckBox &&
                            record.status === 4
                        );
                    }}
                >
                    <NameField label="Site" />
                    {isAdmin && <ProjectNameField label="Project" />}
                    {appStore.organisationId === '-' && <OrganisationNameField label="Customer" />}
                    <TextField source="deviceId" label="Device ID" />
                    <SiteStatusField source="status" />
                    {!isSmall && !isMed && <TextField source="address" />}
                    {!isSmall && (
                        <SelectField label="Site Type" source="siteTypeId" choices={siteTypes} />
                    )}
                    {!isSmall && (
                        <SelectField
                            label="Application Type"
                            source="applicationTypeId"
                            choices={applicationTypes}
                        />
                    )}
                    {!isSmall && !isMed && (
                        <DateField
                            source="createdAt"
                            label="Since"
                            options={{
                                day: 'numeric',
                                month: 'short',
                                year: 'numeric',
                                hour: 'numeric',
                                minute: 'numeric',
                                second: 'numeric',
                                hour12: false,
                            }}
                            locales={locale}
                            showTime
                        />
                    )}
                </DatagridWrapper>
            </List>
        </Box>
    );
}

const ProjectNameField = () => {
    const post = useRecordContext();
    const siteShowPage = `/projects/${post.projectId}/show`;
    const useStyles = makeStyles(() => ({
        linkUrl: {
            color: '#00AEEF',
            textDecoration: 'none',
        },
    }));
    const classes = useStyles();

    if (post.project?.projectStatus !== 5) {
        return (
            <Link to={siteShowPage} className={classes.linkUrl}>
                {post.project?.name}
            </Link>
        );
    } else {
        return <TextField source="projectName" label="Project" />;
    }
};

const OrganisationNameField = () => {
    const post = useRecordContext();
    const siteShowPage = `/organisations/${post.project?.organisationId}/show`;
    const useStyles = makeStyles(() => ({
        linkUrl: {
            color: '#00AEEF',
            textDecoration: 'none',
        },
    }));
    const classes = useStyles();

    return (
        <Link to={siteShowPage} className={classes.linkUrl}>
            {post.project?.organisation?.name}
        </Link>
    );
};

const NameField = () => {
    const post = useRecordContext();
    const siteShowPage = `/sites/${post.id}/show`;
    const useStyles = makeStyles(() => ({
        linkUrl: {
            color: '#00AEEF',
            textDecoration: 'none',
        },
    }));
    const classes = useStyles();

    return (
        <Link to={siteShowPage} className={classes.linkUrl}>
            {post.name}
        </Link>
    );
};
