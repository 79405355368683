import { getAccessToken } from '../auth/auth0Functions';

const UpdateWithPromise = async (endpointwithparams, body, method = 'PATCH') => {
    let status;
    let errorToReturn = '';

    const token = await getAccessToken();

    await fetch(`${process.env.REACT_APP_API_BASE_URL}/${endpointwithparams}`, {
        method: method,
        headers: {
            'Content-Type': 'application/json',
            Authorization: token ?? '',
        },
        body: JSON.stringify(body),
    })
        .then((response) => {
            status = response.status;
            if (status === 400) {
                errorToReturn = 'Bad request';
            }
            if (status === 403) {
                errorToReturn = 'Forbidden call';
            }
            return response.json();
        })
        .then(() => {})
        .catch((error) => {
            console.log(error);
            errorToReturn = error;
        });

    return [status, errorToReturn];
};

export { UpdateWithPromise };
