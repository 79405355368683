import GROUPS from '../auth/groups';
import List from './List';

export default function (permissions) {
    const requests = {
        list: null,
        show: null,
        edit: null,
    };
    if (permissions && permissions.includes(GROUPS.HSW_ADMIN)) {
        requests.list = List;
    }

    return requests;
}
