import React from 'react';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useRedirect } from 'react-admin';

const useStyles = makeStyles(() => ({
    cardContainer: {
        gap: '25px',
    },
    card: {
        boxSizing: 'border-box',
        height: '140px',
        border: '1px solid #D8D8D8',
        backgroundColor: '#FFFFFF',
        borderRadius: 4,
        flexGrow: 1,
        flexBasis: 0,
        flexDirection: 'column',
        cursor: 'pointer',
    },
    cardKey: {
        fontSize: '40px',
        fontWeight: 'bold',
        letterSpacing: '-0.96px',
        lineHeight: '53px',
    },
    cardLabel: {
        fontSize: '16px',
        fontWeight: 'bold',
        letterSpacing: '-0.38px',
        lineHeight: '21px',
        padding: '0px 0px 0px 40px',
    },
}));

const QuickCards = (props) => {
    const classes = useStyles();
    const { cards, enableLinks } = props;
    const redirect = useRedirect();

    const handleClick = (href) => {
        if (enableLinks) redirect(href);
    };

    return (
        <Box
            display="flex"
            justifyContent="space-between"
            mb="52px"
            gap="25px"
            className={classes.cardContainer}
        >
            {cards &&
                cards.map((card) => {
                    return (
                        <Box
                            key={card.title}
                            className={classes.card}
                            display="flex"
                            style={{ cursor: enableLinks ? 'pointer' : 'inherit' }}
                            onClick={() => handleClick(card.href)}
                        >
                            <Box p={2} display="flex" justifyContent="left">
                                <Box mr="5px" mt="6px">
                                    {card.icon}
                                </Box>
                                <Typography
                                    variant="body1"
                                    className={classes.cardKey}
                                    style={{ color: card.colour }}
                                >
                                    {card.value}
                                </Typography>
                            </Box>
                            <Box p={2} pt={0} display="flex" justifyContent="left">
                                <Box mr="5px" mt="6px">
                                    {'   ' + '      '}
                                </Box>
                                <Typography variant="body1" className={classes.cardLabel}>
                                    {card.title}
                                </Typography>
                            </Box>
                        </Box>
                    );
                })}
        </Box>
    );
};

export default QuickCards;
