import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {
    Box,
    Button,
    List,
    ListItem,
    ListItemIcon,
    Checkbox,
    Typography,
    LinearProgress,
    ListItemText,
    CircularProgress,
} from '@mui/material';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Switch from '@mui/material/Switch';
import { useGetList, useCreate, useNotify } from 'react-admin';
import { makeStyles } from '@mui/styles';
import GROUPS from '../auth/groups';
import { addRolesToUser, removeRolesFromUser } from '../auth/auth0Functions';

const useStyles = makeStyles(() => ({
    dialog: {
        minHeight: '60vh',
        maxHeight: '60vh',
    },
}));

const UserRoles = ({ isOpen, onClose, user, roles }) => {
    const classes = useStyles();
    const [create] = useCreate();
    const notify = useNotify();
    const initialRoles = roles;
    const [open, setOpen] = useState(isOpen ?? false);
    const [checked, setChecked] = useState([]);
    const [isCustomerAdmin, setIsCustomerAdmin] = useState(
        roles?.includes(GROUPS.CUSTOMER_ADMIN) ?? false,
    );
    const [isPortalAccess, setIsPortalAccess] = useState(
        roles?.includes(GROUPS.PORTALACCESS) ?? false,
    );
    const [isInstaller, setIsInstaller] = useState(roles?.includes(GROUPS.INSTALLER) ?? false);
    const [isSurveyor, setIsSurveyor] = useState(roles?.includes(GROUPS.SURVEYOR) ?? false);
    const [isAnalytics, setIsAnalytics] = useState(roles?.includes(GROUPS.ANALYTICS) ?? false);
    const [submitting, setSubmitting] = useState(false);

    useEffect(() => {
        setIsCustomerAdmin(!!roles.includes(GROUPS.CUSTOMER_ADMIN));
        setIsPortalAccess(!!roles.includes(GROUPS.PORTALACCESS));
        setIsInstaller(!!roles.includes(GROUPS.INSTALLER));
        setIsSurveyor(!!roles.includes(GROUPS.SURVEYOR));
        setOpen(isOpen);
        if (isOpen === true) refetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen, roles]);

    const handleClose = () => {
        setOpen(false);
        if (onClose) onClose();
    };

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handleSave = async () => {
        try {
            setSubmitting(true);
            await addOrRemoveRoles();
            await create('jobs', {
                data: {
                    organisationId: user.organisationId,
                    installerId: user.id,
                    projectIds: checked,
                },
            });
            setSubmitting(false);
            setOpen(false);
            refetch();
            if (onClose) onClose();
            notify('Roles saved for user.', { type: 'success' });
        } catch (err) {
            setSubmitting(false);
            notify('Something went wrong while saving roles.', { type: 'error' });
        }
    };

    const addOrRemoveRoles = async () => {
        let rolesToAdd = [];
        let rolesToRemove = [];

        if (isCustomerAdmin && !initialRoles.includes(GROUPS.CUSTOMER_ADMIN))
            rolesToAdd.push(GROUPS.CUSTOMER_ADMIN);
        if (!isCustomerAdmin && initialRoles.includes(GROUPS.CUSTOMER_ADMIN))
            rolesToRemove.push(GROUPS.CUSTOMER_ADMIN);

        if (isPortalAccess && !initialRoles.includes(GROUPS.PORTALACCESS)) {
            rolesToAdd.push(GROUPS.PORTALACCESS);
        }
        if (!isPortalAccess && initialRoles.includes(GROUPS.PORTALACCESS)) {
            rolesToRemove.push(GROUPS.PORTALACCESS);
        }

        if (isInstaller && !initialRoles.includes(GROUPS.INSTALLER))
            rolesToAdd.push(GROUPS.INSTALLER);
        if (!isInstaller && initialRoles.includes(GROUPS.INSTALLER))
            rolesToRemove.push(GROUPS.INSTALLER);

        if (isSurveyor && !initialRoles.includes(GROUPS.SURVEYOR)) rolesToAdd.push(GROUPS.SURVEYOR);
        if (!isSurveyor && initialRoles.includes(GROUPS.SURVEYOR))
            rolesToRemove.push(GROUPS.SURVEYOR);

        if (isAnalytics && !initialRoles.includes(GROUPS.ANALYTICS))
            rolesToAdd.push(GROUPS.ANALYTICS);
        if (!isAnalytics && initialRoles.includes(GROUPS.ANALYTICS))
            rolesToRemove.push(GROUPS.ANALYTICS);

        //Add roles
        if (rolesToAdd.length > 0) {
            try {
                await addRolesToUser(user.userId, rolesToAdd)
                    .then((res) => {
                        if (res.status !== 200 && res.status !== 201) {
                            notify('Error while adding roles', { type: 'error' });
                        }
                    })
                    .catch(() => {
                        notify('Error while adding roles', { type: 'error' });
                    });
            } catch (error) {
                notify('Error while adding roles', { type: 'error' });
            }
        }
        //Remove roles
        if (rolesToRemove.length > 0) {
            try {
                await removeRolesFromUser(user.userId, rolesToRemove)
                    .then((res) => {
                        if (res.status !== 200 && res.status !== 201) {
                            notify('Error while removing roles', { type: 'error' });
                        }
                    })
                    .catch(() => {
                        notify('Error while removing roles', { type: 'error' });
                    });
            } catch (error) {
                notify('Error while removing roles', { type: 'error' });
            }
        }
    };

    const { data: projects, isLoading: loading } = useGetList('projects', {
        pagination: { page: 1, perPage: 200 },
        sort: { field: 'name', order: 'ASC' },
        filter: {
            organisationId: user.organisationId,
            status: 0,
            enabled: isSurveyor || isInstaller,
        },
    });

    const {
        data: jobs,
        isLoading: jobLoading,
        refetch,
    } = useGetList('jobs', {
        pagination: { page: 1, perPage: 200 },
        filter: { userId: user.id, enabled: isSurveyor || isInstaller },
    });
    const allocatedProjectIds = jobs ? jobs.map((job) => job.projectId) : [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
    React.useEffect(() => {
        if (jobs) {
            setChecked(jobs.map((job) => job.projectId));
        }
    }, [jobs]);

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                maxWidth="sm"
                fullWidth
                classes={{ paper: classes.dialog }}
                scroll="paper"
            >
                <DialogTitle id="form-dialog-title">Select user roles</DialogTitle>
                <DialogContent>
                    <Box display="flex" justifyContent="space-around" marginBottom={4}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Base Roles</FormLabel>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            name="customer-admin"
                                            checked={isCustomerAdmin}
                                            onChange={() => setIsCustomerAdmin(!isCustomerAdmin)}
                                            disabled={user.isAzureADUser}
                                        />
                                    }
                                    label="Customer Admin"
                                />
                                <FormControlLabel
                                    control={
                                        <Switch
                                            name="portal-access"
                                            checked={isPortalAccess}
                                            onChange={() => setIsPortalAccess(!isPortalAccess)}
                                            disabled={user.isAzureADUser}
                                        />
                                    }
                                    label="Portal Access"
                                />
                                <FormControlLabel
                                    control={
                                        <Switch
                                            name="analytics"
                                            checked={isAnalytics}
                                            onChange={() => setIsAnalytics(!isAnalytics)}
                                            disabled={user.isAzureADUser}
                                        />
                                    }
                                    label="Analytics"
                                />
                            </FormGroup>
                            <FormHelperText>For use of the Aquanect portal.</FormHelperText>
                        </FormControl>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Support Roles</FormLabel>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            name="installer"
                                            checked={isInstaller}
                                            onChange={() => setIsInstaller(!isInstaller)}
                                            disabled={user.isAzureADUser}
                                        />
                                    }
                                    label="Installer"
                                />
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={isSurveyor}
                                            onChange={() => setIsSurveyor(!isSurveyor)}
                                            name="surveyor"
                                            disabled={user.isAzureADUser}
                                        />
                                    }
                                    label="Surveyor"
                                />
                            </FormGroup>
                            <FormHelperText>For use of the mobile application.</FormHelperText>
                        </FormControl>
                    </Box>
                    <Box>
                        {(loading || jobLoading) && <LinearProgress />}
                        {(isInstaller || isSurveyor) && projects && (
                            <>
                                <Typography variant="h6" gutterBottom>
                                    Customer allocation for install/survey
                                </Typography>
                                <Typography variant="caption" gutterBottom>
                                    Select the projects to allocate to.
                                </Typography>
                                <List>
                                    {projects.map((project) => {
                                        const value = project.id;
                                        const labelId = `checkbox-list-label-${value}`;
                                        return (
                                            <ListItem
                                                key={value}
                                                role={undefined}
                                                dense
                                                button
                                                selected={
                                                    allocatedProjectIds?.includes(value) ||
                                                    checked.includes(value)
                                                }
                                            >
                                                <ListItemIcon>
                                                    <Checkbox
                                                        edge="start"
                                                        tabIndex={-1}
                                                        disableRipple
                                                        inputProps={{ 'aria-labelledby': labelId }}
                                                        checked={checked.includes(value)}
                                                        onChange={handleToggle(value)}
                                                    />
                                                </ListItemIcon>
                                                <ListItemText id={labelId} primary={project.name} />
                                            </ListItem>
                                        );
                                    })}
                                </List>
                            </>
                        )}
                        {(!projects || projects.length === 0) && <span>No projects available</span>}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button
                        onClick={handleSave}
                        color="primary"
                        disabled={submitting || (user.isAzureADUser && !isInstaller && !isSurveyor)}
                    >
                        Save
                        {submitting && (
                            <CircularProgress
                                style={{ marginLeft: '8px' }}
                                size={18}
                                color="inherit"
                            />
                        )}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default UserRoles;
