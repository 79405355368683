import { useState } from 'react';
import { useDataProvider, useGetOne } from 'react-admin';

const defaultError = "Couldn't load next window";

const useGetNextWindow = (deviceId) => {
    // pagination and sorting are not needed here. Only adding for react-admin
    const {
        data,
        isLoading: loading,
        error,
    } = useGetOne('downlinkCommands/nextwindow', { id: deviceId });

    return {
        loading,
        loaded: !loading,
        error: (!loading && !data) || error ? defaultError : null,
        waitingFirstMessage: data?.waitingFirstMessage,
        lastMessageTime: data?.lastMessageTime,
        nextWindow: !loading ? data?.nextWindow || 0 : undefined,
        nextWindowDistance: data?.nextWindowDistance,
    };
};

export const useLazyGetNextWindow = () => {
    const dataProvider = useDataProvider();
    const [loading, setLoading] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(null);
    const [waitingFirstMessage, setwaitingFirstMessage] = useState();
    const [lastMessageTime, setlastMessageTime] = useState();
    const [nextWindow, setnextWindow] = useState();
    const [nextWindowDistance, setnextWindowDistance] = useState();

    const promise = async (deviceId) => {
        try {
            setError(null);
            setLoading(true);
            const { data } = await dataProvider.getOne('downlinkCommands/nextwindow', {
                id: deviceId,
            });
            setwaitingFirstMessage(data?.waitingFirstMessage);
            setlastMessageTime(data?.lastMessageTime);
            setnextWindow(data?.nextWindow || 0);
            setnextWindowDistance(data?.nextWindowDistance);
        } catch (error) {
            setError(defaultError);
            setwaitingFirstMessage(undefined);
            setlastMessageTime(undefined);
            setnextWindow(undefined);
            setnextWindowDistance(undefined);
        } finally {
            setLoading(false);
            setLoaded(true);
        }
        return {
            loading,
            error,
            waitingFirstMessage,
            lastMessageTime,
            nextWindow,
            nextWindowDistance,
        };
    };
    return [
        promise,
        {
            loading,
            loaded,
            error,
            waitingFirstMessage,
            lastMessageTime,
            nextWindow,
            nextWindowDistance,
        },
    ];
};

export default useGetNextWindow;
