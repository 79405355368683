import React from 'react';
import useLocalStorage from '../hooks/useLocalStorage';
import useRemoveLocalStorage from '../hooks/useRemoveLocalStorage';

const defaultStore = {
    organisationId: '-',
    userId: '',
    email: '',
};
export const AppContext = React.createContext(undefined);
export const useAppContext = () => {
    const context = React.useContext(AppContext);
    if (context === undefined) {
        throw new Error('Context can only be used inside Provider');
    }
    return context;
};

const AppProvider = ({ children }) => {
    const [localStore, setLocalStore] = useLocalStorage('app', defaultStore);
    const [store, setStore] = React.useState(localStore);
    const remove = useRemoveLocalStorage();

    React.useEffect(() => {
        setLocalStore(store);

        // Reset selected sites when organization has changed
        remove('RaStore.sites.list.selected');
        remove('RaStore.sites.selectedIds');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store]);

    const setContextStore = (store) => setStore(store);
    const value = [store, setContextStore];
    return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export default AppProvider;
