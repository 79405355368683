import React, { useState, useEffect } from 'react';
import { Dialog, Button, TextField, Box } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import RemoveableItemList from '../components/RemovableItemList';
import InputMask from 'react-input-mask';
import ROOT from '../theme/root';
import { validateEmail } from '../utils/validation';

const AlarmRecipientDialog = (props) => {
    const [inputVal, setinputVal] = useState('');
    const [error, setError] = useState(false);
    const [validation, setValidation] = useState('');
    const [data, setData] = useState([]);

    useEffect(() => {
        setData(props.data.filter((item) => item.canEdit));
        setError(false);
    }, [props]);

    const handleSave = () => {
        props.handleSave(data);
    };

    const handleAddData = () => {
        const enterValue = inputVal.replace('+', '').replace('-', '');
        let isValid = true;
        let error = '';
        if (data.length > 0) {
            const excists = data.find((d) => d.details === enterValue);
            if (excists) {
                isValid = false;
                error += 'item value is already set. ';
            }
        }
        if (!isValid) {
            setValidation(error);
            setError(true);
            setinputVal('');
        } else {
            if (props.mode === 'email') {
                if (data.includes(enterValue)) {
                    setValidation('Email is already set');
                    setError(true);
                } else if (validateEmail(enterValue)) {
                    setError(false);
                    setValidation('');
                    setData((x) => [...x, { details: enterValue, canEdit: true }]);
                    setinputVal('');
                } else {
                    setValidation('Please enter a valid email');
                    setError(true);
                }
            } else {
                if (data.includes(enterValue)) {
                    setValidation('Mobile is already set');
                    setError(true);
                } else {
                    setError(false);
                    setValidation('');
                    setData((x) => [...x, { details: enterValue, canEdit: true }]);
                    setinputVal('');
                }
            }
        }
    };

    const handleRemoveData = (value) => {
        setData(data.filter((item) => item !== value));
    };

    return (
        <Dialog
            open={props.open}
            onClose={props.handleClose}
            maxWidth="sm"
            scroll="paper"
            fullWidth
        >
            <DialogTitle>
                {props.mode === 'email' ? 'Add Email Addresses' : 'Add Mobile Numbers'}
            </DialogTitle>
            <DialogContent>
                <Box display="flex">
                    {props.mode === 'email' ? (
                        <TextField
                            id="input-email"
                            variant="outlined"
                            required
                            error={error}
                            helperText={error ? validation : ''}
                            type={'email'}
                            placeholder={'Enter email address'}
                            value={inputVal}
                            onChange={(event) => setinputVal(event.target.value)}
                            onKeyDown={(evnt) => {
                                if (evnt.key === 'Enter') handleAddData();
                            }}
                            fullWidth
                            autoFocus
                            margin="none"
                        />
                    ) : (
                        <InputMask
                            mask={'+99-999999999'}
                            maskPlaceholder={null}
                            value={inputVal}
                            onChange={(event) => setinputVal(event.target.value)}
                        >
                            <TextField
                                id="input-phone"
                                variant="outlined"
                                required
                                error={error}
                                helperText={error ? validation : ''}
                                type={'tel'}
                                placeholder={'+xx-xxxxxxxx'}
                                onKeyDown={(evnt) => {
                                    if (evnt.key === 'Enter') handleAddData();
                                }}
                                fullWidth
                                autoFocus
                                margin="none"
                            />
                        </InputMask>
                    )}

                    <Button
                        variant="outlined"
                        color="primary"
                        style={{ width: '120px', marginLeft: '16px', height: ROOT.INPUTS.HEIGHT }}
                        onClick={() => handleAddData()}
                    >
                        Add
                    </Button>
                </Box>
                <br />
                <RemoveableItemList data={data} handleRemove={handleRemoveData} />
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    variant="outlined"
                    size="large"
                    onClick={() => handleSave()}
                >
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AlarmRecipientDialog;
