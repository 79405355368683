export const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_AZURE_AD_CLIENT_ID,
        authority: process.env.REACT_APP_AZURE_AD_AUTHORITY,
        redirectUri: process.env.REACT_APP_AZURE_AD_REDIRECT_URI,
        navigateToLoginRequestUrl: false,
    },
    cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: false,
    },
};

export const loginRequest = {
    scopes: [process.env.REACT_APP_AZURE_AD_APP_ID],
};
