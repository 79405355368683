import React, { useState } from 'react';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ReactComponent as CloseIcon } from '../icons/close.svg';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
    item: {
        height: '20px',
        color: '#272B30',
        padding: '6px 0px 6px 6px',
    },
    hoverable: {
        '&:hover': {
            padding: '6px 0px 6px 6px',
            backgroundColor: '#D8D8D8',
            transition: '400ms',
        },
    },
    icon: {
        cursor: 'pointer',
    },
    container: {
        boxSizing: 'content-box',
    },
}));

const RemovableItem = (props) => {
    const classes = useStyles();
    const [showClose, setShowClose] = useState(false);

    return (
        <Box
            className={clsx(classes.item, props.enabled && classes.hoverable)}
            display="flex"
            justifyContent="space-between"
            onMouseEnter={() => setShowClose(true)}
            onMouseLeave={() => setShowClose(false)}
        >
            <Box>{props.item}</Box>
            <Box
                display={showClose && props.enabled ? 'block' : 'none'}
                className={classes.icon}
                onClick={() => props.handleRemove(props.item)}
            >
                <CloseIcon height="16px" />
            </Box>
        </Box>
    );
};

const RemoveableItemList = (props) => {
    const { data } = props;
    const classes = useStyles();

    return (
        <Box className={classes.container}>
            {data.map((item, index) => (
                <RemovableItem
                    key={index}
                    item={item.details}
                    handleRemove={props.handleRemove}
                    enabled={props.enabled}
                />
            ))}
        </Box>
    );
};

export default RemoveableItemList;
