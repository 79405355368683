function range(start, end) {
    return Array(end - start + 1)
        .fill()
        .map((_, idx) => start + idx);
}

function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

const generateData = (count = 25, empty = false) => {
    const series = range(1, count);
    const seriesData = series.map((val) => {
        const date = new Date(new Date().setDate(new Date().getDate() - (count - val)));
        return {
            x: date.getTime(),
            y: empty ? 0 : getRandomInt(200, 600),
        };
    });
    return seriesData;
};

function getFilename(name = 'site', chartType = 'level_values') {
    const siteName = name.replace(/ /g, '_');
    const now = new Date();
    return `${siteName}_${chartType}_${now.getDate()}_${now.getMonth() + 1}_${now.getFullYear()}`;
}

export { range, getRandomInt, generateData, getFilename };
