import React from 'react';
import { makeStyles } from '@mui/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Button, TextField } from '@mui/material';

const useStyles = makeStyles(() => ({
    dialog: {
        minHeight: '40vh',
        maxHeight: '60vh',
    },
}));

const SurveyFeedback = ({ isOpen, onClose, onSave }) => {
    const classes = useStyles();
    const [feedback, setFeedback] = React.useState('');
    const [validFeedback, setValidFeedback] = React.useState(false);
    const [open, setOpen] = React.useState(isOpen ?? false);
    React.useEffect(() => setOpen(isOpen), [isOpen]);
    const handleClose = () => {
        setOpen(false);
        if (onClose) onClose();
    };

    const handleSave = () => {
        if (feedback.length < 3 || feedback.length > 500) {
            setValidFeedback(false);
            return;
        }
        setOpen(false);
        if (onSave) onSave(feedback);
        if (onClose) onClose();
    };
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="site-location"
            maxWidth="sm"
            fullWidth
            classes={{ paper: classes.dialog }}
            scroll="paper"
        >
            <DialogTitle id="customer-selector">Rejected</DialogTitle>
            <DialogContent>
                <Box display="flex" flexDirection="column">
                    <span>Please leave a comment</span>
                    <TextField
                        aria-label="feedback"
                        multiline
                        rows={13}
                        placeholder="Comment"
                        error={!validFeedback}
                        onChange={(event) => {
                            const feedback = event.target.value;
                            setFeedback(feedback);
                            setValidFeedback(feedback.length >= 3 && feedback.length <= 500);
                        }}
                        helperText={
                            !validFeedback ? 'Feedback limit between 3-500 characters.' : undefined
                        }
                        style={{ minHeight: '300px' }}
                    />
                </Box>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleSave} disabled={!validFeedback} color="primary">
                    Send
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default SurveyFeedback;
