import React from 'react';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import LineChart from '../components/LineChart';

const tempChartOptions = {
    title: {
        text: '',
        align: 'left',
        style: {
            fontWeight: 600,
        },
    },
    yAxis: {
        title: {
            text: 'μS/cm',
        },
        opposite: false,
        min: 0,
        lineWidth: 1,
        labels: {
            format: '{value:.0f}',
        },
        tickAmount: 10,
    },

    tooltip: {
        valueSuffix: 'μS/cm',
    },
};

const useStyles = makeStyles(() => ({
    container: {
        padding: '16px',
        flex: 1,
    },
}));

const Conductivity = (props) => {
    const { fromDate, toDate, data, isLoading, error, color } = props;
    const classes = useStyles();

    const series = {
        id: 'cond',
        name: 'Conductivity',
        data: data ?? [],
        dataGrouping: {
            enabled: false,
        },
        showInNavigator: true,
        yAxis: 0,
    };

    if (color) {
        tempChartOptions.colors = [color];
    }

    return (
        <Box className={classes.container}>
            <Typography component="h6" variant="h6" mb="20px" mt="10px">
                Conductivity
            </Typography>
            <LineChart
                chartOptions={tempChartOptions}
                series={[series]}
                loading={isLoading}
                error={error?.message}
                min={fromDate}
                max={toDate}
            />
        </Box>
    );
};

export default Conductivity;
