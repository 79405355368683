import React from 'react';
import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ROOT from '../../theme/root';
import { SaveButton, TopToolbar, useRedirect } from 'react-admin';
import { Schedule } from '@mui/icons-material';
import { useFormState } from 'react-hook-form';

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        rowGap: 60,
    },
    cancel: {
        color: ROOT.COLORS.RED,
        fontWeight: 600,
        fontSize: 14,
    },
}));

const DowlinkCreateActions = (props) => {
    const classes = useStyles();
    const { isValid } = useFormState();
    const { cancelUrl, saving, saveBtnVisible = true } = props;
    const redirect = useRedirect();
    const cancelHandler = () => {
        redirect(cancelUrl);
    };
    return (
        <TopToolbar className={classes.container}>
            {saveBtnVisible && (
                <SaveButton
                    icon={<Schedule />}
                    label="SCHEDULE DOWNLINK COMMAND"
                    disabled={saving || !isValid}
                />
            )}
            <Button
                onClick={cancelHandler}
                variant="secondary"
                className={classes.cancel}
                disabled={saving}
                aria-label="CANCEL"
            >
                CANCEL
            </Button>
        </TopToolbar>
    );
};

export default DowlinkCreateActions;
