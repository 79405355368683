import React from 'react';
import {
    Edit,
    SimpleForm,
    TextInput,
    SelectInput,
    required,
    minLength,
    maxLength,
    useEditController,
    Toolbar,
    SaveButton,
    useRecordContext,
} from 'react-admin';
import { Box, Grid, LinearProgress } from '@mui/material';
import Layout from '../components/Layout';
import { deviceTypes, deviceApplicationTypes } from './device';
import DeleteButton from './Delete';

const EditToolbar = () => {
    const device = useRecordContext();
    return (
        <Toolbar>
            <Box display="flex" justifyContent="space-between" width="100%">
                {!device.siteId && device.status !== 0 && <SaveButton />}
                {!device.siteId && device.status !== 0 && <DeleteButton />}
            </Box>
        </Toolbar>
    );
};

const EditDevice = () => {
    const controller = useEditController();
    if (!controller.record) return <LinearProgress />;
    const overrides = {};
    overrides[controller.record.id] = controller.record.deviceId;
    return (
        <Layout title="Edit Device" breadcrumb overrides={overrides}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6}>
                    <Edit
                        actions={<></>}
                        mutationMode="undoable"
                        redirect={() => {
                            return '/devices';
                        }}
                    >
                        <SimpleForm toolbar={<EditToolbar />}>
                            <TextInput
                                source="storageLocation"
                                label="Storage Location"
                                variant="outlined"
                                validate={[minLength(2), maxLength(200)]}
                            />
                            <SelectInput
                                source="deviceType"
                                choices={deviceTypes}
                                variant="outlined"
                                validate={[required()]}
                            />
                            <SelectInput
                                source="deviceApplication"
                                choices={deviceApplicationTypes}
                                variant="outlined"
                                validate={[required()]}
                            />
                        </SimpleForm>
                    </Edit>
                </Grid>
            </Grid>
        </Layout>
    );
};

export default EditDevice;
