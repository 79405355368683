import OrganisationCreate from './Create';
import OrganisationEdit from './Edit';
import OrganisationList from './List';
import OrganisationShow from './Show';
import GROUPS from '../auth/groups';

const organisations = (permissions) => {
    const organisation = {};
    if (permissions && permissions.includes(GROUPS.HSW_ADMIN)) {
        organisation.create = OrganisationCreate;
        organisation.edit = OrganisationEdit;
        organisation.list = OrganisationList;
        organisation.show = OrganisationShow;
    } else {
        organisation.create = null;
        organisation.edit = null;
        organisation.list = null;
        organisation.show = null;
    }

    return organisation;
};
export default organisations;
