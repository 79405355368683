import React, { useEffect } from 'react';
import {
    Edit,
    SimpleForm,
    TextInput,
    ReferenceInput,
    SelectInput,
    required,
    minLength,
    maxLength,
    useEditController,
    Toolbar,
    SaveButton,
    useRedirect,
    usePermissions,
} from 'react-admin';
import { useAppContext } from '../context/AppContext';
import { Box, Typography, LinearProgress, Grid } from '@mui/material';
import Breadcrumb from '../menu/Breadcrumb';
import Layout from '../components/Layout';
import { makeStyles } from '@mui/styles';
import GROUPS from '../auth/groups';
import { validText } from './utils';
import { siteTypes, applicationTypes, networkTypes } from './components/SiteTypes';

const useStyles = makeStyles(() => ({
    header: {
        marginTop: '27px',
        marginBottom: '30px',
        color: '#0B0B0D',
        fontSize: '32px',
        fontWeight: 'bold',
        letterSpacing: '-0.77px',
        lineHeight: '43px',
    },
    formInput: {
        width: '256px',
    },
}));

const validate = (values) => {
    const errors = {};
    if (!values.name) errors.name = 'The site name is required between 3 and 200 characters.';
    return errors;
};

const UserEditToolbar = () => {
    return (
        <Toolbar>
            <Box display="flex" justifyContent="space-between" width="100%">
                <SaveButton />
            </Box>
        </Toolbar>
    );
};

const EditSite = () => {
    const classes = useStyles();
    const controller = useEditController();
    const [appStore] = useAppContext();
    const redirect = useRedirect();
    const { permissions } = usePermissions();
    const isCustomerAdminAnalytics =
        !!permissions &&
        permissions.includes(GROUPS.CUSTOMER_ADMIN) &&
        permissions.includes(GROUPS.ANALYTICS);
    const isRaftSensor = controller?.record?.siteTypeId === 5;

    useEffect(() => {
        if (!isCustomerAdminAnalytics) {
            redirect('/');
        }
    }, [redirect, isCustomerAdminAnalytics]);

    if (!controller.record) return <LinearProgress />;
    const overrides = {};
    overrides[controller.record.id] = controller.record.name;

    return (
        <Layout>
            <Breadcrumb overrides={overrides} />
            <Typography variant="h5" gutterBottom className={classes.header}>
                Edit Site
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6}>
                    <Edit actions={<></>} redirect="show">
                        <SimpleForm toolbar={<UserEditToolbar />} validate={validate}>
                            <ReferenceInput
                                source="projectId"
                                reference="projects"
                                isRequired={true}
                                loading
                                filter={{ organisationId: appStore.organisationId }}
                                disabled
                            >
                                <SelectInput
                                    optionText="name"
                                    value="id"
                                    label="Project"
                                    validate={[required()]}
                                    variant="outlined"
                                    className={classes.formInput}
                                    disabled
                                />
                            </ReferenceInput>
                            <TextInput
                                source="name"
                                label="Site Name"
                                variant="outlined"
                                validate={[required(), minLength(2), maxLength(50), validText()]}
                                className={classes.formInput}
                                disabled
                            />
                            <SelectInput
                                source="networkTypeId"
                                choices={networkTypes}
                                variant="outlined"
                                validate={[required()]}
                                className={classes.formInput}
                                disabled
                            />
                            <SelectInput
                                source="siteTypeId"
                                choices={siteTypes}
                                variant="outlined"
                                disabled
                                className={classes.formInput}
                            />
                            <SelectInput
                                source="applicationTypeId"
                                choices={applicationTypes}
                                variant="outlined"
                                validate={[required()]}
                                className={classes.formInput}
                                disabled
                            />
                            <TextInput
                                source="deviceId"
                                label="Device ID"
                                variant="outlined"
                                disabled
                                className={classes.formInput}
                            />
                            {!isRaftSensor && (
                                <ReferenceInput
                                    reference="rainfallSites"
                                    source="rainfallSiteId"
                                    perPage={1000}
                                    sort={{ field: 'name', order: 'ASC' }}
                                    queryOptions={{
                                        enabled: isCustomerAdminAnalytics,
                                    }}
                                >
                                    <SelectInput
                                        label="Rainfall Site"
                                        value="id"
                                        optionText="name"
                                        fullWidth
                                        variant="outlined"
                                    />
                                </ReferenceInput>
                            )}
                        </SimpleForm>
                    </Edit>
                </Grid>
            </Grid>
        </Layout>
    );
};

export default EditSite;
