import { useState, useEffect } from 'react';
import { useUpdate } from 'react-admin';
import useGetPendingCommands from '../hooks/useGetPendingCommands';
import useGetNextWindow from '../hooks/useGetNextWindow';

const usePendingCommands = (deviceId) => {
    const {
        data,
        ids,
        refetch,
        loading,
        error: fetchError,
    } = useGetPendingCommands(deviceId, false);
    const [selectedIds, setSelectedIds] = useState([]);
    const [update, { isLoading: updating, error: updateError }] = useUpdate('downlinkCommands');
    const {
        loading: loadingNextWindow,
        error: errorNextWindow,
        nextWindow,
        nextWindowDistance,
        waitingFirstMessage,
    } = useGetNextWindow(deviceId);
    const [errorMessage, seterrorMessage] = useState('');

    // Handlers
    const removeHandler = async () => {
        const body = data
            .filter((record) => selectedIds.indexOf(record.timestamp) >= 0)
            .map((record) => ({
                deviceId: record.deviceId,
                timestamp: record.timestamp,
            }));
        await update(
            'downlinkCommands',
            { id: deviceId, data: body },
            {
                onSuccess: () => refetch(),
            },
        );
    };
    const toggleItemHandler = (id) => {
        const selected = selectedIds.indexOf(id) >= 0;
        const newSelection = selected
            ? selectedIds.filter((s_id) => s_id !== id)
            : [...selectedIds, id];
        setSelectedIds(newSelection);
    };
    const selectItemHandler = (ids) => setSelectedIds(ids);

    // Efects
    useEffect(() => setSelectedIds([]), [data]);
    useEffect(() => {
        let text = '';
        if (updateError) {
            text =
                'One or more commands not removed. Please try again or contact Hynds administrator.';
        }
        seterrorMessage(text);
    }, [updateError]);
    useEffect(() => {
        let text = '';
        if (fetchError) {
            text =
                'Unable to fetch pending commands. Please try again or contact Hynds administrator.';
        }
        seterrorMessage(text);
    }, [fetchError]);
    useEffect(() => {
        seterrorMessage('');
    }, [selectedIds]);

    return {
        data,
        ids,
        loading,
        errorMessage,
        refetch,
        removeHandler,
        toggleItemHandler,
        selectItemHandler,
        updating,
        selectedIds,
        nextWindow,
        nextWindowDistance,
        waitingFirstMessage,
        loadingNextWindow,
        errorNextWindow,
    };
};

export default usePendingCommands;
