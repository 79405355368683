import * as React from 'react';
import {
    Create,
    SimpleForm,
    TextInput,
    RadioButtonGroupInput,
    required,
    minLength,
    maxLength,
    useRedirect,
    useNotify,
    useRefresh,
    usePermissions,
} from 'react-admin';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import Breadcrumb from '../menu/Breadcrumb';
import { useAppContext } from '../context/AppContext';
import Layout from '../components/Layout';
import GROUPS from '../auth/groups';
import { validateText } from '../utils/validation';

const CreateProject = () => {
    const [appStore] = useAppContext();
    const defaults = () => ({
        organisationId: appStore.organisationId ?? '',
        projectStatus: 0,
    });
    const redirect = useRedirect();
    const notify = useNotify();
    const refresh = useRefresh();
    const { permissions } = usePermissions();

    if (!!permissions && !permissions.includes(GROUPS.HSW_ADMIN)) {
        redirect('/');
    }

    const onSuccess = () => {
        notify('New project created', { type: 'success' });
        redirect('/projects');
        refresh();
    };

    const onFailure = (error) => {
        notify(`Could not create project: ${error.message}`, { type: 'warning' });
        redirect('/projects');
        refresh();
    };

    const transform = (data) => ({
        ...data,
        name: data.name.trim(),
    });

    const validateProjectCreation = (values) => {
        const errors = {};
        const nameCheck = values.name ? values.name.trim() : '';
        if (nameCheck.length < 3) {
            errors.name = 'The project name is required';
        }
        return errors;
    };

    const validText =
        (message = 'Invalid text') =>
        (value) => {
            if (value && value.length > 2) {
                if (!validateText(value)) {
                    return message;
                } else {
                    return '';
                }
            }
            return '';
        };

    return (
        <Layout>
            <Breadcrumb />
            <Typography variant="h5" gutterBottom>
                Create New Project
            </Typography>
            <Create
                mutationOptions={{
                    onSuccess: onSuccess,
                    onError: onFailure,
                }}
                transform={transform}
            >
                <SimpleForm defaultValues={defaults} validate={validateProjectCreation}>
                    <Typography variant="h5" gutterBottom>
                        Project Details
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <TextInput
                            source="name"
                            label="Project name"
                            variant="outlined"
                            validate={[required(), minLength(2), maxLength(200), validText()]}
                        />
                        <RadioButtonGroupInput
                            source="projectStatus"
                            variant="outlined"
                            choices={[
                                { id: 0, name: 'Active' },
                                { id: 1, name: 'Inactive' },
                            ]}
                        />
                    </Box>
                </SimpleForm>
            </Create>
        </Layout>
    );
};

export default CreateProject;
