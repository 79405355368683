import * as React from 'react';
import { useState, useEffect } from 'react';
import { useLogin, useNotify, useLogout, useRedirect } from 'react-admin';
import { ThemeProvider, makeStyles } from '@mui/styles';
import { createTheme } from '@mui/material/styles';
import {
    Box,
    Card,
    TextField,
    FormControl,
    Button,
    Link,
    CircularProgress,
    LinearProgress,
} from '@mui/material';
import { Buffer } from 'buffer';
import Theme from '../theme/index';
import { ReactComponent as Logo } from '../img/hynds-aquanect-logo-grey.svg';
import PasswordFeild from '../components/PasswordField';
import MaintenancePage from './MaintenancePage';
import { getUserInfo } from '../auth/auth0Functions';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from './azureADConfig';

const useStyles = makeStyles((theme) => ({
    formControl: {
        marginTop: theme.spacing(3),
        minWidth: 120,
        width: '100%',
    },
    label: {
        color: '#636364',
        fontSize: '14px',
        letterSpacing: '-0.34px',
        lineHeight: '19px',
        marginBottom: '4px',
    },
    submit: {
        color: '#fff',
        backgroundColor: '#00AEEF',
        height: '45px',
        width: '100%',
        marginTop: '32px',
        marginBottom: '24px',
    },
    hyndsStaff: {
        paddingLeft: '0px',
        marginTop: '20px',
        display: 'block',
        textTransform: 'none',
    },
}));

const LoginPage = () => {
    const classes = useStyles();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const login = useLogin();
    const handleLogout = useLogout();
    const notify = useNotify();
    const redirect = useRedirect();
    const { instance } = useMsal();

    // For Dev and Test ONLY
    const isProduction = process.env.REACT_APP_BUILD_ENV === 'production';
    const [isMaintenanceMode, setIsMaintenanceMode] = useState(false);
    const [loading, setLoading] = useState(!isProduction);

    useEffect(() => {
        if (!isProduction) {
            fetch(process.env.REACT_APP_API_BASE_URL + '/status')
                .then((response) => response.json())
                .then((data) => {
                    if (data.status === 'MAINTENANCE') setIsMaintenanceMode(true);
                    setLoading(false);
                })
                .catch((error) => {
                    console.error('Error checking maintenance mode:', error);
                    setLoading(false);
                });
        }
    }, [isProduction]);

    if (loading) {
        return <LinearProgress />;
    }
    if (isMaintenanceMode) {
        return <MaintenancePage />;
    }

    //-------- AUTH0 ----------
    const submitAuth0 = async (e) => {
        e.preventDefault();
        if (!email || !email.length || !password || !password.length) {
            notify('Username and password is required.', { type: 'error' });
            return;
        }

        setSubmitting(true);

        await login({
            username: email,
            password: password,
            isAuth0: true,
        })
            .then(async () => {
                const userInfo = await getUserInfo();
                if (!userInfo?.externalUserId) {
                    handleLogout();
                    return;
                }
                redirect('/');
            })
            .catch((error) => {
                if (error.message.toLowerCase().includes('password expired')) {
                    const payload = Buffer.from(
                        JSON.stringify({
                            userName: email,
                        }),
                    ).toString('base64');
                    redirect(`/password-expired?data=${payload}`);
                    return;
                }
                notify(error.message ?? 'Invalid username/password', { type: 'error' });
            });

        setSubmitting(false);
    };

    const handleStaffLogin = async (instance) => {
        instance.loginRedirect(loginRequest).catch((e) => {
            console.error(e);
        });
    };

    return (
        <ThemeProvider theme={createTheme(Theme)}>
            <Box
                display="flex"
                width="100%"
                alignItems="center"
                flexDirection="column"
                justifyContent="center"
                height="100vh"
                style={{ backgroundColor: '#f5f5f8' }}
            >
                <Logo height="100px" />
                <Card style={{ padding: '16px', maxWidth: '360px', textAlign: 'center' }}>
                    <h2>Sign In</h2>
                    <form onSubmit={submitAuth0} style={{ textAlign: 'left' }}>
                        <FormControl className={classes.formControl}>
                            <span className={classes.label}>Username</span>
                            <TextField
                                name="email"
                                type="email"
                                variant="outlined"
                                fullWidth
                                value={email}
                                onChange={(e) => setEmail(e.target.value.trim())}
                            />
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <span className={classes.label}>Password</span>
                            <PasswordFeild
                                name="password"
                                variant="outlined"
                                fullWidth={true}
                                value={password}
                                onChange={setPassword}
                            />
                        </FormControl>
                        <Button
                            type="submit"
                            onClick={submitAuth0}
                            color="primary"
                            disabled={submitting}
                            className={classes.submit}
                        >
                            Sign in
                            {submitting && (
                                <CircularProgress
                                    style={{ marginLeft: '8px' }}
                                    size={18}
                                    color="inherit"
                                />
                            )}
                        </Button>
                    </form>
                    <Link
                        style={{ cursor: 'pointer' }}
                        onClick={() => redirect('/forgot-password')}
                    >
                        Forgot password
                    </Link>
                </Card>
                <Box mt="16px" maxWidth="360px">
                    <span className={classes.label}>
                        If you don&apos;t have account, please contact the Hynds Aquanect Team
                    </span>
                    <Button
                        className={classes.hyndsStaff}
                        onClick={() => handleStaffLogin(instance)}
                        variant="secondary"
                    >
                        Hynds staff
                    </Button>
                </Box>
            </Box>
        </ThemeProvider>
    );
};

export default LoginPage;
