import React from 'react';
import { DateField, TextField, ListContext, FunctionField, useLocale } from 'react-admin';
import useFetch from '../../hooks/useFetch';
import { deviceStatus } from '../device';
import Loading from '../../components/Loading';
import Error from '../../components/Error';
import DatagridWrapper from '../../components/DatagridWrapper';

const OnboardHistory = (props) => {
    const locale = useLocale();
    const { organisationId } = props;
    let url = `${process.env.REACT_APP_API_BASE_URL}/devices/${props.deviceId}/history`;
    if (organisationId !== '-') url += `?filter={"organisationId":"${organisationId}"}`;
    const { response, error, isLoading } = useFetch(encodeURI(url), { method: 'GET' });
    const [deviceHistory, setDeviceHistory] = React.useState([]);
    React.useEffect(() => {
        if (!response) return;
        setDeviceHistory(response);
    }, [response]);
    const [page, setPage] = React.useState(1);
    const perPage = 100;
    if (isLoading) return <Loading />;
    if (error) return <Error message="Could not load device history." />;
    return (
        <ListContext.Provider
            value={{
                data: deviceHistory,
                sort: { field: 'name', order: 'ASC' },
                page: page,
                perPage: perPage,
                setPage,
                total: deviceHistory.length,
                resource: 'devices',
                defaultTitle: 'Onboarding History',
            }}
        >
            <DatagridWrapper
                empty={<span>No onboarding history.</span>}
                rowStyle={() => ({
                    height: 50,
                    boxSizing: 'content-box',
                })}
            >
                <TextField source="deviceId" label="Device ID" />
                <TextField source="site.name" label="Site" />
                <TextField source="site.address" label="Address" />
                <FunctionField
                    label="Status"
                    source="status"
                    fullWidth
                    render={(record) => deviceStatus[record.status]}
                />
                <DateField
                    source="fromDate"
                    label="From"
                    options={{
                        day: 'numeric',
                        month: 'short',
                        year: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                        second: 'numeric',
                        hour12: false,
                    }}
                    locales={locale}
                    showTime
                />
                <DateField
                    source="toDate"
                    label="To"
                    options={{
                        day: 'numeric',
                        month: 'short',
                        year: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                        second: 'numeric',
                        hour12: false,
                    }}
                    locales={locale}
                    showTime
                />
            </DatagridWrapper>
        </ListContext.Provider>
    );
};

export default OnboardHistory;
