import * as React from 'react';
import { useRedirect } from 'react-admin';
import { loginRequest } from './azureADConfig';
import { useMsal } from '@azure/msal-react';
import { useIsAuthenticated } from '@azure/msal-react';

const AzureADCallback = () => {
    const redirect = useRedirect();
    const { instance } = useMsal();

    const isAuthenticated = useIsAuthenticated();

    React.useEffect(() => {
        if (isAuthenticated) {
            const accounts = instance.getAllAccounts();
            const account = accounts.find(
                (a) => a?.tenantId === process.env.REACT_APP_AZURE_AD_TENANT_ID,
            );
            const request = {
                ...loginRequest,
                account: account,
            };
            instance
                .acquireTokenSilent(request)
                .then((response) => {
                    localStorage.setItem('hsw_auth', response.accessToken);
                    localStorage.setItem('hsw_isAzureAD', true);

                    // redirect and force reload of react resources
                    window.location.href = '/';
                })
                .catch(() => {
                    redirect('/');
                });
        }
    }, [instance, isAuthenticated, redirect]);

    return <div></div>;
};

export default AzureADCallback;
