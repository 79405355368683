import EditDevice from './Edit';
import ListDevices from './List';
import ShowDevice from './Show';
import GROUPS from '../auth/groups';

const devices = (permissions) => {
    const device = {};
    if (permissions && permissions.includes(GROUPS.HSW_ADMIN)) {
        device.list = ListDevices;
        device.show = ShowDevice;
        device.edit = EditDevice;
    } else {
        device.list = null;
        device.show = null;
        device.edit = null;
    }

    return device;
};

export default devices;
