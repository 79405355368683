import { Box, Card, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { SimpleShowLayout } from 'react-admin';
import { Marker } from '@react-google-maps/api';
import Map from '../../components/Map';
import Label from '../../components/Label';

const useStyles = makeStyles(() => ({
    noPadding: {
        padding: '0px',
    },
}));

/**
 * Display Address and uses Google map to display location
 * @param {*} props Device record
 * @returns Device Site information with map
 */
const DeviceSite = (props) => {
    const { device } = props;
    const classes = useStyles();

    return (
        <Card style={{ minHeight: '385px' }}>
            <Box display="flex" justifyContent="space-between" width="100%">
                <Typography variant="h6" gutterBottom>
                    Device Location
                </Typography>
            </Box>
            <SimpleShowLayout record={device} className={classes.noPadding}>
                <Label>Address</Label>
                <TextField
                    id="device-address"
                    variant="outlined"
                    fullWidth
                    disabled
                    defaultValue={device.site.address}
                    margin="none"
                />
                <Box height="228px" mt="20px">
                    <Map
                        center={{
                            lat: +device.site.latitude,
                            lng: +device.site.longitude,
                        }}
                        zoom={15}
                        withLocationMem={false}
                    >
                        {device.site.latitude && (
                            <Marker
                                position={{
                                    lat: +device.site.latitude,
                                    lng: +device.site.longitude,
                                }}
                            />
                        )}
                    </Map>
                </Box>
            </SimpleShowLayout>
        </Card>
    );
};

export default DeviceSite;
