import { useState } from 'react';

function useSessionStorage(key, initialValue) {
    const [storedValue, setStoredValue] = useState(() => {
        try {
            const item = window.sessionStorage.getItem(key);
            if (!item) return initialValue;
            return item instanceof String ? item : JSON.parse(item);
        } catch (error) {
            console.log(error);
            return initialValue;
        }
    });
    const setValue = (value) => {
        try {
            const valueToStore = value instanceof Function ? value(storedValue) : value;
            setStoredValue(valueToStore);
            window.sessionStorage.setItem(
                key,
                valueToStore instanceof String ? valueToStore : JSON.stringify(valueToStore),
            );
        } catch (error) {
            console.log(error);
        }
    };
    return [storedValue, setValue];
}

export default useSessionStorage;
