import GROUPS from './groups';

const getFriendlyRoleName = (group) => {
    switch (group) {
        case GROUPS.CUSTOMER_ADMIN:
            return 'Customer Admin';
        case GROUPS.CUSTOMER_USER:
            return 'Customer';
        case GROUPS.HSW_ADMIN:
            return 'Hynds Admin';
        case GROUPS.HSW_USER:
            return 'Hynds User';
        case GROUPS.INSTALLER:
            return 'Installer';
        case GROUPS.SURVEYOR:
            return 'Surveyor';
        case GROUPS.PORTALACCESS:
            return 'Portal Access';
        case GROUPS.ANALYTICS:
            return 'Analytics';
        default:
            return '';
    }
};

export default getFriendlyRoleName;
