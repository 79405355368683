import { Box, Card, Divider, ButtonBase } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useRedirect } from 'react-admin';
import { useLocation } from 'react-router-dom';

import LastDownlinkCommand from '../components/LastCommand';
import NextWindow from '../components/NextWindow';
import PendingCommandsCounter from '../components/PendingCommandsCounter';
import useGetLastCommand from '../hooks/useGetLastCommand';
import useGetPendingCommands from '../hooks/useGetPendingCommands';
import useGetNextWindow from '../hooks/useGetNextWindow';
import ActionsMenu from '../components/ActionsMenu';

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: 20,
    },
    section: {
        display: 'flex',
        flexDirection: 'row',
        columnGap: 20,
    },
    title: {
        flexGrow: 0,
        fontWeight: 600,
        minWidth: 150,
        display: 'flex',
        alignItems: 'start',
    },
    actions: {
        marginLeft: 'auto',
    },
    menuItems: {
        fontSize: 12,
        fontWeight: 600,
        textTransform: 'uppercase',
        padding: '6px 16px',
    },
});

/**
 * Display last downlink command, next windows and pending commands
 * No pagination or sorting
 */
const DownlinkCommands = ({ deviceId, deviceUuid }) => {
    const classes = useStyles();
    const {
        loading: loadingLastCommand,
        error: errorLastCommand,
        lastCommand,
    } = useGetLastCommand(deviceId);
    const {
        count: pendingCommands,
        loading: loadingPending,
        error: errorPending,
    } = useGetPendingCommands(deviceId);

    const {
        loading: loadingNextWindow,
        error: errorNextWindow,
        waitingFirstMessage,
        nextWindow,
        nextWindowDistance,
    } = useGetNextWindow(deviceId);
    const redirect = useRedirect();
    const { pathname } = useLocation();
    const createHandler = () =>
        redirect('create', '/downlinkCommands', null, null, {
            from: pathname,
            deviceId,
            deviceUuid,
        });
    const pendingHandler = () => {
        redirect('list', '/downlinkCommands', null, null, {
            from: pathname,
            status: 'pending',
            deviceId,
            deviceUuid,
        });
    };
    const commandsHistoryHandler = () => {
        redirect('list', '/downlinkCommands', null, null, {
            from: pathname,
            status: 'all',
            deviceId,
            deviceUuid,
        });
    };
    const commandsRequestsHandler = () => {
        redirect('list', '/commandRequests', null, null, {
            from: pathname,
            deviceId,
            deviceUuid,
        });
    };

    return (
        <Box>
            <Card className={classes.container}>
                <Box className={classes.section}>
                    <Box className={classes.title}>Last command</Box>
                    <LastDownlinkCommand
                        command={lastCommand}
                        loading={loadingLastCommand}
                        error={errorLastCommand}
                    />
                    <ActionsMenu className={classes.actions}>
                        <ButtonBase onClick={createHandler} className={classes.menuItems}>
                            Schedule new downlink command
                        </ButtonBase>
                        <ButtonBase onClick={pendingHandler} className={classes.menuItems}>
                            View pending commands
                        </ButtonBase>
                        <ButtonBase onClick={commandsHistoryHandler} className={classes.menuItems}>
                            View commands history
                        </ButtonBase>
                        <ButtonBase onClick={commandsRequestsHandler} className={classes.menuItems}>
                            View command requests history
                        </ButtonBase>
                    </ActionsMenu>
                </Box>
                <Divider />
                <Box className={classes.section}>
                    <Box className={classes.title}>Next windows</Box>
                    <NextWindow
                        nextWindow={nextWindow}
                        nextWindowDistance={nextWindowDistance}
                        waitingFirstMessage={waitingFirstMessage}
                        loading={loadingNextWindow}
                        error={errorNextWindow}
                    />
                </Box>
                <Divider />
                <Box className={classes.section}>
                    <Box className={classes.title}>Pending commands</Box>
                    <PendingCommandsCounter
                        count={pendingCommands}
                        loading={loadingPending}
                        error={errorPending}
                    />
                </Box>
            </Card>
        </Box>
    );
};

export default DownlinkCommands;
