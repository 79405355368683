import { useGetManyReference } from 'react-admin';

const useGetLastCommand = (deviceId) => {
    // pagination and sorting are not needed here. Only adding for react-admin
    const {
        data,
        isLoading: loading,
        error,
    } = useGetManyReference('downlinkCommands', {
        target: 'device_id',
        id: deviceId,
        pagination: { page: 1, perPage: 25 },
        sort: { field: 'id', order: 'DESC' },
        filter: { last: true },
    });

    const lastCommand =
        data && data.length > 0 && data[data.length - 1] ? data[data.length - 1] : null;

    return {
        loading,
        error: (!loading && !data) || error ? "Couldn't load last command" : null,
        lastCommand,
    };
};

export default useGetLastCommand;
