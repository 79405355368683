import React, { useState, useMemo } from 'react';
import {
    SimpleShowLayout,
    EditButton,
    ReferenceField,
    useUpdate,
    TextField,
    FunctionField,
    useRefresh,
    usePermissions,
    WrapperField,
} from 'react-admin';
import { Marker } from '@react-google-maps/api';
import { Box, Grid, Card, ButtonGroup, Button, Typography } from '@mui/material';
import Map from '../../components/Map';
import SiteLocationEdit from '../settings/SiteLocationEdit';
import { makeStyles } from '@mui/styles';
import Label from '../../components/Label';
import GROUPS from '../../auth/groups';
import ROOT from '../../theme/root';
import { getApplicationType, getSiteType } from './SiteTypes';

const useStyles = makeStyles(() => ({
    remove: {
        color: '#D0021B',
        fontSize: '14px',
    },
    siteTitle: {
        fontSize: '32px',
        fontWeight: 'bold',
        lineHeight: '43px',
    },
    noPadding: {
        padding: '0px',
    },
    girdLeft: {
        paddingTop: '0px',
        paddingBottom: '0px',
        paddingLeft: '0px',
        paddingRight: '8px',
    },
    girdRight: {
        paddingTop: '0px',
        paddingBottom: '0px',
        paddingLeft: '8px',
        paddingRight: '0px',
    },
    marginBottom: {
        marginBottom: '16px',
    },
}));

const getNetworkType = (networkTypeId) => {
    let result = '';
    switch (networkTypeId) {
        case 0:
            result = 'Sigfox';
            break;

        default:
            result = 'Sigfox';
            break;
    }
    return result;
};

const getSiteSurveyApproval = (approval) => {
    let result = '';
    switch (approval) {
        case false:
            result = 'False';
            break;

        case true:
            result = 'True';
            break;

        default:
            result = 'Not specified';
            break;
    }
    return result;
};

const getGPSDeviceId = (devices) => {
    let deviceID = '';
    if (devices) {
        const gpsDevice = devices.find((d) => d.deviceApplication === 7 && d.toDate === null);
        deviceID = gpsDevice?.deviceId ?? '';
    }
    return deviceID;
};

const SiteDetails = ({ record }) => {
    const classes = useStyles();
    const [update] = useUpdate();
    const refresh = useRefresh();
    const [showLocationEdit, setShowLocationEdit] = useState(false);
    const { permissions } = usePermissions();

    // Roles
    const isHyndsAdmin = !!permissions && permissions.includes(GROUPS.HSW_ADMIN);
    const isHyndsUser = !!permissions && permissions.includes(GROUPS.HSW_USER);
    const isCustomerAdmin = !!permissions && permissions.includes(GROUPS.CUSTOMER_ADMIN);
    const isCustomerAdminAnalytics =
        !!permissions &&
        permissions.includes(GROUPS.CUSTOMER_ADMIN) &&
        permissions.includes(GROUPS.ANALYTICS);
    const isCustomerAnalytics =
        !!permissions &&
        permissions.includes(GROUPS.PORTALACCESS) &&
        permissions.includes(GROUPS.ANALYTICS);

    // Permissions
    const canSeeAnalytics = isHyndsAdmin || isCustomerAnalytics;
    const isRaftSensor = record?.siteTypeId === 5;
    const canEdit =
        record.status !== 5 && (isHyndsAdmin || (isCustomerAdminAnalytics && !isRaftSensor));
    const canEditLocation = isHyndsAdmin && record?.status !== 5;
    const canSeeProject = isHyndsAdmin || isHyndsUser || isCustomerAdmin;

    const handleSaveLocation = ({ address, location }) => {
        const upsert = { ...record };
        upsert.address = address;
        upsert.latitude = location.latitude;
        upsert.longitude = location.longitude;
        update('sites', { id: record.id, data: upsert, previousData: record });
        refresh(true);
    };

    const siteLocation = useMemo(() => {
        return (
            <SiteLocationEdit
                isOpen={showLocationEdit}
                onOpen={() => setShowLocationEdit(true)}
                onClose={() => setShowLocationEdit(false)}
                onSave={({ address, location }) => handleSaveLocation({ address, location })}
                siteAddress={record.address}
                location={{ latitude: record.latitude, longitude: record.longitude }}
            />
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [record, showLocationEdit]);

    return (
        <Box mt="24px">
            <Grid container spacing={2}>
                <Grid item sm={12} md={6}>
                    <Card style={{ minHeight: '385px' }}>
                        <Box display="flex" justifyContent="space-between" width="100%">
                            <Typography variant="h6" gutterBottom>
                                Site Details
                            </Typography>
                            {canEdit && <EditButton record={record} icon={null} />}
                        </Box>
                        <Grid container spacing={0}>
                            <Grid item sm={12} md={6}>
                                <SimpleShowLayout record={record} className={classes.girdLeft}>
                                    <TextField source="name" label="Site Name" fullWidth />
                                    <WrapperField
                                        className="MuiFormControl-root MuiFormControl-marginDense MuiFormControl-fullWidth"
                                        label={false}
                                    >
                                        <Label>Site Type</Label>
                                        <ButtonGroup
                                            color="primary"
                                            aria-label="primary button group"
                                            size="large"
                                            className={classes.marginBottom}
                                            fullWidth
                                            disabled
                                        >
                                            <Button style={{ color: '#fff' }} color={'primary'}>
                                                {getSiteType(record.siteTypeId)}
                                            </Button>
                                        </ButtonGroup>
                                    </WrapperField>

                                    <FunctionField
                                        label="Network Type"
                                        source="networkTypeId"
                                        fullWidth
                                        render={(record) => getNetworkType(record.networkTypeId)}
                                    />
                                    {isRaftSensor && (
                                        <FunctionField
                                            label="GPS device ID"
                                            fullWidth
                                            render={(record) => getGPSDeviceId(record?.devices)}
                                        />
                                    )}
                                </SimpleShowLayout>
                            </Grid>
                            <Grid item sm={12} md={6}>
                                <SimpleShowLayout record={record} className={classes.girdRight}>
                                    {canSeeProject && (
                                        <ReferenceField
                                            source="projectId"
                                            reference="projects"
                                            label="Project Name"
                                            link="show"
                                            fullWidth
                                            sx={{
                                                backgroundColor: 'white',
                                                border: ROOT.BORDERS.BORDER,
                                                borderRadius: ROOT.BORDERS.RADIUS,
                                                color: ROOT.INPUTS.COLOR,
                                                padding: '0px 12px',
                                                height: ROOT.INPUTS.HEIGHT,
                                                display: 'flex',
                                                alignItems: 'center',
                                                width: 'auto',
                                                marginBottom: '4px',
                                                minWidth: '160px',
                                            }}
                                        >
                                            <TextField source="name" />
                                        </ReferenceField>
                                    )}

                                    <TextField source="deviceId" label="Device ID" fullWidth />

                                    <Box mt="12px">
                                        <Box
                                            className="MuiFormControl-root MuiFormControl-marginDense MuiFormControl-fullWidth"
                                            style={{
                                                marginTop: canSeeProject ? '12px' : '-4px',
                                            }}
                                        >
                                            <Label>Application Type</Label>
                                            <FunctionField
                                                source="applicationTypeId"
                                                sx={{
                                                    backgroundColor: 'white',
                                                    border: ROOT.BORDERS.BORDER,
                                                    borderRadius: ROOT.BORDERS.RADIUS,
                                                    color: ROOT.INPUTS.COLOR,
                                                    padding: '0px 12px',
                                                    height: ROOT.INPUTS.HEIGHT,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    width: 'auto',
                                                    marginBottom: '4px',
                                                    minWidth: '160px',
                                                }}
                                                render={(record) =>
                                                    getApplicationType(record.applicationTypeId)
                                                }
                                            />
                                        </Box>
                                    </Box>
                                </SimpleShowLayout>
                            </Grid>
                            {canSeeAnalytics && !isRaftSensor && (
                                <Grid item sm={12} md={6} sx={{ marginTop: '8px' }}>
                                    <Label>Rainfall Site</Label>
                                    <SimpleShowLayout record={record} className={classes.girdLeft}>
                                        <Box
                                            sx={{
                                                backgroundColor: 'white',
                                                border: ROOT.BORDERS.BORDER,
                                                borderRadius: ROOT.BORDERS.RADIUS,
                                                color: ROOT.INPUTS.COLOR,
                                                padding: '0px 12px',
                                                height: ROOT.INPUTS.HEIGHT,
                                                display: 'flex',
                                                alignItems: 'center',
                                                width: 'auto',
                                                marginBottom: '4px',
                                                minWidth: '160px',
                                            }}
                                        >
                                            <ReferenceField
                                                source="rainfallSiteId"
                                                reference="rainfallSites"
                                                link={(record, reference) =>
                                                    `/${reference}/${record.rainfallSiteId}/show`
                                                }
                                                emptyText="Not selected"
                                                label={false}
                                            >
                                                <TextField source="name" id="rainfall-site-id" />
                                            </ReferenceField>
                                        </Box>
                                    </SimpleShowLayout>
                                </Grid>
                            )}
                            {isHyndsAdmin && !isRaftSensor && (
                                <Grid item sm={12} md={6} sx={{ marginTop: '8px' }}>
                                    <SimpleShowLayout record={record} className={classes.girdRight}>
                                        <FunctionField
                                            label="Automatic Suvery Approval"
                                            source="automaticSurveyApproval"
                                            fullWidth
                                            render={(record) =>
                                                getSiteSurveyApproval(
                                                    record.automaticSurveyApproval,
                                                )
                                            }
                                        />
                                    </SimpleShowLayout>
                                </Grid>
                            )}
                        </Grid>
                    </Card>
                </Grid>
                <Grid item sm={12} md={6}>
                    <Card style={{ minHeight: '385px', height: '100%' }}>
                        <Box display="flex" justifyContent="space-between" width="100%">
                            <Typography variant="h6" gutterBottom>
                                Site Location
                            </Typography>
                            {canEditLocation && (
                                <Button onClick={() => setShowLocationEdit(true)} color="primary">
                                    Edit
                                </Button>
                            )}
                        </Box>
                        {siteLocation}
                        <SimpleShowLayout record={record} className={classes.noPadding}>
                            <TextField source="address" label="Address" fullWidth />
                            <Box height="228px">
                                <Map
                                    center={{ lat: +record.latitude, lng: +record.longitude }}
                                    zoom={15}
                                    withLocationMem={false}
                                >
                                    {record.latitude && (
                                        <Marker
                                            position={{
                                                lat: +record.latitude,
                                                lng: +record.longitude,
                                            }}
                                        />
                                    )}
                                </Map>
                            </Box>
                        </SimpleShowLayout>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    );
};

export default SiteDetails;
