import { getAccessToken } from '../../auth/auth0Functions';

const updateSurveyData = async (survey) => {
    const body = survey;
    const token = await getAccessToken();
    await fetch(`${process.env.REACT_APP_API_BASE_URL}/surveys`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: token,
        },
        body: JSON.stringify(body),
    })
        .then((response) => {
            return response.json();
        })
        .catch((error) => {
            console.log(error);
        });
    return true;
};

const uploadSurveyImage = async (file, id) => {
    const token = await getAccessToken();
    await fetch(process.env.REACT_APP_API_OUTSYSTEMS_URL + `/UploadSurveyImage?SurveyId=${id}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'image/jpeg',
            Authorization: token ?? '',
        },
        body: file,
    })
        .then((response) => {
            return response.json();
        })
        .catch((error) => {
            console.log(error);
        });
};

const deleteSurveyImage = async (id, key) => {
    const token = await getAccessToken();
    await fetch(
        process.env.REACT_APP_API_OUTSYSTEMS_URL +
            `/DeleteSurveyImage?SurveyId=${id}&ObjectKey=${key}`,
        {
            method: 'DELETE',
            headers: {
                'Content-Type': 'image/jpeg',
                Authorization: token ?? '',
            },
        },
    )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
        });
};

const fetchImages = async (siteId) => {
    let status;
    let images;
    let errorToReturn = '';
    const token = await getAccessToken();
    await fetch(process.env.REACT_APP_API_OUTSYSTEMS_URL + `/GetSurveyImages?SiteId=${siteId}`, {
        method: 'GET',
        headers: { Authorization: token },
    })
        .then((response) => {
            status = response.status;
            if (response.status === 200) {
                return response.json();
            }
        })
        .then((data) => {
            images = data;
            return data;
        })
        .catch((err) => {
            console.log(err);
            errorToReturn = err;
        });
    return [status, errorToReturn, images];
};

const fetchComments = async (siteId) => {
    const token = await getAccessToken();
    var response = await fetch(process.env.REACT_APP_API_BASE_URL + `/sites/feedback/${siteId}`, {
        method: 'GET',
        headers: { Authorization: token },
    });

    if (response.status === 200) {
        return await response.json();
    }
    return [];
};

export { updateSurveyData, uploadSurveyImage, deleteSurveyImage, fetchImages, fetchComments };
