import React from 'react';
import { SimpleShowLayout } from 'react-admin';
import Typography from '@mui/material/Typography';
import { Box, Card, LinearProgress, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Layout from '../../components/Layout';
import Label from '../../components/Label';
import JSONField from './JSONField';

const useStyles = makeStyles(() => ({
    container: {
        padding: '16px 0px',
    },
}));

const RainfallNetworkShowLayout = ({ record: rainfallNetwork, isLoading, error, ...props }) => {
    const classes = useStyles();

    if (isLoading || !rainfallNetwork) {
        return (
            <Layout>
                <LinearProgress />
            </Layout>
        );
    }

    if (error) {
        return (
            <Box>
                <Typography variant="body2">Could not load Rainfall Network</Typography>
            </Box>
        );
    }

    return (
        <SimpleShowLayout record={rainfallNetwork} className={classes.container} {...props}>
            <Card>
                <Typography variant="h6" gutterBottom>
                    Rainfall Network Details
                </Typography>
                <Box
                    display="flex"
                    flexDirection="column"
                    rowGap="20px"
                    justifyContent="stretch"
                    alignItems="stretch"
                >
                    <Box>
                        <Label>Name</Label>
                        <TextField
                            id="rainfall-site-name"
                            variant="outlined"
                            disabled
                            defaultValue={rainfallNetwork.name}
                            fullWidth={true}
                        />
                    </Box>
                    <Box>
                        <Label>Parse Method</Label>
                        <TextField
                            id="rainfall-network-parsemethod"
                            variant="outlined"
                            disabled
                            defaultValue={rainfallNetwork.parseMethod.name}
                            fullWidth={true}
                        />
                    </Box>
                    <Box>
                        <Label>Request URL</Label>
                        <TextField
                            id="rainfall-network-url"
                            variant="outlined"
                            disabled
                            defaultValue={rainfallNetwork.requestURL}
                            fullWidth={true}
                        />
                    </Box>
                    <Box>
                        <Label>Request Type</Label>
                        <TextField
                            id="rainfall-network-requesttype"
                            variant="outlined"
                            disabled
                            defaultValue={rainfallNetwork.requestType}
                            fullWidth={true}
                        />
                    </Box>
                    <Box>
                        <Label>Request Headers</Label>
                        <JSONField id="rainfall-network-requestheaders" source="requestHeaders" />
                    </Box>
                    <Box>
                        <Label>Request Body</Label>
                        <JSONField id="rainfall-network-requestbody" source="requestBody" />
                    </Box>
                    <Box>
                        <Label>Request Variables</Label>
                        <JSONField
                            id="rainfall-network-requestvariables"
                            source="requestVariables"
                        />
                    </Box>
                    <Box>
                        <Label>Response Type</Label>
                        <TextField
                            id="rainfall-network-responsetype"
                            variant="outlined"
                            disabled
                            defaultValue={rainfallNetwork.responseType.name}
                            fullWidth={true}
                        />
                    </Box>
                </Box>
            </Card>
        </SimpleShowLayout>
    );
};

export default RainfallNetworkShowLayout;
