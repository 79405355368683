import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { TablePagination, Toolbar } from '@mui/material';
import { useListContext, useTranslate, useListPaginationContext } from 'react-admin';

/**
 * Based on ra-ui-materialui/src/list/pagination/Pagination.tsx
 * Manages filter to achieve pagination using pageKey index
 * page number is managed in the local state
 */
const Pagination = (props) => {
    const { isLoading, hasNextPage, perPage, total } = useListPaginationContext(props);
    const { setFilters, data } = useListContext();
    const [page, setPage] = useState(1);
    const [pageKey, setPageKey] = useState([]);
    const translate = useTranslate();

    const totalPages = useMemo(() => {
        return total != null ? Math.ceil(total / perPage) : undefined;
    }, [perPage, total]);

    // Keep index of pages and lastKey
    useEffect(() => {
        if (data && data.length > 0) {
            let lastKey = {
                deviceId: data[data.length - 1].deviceId,
                timestamp: data[data.length - 1].timestamp,
            };
            const newPageKey = [...pageKey];
            newPageKey[page + 1] = lastKey;
            setPageKey(newPageKey);
        }
    }, [data, page, pageKey]);

    const handlePageChange = useCallback(
        (event, newPage) => {
            event && event.stopPropagation();
            if (newPage < 0 || newPage > totalPages - 1 || !data) {
                throw new Error(
                    translate('ra.navigation.page_out_of_boundaries', {
                        page: page + 1,
                    }),
                );
            }
            const pageIdx = newPage + 1;

            if (pageIdx === page) return;

            // Add starting date in filter for correct page before fetching data
            const lastKey = pageKey[`${pageIdx}`];
            if (lastKey) {
                setFilters({ lastKey });
            } else {
                setFilters({});
            }

            setPage(pageIdx);
        },
        [totalPages, setPage, translate, data, page, pageKey, setFilters],
    );

    const labelDisplayedRows = useCallback(
        ({ from, to, count }) =>
            count === -1 && hasNextPage
                ? translate('ra.navigation.partial_page_range_info', {
                      offsetBegin: from,
                      offsetEnd: to,
                      _: '%{from}-%{to} of more than %{to}',
                  })
                : translate('ra.navigation.page_range_info', {
                      offsetBegin: from,
                      offsetEnd: to,
                      total: count === -1 ? to : count,
                      _: '%{from}-%{to} of %{count === -1 ? to : count}',
                  }),
        [translate, hasNextPage],
    );

    const labelItem = useCallback(
        (type) => translate(`ra.navigation.${type}`, { _: `Go to ${type} page` }),
        [translate],
    );

    if (isLoading) {
        return <Toolbar variant="dense" />;
    }

    return (
        <TablePagination
            count={total == null ? -1 : total}
            rowsPerPage={perPage}
            page={page - 1}
            onPageChange={handlePageChange}
            nextIconButtonProps={{
                disabled: !hasNextPage,
            }}
            component="span"
            labelRowsPerPage={translate('ra.navigation.page_rows_per_page')}
            labelDisplayedRows={labelDisplayedRows}
            getItemAriaLabel={labelItem}
            {...props}
        />
    );
};

export default Pagination;
