import React from 'react';
import {
    useDelete,
    useNotify,
    useRedirect,
    useRefresh,
    Confirm,
    usePermissions,
    useRecordContext,
} from 'react-admin';
import { Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import GROUPS from '../auth/groups';

const DeleteButton = () => {
    const notify = useNotify();
    const redirect = useRedirect();
    const refresh = useRefresh();
    const [openConfirm, setOpenConfirm] = React.useState(false);
    const [deleteOne, { data: deleted, isLoading: loading, error }] = useDelete();
    const record = useRecordContext();
    const handleDelete = async (event) => {
        event.preventDefault();
        deleteOne('projects', { id: record.id, previousData: record });
    };
    const { permissions } = usePermissions();

    if (!!permissions && !permissions.includes(GROUPS.HSW_ADMIN)) {
        redirect('/');
    }

    React.useEffect(() => {
        if (error) {
            notify('Project could not be deleted.', { type: 'warning' });
        }
        if (!deleted) return;
        const response = deleted;
        if (response.errors) {
            notify(response.errors.join(' '), { type: 'warning' });
            return;
        }
        notify('Project deleted.', { type: 'info', messageArgs: { smart_count: 1 } });
        redirect('/projects');
        setTimeout(refresh, 500);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleted, error]);

    if (error) {
        return <p>Could not remove project</p>;
    }
    return (
        <>
            <Button
                disabled={loading}
                onClick={() => setOpenConfirm(true)}
                startIcon={<DeleteIcon />}
                color="secondary"
                style={{ color: '#f44336' }}
            >
                Delete
            </Button>

            <Confirm
                isOpen={openConfirm}
                loading={loading}
                title="Delete project"
                content="Are you sure you want to delete this project? This will delete all sites associated with this project aswell."
                onConfirm={(evnt) => handleDelete(evnt)}
                onClose={() => setOpenConfirm(false)}
            />
        </>
    );
};

export default DeleteButton;
