import React from 'react';
import { Show, useShowController, useRedirect, usePermissions } from 'react-admin';
import PageLayout from './ShowLayout';
import Loading from '../components/Loading';
import Layout from '../components/Layout';
import GROUPS from '../auth/groups';

const ShowUser = () => {
    const controller = useShowController();
    const redirect = useRedirect();
    const { permissions } = usePermissions();
    const overrides = {};

    if (!controller.record) return <Loading />;

    if (
        !!permissions &&
        !permissions.includes(GROUPS.HSW_ADMIN) &&
        !permissions.includes(GROUPS.CUSTOMER_ADMIN)
    ) {
        redirect('/');
    }

    overrides[controller.record.id] = controller.record.name;
    return (
        <Layout breadcrumb overrides={overrides} title={controller.record.name}>
            <Show component="div" actions={<></>}>
                <PageLayout />
            </Show>
        </Layout>
    );
};

export default ShowUser;
