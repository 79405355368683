import * as React from 'react';
import {
    SimpleShowLayout,
    TextField,
    EditButton,
    useRedirect,
    usePermissions,
    useGetRecordId,
    useGetList,
    ShowContextProvider,
} from 'react-admin';
import { Card, Grid, Typography, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Layout from '../components/Layout';
import Loading from '../components/Loading';
import GROUPS from '../auth/groups';

const useStyles = makeStyles(() => ({
    card: {
        '& h6': {
            paddingLeft: '15px',
            paddingTop: '10px',
        },
    },
}));

const OrganisationShow = () => {
    const redirect = useRedirect();
    const { permissions } = usePermissions();
    const classes = useStyles();
    const orgIdPath = useGetRecordId();
    const [organisation, setOrganisation] = React.useState();

    const {
        data: organisations,
        isLoading,
        isFetching,
        refetch,
        error,
    } = useGetList(
        'organisations',
        { pagination: { page: 1, perPage: 1000 } },
        { enabled: !!orgIdPath },
    );

    React.useEffect(() => {
        if (!organisations) {
            return;
        }
        const org = organisations.find((o) => o.id === orgIdPath);
        setOrganisation(org);
    }, [organisations, orgIdPath]);

    React.useEffect(() => {
        if (
            !!permissions &&
            !permissions.includes(GROUPS.HSW_ADMIN) &&
            !permissions.includes(GROUPS.HSW_USER)
        ) {
            redirect('/');
        }
    });

    if (!organisation) return <Loading />;

    const overrides = { organisations: 'Customers' };
    overrides[organisation.id] = organisation.name;

    return (
        <ShowContextProvider
            value={{
                defaultTitle: 'Customer',
                error,
                isFetching,
                isLoading,
                resource: 'organisations',
                record: organisation,
                refetch,
            }}
        >
            <Layout breadcrumb overrides={overrides} title={organisation.name}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Card className={classes.card}>
                            <Box display="flex" justifyContent="space-between">
                                <Typography variant="subtitle1" gutterBottom>
                                    Customer Details
                                </Typography>
                                <EditButton icon={<></>} />
                            </Box>

                            <SimpleShowLayout>
                                <TextField source="name" label="Customer Name" fullWidth />
                                <TextField source="accountNo" label="Account Number" fullWidth />
                                <TextField source="address.line1" label="Address" fullWidth />
                                <TextField
                                    source="address.line2"
                                    label="Address Line 2"
                                    fullWidth
                                />
                                <TextField source="address.city" label="City" fullWidth />
                                <TextField source="address.country" label="Country" fullWidth />
                            </SimpleShowLayout>
                        </Card>
                    </Grid>
                </Grid>
            </Layout>
        </ShowContextProvider>
    );
};

export default OrganisationShow;
