import React, { useState } from 'react';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Controls from '../components/Controls';
import AlgorithmSelector from './selector';
import { LevelTrendChart } from './charts';
import { LevelRainfallContextProvider } from '../analytics/dataproviders';
import { addHours } from 'date-fns';

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        gap: '30px',
        marginTop: '24px',
    },
}));

const defaultAlgorithm = {
    id: 'sma',
    name: 'Moving Average',
    param: 1,
};

const TrendAnalysis = (props) => {
    const classes = useStyles();
    const { siteId, rainfallSiteId } = props;
    const [fromDate, setFromDate] = useState(addHours(new Date(), -24 * 30));
    const [toDate, setToDate] = useState(new Date());
    const [algorithm, setAlgorithm] = useState(defaultAlgorithm);

    const updateDates = (from, to) => {
        setFromDate(from);
        setToDate(to);
    };

    const applyAlgorithm = (newAlgorithm) => {
        setAlgorithm(newAlgorithm);
    };

    return (
        <>
            <span id="trend-analysis-data-anchor-top" />
            <Box className={classes.container}>
                <Controls fromDate={fromDate} toDate={toDate} updateDates={updateDates} />
                <AlgorithmSelector algorithm={algorithm} onApply={applyAlgorithm} />
                <LevelRainfallContextProvider
                    siteId={siteId}
                    rainfallSiteId={rainfallSiteId}
                    fromDate={fromDate}
                    toDate={toDate}
                    useEmptyReadings={false}
                    withSummary={false}
                >
                    <LevelTrendChart algorithm={algorithm} />
                </LevelRainfallContextProvider>
            </Box>
        </>
    );
};

export default TrendAnalysis;
