import React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { SearchInput } from 'react-admin';

const useStyles = makeStyles(() => ({
    searchInput: {
        height: '45px',
        width: '300px',
        marginTop: '4px',
        '& input': {
            color: '#636364',
        },
        // Workaround to avoid displaying gap at top left of the input
        // https://github.com/marmelab/react-admin/issues/6468
        '& legend': {
            width: 0,
        },
    },
}));

const SearchFilter = ({ placeholder }) => {
    const classes = useStyles();
    return (
        <SearchInput
            key="filter-query"
            source="query"
            variant="outlined"
            className={classes.searchInput}
            placeholder={placeholder ?? 'Search'}
            alwaysOn
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <SearchIcon />
                    </InputAdornment>
                ),
            }}
        />
    );
};

export default SearchFilter;
