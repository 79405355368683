import React from 'react';
import { ReactComponent as ManholeImage } from './manhole.svg';
import { ReactComponent as GroundwaterImage } from './groundwater.svg';
import { ReactComponent as TankImage } from '../../surveys/images/tank.svg';
import { makeStyles } from '@mui/styles';
import { Button, Box, TextField, CircularProgress, Grid } from '@mui/material';
import GROUPS from '../../auth/groups';
import { useRefresh, useNotify, Confirm } from 'react-admin';
import { updateSurveyData } from './EditSurvey';
import InputMask from 'react-input-mask';
import Loading from '../../components/Loading';
import Label from '../../components/Label';

const useStyles = makeStyles(() => ({
    labelDescription: {
        color: '#EA2F2F',
        fontWeight: 'bold',
        fontSize: '24px',
        marginRight: '8px',
        display: 'flex',
        alignItems: 'center',
        height: '100%',
    },
    valueContainer: {
        backgroundColor: '#F5F5F5',
        border: '1px solid #D8D8D8',
        borderRadius: '4px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: 'bold',
        width: 'auto',
        padding: '0px 8px',
        height: '100%',
    },
    valueOverlayWrapper: {
        position: 'absolute',
        height: '100%',
        width: '100%',
        left: '0px',
        top: '0px',
    },
    EditlabelDescription: {
        color: '#EA2F2F',
        fontWeight: 'bold',
        fontSize: '24px',
        marginRight: '8px',
        display: 'flex',
        alignItems: 'center',
        height: '100%',
    },
    EditValueContainer: {
        backgroundColor: '#F5F5F5',
        border: '1px solid #D8D8D8',
        borderRadius: '4px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: 'bold',
        width: '39px',
        padding: '0px 8px',
        fontSize: '16px',
        boxSizing: 'content-box',
        height: '100%',
    },
    EditValueContainerSmall: {
        backgroundColor: '#F5F5F5',
        border: '1px solid #D8D8D8',
        borderRadius: '4px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: 'bold',
        width: '28px',
        padding: '0px 8px',
        fontSize: '16px',
        boxSizing: 'content-box',
        height: '100%',
    },
    CommentContainer: {
        backgroundColor: '#F5F5F5',
        border: '1px solid #D8D8D8',
        borderRadius: '4px',
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'left',
        height: '44px',
        fontWeight: 'bold',
        width: '100%',
        padding: '0px 8px',
    },
    validation: {
        marginTop: '10px',
        fontSize: '11px',
        color: 'red',
    },

    // Styles HelperLabel
    alignCenter: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '10px',
    },
    redLabel: {
        fontSize: '16px',
        fontWeight: 'bold',
        color: '#EA2F2F',
        marginRight: '8px',
    },
    description: {
        fontSize: '14px',
    },
    root: {
        display: 'flex',
        justifyContent: 'flex-end',
        backgroundColor: '#F5F5F5',
        padding: '12px',
    },
}));

const renderAnswer = (isSurveyEdit, record, id) => {
    if (!isSurveyEdit || !record.surveyAnswers) return '';

    let answers = record.surveyAnswers;
    let value;
    for (const answer of answers) {
        if (answer.surveyQuestionId === id) value = answer.value;
    }
    return value;
};

const HelperLabel = (props) => {
    const classes = useStyles();

    return (
        <div className={classes.alignCenter}>
            <div className={classes.redLabel}>{props.label}</div>
            <div className={classes.description}>{props.description}</div>
        </div>
    );
};

const SubmitSurveyButtons = (props) => {
    const { validation, error, submitting, handleCancel, handleSubmit } = props;
    return (
        <div>
            <Box marginTop="10px" fontSize="11px" color="red">
                {error === true && validation}
            </Box>
            <Box display="flex" columnGap="32px" flexGrow="1" flexDirection="row-reverse">
                <Button
                    onClick={() => handleSubmit()}
                    color="primary"
                    disabled={error || submitting}
                    style={{ fontSize: '16px', height: '48px' }}
                    startIcon={submitting && <CircularProgress size={20} />}
                >
                    Submit
                </Button>
                <Button
                    onClick={() => handleCancel()}
                    color="primary"
                    disabled={submitting}
                    style={{ fontSize: '16px', height: '48px' }}
                >
                    Cancel
                </Button>
            </Box>
        </div>
    );
};

const UpdateSurveyButtons = (props) => {
    const { setValue, validation, setConfirmOpen, handleCancel, error, comment } = props;
    return (
        <Box display="flex" flexDirection="column">
            <Label>Comment</Label>
            <TextField
                label=""
                value={comment}
                name="COMMENT"
                variant="outlined"
                onChange={(e) => {
                    setValue(e.target.name, e.target.value);
                }}
                error={error === true}
                helperText={error === true && validation}
            />
            <Box display="flex" flexDirection="row-reverse" columnGap="32px">
                <Button onClick={() => setConfirmOpen(true)} color="primary" disabled={error}>
                    Save
                </Button>
                <Button onClick={() => handleCancel()} color="primary">
                    Cancel
                </Button>
            </Box>
        </Box>
    );
};

// Helper container per siteTypeId
const ManholeHelperContainer = () => {
    const classes = useStyles();

    return (
        <Grid container spacing={0} classes={classes}>
            <Grid item xs={6} padding="16px 0px 0px 16px">
                <HelperLabel label="a" description="Lid diameter" />
                <HelperLabel label="c" description="Frame depth" />
                <HelperLabel label="e" description="Depth to highest benching point" />
            </Grid>
            <Grid item xs={6} padding="16px 0px 0px 16px">
                <HelperLabel label="b" description="Lid thickness - excluding ribs" />
                <HelperLabel label="d" description="Throat Depth" />
                <HelperLabel label="f" description="Depth to invert" />
            </Grid>
        </Grid>
    );
};
const GroundwaterHelperContainer = () => {
    const classes = useStyles();

    return (
        <Grid container spacing={0} classes={classes}>
            <Grid item xs padding="16px 0px 0px 16px">
                <HelperLabel label="a" description="Tube diameter" />
                <HelperLabel label="c" description="Tube length above ground" />
                <HelperLabel label="e" description="Sensor distance to tube bottom" />
            </Grid>
        </Grid>
    );
};
const WaterTankHelperContainer = () => {
    const classes = useStyles();

    return (
        <Grid container spacing={0} classes={classes}>
            <Grid item xs={6} padding="16px 0px 0px 16px">
                <HelperLabel label="a" description="Distance to sensor" />
                <HelperLabel label="b" description="Distance to max level" />
                <HelperLabel label="c" description="Zero mark" />
            </Grid>
            <Grid item xs={6}></Grid>
        </Grid>
    );
};

// Values per siteTypeId
const Value = (props) => {
    const classes = useStyles();

    return (
        <div
            style={{
                position: 'absolute',
                left: props.left,
                top: props.top,
                height: props.height ?? '44px',
            }}
        >
            <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                <div className={classes.labelDescription}>{props.label}</div>
                <div className={classes.valueContainer}>{props.value}</div>
            </div>
        </div>
    );
};
const ValuesManhole = (props) => {
    const classes = useStyles();
    const { man1, man2, man3, man4, man5, man6 } = props;
    return (
        <div className={classes.valueOverlayWrapper}>
            <Value label="a" value={man1} left="14%" />
            <Value label="b" value={man2} left="68%" top="10%" />
            <Value label="c" value={man3} left="-28px" top="10%" />
            <Value label="d" value={man4} left="30%" top="19%" />
            <Value label="e" value={man5} left="10%" top="50%" />
            <Value label="f" value={man6} left="55%" top="50%" />
        </div>
    );
};
const ValuesGroundwater = (props) => {
    const classes = useStyles();
    const { man1, man3, man5 } = props;
    return (
        <div className={classes.valueOverlayWrapper}>
            <Value label="a" value={man1} left="38%" />
            <Value label="c" value={man3} left="48px" top="13.5%" />
            <Value label="e" value={man5} left="15%" top="45%" />
        </div>
    );
};
const ValuesWaterTank = (props) => {
    const classes = useStyles();
    const { tank1, tank2, tank4 } = props;
    const t1 = tank1 !== null && tank1 != undefined ? tank1 : '';
    const t2 = tank2 !== null && tank2 != undefined ? tank2 : '';
    const t4 = tank4 !== null && tank4 != undefined ? tank4 : '';
    return (
        <div className={classes.valueOverlayWrapper}>
            <Value label="a" value={t1} left="3%" top="42%" height="25px" />
            <Value label="b" value={t2} left="27%" top="52%" height="25px" />
            <Value label="c" value={t4} left="49%" top="72%" height="25px" />
        </div>
    );
};

// Value edits per siteTypeId
const ValueEdit = ({ setValue, left, top, label, value, name, className, mask, height }) => {
    const classes = useStyles();

    return (
        <div style={{ position: 'absolute', left: left, top: top, height: height ?? '44px' }}>
            <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                <div className={classes.EditlabelDescription}>{label}</div>
                <InputMask
                    mask={mask}
                    maskPlaceholder={null}
                    className={className}
                    value={value.toString()}
                    name={name}
                    onChange={(e) => {
                        setValue(e.target.name, e.target.value);
                    }}
                />
            </div>
        </div>
    );
};
const ValueEditsManhole = (props) => {
    const { man1, man2, man3, man4, man5, man6, setValue } = props;
    const classes = useStyles();
    return (
        <div className={classes.valueOverlayWrapper}>
            <ValueEdit
                label="a"
                name="MAN1"
                value={man1}
                left={'14%'}
                setValue={setValue}
                top="0%"
                className={classes.EditValueContainerSmall}
                mask="999"
            />
            <ValueEdit
                label="b"
                name="MAN2"
                value={man2}
                left="68%"
                top="10%"
                setValue={setValue}
                className={classes.EditValueContainerSmall}
                mask="999"
            />
            <ValueEdit
                label="c"
                name="MAN3"
                value={man3}
                left={'-28px'}
                top={'10%'}
                setValue={setValue}
                className={classes.EditValueContainerSmall}
                mask="999"
            />
            <ValueEdit
                label="d"
                name="MAN4"
                value={man4}
                left="30%"
                top="19%"
                setValue={setValue}
                className={classes.EditValueContainerSmall}
                mask="999"
            />
            <ValueEdit
                label="e"
                name="MAN5"
                value={man5}
                left={'10%'}
                top={'50%'}
                setValue={setValue}
                className={classes.EditValueContainer}
                mask="9999"
            />
            <ValueEdit
                label="f"
                name="MAN6"
                value={man6}
                left="55%"
                top="50%"
                setValue={setValue}
                className={classes.EditValueContainer}
                mask="9999"
            />
        </div>
    );
};
const ValueEditsGroundwater = (props) => {
    const { man1, man3, man5, setValue } = props;
    const classes = useStyles();
    return (
        <div className={classes.valueOverlayWrapper}>
            <ValueEdit
                label="a"
                name="MAN1"
                value={man1}
                left={'38%'}
                setValue={setValue}
                top="0%"
                className={classes.EditValueContainerSmall}
                mask="999"
            />

            <ValueEdit
                label="c"
                name="MAN3"
                value={man3}
                left={'48px'}
                top={'13.5%'}
                setValue={setValue}
                className={classes.EditValueContainerSmall}
                mask="999"
            />

            <ValueEdit
                label="e"
                name="MAN5"
                value={man5}
                left={'15%'}
                top={'45%'}
                setValue={setValue}
                className={classes.EditValueContainer}
                mask="9999"
            />
        </div>
    );
};
const ValueEditsWaterTank = (props) => {
    const { tank1, tank2, tank4, setValue } = props;
    const classes = useStyles();

    return (
        <div className={classes.valueOverlayWrapper}>
            <ValueEdit
                label="a"
                name="TANK1"
                value={tank1}
                left="3%"
                top="42%"
                height="25px"
                setValue={setValue}
                className={classes.EditValueContainer}
                mask="9999"
            />

            <ValueEdit
                label="b"
                name="TANK2"
                value={tank2}
                left={'27%'}
                top={'52%'}
                height="25px"
                setValue={setValue}
                className={classes.EditValueContainer}
                mask="9999"
            />

            <ValueEdit
                label="c"
                name="TANK4"
                value={tank4}
                left={'49%'}
                top={'72%'}
                height="25px"
                setValue={setValue}
                className={classes.EditValueContainer}
                mask="9999"
            />
        </div>
    );
};

// Validate data per siteTypeId
const validateManholeData = (answerValues) => {
    const {
        man1: lidDiameter,
        man2: lidThickness,
        man3: frameDepth,
        man4: throatDepth,
        man5: depthToBenching,
        man6: depthToInvert,
    } = answerValues;
    if (!lidDiameter || lidDiameter <= 0) return 'a must be greater than 0';
    if (!lidThickness || lidThickness <= 0) return 'b must be greater than 0';
    if (!frameDepth || frameDepth <= 0) return 'c must be greater than 0';
    if (!throatDepth || throatDepth <= 0) return 'd must be greater than 0';
    if (!depthToBenching || depthToBenching <= 0) return 'e must be greater than 0';
    if (!depthToInvert || depthToInvert <= 0) return 'f must be greater than 0';
    if (depthToInvert < depthToBenching) return 'f must be greater than e';
    if (frameDepth < lidThickness) return 'c must be greater than b';
    return '';
};
const validateGroundwaterData = (answerValues) => {
    const {
        man1: tubeDiameter,
        man3: tubeLengthAboveGround,
        man5: distanceToBottom,
    } = answerValues;
    if (!tubeDiameter || tubeDiameter <= 0) return 'a must be greater than 0';
    if (!tubeLengthAboveGround || tubeLengthAboveGround <= 0) return 'c must be greater than 0';
    if (!distanceToBottom || distanceToBottom <= 0) return 'e must be greater than 0';
    if (distanceToBottom < tubeLengthAboveGround) return 'e must be greater than c';
    return '';
};
const validateWatertankData = (answerValues) => {
    const { tank1: distanceToSensor, tank2: distanceToMaxLevel, tank4: zeroMark } = answerValues;
    if (!distanceToSensor || distanceToSensor <= 0) return 'a must be greater than 0';
    if (!distanceToMaxLevel || distanceToMaxLevel <= 0) return 'b must be greater than 0';
    if (distanceToSensor < distanceToMaxLevel) return 'a must be greater than b';
    if (
        !zeroMark === null ||
        zeroMark === undefined ||
        zeroMark < 0 ||
        zeroMark > distanceToMaxLevel
    )
        return 'c must be between 0 and value of "b"';
    return '';
};

// Create survey per siteTypeId
const createSurveyManhole = (siteId, userName, userId, answerValues) => {
    const { man1, man2, man3, man4, man5, man6 } = answerValues;

    const survey = {
        siteId,
        surveyTypeId: 1,
        inspectorName: userName,
        inspectorGuid: userId,
        questions: {
            'Lid diameter': man1,
            'Lid thickness': man2,
            'Frame depth': man3,
            'Throat depth': man4,
            'Depth to highest benching point': man5,
            'Depth to invert': man6,
            'Is it hinged?': false,
            'Is the cover installed on a slope/angle or is it horizontal?': false,
            'Manufacturer (if available)': null,
            'Material (if available)': null,
            'Sigox Signal Strength': null,
            'Is Manual Signal Strength': null,
            'Signal Strength Device Id': null,
            'Signal Strength Device Last Comms': null,
        },
    };
    return survey;
};
const createSurveyGroundwater = (siteId, userName, userId, answerValues) => {
    const { man1, man3, man5 } = answerValues;
    const survey = {
        siteId,
        surveyTypeId: 1,
        inspectorName: userName,
        inspectorGuid: userId,
        questions: {
            'Lid diameter': man1,
            'Lid thickness': 0,
            'Frame depth': man3,
            'Throat depth': 0,
            'Depth to highest benching point': man5,
            'Depth to invert': man5,
            'Is it hinged?': false,
            'Is the cover installed on a slope/angle or is it horizontal?': false,
            'Manufacturer (if available)': null,
            'Material (if available)': null,
            'Sigox Signal Strength': null,
            'Is Manual Signal Strength': null,
            'Signal Strength Device Id': null,
            'Signal Strength Device Last Comms': null,
        },
    };
    return survey;
};
const createSurveyWatertank = (siteId, userName, userId, answerValues) => {
    const { tank1, tank2, tank4 } = answerValues;
    const survey = {
        siteId,
        surveyTypeId: 6,
        inspectorName: userName,
        inspectorGuid: userId,
        questions: {
            'Distance to sensor': tank1,
            'Distance to max level': tank2,
            'Zero mark': tank4,
            'Tank material': 0, // Plastic by default
        },
    };
    return survey;
};

// Create survey answers per siteTypeId
const createSurveyAnswersManhole = (surveyAnswers, answerValues) => {
    const newAnswers = [...surveyAnswers];
    const { man1, man2, man3, man4, man5, man6 } = answerValues;

    for (const answer of newAnswers) {
        if (answer.surveyQuestionId === 'MAN1') answer.value = man1;
        if (answer.surveyQuestionId === 'MAN2') answer.value = man2;
        if (answer.surveyQuestionId === 'MAN3') answer.value = man3;
        if (answer.surveyQuestionId === 'MAN4') answer.value = man4;
        if (answer.surveyQuestionId === 'MAN5') answer.value = man5;
        if (answer.surveyQuestionId === 'MAN6') answer.value = man6;
    }
    return newAnswers;
};
const createSurveyAnswersGroundwater = (surveyAnswers, answerValues) => {
    const newAnswers = [...surveyAnswers];
    const { man1, man2, man3, man4, man5 } = answerValues;

    for (const answer of newAnswers) {
        if (answer.surveyQuestionId === 'MAN1') answer.value = man1;
        if (answer.surveyQuestionId === 'MAN2') answer.value = man2;
        if (answer.surveyQuestionId === 'MAN3') answer.value = man3;
        if (answer.surveyQuestionId === 'MAN4') answer.value = man4;
        if (answer.surveyQuestionId === 'MAN5') answer.value = man5;
        if (answer.surveyQuestionId === 'MAN6') answer.value = man5; // Groundwater Sites (f is always equal to e)
    }
    return newAnswers;
};
const createSurveyAnswersWatertank = (surveyAnswers, answerValues) => {
    const newAnswers = [...surveyAnswers];
    const { tank1, tank2, tank4 } = answerValues;

    for (const answer of newAnswers) {
        if (answer.surveyQuestionId === 'TANK1') answer.value = tank1;
        if (answer.surveyQuestionId === 'TANK2') answer.value = tank2;
        if (answer.surveyQuestionId === 'TANK4') answer.value = tank4;
    }
    return newAnswers;
};

const siteTypes = {
    1: {
        validateData: validateManholeData,
        ValueEditsComponent: ValueEditsManhole,
        ValuesComponent: ValuesManhole,
        HelperContainer: ManholeHelperContainer,
        DiagramImage: <ManholeImage style={{ width: '100%', paddingTop: '48px' }} />,
        createSurvey: createSurveyManhole,
        createSurveyAnswers: createSurveyAnswersManhole,
    },
    2: {
        validateData: validateGroundwaterData,
        ValueEditsComponent: ValueEditsGroundwater,
        ValuesComponent: ValuesGroundwater,
        HelperContainer: GroundwaterHelperContainer,
        DiagramImage: <GroundwaterImage style={{ width: '100%', paddingTop: '48px' }} />,
        createSurvey: createSurveyGroundwater,
        createSurveyAnswers: createSurveyAnswersGroundwater,
    },
    3: {
        validateData: validateWatertankData,
        ValueEditsComponent: ValueEditsWaterTank,
        ValuesComponent: ValuesWaterTank,
        HelperContainer: WaterTankHelperContainer,
        DiagramImage: <TankImage style={{ width: '600px' }} />,
        createSurvey: createSurveyWatertank,
        createSurveyAnswers: createSurveyAnswersWatertank,
    },
    4: {
        validateData: validateGroundwaterData,
        ValueEditsComponent: ValueEditsGroundwater,
        ValuesComponent: ValuesGroundwater,
        HelperContainer: GroundwaterHelperContainer,
        DiagramImage: <GroundwaterImage style={{ width: '100%', paddingTop: '48px' }} />,
        createSurvey: createSurveyGroundwater,
        createSurveyAnswers: createSurveyAnswersGroundwater,
    },
};

const getSurveyAnswers = (siteTypeId, man1, man2, man3, man4, man5, man6, tank1, tank2, tank4) => {
    const intMan1 = parseInt(man1);
    const intMan2 = parseInt(man2);
    const intMan3 = parseInt(man3);
    const intMan4 = parseInt(man4);
    const intMan5 = parseInt(man5);
    const intMan6 = parseInt(man6);
    const intTank1 = parseInt(tank1);
    const intTank2 = parseInt(tank2);
    const intTank4 = parseInt(tank4);

    // Answers per siteTypeId
    const answers = {
        1: {
            man1: intMan1,
            man2: intMan2,
            man3: intMan3,
            man4: intMan4,
            man5: intMan5,
            man6: intMan6,
        },
        2: { man1: intMan1, man3: intMan3, man5: intMan5 },
        3: { tank1: intTank1, tank2: intTank2, tank4: intTank4 },
        4: { man1: intMan1, man3: intMan3, man5: intMan5 },
    };

    if (!answers[`${siteTypeId}`]) return null;

    return answers[`${siteTypeId}`];
};

const MeasurementDiagram = (props) => {
    const { isSurveyEdit, siteStatus, siteTypeId, permissions, userId, userName } = props;
    const [comment, setComment] = React.useState('');
    const [error, setError] = React.useState(false);
    const [validation, setValidation] = React.useState('');
    const [confirmOpen, setConfirmOpen] = React.useState(false);

    const [newSurvey, setNewSurvey] = React.useState(props.record);
    const [man1, setMan1] = React.useState(renderAnswer(isSurveyEdit, props.record, 'MAN1'));
    const [man2, setMan2] = React.useState(renderAnswer(isSurveyEdit, props.record, 'MAN2'));
    const [man3, setMan3] = React.useState(renderAnswer(isSurveyEdit, props.record, 'MAN3'));
    const [man4, setMan4] = React.useState(renderAnswer(isSurveyEdit, props.record, 'MAN4'));
    const [man5, setMan5] = React.useState(renderAnswer(isSurveyEdit, props.record, 'MAN5'));
    const [man6, setMan6] = React.useState(renderAnswer(isSurveyEdit, props.record, 'MAN6'));
    const [tank1, setTank1] = React.useState(renderAnswer(isSurveyEdit, props.record, 'TANK1'));
    const [tank2, setTank2] = React.useState(renderAnswer(isSurveyEdit, props.record, 'TANK2'));
    const [tank4, setTank4] = React.useState(renderAnswer(isSurveyEdit, props.record, 'TANK4'));
    const [loading, setLoading] = React.useState(false);
    const [submitting, setSubmitting] = React.useState(false);
    const notify = useNotify();
    const refresh = useRefresh();

    const isValidStatus =
        (isSurveyEdit && (siteStatus == 1 || siteStatus == 2 || siteStatus == 4)) ||
        (!isSurveyEdit && siteStatus == 0);

    const {
        validateData,
        ValueEditsComponent,
        ValuesComponent,
        HelperContainer,
        DiagramImage,
        createSurvey,
        createSurveyAnswers,
    } = siteTypes[`${siteTypeId}`];

    const surveyAnswers = getSurveyAnswers(
        siteTypeId,
        man1,
        man2,
        man3,
        man4,
        man5,
        man6,
        tank1,
        tank2,
        tank4,
    );

    const handleSubmit = async () => {
        if (submitting) return;

        const survey = createSurvey(props.record.id, userName, userId, surveyAnswers);
        setSubmitting(true);
        await updateSurveyData(survey);
        handleCancel();
        refresh();
        notify('Site survey submitted.', { type: 'success' });
    };

    const handleUpdate = async () => {
        const updatedSurvey = newSurvey;
        updatedSurvey.surveyAnswers = createSurveyAnswers(newSurvey.surveyAnswers, surveyAnswers);
        updatedSurvey.comment = comment;
        updatedSurvey.userId = userId;
        setNewSurvey(updatedSurvey);

        setLoading(true);
        await updateSurveyData(updatedSurvey);
        setLoading(false);
        handleCancel();
        setConfirmOpen(false);
    };

    const setValue = (q, value) => {
        if (q === 'MAN1') setMan1(value);
        if (q === 'MAN2') setMan2(value);
        if (q === 'MAN3') setMan3(value);
        if (q === 'MAN4') setMan4(value);
        if (q === 'MAN5') setMan5(value);
        if (q === 'MAN6') setMan6(value);
        if (q === 'TANK1') setTank1(value);
        if (q === 'TANK2') setTank2(value);
        if (q === 'TANK4') setTank4(value);
        if (q === 'COMMENT') {
            setComment(value);
        }
    };

    React.useEffect(() => {
        // Validate survey create/edit
        const validate = () => {
            let errorMessage = '';
            let error = false;

            // Validate per site type
            if (validateData && surveyAnswers !== null) {
                errorMessage = validateData(surveyAnswers);
            }

            // Validate comments are required for survey edit
            if (isSurveyEdit && errorMessage === '' && !comment?.length) {
                errorMessage = 'comments are required';
            }

            // Define error if error message is not empty
            error = !!errorMessage && errorMessage.length > 0;

            // create survey: Hide errorMessage if fields are empty
            const areNotNumbers = Object.values(surveyAnswers).every((n) => isNaN(n));
            if (!isSurveyEdit && (siteTypeId === 2 || siteTypeId === 4) && areNotNumbers === true) {
                errorMessage = '';
            }

            return {
                errorMessage,
                error,
            };
        };

        const { errorMessage, error } = validate();

        // Update state
        setValidation(errorMessage);
        setError(error);
    }, [siteTypeId, isSurveyEdit, comment, surveyAnswers, validateData]);

    const handleCancel = () => {
        if (isSurveyEdit) {
            props.setIsEditMode(false);
            setMan1(renderAnswer(isSurveyEdit, props.record, 'MAN1'));
            setMan2(renderAnswer(isSurveyEdit, props.record, 'MAN2'));
            setMan3(renderAnswer(isSurveyEdit, props.record, 'MAN3'));
            setMan4(renderAnswer(isSurveyEdit, props.record, 'MAN4'));
            setMan5(renderAnswer(isSurveyEdit, props.record, 'MAN5'));
            setMan6(renderAnswer(isSurveyEdit, props.record, 'MAN6'));
            setTank1(renderAnswer(isSurveyEdit, props.record, 'TANK1'));
            setTank2(renderAnswer(isSurveyEdit, props.record, 'TANK2'));
            setTank4(renderAnswer(isSurveyEdit, props.record, 'TANK4'));
            setComment('');
        } else {
            props.onClose();
        }
    };

    if (loading) return <Loading />;

    return (
        <Grid container spacing={0}>
            <Grid item xs={8}>
                <Box position="relative" maxWidth="366px">
                    {DiagramImage}
                    {!props.isEditMode && <ValuesComponent {...surveyAnswers} />}
                    {props.isEditMode &&
                        permissions.includes(GROUPS.HSW_ADMIN) &&
                        isValidStatus && (
                            <ValueEditsComponent setValue={setValue} {...surveyAnswers} />
                        )}
                </Box>
                <Confirm
                    isOpen={confirmOpen}
                    loading={false}
                    title="Update Survey"
                    content="Are you sure? Saving these data changes will reset water level alarms and affect calculations"
                    onConfirm={() => handleUpdate()}
                    onClose={() => setConfirmOpen(false)}
                />
            </Grid>

            <Grid
                item
                xs={4}
                display="flex"
                flexDirection="column"
                rowGap="50px"
                justifyContent="space-between"
            >
                <HelperContainer />
                {props.isEditMode &&
                    permissions.includes(GROUPS.HSW_ADMIN) &&
                    isValidStatus &&
                    isSurveyEdit && (
                        <UpdateSurveyButtons
                            setValue={setValue}
                            validation={validation}
                            setConfirmOpen={setConfirmOpen}
                            handleCancel={handleCancel}
                            error={error}
                            comment={comment}
                        />
                    )}
                {props.isEditMode &&
                    permissions.includes(GROUPS.HSW_ADMIN) &&
                    isValidStatus &&
                    !isSurveyEdit && (
                        <SubmitSurveyButtons
                            validation={validation}
                            error={error}
                            submitting={submitting}
                            handleCancel={handleCancel}
                            handleSubmit={handleSubmit}
                        />
                    )}
            </Grid>
        </Grid>
    );
};

export default MeasurementDiagram;
