const GROUPS = {
    HSW_ADMIN: 'hsw_admin',
    HSW_USER: 'hsw_user',
    CUSTOMER_ADMIN: 'hsw_customeradmin',
    CUSTOMER_USER: 'hsw_customer',
    SURVEYOR: 'hsw_surveyor',
    INSTALLER: 'hsw_installer',
    PORTALACCESS: 'hsw_portalaccess',
    ANALYTICS: 'hsw_analytics',
};

export default GROUPS;
