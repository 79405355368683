// Simple Moving Average (SMA)
export const calculateSMA = (data, period) => {
    let values = [];

    if (period <= 0) return values;

    for (let i = period - 1; i < data.length; i++) {
        const sum = data.slice(i - period + 1, i + 1).reduce((acc, v) => acc + v[1], 0);
        const y = Math.floor(sum / period);
        const elem = data.at(i);
        const x = elem[0];
        values.push([x, y]);
    }

    return values;
};
