import { calculateSMA } from './sma.js';

export const levelTrendAnalysis = (level, algorithm) => {
    if (algorithm.id === 'sma') {
        const values = calculateSMA(level, algorithm.param * 96);

        return {
            data: values,
        };
    }

    return null;
};
