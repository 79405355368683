import React from 'react';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import ROOT from '../../theme/root';
const useStyles = makeStyles(() => ({
    card: {
        boxSizing: 'border-box',
        height: '140px',
        border: '1px solid #D8D8D8',
        backgroundColor: '#FFFFFF',
        borderRadius: 4,
        flexGrow: 1,
        flexBasis: 0,
        flexDirection: 'column',
    },
    cardKey: {
        fontSize: '40px',
        fontWeight: 'bold',
        letterSpacing: '-0.96px',
        lineHeight: '53px',
    },
    cardLabel: {
        fontSize: '16px',
        fontWeight: 'bold',
        letterSpacing: '-0.38px',
        lineHeight: '21px',
        padding: '0px 0px 0px 40px',
    },
}));

const Card = ({ title, text, Icon = InfoIcon, colour = ROOT.COLORS.PRIMARY }) => {
    const classes = useStyles();
    return (
        <Box key={title} className={classes.card} display="flex">
            <Box p={2} display="flex" justifyContent="left" alignItems="center">
                <Box mr="5px" mt="6px">
                    <Icon color="primary" fontSize="large" />
                </Box>
                <Typography variant="body1" className={classes.cardKey} style={{ color: colour }}>
                    {text}
                </Typography>
            </Box>
            <Box p={2} pt={0} display="flex" justifyContent="left">
                <Box mr="5px" mt="6px">
                    {'   ' + '      '}
                </Box>
                <Typography variant="body1" className={classes.cardLabel}>
                    {title}
                </Typography>
            </Box>
        </Box>
    );
};

export default Card;
