import ShowSite from './Show';
import CreateSite from './Create';
import EditSite from './Edit';
import EditSiteRestricted from './EditRestricted';
import SiteList from './List';
import GROUPS from '../auth/groups';

const sites = (permissions) => {
    const site = {
        list: SiteList,
        show: ShowSite,
        create: null,
        edit: null,
    };

    if (!permissions) {
        return site;
    } else if (permissions.includes(GROUPS.HSW_ADMIN)) {
        site.create = CreateSite;
        site.edit = EditSite;
    } else if (
        permissions.includes(GROUPS.CUSTOMER_ADMIN) &&
        permissions.includes(GROUPS.ANALYTICS)
    ) {
        site.edit = EditSiteRestricted;
    }
    return site;
};

export default sites;
