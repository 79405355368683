import React, { useCallback, useState } from 'react';
import { Box, Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { addHours } from 'date-fns';
import Controls from '../components/Controls';
import useGpsDeviceData from './useGpsDeviceData';
import GpsLocations from '../../sites/raft/GpsLocations';
import { LqiDataContextProvider } from '../dataproviders/LqiDataContext';
import LinkQualityIndicator from '../charts/LinkQualityIndicator';

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: 15,
        justifyContent: 'stretch',
        position: 'relative',
        overflow: 'visible',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        columnGap: 5,
        justifyContent: 'stretch',
        position: 'relative',
        height: 490,
    },
    divider: {
        height: '100%',
        alignSelf: 'center',
    },
    lqiContainer: {
        flex: 1,
        padding: '16px',
    },
}));

const DevicesDataGps = ({ deviceId, createdAt }) => {
    const classes = useStyles();
    const [from, setFrom] = useState(addHours(new Date(), -24));
    const [to, setTo] = useState(new Date());

    const updateDates = useCallback(
        (fromDate, toDate) => {
            if (fromDate) setFrom(fromDate);
            if (toDate) setTo(toDate);
        },
        [setFrom, setTo],
    );

    const {
        data: gpsData,
        isLoading: isLoadingGps,
        error: gpsError,
    } = useGpsDeviceData(deviceId, from, to);

    return (
        <Box className={classes.container}>
            <Controls updateDates={updateDates} initialFrom={from} initialTo={to} />
            <Box className={classes.row}>
                <GpsLocations isLoading={isLoadingGps} error={gpsError} data={gpsData} />
                <Divider orientation="vertical" flexItem={true} className={classes.divider} />
                <Box className={classes.lqiContainer}>
                    <LqiDataContextProvider
                        deviceId={deviceId}
                        from={from}
                        to={to}
                        createdAt={createdAt}
                    >
                        <LinkQualityIndicator />
                    </LqiDataContextProvider>
                </Box>
            </Box>
            <Divider flexItem={true} className={classes.dividerHorizontal} />
        </Box>
    );
};

export default DevicesDataGps;
