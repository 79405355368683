import { stringify } from 'query-string';
import {
    fetchUtils,
    GET_LIST,
    GET_ONE,
    GET_MANY,
    GET_MANY_REFERENCE,
    CREATE,
    UPDATE,
    UPDATE_MANY,
    DELETE,
    DELETE_MANY,
} from 'react-admin';
import { getAccessToken } from '../auth/auth0Functions';

/**
 * Maps react-admin queries to a simple REST API
 *
 * GET_LIST     => GET http://my.api.url/posts?sort=['title','ASC']&range=[0, 24]
 * GET_ONE      => GET http://my.api.url/posts/123
 * GET_MANY     => GET http://my.api.url/posts?filter={ids:[123,456,789]}
 * UPDATE       => PUT http://my.api.url/posts/123
 * CREATE       => POST http://my.api.url/posts
 * DELETE       => DELETE http://my.api.url/posts/123
 */

/**
 * @param {string} type Request type, e.g GET_LIST
 * @param {string} resource Resource name, e.g. "posts"
 * @param {Object} payload Request parameters. Depends on the request type
 * @returns {Promise} the Promise for a data response
 */

const dataProvider = async (type, resource, params) => {
    const httpClient = fetchUtils.fetchJson;
    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    let url = '';
    const options = {};
    options.headers = new Headers();
    const token = await getAccessToken();

    options.headers.append('Authorization', token);
    options.headers.append('Accept', 'application/json');

    switch (type) {
        case GET_LIST: {
            const { page, perPage } = params.pagination;
            const { field, order } = params.sort;

            // filters with 'all' should act like null
            for (const key in params.filter) {
                if (Object.hasOwnProperty.call(params.filter, key)) {
                    const element = params.filter[`${key}`];
                    if (element === 'All') delete params.filter[`${key}`];
                }
            }

            const query = {
                order: order,
                sort: field,
                skip: (page - 1) * perPage,
                take: perPage,
                filter: JSON.stringify(params.filter),
            };
            url = `${apiUrl}/${resource}?${stringify(query)}`;
            options.method = 'GET';

            const response = await httpClient(url, options);
            let result = {
                data: response.json.data,
            };
            if (response.json.pageInfo && response.json.pageInfo.hasNextPage) {
                let pageInfo = response.json.pageInfo;
                pageInfo.hasPreviousPage = true;
                result.pageInfo = pageInfo;
            } else {
                const total = parseInt(response.headers.get('X-Total-Count'));
                result.total = total;
            }
            return result;
        }
        case GET_MANY: {
            if (params.ids.length > 1) {
                const query = {
                    filter: JSON.stringify({ id: params.ids }),
                };
                url = `${apiUrl}/${resource}?${stringify(query)}`;
                return httpClient(url, options).then((response) => ({
                    data: response.json.data,
                }));
            } else {
                url = `${apiUrl}/${resource}/${params.ids[0]}`;
                return httpClient(url, options).then((response) => ({
                    data: Array.isArray(response.json.data)
                        ? [response.json.data[0]]
                        : [response.json],
                }));
            }
        }
        case GET_ONE:
            url = `${apiUrl}/${resource}/${params.id}`;
            return httpClient(url, options).then((response) => {
                let data = response.json;

                // Workaround: /organisations/{id} returns a list instead of only one record
                if (data && Array.isArray(response.json.data)) {
                    data = response.json.data.find((record) => record.id === params.id) || null;
                }
                return {
                    data,
                };
            });

        case GET_MANY_REFERENCE: {
            const { page, perPage } = params.pagination;
            const { field, order } = params.sort;
            const query = {
                sort: JSON.stringify([field, order]),
                range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
                filter: JSON.stringify({
                    ...params.filter,
                    [params.target]: params.id,
                }),
            };
            url = `${apiUrl}/${resource}?${stringify(query)}`;
            return httpClient(url, options).then((response) => {
                return {
                    data: response.json.data,
                    total: parseInt(response.headers.get('X-Total-Count')),
                };
            });
        }
        case UPDATE:
            url = `${apiUrl}/${resource}`;
            options.method = 'PATCH';
            options.body = JSON.stringify(params.data);
            return httpClient(url, options).then((response) => ({
                data: response.json,
            }));
        case CREATE:
            url = `${apiUrl}/${resource}`;
            options.method = 'POST';
            options.body = JSON.stringify(params.data);
            return httpClient(url, options)
                .then((response) => {
                    return {
                        data: {
                            ...params.data,
                            ...response.json,
                            id: response.json?.id ?? '',
                        },
                    };
                })
                .catch((error) => {
                    throw new Error(
                        'Error ' + error && error.body ? JSON.stringify(error.body) : error.message,
                    );
                });
        case DELETE:
            url = `${apiUrl}/${resource}/${params.id}`;
            options.method = 'DELETE';
            return httpClient(url, options).then((response) => ({
                data: response.json || {},
            }));
        case UPDATE_MANY:
            options.method = 'POST';
            options.body = JSON.stringify(params.data);
            return Promise.all(
                params.ids.map((id) => httpClient(`${apiUrl}/${resource}/${id}`, options)),
            ).then((responses) => ({
                data: responses.map((response) => response.json) || [],
            }));
        case DELETE_MANY:
            options.method = 'DELETE';
            return Promise.all(
                params.ids.map((id) => httpClient(`${apiUrl}/${resource}/${id}`, options)),
            ).then((responses) => ({
                data: responses.map((response) => response.json) || [],
            }));
        default:
            return Promise.reject(new Error(`Unsupported fetch action type ${type}`));
    }
};

export default dataProvider;
