const defaultParameterGen3 = {
    1: {
        name: 'uplink-intval',
        id: 1,
        value: 15,
        unit: 'min',
        validate: function (value) {
            // 1 - 360
            return Number.isInteger(value) && value >= 1 && value <= 360;
        },
        hint: 'Value between 1 and 360',
    },
    2: {
        name: 'downlink-intval',
        id: 2,
        value: 1440,
        unit: 'min',
        validate: function (value, uplinkValue) {
            // 1 - 65535 and must be greater than or equal to uplink-intval
            const valid = Number.isInteger(value) && value >= 1 && value <= 65535;
            if (uplinkValue) {
                return valid && value >= uplinkValue;
            }
            return valid;
        },
        hint: 'Value between 1 and 65535',
    },
    3: {
        name: 'radar-monitoring-depth',
        id: 3,
        value: 6000,
        unit: 'mm',
        validate: function (value) {
            // 1-6000
            return Number.isInteger(value) && value >= 1 && value <= 6000;
        },
        hint: 'Value between 1 and 6000',
    },
    4: {
        name: 'radar-throat-depth',
        id: 4,
        value: 200,
        unit: 'mm',
        validate: function (value) {
            // 1-6000
            return Number.isInteger(value) && value >= 1 && value <= 6000;
        },
        hint: 'Value between 1 and 6000',
    },
    5: {
        name: 'acc-wakeup-threshold',
        id: 5,
        value: 500,
        unit: 'mg',
        validate: function (value) {
            // 1-1000
            return Number.isInteger(value) && value >= 1 && value <= 1000;
        },
        hint: 'Value between 1 and 1000',
    },
    6: {
        name: 'acc-tilt-degree',
        id: 6,
        value: 5,
        unit: '° (deg)',
        validate: function (value) {
            // 1-90
            return Number.isInteger(value) && value >= 1 && value <= 90;
        },
        hint: 'Value between 1 and 90',
    },
    7: {
        name: 'radar-amplitude-threshold',
        id: 7,
        value: 150,
        unit: 'amplitude',
        validate: function (value) {
            // 1-65535
            return Number.isInteger(value) && value >= 1 && value <= 65535;
        },
        hint: 'Value between 1 and 65535',
    },
    8: {
        name: 'radar-amplitude-close-water',
        id: 8,
        value: 1200,
        unit: 'amplitude',
        validate: function (value) {
            // 1-65535
            return Number.isInteger(value) && value >= 1 && value <= 65535;
        },
        hint: 'Value between 1 and 65535',
    },
    9: {
        name: 'radar-service-profile',
        id: 9,
        value: 2,
        unit: 'enum',
        validate: function (value) {
            // 1-5
            return Number.isInteger(value) && value >= 1 && value <= 5;
        },
        hint: 'Value between 1 and 5',
    },
    10: {
        name: 'num-of-radar-readings',
        id: 10,
        value: 1,
        unit: 'number',
        validate: function (value) {
            // 1-4
            return Number.isInteger(value) && value >= 1 && value <= 4;
        },
        hint: 'Value between 1 and 4',
    },
    11: {
        name: 'movement-check-acc-int',
        id: 11,
        value: 0,
        unit: 'boolean',
        validate: function (value) {
            // 0 or 1
            return value === 0 || value === 1;
        },
        hint: 'Value should be 0 or 1',
    },
    12: {
        name: 'movement-check-intval',
        id: 12,
        value: 15,
        unit: 'min',
        validate: function (value) {
            // 1-65535
            return Number.isInteger(value) && value >= 1 && value <= 65535;
        },
        hint: 'Value between 1 and 65535',
    },
    13: {
        name: 'movement-check-swap',
        id: 13,
        value: 0,
        unit: 'boolean',
        validate: function (value) {
            // 0 or 1
            return value === 0 || value === 1;
        },
        hint: 'Value should be 0 or 1',
    },
    14: {
        name: 'movement-check-disable',
        id: 14,
        value: 0,
        unit: 'boolean',
        validate: function (value) {
            // 0 or 1
            return value === 0 || value === 1;
        },
        hint: 'Value should be 0 or 1',
    },
    15: {
        name: 'movement-check-upon-data-transmission',
        id: 15,
        value: 1,
        unit: 'boolean',
        validate: function (value) {
            // 0 or 1
            return value === 0 || value === 1;
        },
        hint: 'Value should be 0 or 1',
    },
    16: {
        name: 'tilt-angles-transmission',
        id: 16,
        value: 1,
        unit: 'boolean',
        validate: function (value) {
            // 0 or 1
            return value === 0 || value === 1;
        },
        hint: 'Value should be 0 or 1',
    },
    17: {
        name: 'wakeup-stats-intval',
        id: 17,
        value: 1440,
        unit: 'min',
        validate: function (value) {
            // 1-65535
            return Number.isInteger(value) && value >= 1 && value <= 65535;
        },
        hint: 'Value between 1 and 65535',
    },
    18: {
        name: 'power-off',
        id: 18,
        value: 0,
        unit: 'boolean',
        validate: function (value) {
            // 0 or 1
            return value === 0 || value === 1;
        },
        hint: 'Value should be 0 or 1.***WARNING*** You need to manually turn on the device after this command is processed',
    },
    19: {
        name: 'acc-z-tilt-degree',
        id: 19,
        value: 30,
        unit: '° (deg)',
        validate: function (value) {
            // 30-90
            return Number.isInteger(value) && value >= 30 && value <= 90;
        },
        hint: 'Value between 30 and 90',
    },
    20: {
        name: 'acc-sleep-power-mode',
        id: 20,
        value: 5,
        unit: 'Hz',
        validate: function (value) {
            // 1, 2, 5 or 10
            return [1, 2, 5, 10].includes(value);
        },
        hint: 'Value should be 1, 2, 5 or 10',
    },
    21: {
        name: 'bth-intval',
        id: 21,
        value: 360,
        unit: 'min',
        validate: function (value) {
            // 1-65535
            return Number.isInteger(value) && value >= 1 && value <= 65535;
        },
        hint: 'Value between 1 and 65535',
    },
    22: {
        name: 'battery-low-threshold',
        id: 22,
        value: 3000,
        unit: 'mV',
        validate: function (value) {
            // 1-65535
            return Number.isInteger(value) && value >= 1 && value <= 65535;
        },
        hint: 'Value between 1 and 65535',
    },
    23: {
        name: 'radar-reading-compensation',
        id: 23,
        value: 10,
        unit: 'mm',
        validate: function (value) {
            // 1-6000
            return Number.isInteger(value) && value >= 1 && value <= 6000;
        },
        hint: 'Value between 1 and 6000',
    },
    24: {
        name: 'radar-sort-setting',
        id: 24,
        value: 3,
        unit: 'enum',
        validate: function (value) {
            return [0, 1, 2, 3].includes(value);
        },
        hint: 'Value should be 0, 1, 2 or 3',
    },
    25: {
        name: 'apply-default-settings',
        id: 25,
        value: 0,
        unit: 'boolean',
        validate: function (value) {
            // 0 or 1
            return value === 0 || value === 1;
        },
        hint: 'Value should be 0 or 1',
    },
    26: {
        name: 'downlink-cmd-oob-message-enabled',
        id: 26,
        value: 0,
        unit: 'boolean',
        validate: function (value) {
            // 0 or 1
            return value === 0 || value === 1;
        },
        hint: 'Value should be 0 or 1',
    },
    27: {
        name: 'downlink-cmd-test-mode',
        id: 27,
        value: 0,
        unit: 'enum',
        validate: function (value) {
            // 0-3
            return Number.isInteger(value) && value >= 0 && value <= 3;
        },
        hint: 'Value should be 0, 1, 2 or 3',
    },
};

export default defaultParameterGen3;
