import React from 'react';
import { useRecordContext } from 'react-admin';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    jsonField: {
        fontFamily: 'inherit',
        fontSize: '0.8rem',
        border: '1px solid rgba(0, 0, 0, 0.26)',
        borderRadius: '4px',
        color: 'rgba(0, 0, 0, 0.38)',
        minHeight: '45px',
    },
}));

const JSONField = ({ source, ...props }) => {
    const classes = useStyles();

    const record = useRecordContext();
    let value = (record[`${source}`] && JSON.stringify(record[`${source}`], null, 2)) || '';

    return (
        <pre {...props} className={classes.jsonField}>
            {value}
        </pre>
    );
};

export default JSONField;
