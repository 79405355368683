import React, { useEffect, useState } from 'react';
import { useLevelDataContext } from '../dataproviders';
import LineChart, { defaultChartOptions, defaultSeries } from './LineChart';

const createSeries = (data, name, property) => {
    const series = {
        ...defaultSeries,
        name,
        data: (data && data[`${property}`]) || [],
    };
    return series;
};

const chartOptions = {
    title: {
        ...defaultChartOptions.title,
        text: 'Level benching and invert',
    },
    yAxis: [
        {
            ...defaultChartOptions.yAxis[0],
            title: {
                text: 'mm',
            },
        },
    ],
};

const Level = () => {
    const { level, from, to } = useLevelDataContext();
    const [series, setSeries] = useState();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(!!level.loading);
        if (level.loading == true) return;
        const benching = createSeries(level.data, 'Benching', 'level');
        const invert = createSeries(level.data, 'Invert', 'level2');
        setSeries([benching, invert]);
    }, [level]);

    return (
        <LineChart
            chartOptions={chartOptions}
            series={series}
            loading={isLoading}
            error={level.error?.message}
            min={from}
            max={to}
        />
    );
};

export default Level;
