import React from 'react';
import { Box, Typography, LinearProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import InfoIcon from '@mui/icons-material/Info';
import ROOT from '../../../theme/root';

const useStyles = makeStyles(() => ({
    card: {
        boxSizing: 'border-box',
        height: '140px',
        backgroundColor: '#FFFFFF',
        borderRadius: 4,
        flexDirection: 'column',
        minWidth: '175px',
        flexGrow: 1,
    },
    cardKey: {
        fontSize: '35px',
        letterSpacing: '-0.96px',
        lineHeight: '53px',
    },
    cardLabel: {
        fontSize: '16px',
        letterSpacing: '-0.38px',
        lineHeight: '21px',
        padding: '0px 0px 0px 40px',
    },
    loadingContainer: {
        alignSelf: 'center',
        width: '30%',
    },
    middleLine: {
        borderRight: ROOT.BORDERS.BORDER,
        position: 'relative',
        height: '80%',
        top: '10%',
    },
}));

const Card = (props) => {
    const { title, color, Icon = InfoIcon, value, addBorder, isLoading } = props;
    const classes = useStyles();
    return (
        <>
            {addBorder && <div className={classes.middleLine} id="analytics-card"></div>}
            <Box key={title} className={classes.card} display="flex">
                <Box p={2} display="flex" justifyContent={'flex-start'}>
                    <Box mr="30px" mt="6px" ml="0px">
                        <Icon />
                    </Box>
                    {isLoading && (
                        <Box className={classes.loadingContainer}>
                            <LinearProgress />
                        </Box>
                    )}
                    {!isLoading && (
                        <Typography
                            variant="h4"
                            className={classes.cardKey}
                            style={{ color: color }}
                        >
                            {value}
                        </Typography>
                    )}
                </Box>
                <Box p={2} pt={0} display="flex" justifyContent={'flex-start'}>
                    <Box mr="25px" mt="6px">
                        {'   ' + '      '}
                    </Box>
                    <Typography variant="body1" className={classes.cardLabel}>
                        {title}
                    </Typography>
                </Box>
            </Box>
        </>
    );
};

export default Card;
