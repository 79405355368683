function validateEmail(email) {
    /* eslint-disable */
    const regexp =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line no-useless-escape
    return regexp.test(email);
}

function validateText(value) {
    if (!value) return true;

    /* eslint-disable */
    const RGEX = /^[a-zA-Z\u00C0-\u024F\u1E00-\u1EFF0-9s(\r\n|\r|\n|\s)._~()'!*:@,;?-]*$/;
    const Result = RGEX.test(value);

    if (!Result) {
        return false;
    } else {
        return true;
    }
}

export { validateEmail, validateText};
