import React from 'react';
import MeasurementDiagram from './MeasurementDiagram';
import { makeStyles } from '@mui/styles';
import { Box, Button, Typography } from '@mui/material';
import GROUPS from '../../auth/groups';

const useStyles = makeStyles(() => ({
    container: {
        backgroundColor: 'white',
        padding: '24px',
    },
    buttonRight: {
        display: 'flex',
        float: 'right',
    },
}));

const MeasurementSection = (props) => {
    const { siteTypeId } = props;
    const classes = useStyles();
    const [siteStatus] = React.useState(props.siteStatus);
    const displayEditButton =
        !props.isEditMode &&
        props.permissions.includes(GROUPS.HSW_ADMIN) &&
        (siteStatus == 1 || siteStatus == 2 || siteStatus == 4);

    return (
        <div className={classes.container}>
            <Box display="flex" justifyContent="space-between">
                <Typography variant="h6" gutterBottom>
                    {siteTypeId === 1 && 'Manhole measurements'}
                    {siteTypeId === 2 && 'Raingarden measurements'}
                    {siteTypeId === 3 && 'Water tank measurements'}
                    {siteTypeId === 4 && 'Groundwater measurements'}
                </Typography>
                {displayEditButton && (
                    <Button
                        onClick={() => props.setIsEditMode(true)}
                        color="primary"
                        className={classes.buttonRight}
                    >
                        Edit
                    </Button>
                )}
            </Box>

            <MeasurementDiagram
                record={props.record}
                permissions={props.permissions}
                userId={props.userId}
                setIsEditMode={props.setIsEditMode}
                isEditMode={props.isEditMode}
                isSurveyEdit={true}
                siteStatus={siteStatus}
                siteTypeId={siteTypeId}
            />
        </div>
    );
};

export default MeasurementSection;
