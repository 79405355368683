import React from 'react';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { usePermissions, useGetList } from 'react-admin';
import { useAppContext } from '../context/AppContext';
import DefaultIcon from '@mui/icons-material/ViewList';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SelectorDialog from './SelectorDialog';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: '90%',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    customerContainer: {
        padding: '16px',
    },
    customerSelector: {
        height: '48px',
        backgroundColor: '#363637',
        color: '#fff',
        borderRadius: '4px',
        padding: '0 8px 0 8px',
    },
    customerDisplay: {
        maxWidth: '140px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
}));

const OrganisationSelector = (props) => {
    const classes = useStyles();
    const { isLoading: permissionLoading } = usePermissions();
    const {
        data: organisations,
        refetch,
        isLoading: loadingOrg,
    } = useGetList('organisations', {
        pagination: { page: 1, perPage: 100 },
        sort: { field: 'name', order: 'ASC' },
    });
    const [appStore] = useAppContext();
    const [showDialog, setShowDialog] = React.useState(false);
    const [selectedCustomer, setSelectedCustomer] = React.useState();

    const { canEdit } = props;

    React.useEffect(() => {
        if (!loadingOrg) return;
        refetch();
    }, [appStore, loadingOrg, refetch]);

    React.useEffect(() => {
        if (!organisations || !appStore || !appStore.organisationId) return;
        const org = organisations.find((org) => org.id === appStore.organisationId);
        setSelectedCustomer(org ? org : { id: '-', name: 'All' });
    }, [appStore, organisations]);
    const selector = React.useMemo(() => {
        if (organisations && organisations.length > 0) {
            return (
                <SelectorDialog
                    isOpen={showDialog}
                    onClose={() => setShowDialog(false)}
                    customersHash={organisations ?? []}
                />
            );
        }
        return <></>;
    }, [organisations, showDialog]);

    if (permissionLoading || !organisations) return null;

    return (
        <div style={{ marginBottom: '16px' }}>
            {selector}
            {organisations && (
                <Box
                    display="flex"
                    flexDirection="column"
                    className={classes.customerContainer}
                    style={{
                        padding: props.open ? '16px' : '0px',
                        cursor: canEdit ? 'pointer' : 'inherit',
                    }}
                    onClick={() => setShowDialog(canEdit)}
                >
                    <Box
                        display="flex"
                        justifyContent={props.open ? 'space-between' : 'center'}
                        alignItems="center"
                        className={classes.customerSelector}
                    >
                        <DefaultIcon style={{ fill: '#00AEEF' }} />
                        {props.open && (
                            <Box
                                display="flex"
                                width="100%"
                                alignItems="center"
                                ml="5px"
                                justifyContent="space-between"
                            >
                                <span className={classes.customerDisplay}>
                                    {!!selectedCustomer && selectedCustomer.name}
                                </span>
                                {canEdit && <ExpandMoreIcon />}
                            </Box>
                        )}
                    </Box>
                </Box>
            )}
        </div>
    );
};

export default OrganisationSelector;
