export const networkTypes = [{ id: 1, name: 'Sigfox' }];

export const applicationTypes = [
    { id: 1, name: 'Stormwater' },
    { id: 2, name: 'Wastewater' },
    { id: 3, name: 'Stream' },
    { id: 4, name: 'Lake' },
    { id: 5, name: 'Water supply' },
];

export const applicationTypesRaft = applicationTypes.filter((at) => at.id === 3 || at.id === 4);
export const applicationTypesGroundwater = applicationTypes.filter((at) => at.id === 1);
export const applicationTypesMh = applicationTypes.filter((at) => at.id === 1 || at.id === 2);
export const applicationTypesTank = applicationTypes.filter((at) => at.id === 5);

export const siteTypes = [
    { id: 1, name: 'Manhole' },
    { id: 2, name: 'Raingarden' },
    { id: 3, name: 'Water tank' },
    { id: 4, name: 'Groundwater' },
    { id: 5, name: 'Raft' },
];

export const getSiteType = (siteTypeId) => {
    const st = siteTypes.filter((siteType) => siteType.id === siteTypeId);
    return st.length > 0 ? st[0].name : '';
};

export const getApplicationType = (applicationTypeId) => {
    const at = applicationTypes.filter(
        (applicationType) => applicationType.id === applicationTypeId,
    );
    return at.length > 0 ? at[0].name : '';
};

export const surveyApprovalTypes = [
    { id: 'not-specified', name: 'Not Specified' },
    { id: false, name: 'False' },
    { id: true, name: 'True' },
];
