import React, { useMemo } from 'react';
import { useRedirect } from 'react-admin';
import { useAppContext } from '../context/AppContext';
import SearchIcon from '@mui/icons-material/Search';
import {
    Box,
    Button,
    TextField,
    List,
    ListItem,
    ListItemText,
    InputAdornment,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import useSessionStorage from '../hooks/useSessionStorage';

const useStyles = makeStyles(() => ({
    dialog: {
        minHeight: '60vh',
        maxHeight: '60vh',
    },
}));

const SelectorDialog = ({ isOpen, onClose, customersHash }) => {
    const classes = useStyles();
    const redirect = useRedirect();
    const [appStore, setAppStore] = useAppContext();
    const [open, setOpen] = React.useState(isOpen ?? false);

    const customers = useMemo(() => {
        let customersArray = [...customersHash];
        if (customersArray[0] && customersArray[0].id !== '-') {
            customersArray = [{ id: '-', name: 'All' }, ...customersArray];
        }
        return customersArray;
    }, [customersHash]);

    const [filteredCustomers, setFilteredCustomers] = React.useState(customers ?? []);
    const [filter, setFilter] = React.useState('');
    // eslint-disable-next-line no-unused-vars
    const [refreshDashboardMap, setRefreshDashboardMap] = useSessionStorage('refreshDashboardMap');

    React.useEffect(() => setOpen(isOpen), [isOpen]);
    const handleClose = () => {
        setOpen(false);
        setFilter('');
        if (onClose) onClose();
    };

    React.useEffect(() => {
        if (filter) {
            let results = customers.filter(
                (c) => c.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0,
            );
            setFilteredCustomers(results);
        }
        return () => {
            setFilteredCustomers(customers ?? []);
        };
    }, [filter, customers]);

    const [selectedOrg, setSelectedOrg] = React.useState(
        appStore.organisationId !== '-'
            ? filteredCustomers.find((x) => x.id === appStore.organisationId) ?? undefined
            : undefined,
    );
    const handleSelectCustomer = (customer) => {
        setSelectedOrg(customer);
        const orgId = customer?.id ?? '-';
        setAppStore({ organisationId: orgId });
        if (appStore.organisationId !== orgId) {
            window.localStorage.setItem(
                'location',
                JSON.stringify({ center: undefined, zoom: 10 }),
            );
        }

        setRefreshDashboardMap({
            forceRefresh: true,
        });
        redirect('/');
        if (handleClose) handleClose();
    };
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="customer-selector"
            maxWidth="sm"
            fullWidth
            classes={{ paper: classes.dialog }}
            scroll="paper"
        >
            <DialogTitle id="customer-selector">Select a customer</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Search"
                    type="text"
                    placeholder="Search"
                    variant="outlined"
                    onChange={(event) => setFilter(event.target.value)}
                    onKeyDown={(e) => {
                        e.stopPropagation();
                    }}
                    value={filter}
                    fullWidth
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
                {filteredCustomers && filteredCustomers.length > 0 ? (
                    <List component="nav">
                        {filteredCustomers.map((customer) => (
                            <ListItem
                                button
                                key={customer.id}
                                selected={!!selectedOrg && selectedOrg.id === customer.id}
                                onClick={() => {
                                    setSelectedOrg(customer);
                                    setFilter(customer.name);
                                }}
                            >
                                <ListItemText primary={customer.name} />
                            </ListItem>
                        ))}
                    </List>
                ) : (
                    <Box display="flex" justifyContent="center" mt="30px">
                        <span>No customers match your search.</span>
                    </Box>
                )}
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button
                    onClick={() => handleSelectCustomer(selectedOrg)}
                    disabled={!selectedOrg}
                    color="primary"
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default SelectorDialog;
