import React, { useEffect } from 'react';
import {
    Edit,
    SimpleForm,
    TextInput,
    ReferenceInput,
    SelectInput,
    required,
    minLength,
    maxLength,
    useEditController,
    Toolbar,
    SaveButton,
    useNotify,
    useRefresh,
    useRedirect,
    useDelete,
    Confirm,
    usePermissions,
    useEditContext,
} from 'react-admin';
import { useAppContext } from '../context/AppContext';
import { Box, Button, Typography, LinearProgress, Grid } from '@mui/material';
import Breadcrumb from '../menu/Breadcrumb';
import Layout from '../components/Layout';
import { makeStyles } from '@mui/styles';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import GROUPS from '../auth/groups';
import { validText } from './utils';
import {
    siteTypes,
    networkTypes,
    applicationTypesMh,
    applicationTypesGroundwater,
    applicationTypesRaft,
    surveyApprovalTypes,
} from './components/SiteTypes';

const useStyles = makeStyles(() => ({
    header: {
        marginTop: '27px',
        marginBottom: '30px',
        color: '#0B0B0D',
        fontSize: '32px',
        fontWeight: 'bold',
        letterSpacing: '-0.77px',
        lineHeight: '43px',
    },
    formInput: {
        width: '256px',
    },
}));

const validate = (values) => {
    const errors = {};
    if (!values.name) errors.name = 'The site name is required between 3 and 200 characters.';
    return errors;
};

const DeleteButton = () => {
    const { record: site } = useEditContext();
    const device = site.devices.length > 0 ? site.devices[0] : undefined;
    const notify = useNotify();
    const redirect = useRedirect();
    const refresh = useRefresh();
    const [openConfirm, setOpenConfirm] = React.useState(false);
    const [deleteOne, { data: deleted, isLoading: loading, error }] = useDelete('devices', {
        id: device.id,
        previousData: device,
    });
    const handleDelete = async (event) => {
        event.preventDefault();
        deleteOne();
    };
    React.useEffect(() => {
        if (error) {
            notify('Device could not be unlinked.', { type: 'warning' });
        }
        if (!deleted) return;
        const response = deleted;
        if (response.errors) {
            notify(response.errors.join(' '), { type: 'warning' });
            return;
        }
        notify('Device unlinked.', { type: 'success', messageArgs: { smart_count: 1 } });
        redirect('list', 'sites');
        setTimeout(refresh, 500);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleted, error]);

    if (error) return <p>Could not unlink device.</p>;
    return (
        <>
            <Button
                disabled={loading}
                onClick={() => setOpenConfirm(true)}
                startIcon={<LinkOffIcon />}
                color="primary"
                style={{ color: '#f44336' }}
            >
                Unlink Device
            </Button>

            <Confirm
                isOpen={openConfirm}
                loading={loading}
                title="Unlink device"
                content="Are you sure you want to unlink this device?"
                onConfirm={(evnt) => handleDelete(evnt)}
                onClose={() => setOpenConfirm(false)}
            />
        </>
    );
};

const UserEditToolbar = () => {
    const { record: site } = useEditContext();
    return (
        <Toolbar>
            <Box display="flex" justifyContent="space-between" width="100%">
                <SaveButton />
                {site.devices && site.devices.length > 0 && <DeleteButton />}
            </Box>
        </Toolbar>
    );
};

const EditSite = () => {
    const classes = useStyles();
    const controller = useEditController();
    const [appStore] = useAppContext();
    const redirect = useRedirect();
    const { permissions } = usePermissions();
    const isHyndsAdmin = permissions && permissions.includes(GROUPS.HSW_ADMIN);
    useEffect(() => {
        if (!isHyndsAdmin) {
            redirect('/');
        }
    }, [redirect, isHyndsAdmin]);

    if (!controller.record) return <LinearProgress />;
    const overrides = {};
    overrides[controller.record.id] = controller.record.name;

    const isManhole = controller?.record?.siteTypeId === 1;
    const isRaftSensor = controller?.record?.siteTypeId === 5;
    const isGroundwater = [2, 4].indexOf(controller?.record?.siteTypeId) >= 0;

    let appTypes = [];
    if (isManhole) appTypes = applicationTypesMh;
    else if (isGroundwater) appTypes = applicationTypesGroundwater;
    else if (isRaftSensor) appTypes = applicationTypesRaft;

    return (
        <Layout>
            <Breadcrumb overrides={overrides} />
            <Typography variant="h5" gutterBottom className={classes.header}>
                Edit Site
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6}>
                    <Edit actions={<></>} redirect="show">
                        <SimpleForm toolbar={<UserEditToolbar />} validate={validate}>
                            <ReferenceInput
                                source="projectId"
                                reference="projects"
                                isRequired={true}
                                loading
                                filter={{ organisationId: appStore.organisationId }}
                            >
                                <SelectInput
                                    optionText="name"
                                    value="id"
                                    label="Project"
                                    validate={[required()]}
                                    variant="outlined"
                                    className={classes.formInput}
                                />
                            </ReferenceInput>
                            <TextInput
                                source="name"
                                label="Site Name"
                                variant="outlined"
                                validate={[required(), minLength(2), maxLength(50), validText()]}
                                className={classes.formInput}
                            />
                            <SelectInput
                                source="networkTypeId"
                                choices={networkTypes}
                                variant="outlined"
                                validate={[required()]}
                                className={classes.formInput}
                            />
                            <SelectInput
                                source="siteTypeId"
                                choices={siteTypes}
                                variant="outlined"
                                disabled
                                className={classes.formInput}
                            />
                            <SelectInput
                                source="applicationTypeId"
                                choices={appTypes}
                                variant="outlined"
                                validate={[required()]}
                                className={classes.formInput}
                            />
                            <SelectInput
                                source="automaticSurveyApproval"
                                label="Automatic Survey Approval"
                                choices={surveyApprovalTypes}
                                format={(value) => (value === null ? 'not-specified' : value)}
                                parse={(value) => (value === 'not-specified' ? null : value)}
                                variant="outlined"
                                validate={[required()]}
                                className={classes.formInput}
                            />
                            {!isRaftSensor && (
                                <ReferenceInput
                                    reference="rainfallSites"
                                    source="rainfallSiteId"
                                    perPage={1000}
                                    sort={{ field: 'name', order: 'ASC' }}
                                    queryOptions={{
                                        enabled: isHyndsAdmin,
                                    }}
                                >
                                    <SelectInput
                                        label="Rainfall Site"
                                        value="id"
                                        optionText="name"
                                        fullWidth
                                        variant="outlined"
                                    />
                                </ReferenceInput>
                            )}
                        </SimpleForm>
                    </Edit>
                </Grid>
            </Grid>
        </Layout>
    );
};

export default EditSite;
