import React from 'react';
import { Box, Switch, Tooltip } from '@mui/material';
import Label from '../../components/Label';

const MeasureThreshold = ({ checked, onChange, showMode }) => {
    const [check, setCheck] = React.useState(false);
    React.useEffect(() => {
        setCheck((prevCheck) => (checked !== prevCheck ? checked : prevCheck));
    }, [checked]);
    const handleChange = (event, checkedValue) => {
        setCheck(checkedValue);
        if (onChange) onChange(checkedValue);
    };
    return (
        <Box display="flex" justifyContent="space-between" alignItems="center" pt="10px" pb="10px">
            <Label sub>measureFromInvert</Label>

            <Tooltip title="measure from invert or above benching." arrow>
                <Switch
                    checked={check}
                    onChange={handleChange}
                    name="measureFromInvert"
                    disabled={showMode}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                />
            </Tooltip>
        </Box>
    );
};

export default MeasureThreshold;
