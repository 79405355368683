import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Card,
    LinearProgress,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useShowContext, useGetManyReference } from 'react-admin';
import Error from '../../components/Error';

const useStyles = makeStyles({
    table: {
        maxHeight: '65vh',
        overflowY: 'scroll',
    },
});

/**
 * Fetches device parameters from API using deviceId and display results in the screen.
 * No pagination or sorting
 */
const DeviceParameters = () => {
    // Hooks
    const classes = useStyles();
    const { record: device, isLoading: loading } = useShowContext();
    const {
        data,
        isLoading: loadingParams,
        error,
    } = useGetManyReference('deviceParameters', {
        target: 'device_id',
        id: device && device.deviceId,
        pagination: { page: 1, perPage: 25 },
        sort: { field: 'id', order: 'DESC' },
    });

    // Loading & Error
    if (loading || loadingParams) {
        return (
            <Box>
                <LinearProgress />
            </Box>
        );
    }
    if ((!loading && !device) || error) {
        return <Error message="Couldn't load Device Parameters" icon={true} />;
    }

    return (
        <Box>
            <Card>
                <TableContainer component={Paper} className={classes.table}>
                    <Table stickyHeader aria-label="device parameters">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center" size="small">
                                    Command ID
                                </TableCell>
                                <TableCell align="left" size="small">
                                    Parameter
                                </TableCell>
                                <TableCell align="center" size="small">
                                    Value
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data &&
                                data.map((record) => (
                                    <TableRow key={record.id} hover={true}>
                                        <TableCell align="center" component="th" scope="id">
                                            {record.id}
                                        </TableCell>
                                        <TableCell align="left">
                                            {record.name} ({record.unit})
                                        </TableCell>
                                        <TableCell align="center">{record.value}</TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Card>
        </Box>
    );
};

export default DeviceParameters;
