import React, { useState } from 'react';
import { Box, Typography, Switch, Button, LinearProgress } from '@mui/material';
import { useNotify } from 'ra-core';
import RemovableItemList from '../components/RemovableItemList';
import { makeStyles } from '@mui/styles';
import AlarmRecipientDialog from './AlarmRecipientDialog';
import useFetch from '../hooks/useFetch';
import { setRulesActions } from './notifications';
import { ruleActionStatus, ruleActionTypes } from './rules';
import { useDelete, useRefresh } from 'react-admin';

const useStyles = makeStyles(() => ({
    title: {
        marginBottom: '32px',
    },
    sub: {
        fontWeight: 'bold',
        fontSize: '14px',
    },
    link: {
        fontWeight: 'bold',
        fontSize: '14px',
        textDecoration: 'underline',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    count: {
        fontSize: '14px',
        color: '#949495',
    },
    wideButton: {
        position: 'initial',
        display: 'initial',
    },
    inactive: {
        paddingBottom: '70px',
    },
}));

const AlarmNotifications = ({
    id,
    siteStatus = 0,
    hierarchy = 'project',
    permissions,
    refreshData,
}) => {
    const notify = useNotify();
    const classes = useStyles();
    const { response, error, isLoading } = useFetch(
        `${process.env.REACT_APP_API_BASE_URL}/ruleActions/${hierarchy}/${id}`,
        {
            method: 'GET',
        },
    );
    const [isEditMode, setIsEditMode] = useState(false);
    const [openEmail, setOpenEmail] = useState(false);
    const [openMobile, setOpenMobile] = useState(false);
    const [originalEmails, setOriginalEmails] = useState([]);
    const [originalMobiles, setOriginalMobiles] = useState([]);
    const [emails, setEmails] = useState([]);
    const [mobiles, setMobiles] = useState([]);
    const [emailActive, setEmailActive] = React.useState(true);
    const [smsActive, setSmsActive] = React.useState(true);
    const setDataChanged = React.useState(true)[1];
    const canEdit = (permissions && permissions.includes('hsw_admin')) === true ? true : false;
    const [deleteOne, { data: deleted, error: deleteError }] = useDelete();
    const refresh = useRefresh();
    React.useEffect(() => {
        if (!response || response === null) return;
        let isActiveEmail = true;
        let isActiveSms = true;

        const emails = [];
        const sms = [];
        const filteredNotifications = response.filter((r) => r.siteId);
        for (const notification of response) {
            const note = {
                details: notification.details,
                canEdit: hierarchy === 'project' || notification.projectId === null,
            };
            if (notification.action.name === ruleActionTypes.email) {
                let itemExcist = emails.filter((email) => email.details === note.details);
                if (itemExcist.length === 0) {
                    if (hierarchy !== 'project' && filteredNotifications.count > 0) {
                        if (!notification.projectId) {
                            emails.push(note);
                        }
                    } else {
                        emails.push(note);
                    }
                }
                if (notification.status === ruleActionStatus.inactive) isActiveEmail = false;
            }

            if (notification.action.name === ruleActionTypes.sms) {
                let itemExcist = sms.filter((sms) => sms.details === note.details);
                if (itemExcist.length === 0) {
                    sms.push(note);
                }
                if (notification.status === ruleActionStatus.inactive) isActiveSms = false;
            }
        }
        setEmails(emails.filter((r) => r.details !== 'ignore.email@donotsend.com'));
        setOriginalEmails(emails.filter((r) => r.details !== 'ignore.email@donotsend.com'));
        setMobiles(sms.filter((r) => r.details !== '9999999999'));
        setOriginalMobiles(sms.filter((r) => r.details !== '9999999999'));
        setEmailActive(isActiveEmail);
        setSmsActive(isActiveSms);
        setDataChanged(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [response]);

    React.useEffect(() => {
        if (deleteError) {
            notify('Site settings could not be deleted.', { type: 'warning' });
        }
        if (!deleted) return;
        const response = deleted;
        if (response.errors) {
            notify(response.errors.join(' '), { type: 'warning' });
            return;
        }
        notify('Site alarm settings deleted.', {
            type: 'success',
            messageArgs: { smart_count: 1 },
        });
        refresh(true);
        refreshData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleted, deleteError]);

    const toggleMode = () => {
        setIsEditMode(!isEditMode);
        setEmails(originalEmails);
        setMobiles(originalMobiles);
    };

    const getEmailHeader = () =>
        `${emails.length} email address${emails.length === 1 ? '' : 'es'} selected`;
    const getSmsHeader = () =>
        `${mobiles.length} mobile${mobiles.length === 1 ? '' : 's'} selected`;

    const handleSaveEmails = (data) => {
        setEmails(data);
        setOpenEmail(false);
        setDataChanged(true);
    };

    const handleSaveMobiles = (data) => {
        setMobiles(data);
        setOpenMobile(false);
        setDataChanged(true);
    };

    const handleRemoveEmail = (email) => {
        setEmails(emails.filter((item) => item.details !== email));
        notify(`Removed ${email}`, { type: 'success' });
        setDataChanged(true);
    };

    const handleRemoveMobile = (mobile) => {
        setMobiles(mobiles.filter((item) => item.details !== mobile));
        notify(`Removed ${mobile}`, { type: 'success' });
        setDataChanged(true);
    };

    const handleToggleEmailActive = (checkedValue) => {
        setEmailActive(checkedValue);
        setDataChanged(true);
    };

    const handleToggleSmsActive = (checkedValue) => {
        setSmsActive(checkedValue);
        setDataChanged(true);
    };

    const handleSaveNotifications = async (hierarcyAction) => {
        let actions = [
            ...emails.map((email) => {
                return {
                    details: email.details,
                    status: emailActive === true ? 1 : 0,
                    action: ruleActionTypes.email,
                    siteId: hierarchy === 'site' ? id : undefined,
                    projectId: hierarchy === 'project' ? id : undefined,
                };
            }),
            ...mobiles.map((sms) => {
                return {
                    details: sms.details,
                    status: smsActive === true ? 1 : 0,
                    action: ruleActionTypes.sms,
                    siteId: hierarchy === 'site' ? id : undefined,
                    projectId: hierarchy === 'project' ? id : undefined,
                };
            }),
        ];

        if (hierarcyAction === 'project' && hierarchy === 'site') {
            const ruleActionsstoDelete = actions;
            await deleteOne('ruleactions', { id, previousData: ruleActionsstoDelete });
        } else {
            if (hierarchy === 'site') {
                // add empty actions, if all the items were cleared on a site level
                if (actions.length === 0) {
                    let emptyEmailAction = {
                        details: 'ignore.email@donotsend.com',
                        status: 0,
                        action: ruleActionTypes.email,
                        siteId: id,
                        projectId: undefined,
                    };
                    actions.push(emptyEmailAction);
                    const emptySmsAction = {
                        details: '9999999999',
                        status: 0,
                        action: ruleActionTypes.sms,
                        siteId: id,
                        projectId: undefined,
                    };
                    actions.push(emptySmsAction);
                }
            }
            try {
                await setRulesActions(hierarchy, id, actions);
                notify(
                    `Alarm notifications saved for ${hierarchy === 'project' ? 'project' : 'site'}`,
                    { type: 'success' },
                );
                setOriginalEmails(emails);
                setOriginalMobiles(mobiles);
                toggleMode();
                refresh(true);
                refreshData();
            } catch (error) {
                notify('Could not save alarm notifications.', { type: 'warning' });
            }
        }
    };

    const handleSaveSiteNotifications = () => {
        handleSaveNotifications('site');
    };

    const handleSaveProjectNotifications = () => {
        handleSaveNotifications('project');
    };

    if (isLoading) {
        return (
            <Box>
                <LinearProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box>
                <Typography variant="body2">Could not load alarm notifications</Typography>
            </Box>
        );
    }

    return (
        <>
            <Box minHeight="300px" position="relative">
                <Box display="flex" justifyContent="space-between" className={classes.title}>
                    <Typography variant="h6">Alarm Notification Settings</Typography>
                    {canEdit && (hierarchy !== 'site' || siteStatus !== 5) && (
                        <Button onClick={toggleMode}>{isEditMode ? 'Cancel' : 'Edit'}</Button>
                    )}
                </Box>

                <Box display="flex" justifyContent="space-between">
                    <Typography variant="h6" gutterBottom className={classes.sub}>
                        Emails
                    </Typography>
                    <Switch
                        color="primary"
                        checked={emailActive}
                        disabled={!isEditMode}
                        onChange={(evnt, checkedValue) => handleToggleEmailActive(checkedValue)}
                    />
                </Box>
                <Box display="flex" justifyContent="space-between" marginBottom="16px">
                    <Typography variant="body1" className={classes.count}>
                        {getEmailHeader()}
                    </Typography>
                    {isEditMode && (
                        <Button
                            variant="text"
                            onClick={() => setOpenEmail(true)}
                            style={{ display: isEditMode ? 'block' : 'none' }}
                        >
                            <span className={classes.link}>Add Email Address</span>
                        </Button>
                    )}
                </Box>
                <RemovableItemList
                    data={emails}
                    handleRemove={handleRemoveEmail}
                    enabled={isEditMode}
                />
                <hr
                    style={{
                        borderColor: '#E1E3EE',
                        borderWidth: '1px',
                        marginTop: '8px',
                        marginBottom: '8px',
                    }}
                />
                <Box display="flex" justifyContent="space-between">
                    <Typography variant="h6" gutterBottom className={classes.sub}>
                        SMS
                    </Typography>
                    <Switch
                        color="primary"
                        checked={smsActive}
                        disabled={!isEditMode}
                        onChange={(evnt, checkedValue) => handleToggleSmsActive(checkedValue)}
                    />
                </Box>
                <Box display="flex" justifyContent="space-between" marginBottom="16px">
                    <Typography variant="body1" className={classes.count}>
                        {getSmsHeader()}
                    </Typography>
                    <Button
                        variant="text"
                        onClick={() => setOpenMobile(true)}
                        style={{ display: isEditMode ? 'block' : 'none' }}
                    >
                        <span className={classes.link}>Add Mobile</span>
                    </Button>
                </Box>
                <Box pb="35px">
                    <RemovableItemList
                        data={mobiles}
                        handleRemove={handleRemoveMobile}
                        enabled={isEditMode}
                    />
                </Box>
                {isEditMode === true && (
                    <Box position="initial" bottom={0}>
                        <Button
                            onClick={handleSaveProjectNotifications}
                            color="primary"
                            className={classes.wideButton}
                        >
                            {hierarchy === 'project' ? 'Save' : 'Inherit'} Project settings
                        </Button>
                        {hierarchy === 'site' && (
                            <Button
                                onClick={handleSaveSiteNotifications}
                                color="primary"
                                className={classes.wideButton}
                            >
                                Override Project settings
                            </Button>
                        )}
                    </Box>
                )}
            </Box>
            <AlarmRecipientDialog
                open={openEmail}
                mode="email"
                handleClose={() => setOpenEmail(false)}
                data={emails}
                handleSave={handleSaveEmails}
            />
            <AlarmRecipientDialog
                open={openMobile}
                mode="mobile"
                handleClose={() => setOpenMobile(false)}
                data={mobiles}
                handleSave={handleSaveMobiles}
            />
        </>
    );
};

export default AlarmNotifications;
