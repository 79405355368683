import React, { useCallback, useState } from 'react';
import { Box, Divider, Fab } from '@mui/material';
import { makeStyles } from '@mui/styles';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ScrollTop from '../components/ScrollTop';
import useRaftDeviceData from './useRaftDeviceData';
import Controls from '../components/Controls';
import { addHours } from 'date-fns';
import Battery from '../../sites/raft/Battery';
import Temperature from '../../sites/raft/Temperature';
import DissolvedOxygen from '../../sites/raft/DissolvedOxygen';
import Turbidity from '../../sites/raft/Turbidity';
import Conductivity from '../../sites/raft/Conductivity';
import Ph from '../../sites/raft/Ph';
import LinkQualityIndicator from '../charts/LinkQualityIndicator';
import { LqiDataContextProvider } from '../dataproviders/LqiDataContext';

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        gap: '30px',
    },
    splitContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'stretch',
        gap: '20px',
    },
    splitItem: {
        minHeight: '400px',
        flexBasis: '50%',
    },
    fullRow: {
        minHeight: '400px',
    },

    divider: {
        height: '100%',
        alignSelf: 'center',
    },
    dividerHorizontal: {
        height: 10,
    },

    charts: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: 15,
        justifyContent: 'stretch',
        position: 'relative',
        overflow: 'visible',
    },
    chartRow: {
        display: 'flex',
        flexDirection: 'row',
        columnGap: 5,
        justifyContent: 'stretch',
        position: 'relative',
        height: 490,
    },
    mapContainer: {
        flex: 4,
        width: '100%',
        position: 'relative',
        overflow: 'hidden',
        borderRadius: '10px',
    },
    temperatureCard: {
        backgroundColor: COLORS.temperature.secondary,
        color: COLORS.temperature.primary,
    },
    dOxCard: {
        backgroundColor: COLORS.dissolvedOxygen.secondary,
        color: COLORS.dissolvedOxygen.primary,
        paddingLeft: 8,
        paddingRight: 8,
    },
    turbidityCard: {
        backgroundColor: COLORS.turbidity.secondary,
        color: COLORS.turbidity.primary,
    },
    conductivityCard: {
        backgroundColor: COLORS.conductivity.secondary,
        color: COLORS.conductivity.primary,
    },
    phCard: {
        backgroundColor: COLORS.pH.secondary,
        color: COLORS.pH.primary,
    },
    batteryCard: {
        backgroundColor: COLORS.battery.secondary,
        color: COLORS.battery.primary,
    },
}));

const COLORS = {
    temperature: {
        primary: '#B71C1C',
        secondary: '#FFCDD2',
    },
    turbidity: {
        primary: '#0D47A1',
        secondary: '#E1F5FE',
    },
    dissolvedOxygen: {
        primary: '#6A1B9A',
        secondary: '#E1BEE7',
    },
    conductivity: {
        primary: '#F57F17',
        secondary: '#FFF9C4',
    },
    pH: {
        primary: '#E65100',
        secondary: '#FFE0B2',
    },
    battery: {
        primary: '#1B5E20',
        secondary: '#E8F5E9',
    },
};

const DevicesDataRaft = ({ deviceId, createdAt }) => {
    const classes = useStyles();
    const [from, setFrom] = useState(addHours(new Date(), -24));
    const [to, setTo] = useState(new Date());

    const updateDates = useCallback(
        (fromDate, toDate) => {
            if (fromDate) setFrom(fromDate);
            if (toDate) setTo(toDate);
        },
        [setFrom, setTo],
    );

    const { data, isLoading, error } = useRaftDeviceData(deviceId, from, to);

    let temperature = [];
    let dOx = [];
    let tur = [];
    let cond = [];
    let ph = [];
    let bat = [];

    if (data) {
        for (const reading of data) {
            const tempValue = reading.temp !== null ? reading.temp / 10 : reading.temp;
            const dOxValue = reading.do !== null ? reading.do / 10 : reading.do;
            const turValue = reading.tur !== null ? reading.tur / 10 : reading.tur;
            const phValue = reading.ph !== null ? reading.ph / 10 : reading.ph;
            temperature.push([reading.timestamp, tempValue]);
            dOx.push([reading.timestamp, dOxValue]);
            tur.push([reading.timestamp, turValue]);
            cond.push([reading.timestamp, reading.cond]);
            ph.push([reading.timestamp, phValue]);
            bat.push([reading.timestamp, reading.bat]);
        }
    }

    return (
        <>
            <Box className={classes.charts}>
                <Controls updateDates={updateDates} initialFrom={from} initialTo={to} />
                <Box className={classes.chartRow}>
                    <Temperature
                        isLoading={isLoading}
                        error={error}
                        data={temperature}
                        color={COLORS.temperature.primary}
                        fromDate={from}
                        toDate={to}
                    />
                    <Divider orientation="vertical" flexItem={true} className={classes.divider} />
                    <DissolvedOxygen
                        isLoading={isLoading}
                        error={error}
                        data={dOx}
                        color={COLORS.dissolvedOxygen.primary}
                        fromDate={from}
                        toDate={to}
                    />
                </Box>

                <Divider flexItem={true} className={classes.dividerHorizontal} />

                <Box className={classes.chartRow}>
                    <Turbidity
                        isLoading={isLoading}
                        error={error}
                        data={tur}
                        color={COLORS.turbidity.primary}
                        fromDate={from}
                        toDate={to}
                    />
                    <Divider orientation="vertical" flexItem={true} className={classes.divider} />
                    <Conductivity
                        isLoading={isLoading}
                        error={error}
                        data={cond}
                        color={COLORS.conductivity.primary}
                        fromDate={from}
                        toDate={to}
                    />
                </Box>

                <Divider flexItem={true} className={classes.dividerHorizontal} />

                <Box className={classes.chartRow}>
                    <Ph
                        isLoading={isLoading}
                        error={error}
                        data={ph}
                        color={COLORS.pH.primary}
                        fromDate={from}
                        toDate={to}
                    />
                    <Divider orientation="vertical" flexItem={true} className={classes.divider} />
                    <Battery
                        isLoading={isLoading}
                        error={error}
                        data={bat}
                        color={COLORS.battery.primary}
                        fromDate={from}
                        toDate={to}
                    />
                </Box>
                <Divider flexItem={true} className={classes.dividerHorizontal} />
                <Box className={classes.chartRow}>
                    <LqiDataContextProvider
                        deviceId={deviceId}
                        from={from}
                        to={to}
                        createdAt={createdAt}
                    >
                        <LinkQualityIndicator />
                    </LqiDataContextProvider>
                </Box>
            </Box>
            <ScrollTop>
                <Fab color="secondary" size="small" aria-label="scroll back to top">
                    <KeyboardArrowUpIcon />
                </Fab>
            </ScrollTop>
        </>
    );
};

export default DevicesDataRaft;
