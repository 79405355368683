import React, { useEffect, useRef, useState } from 'react';
import { AppBar, Box, Button, Divider, Link, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { addDays, addHours, addMonths, endOfMonth, startOfMonth, subMonths } from 'date-fns';
import { Toolbar } from 'react-admin';

const useStyles = makeStyles(() => ({
    controls: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        gap: '1vw',
        padding: '10px 0px',
    },
    quickrange: {
        display: 'flex',
        justifyContent: 'center',
    },

    list: {
        listStyleType: 'none',
        margin: 0,
        padding: '0px 0.7vw',
    },
}));

const Controls = ({ updateDates, initialFrom, initialTo }) => {
    const [fromDatePicker, setFromDatePicker] = useState(initialFrom);
    const [toDatePicker, setToDatePicker] = useState(initialTo);
    const [dateRange, setDateRange] = useState();
    const updateButton = useRef(null);
    const classes = useStyles();

    const quickHourHandler = (hours) => {
        const now = new Date();
        const from = addHours(now, hours);
        setFromDatePicker(from);
        setToDatePicker(now);
        setTimeout(() => {
            if (updateButton.current) updateButton.current.click();
        });
    };

    const quickDayHandler = (days) => {
        const now = new Date();
        const from = addDays(now, days);
        setFromDatePicker(from);
        setToDatePicker(now);
        setTimeout(() => {
            if (updateButton.current) updateButton.current.click();
        });
    };
    const quickMonthHandler = (months) => {
        const now = new Date();
        const from = addMonths(now, months);
        setFromDatePicker(from);
        setToDatePicker(now);
        setTimeout(() => {
            if (updateButton.current) updateButton.current.click();
        });
    };

    const quickPreviousMonthHandler = () => {
        const now = new Date();
        const previousMonthDate = subMonths(now, 1);
        const from = startOfMonth(previousMonthDate);
        const toDate = endOfMonth(previousMonthDate);
        setFromDatePicker(from);
        setToDatePicker(toDate);
        setTimeout(() => {
            if (updateButton.current) updateButton.current.click();
        });
    };

    const clickHandler = () => {
        setDateRange({
            from: fromDatePicker,
            to: toDatePicker,
        });
    };

    useEffect(() => {
        if (!dateRange) return;
        const { from, to } = dateRange;
        updateDates(from, to);
    }, [updateDates, dateRange]);

    return (
        <AppBar position="sticky" sx={{ backgroundColor: 'white' }}>
            <Toolbar className={classes.controls}>
                <Box className={classes.quickrange}>
                    <ul className={classes.list}>
                        <li>
                            <Link
                                component="button"
                                onClick={() => quickHourHandler(-1)}
                                underline="none"
                            >
                                Last hour
                            </Link>
                        </li>
                        <li>
                            <Link
                                component="button"
                                onClick={() => quickHourHandler(-24)}
                                underline="none"
                            >
                                Last 24 hours
                            </Link>
                        </li>
                    </ul>
                    <ul className={classes.list}>
                        <li>
                            <Link
                                component="button"
                                onClick={() => quickDayHandler(-7)}
                                underline="none"
                            >
                                Last 7 days
                            </Link>
                        </li>
                        <li>
                            <Link
                                component="button"
                                onClick={() => quickDayHandler(-30)}
                                underline="none"
                            >
                                Last 30 days
                            </Link>
                        </li>
                    </ul>
                    <ul className={classes.list}>
                        <li>
                            <Link
                                component="button"
                                onClick={() => quickMonthHandler(-3)}
                                underline="none"
                            >
                                Last 3 months
                            </Link>
                        </li>
                        <li>
                            <Link
                                component="button"
                                onClick={() => quickMonthHandler(-6)}
                                underline="none"
                            >
                                Last 6 months
                            </Link>
                        </li>
                    </ul>
                    <ul className={classes.list}>
                        <li>
                            <Link
                                component="button"
                                onClick={() => quickPreviousMonthHandler()}
                                underline="none"
                            >
                                Previous month
                            </Link>
                        </li>
                    </ul>
                </Box>
                <Divider orientation="vertical" flexItem={true} />
                <Box display="flex" justifyContent="space-between" alignItems="center" gap="10px">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateTimePicker
                            value={fromDatePicker}
                            onChange={setFromDatePicker}
                            disableFuture={true}
                            inputFormat="dd/MM/yyyy HH:mm"
                            label="From"
                            renderInput={(props) => (
                                <TextField id="device-data-from" variant="outlined" {...props} />
                            )}
                            showToolbar={true}
                        />
                        <DateTimePicker
                            value={toDatePicker}
                            onChange={setToDatePicker}
                            minDateTime={fromDatePicker}
                            inputFormat="dd/MM/yyyy HH:mm"
                            label="To"
                            renderInput={(props) => (
                                <TextField id="device-data-to" variant="outlined" {...props} />
                            )}
                            componentsProps={{
                                actionBar: {
                                    actions: ['today'],
                                },
                            }}
                        />
                    </LocalizationProvider>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={clickHandler}
                        ref={updateButton}
                    >
                        Update
                    </Button>
                </Box>
            </Toolbar>
        </AppBar>
    );
};

export default Controls;
