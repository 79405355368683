import React, { useEffect, useState } from 'react';
import { useLqiDataContext } from '../dataproviders/LqiDataContext';
import LineChart, { defaultChartOptions, defaultSeries } from '../mh/charts/LineChart';

const chartOptions = {
    title: {
        ...defaultChartOptions.title,
        text: 'Link Quality Indicator',
    },
    yAxis: [
        {
            ...defaultChartOptions.yAxis[0],
            title: {
                text: 'LQI',
            },
            max: 4,
            tickAmount: 5,
        },
    ],
};

const LinkQualityIndicator = () => {
    const { data, from, to, isLoading: loadingContext, error } = useLqiDataContext();
    const [series, setSeries] = useState();
    const [errorMessage, setErrorMessage] = useState();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(!!loadingContext);
        if (loadingContext == true) return;

        const series = {
            ...defaultSeries,
            name: 'Link Quality Indicator',
            data: data || [],
            showInLegend: false,
        };

        setSeries([series]);
    }, [data, loadingContext]);

    useEffect(() => {
        if (error) {
            try {
                const details = JSON.parse(error.message);
                if (details.details === 'Messages per day exceeded') {
                    setErrorMessage('Uplink interval is probably less than 15 minutes.');
                } else {
                    setErrorMessage(details.message);
                }
            } catch {
                setErrorMessage(error.message);
            }
        } else {
            setErrorMessage(undefined);
        }
    }, [error]);
    return (
        <LineChart
            chartOptions={chartOptions}
            series={series}
            loading={isLoading}
            error={errorMessage}
            min={from}
            max={to}
        />
    );
};

export default LinkQualityIndicator;
