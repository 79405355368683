import React from 'react';
import {
    Breadcrumbs,
    Button,
    Card,
    Link,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
    List,
    DateField,
    TextField,
    useRedirect,
    Datagrid,
    useLocale,
    useRecordContext,
} from 'react-admin';
import { useLocation } from 'react-router-dom';
import Error from '../components/Error';
import Layout from '../components/Layout';
import Pagination from './components/Pagination';
import ROOT from '../theme/root';

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
    },
    cancel: {
        color: ROOT.COLORS.RED,
        fontWeight: 600,
        fontSize: 14,
    },
    card: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        alignSelf: 'center',
    },
    errorContainer: {
        margin: 0,
    },
    title: {
        opacity: 0.6,
    },
}));

const NameUnit = () => {
    const record = useRecordContext();
    const unit = record['unit'] ? `(${record['unit']})` : '';
    return <span>{record && `${record['name'] || ''} ${unit}`}</span>;
};

const DatagridHeader = (props) => (
    <TableHead>
        <TableRow>
            <TableCell></TableCell>
            {props.children.map((child) => (
                <TableCell
                    key={child.props.source}
                    sx={{
                        textTransform: 'capitalize',
                    }}
                >
                    {child.props.label || child.props.source}
                </TableCell>
            ))}
        </TableRow>
    </TableHead>
);

const CommandRequestsList = () => {
    const { state } = useLocation();
    const classes = useStyles();
    const redirect = useRedirect();
    const locale = useLocale();
    const cancelHandler = () => redirect(callbackUrl);

    const callbackUrl =
        state && state.from
            ? state.from
            : state && state.deviceUuid
            ? `/devices/${state.deviceUuid}/show/3`
            : '/devices';

    const error =
        !state || !state.deviceId || !state.deviceUuid
            ? 'Error loading deviceId from the state'
            : null;

    if (error) {
        return (
            <Layout classes={classes.container}>
                <Card className={classes.card}>
                    <Error
                        classes={classes.errorContainer}
                        message={`${error}. Please cancel and start over`}
                    />
                    <Button
                        onClick={cancelHandler}
                        variant="text"
                        className={classes.cancel}
                        aria-label="CANCEL"
                    >
                        CANCEL
                    </Button>
                </Card>
            </Layout>
        );
    }
    return (
        <Layout classes={classes.container}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link color="primary" underline="hover" href="/devices">
                    Devices
                </Link>
                <Link underline="hover" color="primary" href={`/devices/${state.deviceUuid}/show`}>
                    {state.deviceId}
                </Link>
                <Link underline="hover" color="primary" href={`${callbackUrl}`}>
                    Downlink
                </Link>
                <Typography color="inherit">Commands Requests History</Typography>
            </Breadcrumbs>
            <Typography variant="h5" gutterBottom>
                Commands Requests History
            </Typography>
            <Typography variant="h6" className={classes.title}>
                Device {state.deviceId}
            </Typography>
            <List
                filter={{ device_id: state.deviceId }}
                sort={{ field: 'timestamp', order: 'DESC' }}
                pagination={<Pagination rowsPerPageOptions={[100]} />}
                perPage={100}
                disableSyncWithLocation
                actions={false}
            >
                <Datagrid isRowSelectable={() => false} header={<DatagridHeader />}>
                    <DateField
                        source="createdTime"
                        label="Received Time"
                        options={{
                            day: 'numeric',
                            month: 'short',
                            year: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric',
                            second: 'numeric',
                            hourCycle: 'h24',
                        }}
                        locales={locale}
                        showTime
                        sortable={false}
                    />
                    <TextField sortable={true} source="timestamp" />
                    <DateField
                        source="expirytimeMill"
                        label="Expiry Time"
                        options={{
                            day: 'numeric',
                            month: 'short',
                            year: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric',
                            second: 'numeric',
                            hourCycle: 'h24',
                        }}
                        locales={locale}
                        showTime
                        sortable={false}
                    />
                    <TextField sortable={false} source="expirytime" />

                    <TextField sortable={false} source="commandId" label="Command ID" />
                    <NameUnit source="name" label="Parameter" sortable={false} />
                    <TextField sortable={false} source="value" label="Value" />
                </Datagrid>
            </List>
        </Layout>
    );
};

export default CommandRequestsList;
