import React, { useState, useEffect } from 'react';
import { useNotify, usePermissions, useRedirect } from 'react-admin';
import { useLocation } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { addHours } from 'date-fns';
import GROUPS from '../../auth/groups';
import Error from '../../components/Error';
import Controls from '../components/Controls';
import Layout from '../../components/Layout';
import Breadcrumb from '../../menu/Breadcrumb';
import LineChart from '../components/LineChart';
import useSitesLevel from './useSitesLevel';

const CompareSites = () => {
    // Line chart variables
    let series = [];
    let loading = false;
    let showControls = true;

    // Controls variable
    const now = new Date();

    const classes = useStyles();
    const { state } = useLocation();
    const { permissions } = usePermissions();
    const notify = useNotify();
    const redirect = useRedirect();
    const [fromDate, setFromDate] = useState(addHours(now, -24));
    const [toDate, setToDate] = useState(now);
    const [error, setError] = useState(null);
    const [options] = useState(() => {
        if (!state?.sites) return null;
        const series = state.sites.map((site) => ({
            id: site.id,
            name: site.name,
            data: [],
            dataGrouping: {
                enabled: false,
            },
            showInNavigator: true,
            yAxis: 0,
        }));
        return {
            ...chartOptions,
            series,
        };
    });

    // Fetch data logic
    const data = useSitesLevel(state?.sites, fromDate, toDate);

    // Error handling logic
    useEffect(() => {
        // User is not allowed to enter this page
        if (!permissions.includes(GROUPS.HSW_ADMIN) && !permissions.includes(GROUPS.ANALYTICS)) {
            redirect('list', 'sites');
            return;
        }

        // Selected sites are not received in the state
        if (!state?.sites || state.sites.length === 0) {
            redirect('list', 'sites');
            return;
        }

        // Errors from data fetching after all sites finished loading
        if (data) {
            const stillLoading = data.some((site) => site.loading);
            if (stillLoading) return;
            let errorMessage = null;
            const errors = data.filter((site) => site.error !== null).map((site) => site.name);

            if (errors.length > 0 && errors.length < data.length) {
                // Notify error in screen but load chart with other sites
                const erroredSites = errors.join();
                const message = `${messages.siteFetchError} ${erroredSites}`;

                notify(message, {
                    type: 'warning',
                });
            } else if (errors.length >= data.length) {
                // Do not load chart because there is no data. All sites have errors
                const uniqueDetails = data.reduce((acc, obj) => {
                    const details = obj.error?.body?.details;
                    if (details !== null && !acc.includes(details)) {
                        acc.push(details);
                    }
                    return acc;
                }, []);

                errorMessage = uniqueDetails.join(', ') || messages.networkError;
            }
            setError(errorMessage);
        }
    }, [state, permissions, data, redirect, notify]);

    const updateDates = (from, to) => {
        setFromDate(from);
        setToDate(to);
    };

    // Update line chart data
    if (data) {
        series = data.map((site) => {
            loading = loading || site.loading;
            return {
                id: site.id,
                name: site.name,
                data: site.readings,
                dataGrouping: {
                    enabled: false,
                },
                showInNavigator: true,
                yAxis: 0,
            };
        });
    }

    return (
        <Layout>
            <Breadcrumb />
            <Typography variant="h5" className={classes.title}>
                Compare Sites
            </Typography>
            <Box className={classes.container}>
                {showControls && (
                    <Controls fromDate={fromDate} toDate={toDate} updateDates={updateDates} />
                )}
                {error && <Error message={error} icon={true} />}
                {!error && (
                    <LineChart
                        chartOptions={options}
                        series={series}
                        loading={loading}
                        min={fromDate}
                        max={toDate}
                    />
                )}
            </Box>
        </Layout>
    );
};

const chartOptions = {
    title: {
        text: 'Level data',
        align: 'left',
        style: {
            fontWeight: 600,
        },
    },
    yAxis: [
        {
            title: {
                text: 'Level mm',
            },
            opposite: false,
            min: 0,
            lineWidth: 1,
            labels: {
                format: '{value:.0f}',
            },
            tickAmount: 10,
        },
    ],
    series: [],
};

const messages = {
    networkError:
        "Oops! We're having trouble loading the data right now. Please check your internet connection and try again. If the problem persists, please contact customer support for assistance.",
    siteFetchError: "We're sorry, we couldn't fetch data for site(s)",
};

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        gap: '30px',
        marginTop: '24px',
    },
    title: {
        fontSize: '32px',
        fontWeight: 'bold',
        lineHeight: '43px',
    },
}));

export default CompareSites;
