import { Tooltip } from '@mui/material';
import React from 'react';

const locale = 'en-NZ';

// ToDo: this should come from a configuration
const defaultTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
/**
 * Given timestamp in miliseconds displays zoned date in text and tooltip
 * By default uses local timezome, to change timezone send customized objects displayOptions and tooltipOptions
 */
const DatetimeIntl = ({ timestampMilliseconds, displayOptions, tooltipOptions, ariaLabel }) => {
    const displayOpts = {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: false,
        timeZone: defaultTimeZone,
        ...displayOptions,
    };
    const displayFormat = new Intl.DateTimeFormat(locale, displayOpts);

    const tooltipOpts = {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: true,
        timeZone: defaultTimeZone,
        ...tooltipOptions,
    };

    const tooltipFormat = new Intl.DateTimeFormat(locale, tooltipOpts);
    let displayText = timestampMilliseconds;
    let tooltipText = timestampMilliseconds;

    try {
        displayText = displayFormat.format(new Date(timestampMilliseconds));
        tooltipText = `${tooltipFormat.format(new Date(timestampMilliseconds))} ${
            tooltipOpts.timeZone
        }`;
    } catch (error) {
        console.error(error);
    }

    return (
        <Tooltip title={tooltipText} aria-label={ariaLabel}>
            <span>{displayText}</span>
        </Tooltip>
    );
};

export default DatetimeIntl;
