import React, { useState, useRef, useEffect, Children, cloneElement } from 'react';
import { MenuItem, Button, Popper, Grow, Paper, ClickAwayListener, MenuList } from '@mui/material';
import { makeStyles } from '@mui/styles';
import MenuIcon from '@mui/icons-material/Menu';
import ROOT from '../../theme/root';

const useStyles = makeStyles({
    button: {
        color: ROOT.COLORS.PRIMARY,
    },
    menuItem: {
        padding: 0,
    },
    popper: {
        zIndex: 4,
    },
});

const ActionsMenu = (props) => {
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);
    const prevOpen = useRef(open);
    const classes = useStyles();
    const { className: containerClassName, disabled, children } = props;

    const arrayChildren = Children.toArray(children);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const handleListKeyDown = (event) => {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        } else if (event.key === 'Escape') {
            setOpen(false);
        }
    };

    // return focus to the button when we transitioned from !open -> open
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (
        <div className={containerClassName}>
            <Button
                ref={anchorRef}
                id="actions-button"
                aria-controls={open ? 'actions-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                startIcon={<MenuIcon />}
                className={classes.button}
                disabled={disabled}
                variant="secondary"
            >
                Actions
            </Button>
            <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="bottom-end"
                transition
                disablePortal
                className={classes.popper}
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom-end' ? 'left top' : 'left bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList
                                    autoFocusItem={open}
                                    id="actions-menu"
                                    aria-labelledby="actions-button"
                                    onKeyDown={handleListKeyDown}
                                >
                                    {Children.map(arrayChildren, (child, index) => {
                                        return (
                                            <MenuItem
                                                key={`menu-item-${index}`}
                                                onClick={() => setOpen(false)}
                                                disabled={child.props.disabled}
                                                className={classes.menuItem}
                                            >
                                                {cloneElement(child)}
                                            </MenuItem>
                                        );
                                    })}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>
    );
};

export default ActionsMenu;
