import { useCallback } from 'react';

function useRemoveLocalStorage(hookTimeKey) {
    const remove = useCallback(
        (key) => {
            if (typeof key === 'undefined' && typeof hookTimeKey === 'undefined') {
                throw new Error('You must provide a key to remove an item from the store');
            }

            localStorage.removeItem(key ?? hookTimeKey);
        },
        [hookTimeKey],
    );

    return remove;
}

export default useRemoveLocalStorage;
