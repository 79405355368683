import React from 'react';
import { Button, ButtonGroup, TextField, InputAdornment } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    unitSelector: {
        height: '38px',
        marginTop: '5px',
        marginBottom: '5px',
    },
    adornment: {
        paddingRight: '5px',
        '& > div': {
            paddingRight: '5px',
        },
    },
}));

const UnitInput = ({ value, units, onChange, selectedUnit, onChangeUnit, disabled }) => {
    const classes = useStyles();
    const [selected, setSelected] = React.useState(
        selectedUnit !== undefined ? selectedUnit : units[0],
    );
    React.useEffect(() => {
        if (selectedUnit !== undefined && selectedUnit !== selected) setSelected(selectedUnit);
    }, [selectedUnit, selected]);
    return (
        <TextField
            value={value}
            onChange={onChange}
            variant="outlined"
            disabled={disabled}
            className={classes.adornment}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <ButtonGroup
                            size="small"
                            color="primary"
                            disabled={disabled}
                            aria-label="outlined primary button group"
                            className={classes.unitSelector}
                        >
                            {units &&
                                units.map((unit) => (
                                    <Button
                                        color={selected === unit ? 'primary' : 'secondary'}
                                        onClick={() => {
                                            if (selected !== unit) {
                                                setSelected(unit);
                                                if (onChangeUnit) onChangeUnit(unit);
                                            }
                                        }}
                                        key={`unit-${unit}`}
                                    >
                                        {unit}
                                    </Button>
                                ))}
                        </ButtonGroup>
                    </InputAdornment>
                ),
            }}
        />
    );
};

export default UnitInput;
