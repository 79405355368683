import jwtDecode from 'jwt-decode';
import authProvider from '../utils/authProvider';
import GROUPS from '../auth/groups';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig, loginRequest } from '../auth/azureADConfig';

const auth0_nameSpace = process.env.REACT_APP_AUTH0_NAMESPACE;
const auth0_clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
const auth0_domain = process.env.REACT_APP_AUTH0_DOMAIN;
const auth0_audience = process.env.REACT_APP_AUTH0_AUDIENCE;

const azureAD_Groups = {
    Installer: process.env.REACT_APP_AZURE_AD_INSTALLER,
    Surveyor: process.env.REACT_APP_AZURE_AD_SURVEYOR,
    Portal_Access: process.env.REACT_APP_AZURE_AD_PORTALACCESS,
    Portal_Admin: process.env.REACT_APP_AZURE_AD_PORTALADMIN,
    Analytics: process.env.REACT_APP_AZURE_AD_ANALYTICS,
};

function mapAzureADGroups(azureADGroupIds) {
    if (!azureADGroupIds) {
        return [];
    }

    const groups = [];
    azureADGroupIds.forEach((g) => {
        if (azureAD_Groups.Installer && g === azureAD_Groups.Installer) {
            groups.push(GROUPS.INSTALLER);
        } else if (azureAD_Groups.Surveyor && g === azureAD_Groups.Surveyor) {
            groups.push(GROUPS.SURVEYOR);
        } else if (azureAD_Groups.Portal_Access && g === azureAD_Groups.Portal_Access) {
            groups.push(GROUPS.PORTALACCESS);
        } else if (azureAD_Groups.Portal_Admin && g === azureAD_Groups.Portal_Admin) {
            groups.push(GROUPS.HSW_ADMIN);
        } else if (azureAD_Groups.Analytics && g === azureAD_Groups.Analytics) {
            groups.push(GROUPS.ANALYTICS);
        }
    });

    return groups;
}

const getUserInfo = async () => {
    try {
        var access_token = await getAccessToken();
        if (!access_token) {
            return {};
        }

        const isAzureADToken = localStorage.getItem('hsw_isAzureAD')?.toLowerCase() === 'true';
        const decoded_token = jwtDecode(access_token.replace('Bearer ', ''));

        var hsw_user = sessionStorage.getItem('hsw_user');
        if (!hsw_user) {
            const userId = isAzureADToken
                ? decoded_token[AZURE_AD_TOKEN_CONTEXT.USER_ID]
                : decoded_token[AUTH0_TOKEN_CONTEXT.USER_ID].replace('auth0|', '');

            var response = await fetch(
                process.env.REACT_APP_API_BASE_URL + `/users/username/${userId}`,
                {
                    method: 'GET',
                    headers: { Authorization: access_token },
                },
            );
            if (!response.ok) {
                return {};
            }
            const user = await response.json();
            hsw_user = JSON.stringify(user);
            sessionStorage.setItem('hsw_user', hsw_user);
        }
        const user = JSON.parse(hsw_user);

        if (isAzureADToken) {
            // azure ad
            const roles = mapAzureADGroups(decoded_token[AZURE_AD_TOKEN_CONTEXT.ROLES]);
            return {
                organisationId: user?.organisationId,
                username: decoded_token[AZURE_AD_TOKEN_CONTEXT.EMAIL],
                roles: roles,
                email: decoded_token[AZURE_AD_TOKEN_CONTEXT.EMAIL],
                name: user.name,
                id: user.id,
                externalUserId: decoded_token[AZURE_AD_TOKEN_CONTEXT.USER_ID],
            };
        } else {
            // auth0
            return {
                organisationId: decoded_token[AUTH0_TOKEN_CONTEXT.ORGANISATION_ID],
                username: decoded_token[AUTH0_TOKEN_CONTEXT.USER_ID].replace('auth0|', ''),
                roles: decoded_token[AUTH0_TOKEN_CONTEXT.ROLES],
                email: decoded_token[AUTH0_TOKEN_CONTEXT.EMAIL],
                name: user.name,
                id: user.id,
                externalUserId: decoded_token[AUTH0_TOKEN_CONTEXT.USER_ID].replace('auth0|', ''),
            };
        }
    } catch (e) {
        await authProvider.logout();
        return '';
    }
};

const getAccessToken = async () => {
    try {
        const access_token = localStorage.getItem('hsw_auth');
        if (!access_token) {
            throw 'no access_token';
        }

        const decode = access_token ? jwtDecode(access_token) : {};
        const isAzureADToken = localStorage.getItem('hsw_isAzureAD')?.toLowerCase() === 'true';

        const now = new Date().getTime();
        const expiry = isAzureADToken
            ? decode[AZURE_AD_TOKEN_CONTEXT.EXPIRY] * 1000 ?? now
            : decode[AUTH0_TOKEN_CONTEXT.EXPIRY] * 1000 ?? now;

        if (expiry < now + 10000) {
            // refresh token
            if (isAzureADToken) {
                await refreshAuthADToken();
            } else {
                await refreshAuth0Token();
            }
            const access_token = localStorage.getItem('hsw_auth');
            if (!access_token) {
                throw 'no access_token after refresh';
            }
            return 'Bearer ' + access_token;
        } else {
            return 'Bearer ' + access_token;
        }
    } catch (err) {
        await authProvider.logout();
        return '';
    }
};

async function refreshAuth0Token() {
    const currentToken = localStorage.getItem('hsw_token');
    if (!currentToken) {
        return;
    }
    const url = `${auth0_domain}/oauth/token`;
    const body = {
        grant_type: 'refresh_token',
        audience: auth0_audience + ' userinfo',
        scope: 'openid profile email offline_access',
        client_id: auth0_clientId,
        refresh_token: currentToken,
        mode: 'no-cors',
    };
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    };
    try {
        const response = await fetch(url, options);
        const data = await response.json();
        if (!data['access_token']) {
            throw 'unable to refresh access token: ' + response;
        }
        localStorage.setItem('hsw_auth', data['access_token']);
        if (data['refresh_token']) {
            // overwrite the refresh token if there was a new refresh token int he response
            localStorage.setItem('hsw_token', data['refresh_token']);
        }
    } catch (ex) {
        console.log(ex);
    }
}

async function refreshAuthADToken() {
    const instance = new PublicClientApplication(msalConfig);
    const accounts = instance.getAllAccounts();
    const account = accounts.find((a) => a?.tenantId === process.env.REACT_APP_AZURE_AD_TENANT_ID);
    const request = {
        ...loginRequest,
        account: account,
    };
    var response = await instance.acquireTokenSilent(request);

    localStorage.setItem('hsw_auth', response.accessToken);
    localStorage.setItem('hsw_isAzureAD', true);
}

async function listRolesForUser(userId) {
    const url = process.env.REACT_APP_API_BASE_URL + `/userauth/${userId}`;
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: await getAccessToken(),
        },
    };
    const res = await fetch(url, options);

    return res;
}

async function addRolesToUser(userId, roleNames) {
    const url = process.env.REACT_APP_API_BASE_URL + `/userauth/${userId}`;
    const body = { roles: roleNames };
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: await getAccessToken(),
        },
        body: JSON.stringify(body),
    };
    const res = await fetch(url, options);

    return res;
}

async function removeRolesFromUser(userId, roleNames) {
    const url = process.env.REACT_APP_API_BASE_URL + `/userauth/${userId}`;
    const body = { roles: roleNames };
    const options = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            Authorization: await getAccessToken(),
        },
        body: JSON.stringify(body),
    };
    const res = await fetch(url, options);

    return res;
}

async function resetPasswordAuth0(email, code, password) {
    const url = process.env.REACT_APP_API_BASE_URL + '/users/resetPassword';
    const body = {
        email: email,
        code: code,
        password: password,
    };
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    };
    const res = await fetch(url, options);

    return res;
}

async function forgotPassword(email) {
    const url = process.env.REACT_APP_API_BASE_URL + '/users/forgotPassword';
    const body = {
        email,
        isMobile: false,
    };
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    };

    const res = await fetch(url, options);
    const json = await res.json();

    return { status: res.status, data: json };
}

async function signupExistingUserToAuth0(id, roleNames) {
    const url = process.env.REACT_APP_API_BASE_URL + `/users/addToAuth0/${id}`;
    const body = { roles: roleNames };
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: await getAccessToken(),
        },
        body: JSON.stringify(body),
    };
    const res = await fetch(url, options);

    return res;
}

const AUTH0_TOKEN_CONTEXT = {
    ROLES: `${auth0_nameSpace}/roles`,
    USER_ID: 'sub',
    ORGANISATION_ID: `${auth0_nameSpace}/hsw_organisationId`,
    EXPIRY: 'exp',
    EMAIL: `${auth0_nameSpace}/email`,
    LAST_PASSWORD_RESET: `${auth0_nameSpace}/last_password_reset`,
};

const AZURE_AD_TOKEN_CONTEXT = {
    ROLES: 'groups',
    USER_ID: 'oid',
    EXPIRY: 'exp',
    EMAIL: 'upn',
    GIVEN_NAME: 'given_name',
    FAMILY_NAME: 'family_name',
};

export {
    getUserInfo,
    getAccessToken,
    addRolesToUser,
    removeRolesFromUser,
    AUTH0_TOKEN_CONTEXT,
    AZURE_AD_TOKEN_CONTEXT,
    resetPasswordAuth0,
    forgotPassword,
    listRolesForUser,
    signupExistingUserToAuth0,
};
