import React from 'react';
import { Box, Grid, Card, LinearProgress, Typography } from '@mui/material';
import AlarmThresholds from './AlarmThresholds';
import AlarmNotifications from './AlarmNotifications';
import MeasurementType from '../sites/settings/MeasurementType';
import { useNotify, usePermissions, useUpdate, useRefresh } from 'react-admin';
import useFetch from '../hooks/useFetch';
import { setRules, ruleTypes } from './rules';
import { getAccessToken } from '../auth/auth0Functions';

const isProjectTampering = (rule) => {
    return (
        rule.ruleType === ruleTypes.tampering &&
        (rule.projectId !== undefined || rule.projectId !== null)
    );
};

const AlarmSettings = ({
    thresholdUnits,
    id,
    hierarchy = 'project',
    siteDepth = 0,
    site,
    reloadAlarms,
}) => {
    const notify = useNotify();
    const [update] = useUpdate();
    const refresh = useRefresh();
    const { permissions } = usePermissions();
    const { measureFromInvert: siteInvert, status: siteStatus, siteTypeId } = site || {};
    let { response, error, isLoading } = useFetch(
        `${process.env.REACT_APP_API_BASE_URL}/rules/${hierarchy}/${id}`,
        {
            method: 'GET',
        },
    );
    const [alarmRules, setAlarmRules] = React.useState([]);
    const [measureFromInvert, setMeasureFromInvert] = React.useState(siteInvert);
    React.useEffect(() => {
        if (response) {
            if (hierarchy !== 'project') {
                const filteredResponse = response.filter((r) => r.siteId);
                if (filteredResponse.length > 0) {
                    setAlarmRules(filteredResponse);
                } else {
                    setAlarmRules(response);
                }
            } else {
                setAlarmRules(response);
            }
        }
    }, [hierarchy, response]);
    const getRules = async () => {
        const token = await getAccessToken();

        await fetch(`${process.env.REACT_APP_API_BASE_URL}/rules/${hierarchy}/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: token,
            },
        })
            .then((responseNew) => {
                return responseNew.json();
            })
            .then((responseNew) => {
                setAlarmRules(responseNew.data);
                if (hierarchy !== 'project') {
                    const filteredResponse = responseNew.data.filter((r) => r.siteId);
                    if (filteredResponse.length > 0) {
                        setAlarmRules(filteredResponse);
                    } else {
                        setAlarmRules(filteredResponse);
                    }
                } else {
                    setAlarmRules(responseNew.data);
                }
                reloadAlarms(0);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const [editThresholds, setEditThresholds] = React.useState(false);
    const [editMeasurementType, setEditMeasurementType] = React.useState(false);

    if (isLoading || !alarmRules) {
        return (
            <Box pt="20px">
                <LinearProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box>
                <Typography variant="body2">Could not load rules</Typography>
            </Box>
        );
    }

    const handleRulesSave = async (rules) => {
        setEditThresholds(false);
        await setRules(hierarchy, id, rules)
            .then(() => {
                setAlarmRules(rules);
                notify('Alarm thresholds have been updated.', { type: 'success' });
            })
            .catch((error) => {
                // handle any weird uniterable response errors
                if (error.toString().substring(0, 9) === 'TypeError') {
                    notify('Alarm thresholds have been updated.', { type: 'success' });
                    setAlarmRules(rules);
                    getRules(); // maybe calling reloadAlarms(0) is enough
                    refresh(true);
                } else {
                    console.log(error);
                    notify('Alarm thresholds could not be updated.', { type: 'warning' });
                }
            });
    };

    const updateSiteMeasurementType = async (measureFromInvert) => {
        const upsert = { ...site };
        upsert.measureFromInvert = measureFromInvert;
        update('sites', { id: site.id, data: upsert, previousData: site });
        refresh(true);
    };

    const handleMeasurementTypeSave = async (measureFromInvert) => {
        setEditMeasurementType(false);
        setMeasureFromInvert(measureFromInvert);
        updateSiteMeasurementType(measureFromInvert);
        notify('Measurement type has been updated.', { type: 'success' });
    };

    return (
        <Grid container spacing={2} style={{ marginTop: '15px' }}>
            <Grid item sm={8} md={4}>
                <Card>
                    <AlarmThresholds
                        hierarchy={hierarchy}
                        thresholdUnits={thresholdUnits}
                        id={id}
                        onSave={handleRulesSave}
                        edit={editThresholds}
                        onEdit={() => setEditThresholds(true)}
                        onCancel={() => setEditThresholds(false)}
                        permissions={permissions}
                        inactivity={alarmRules?.find(
                            (rule) => rule.ruleType === ruleTypes.inactive,
                        )}
                        tampering={
                            Array.isArray(alarmRules) &&
                            alarmRules.some((rule) => rule.ruleType === ruleTypes.tampering)
                        }
                        tamperingProject={
                            Array.isArray(alarmRules) && alarmRules.some(isProjectTampering)
                        }
                        thresholds={
                            Array.isArray(alarmRules)
                                ? alarmRules.filter((rule) => rule.ruleType === ruleTypes.reading)
                                : []
                        }
                        siteDepth={siteDepth}
                        siteStatus={siteStatus}
                        refreshData={getRules}
                    />
                </Card>
            </Grid>
            <Grid item sm={12} md={4}>
                <Card>
                    <AlarmNotifications
                        id={id}
                        siteStatus={siteStatus}
                        hierarchy={hierarchy}
                        permissions={permissions}
                        refreshData={getRules}
                    />
                </Card>
            </Grid>
            {hierarchy === 'site' && siteTypeId === 1 && (
                <Grid item sm={8} md={4}>
                    <Card>
                        <MeasurementType
                            siteStatus={siteStatus}
                            measureFromInvert={measureFromInvert}
                            onSave={handleMeasurementTypeSave}
                            edit={editMeasurementType}
                            onEdit={() => setEditMeasurementType(true)}
                            onCancel={() => setEditMeasurementType(false)}
                            permissions={permissions}
                        />
                    </Card>
                </Grid>
            )}
        </Grid>
    );
};

export default AlarmSettings;
