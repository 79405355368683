import React from 'react';
import { Box, ButtonBase, Card, LinearProgress } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { makeStyles } from '@mui/styles';
import { DeleteForeverOutlined, RefreshOutlined } from '@mui/icons-material';
import { useLocale } from 'react-admin';
import Layout from '../../components/Layout';
import NextWindow from '../components/NextWindow';
import ActionsMenu from './ActionsMenu';
import ROOT from '../../theme/root';
import classNames from 'classnames';
import usePendingCommands from '../hooks/usePendingCommands';

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 0,
    },
    actions: {
        marginLeft: 'auto',
        display: 'flex',
        flexDirection: 'row-reverse',
    },
    menuItems: {
        fontSize: 12,
        fontWeight: 600,
        textTransform: 'uppercase',
        padding: '6px 16px',
    },
    delete: {
        color: ROOT.COLORS.RED,
    },
    nextWindow: {
        paddingTop: 20,
        opacity: 0.6,
        fontStyle: 'italic',
        fontSize: 14,
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    error: {
        color: ROOT.COLORS.RED,
        fontSize: 14,
    },
}));

// Fetch and display list of commands that can be deleted in batch
const PendingCommands = ({ deviceId, disableVirtualization = false }) => {
    const classes = useStyles();
    const {
        data,
        loading,
        errorMessage,
        refetch,
        removeHandler,
        selectItemHandler,
        updating,
        selectedIds,
        nextWindow,
        nextWindowDistance,
        waitingFirstMessage,
        loadingNextWindow,
        errorNextWindow,
    } = usePendingCommands(deviceId);
    const locale = useLocale();

    const columns = [
        { field: 'commandId', headerName: 'Command ID', sortable: false, flex: 1 },
        {
            field: 'name',
            headerName: 'Parameter',
            sortable: false,
            flex: 1,
            valueGetter: (params) => `${params.row.name || ''} (${params.row.unit || ''})`,
        },
        { field: 'value', headerName: 'Value', sortable: false, flex: 1 },
        {
            field: 'createdTime',
            headerName: 'Received Time',
            sortable: false,
            type: 'dateTime',
            flex: 1,
            valueGetter: (params) =>
                `${new Date(params.row.createdTime).toLocaleDateString(locale, {
                    day: 'numeric',
                    month: 'short',
                    year: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                    hourCycle: 'h24',
                })}`,
        },
    ];

    return (
        <Layout classes={classes.container}>
            <Box marginBottom={5}>
                <Card>
                    <Box className={classes.header}>
                        {errorMessage && <span className={classes.error}>{errorMessage}</span>}
                        <ActionsMenu className={classes.actions}>
                            <ButtonBase
                                onClick={() => removeHandler()}
                                disabled={loading || updating || selectedIds.length === 0}
                                className={classNames(classes.menuItems, classes.delete)}
                            >
                                <DeleteForeverOutlined /> Delete ({selectedIds.length})
                            </ButtonBase>
                            <ButtonBase
                                onClick={() => refetch()}
                                disabled={loading || updating}
                                className={classes.menuItems}
                            >
                                <RefreshOutlined /> Refresh
                            </ButtonBase>
                        </ActionsMenu>
                    </Box>

                    {(loading || updating) && (
                        <Box>
                            <LinearProgress aria-label="pending-commands-loading" />
                        </Box>
                    )}
                    {data && data.length > 0 && (
                        <Box sx={{ height: 400, width: '100%' }}>
                            <DataGrid
                                rows={data}
                                columns={columns}
                                pageSize={100}
                                rowsPerPageOptions={[100]}
                                checkboxSelection
                                disableSelectionOnClick
                                disableColumnFilter
                                disableColumnMenu
                                isRowSelectable={() => !loading && !updating}
                                loading={loading}
                                onSelectionModelChange={(selectionModel) => {
                                    selectItemHandler(selectionModel);
                                }}
                                hideFooter={true}
                                disableVirtualization={disableVirtualization}
                            />
                        </Box>
                    )}
                    <Box className={classes.nextWindow}>
                        Next window{' '}
                        <NextWindow
                            nextWindow={nextWindow}
                            nextWindowDistance={nextWindowDistance}
                            waitingFirstMessage={waitingFirstMessage}
                            loading={loadingNextWindow}
                            error={errorNextWindow}
                        />
                    </Box>
                </Card>
            </Box>
        </Layout>
    );
};
export default PendingCommands;
