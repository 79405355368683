import React from 'react';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    label: {
        color: '#636364',
        fontSize: '14px',
        letterSpacing: '-0.34px',
        lineHeight: '19px',
    },
    title: {
        color: '#0B0B0D',
        fontSize: '24px',
        fontWeight: 'bold',
        letterSpacing: '-0.58px',
        lineHeight: '32px',
    },
    value: {
        fontSize: '16px',
        letterSpacing: '-0.38px',
        lineHeight: '21px',
    },
    status: {
        backgroundColor: '#5CECBF',
        color: '#0B0B0D',
        borderRadius: '4px',
        height: '27px',
        minWidth: '70px',
        paddingLeft: '3px',
        paddingRight: '3px',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '40px',
        flex: 1,
    },
    row: {
        display: 'flex',
        marginTop: '5px',
        justifyContent: 'stretch',
        justifyItems: 'stretch',
        columnGap: '10px',
    },
    column: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
    },
}));

const deviceTypes = [
    { id: 0, name: 'Commercial' },
    { id: 1, name: 'Research Development' },
    { id: 2, name: 'Survey Unit' },
];

const SiteDeviceDetails = (props) => {
    const { site, device } = props;
    const classes = useStyles();
    const getDeviceType = (deviceType) => deviceTypes[parseInt(deviceType)]?.name ?? '';
    return (
        <Box className={classes.container}>
            <Box className={classes.row}>
                <Box className={classes.column}>
                    <span className={classes.label}>Device Type</span>
                    <span className={classes.value}>
                        {device && getDeviceType(device.deviceType)}
                    </span>
                </Box>
                <Box className={classes.column}>
                    <span className={classes.label}>Active Device ID</span>
                    <Box display="flex">
                        <span className={classes.value}>{site.deviceId}</span>
                    </Box>
                </Box>
            </Box>
            <Box className={classes.row}>
                <Box className={classes.column}>
                    <span className={classes.label}>Address</span>
                    <span className={classes.value}>{site.address}</span>
                </Box>
            </Box>
        </Box>
    );
};

export default SiteDeviceDetails;
