const projectStatuses = [
    { id: 0, name: 'Active' },
    { id: 1, name: 'Inactive' },
];

const projectSurveyApproalChoices = [
    { id: false, name: 'False' },
    { id: true, name: 'True' },
];

const validate = (values) => {
    const errors = {};
    let nameCleaned = values.name.trim();
    if (nameCleaned.length < 3) errors.name = 'The project name is required.';
    return errors;
};

export { projectStatuses, projectSurveyApproalChoices, validate };
