import React, { useState } from 'react';
import { Box, Fab } from '@mui/material';
import { makeStyles } from '@mui/styles';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Controls from '../components/Controls';
import { LevelRainfall } from './charts';
import { AlarmAnalyticsContextProvider, LevelRainfallContextProvider } from './dataproviders';
import ScrollTop from './components/ScrollTop';
import { addHours } from 'date-fns';
import AnalyticsSummary from './components/AnalyticsSummary';
import LevelAlarmsList from './components/LevelAlarmsList';

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        gap: '30px',
        marginTop: '24px',
    },
}));

const SiteAnalytics = (props) => {
    const classes = useStyles();
    const { siteId, rainfallSiteId } = props;
    const [fromDate, setFromDate] = useState(addHours(new Date(), -24));
    const [toDate, setToDate] = useState(new Date());

    const updateDates = (from, to) => {
        setFromDate(from);
        setToDate(to);
    };

    return (
        <>
            <span id="analytics-data-anchor-top" />
            <Box className={classes.container}>
                <Controls fromDate={fromDate} toDate={toDate} updateDates={updateDates} />
                <LevelRainfallContextProvider
                    siteId={siteId}
                    rainfallSiteId={rainfallSiteId}
                    fromDate={fromDate}
                    toDate={toDate}
                    useEmptyReadings={true}
                    withSummary={true}
                >
                    <LevelRainfall />

                    <AlarmAnalyticsContextProvider
                        siteId={siteId}
                        fromDate={fromDate}
                        toDate={toDate}
                    >
                        <AnalyticsSummary />
                        <LevelAlarmsList />
                    </AlarmAnalyticsContextProvider>
                </LevelRainfallContextProvider>
            </Box>
            <ScrollTop>
                <Fab color="secondary" size="small" aria-label="scroll back to top">
                    <KeyboardArrowUpIcon />
                </Fab>
            </ScrollTop>
        </>
    );
};

export default SiteAnalytics;
