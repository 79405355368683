import * as React from 'react';
import { ReactComponent as Logo } from '../img/hynds-aquanect-logo-grey.svg';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/styles';
import Theme from '../theme/index';
import { Box, Typography } from '@mui/material';

const MaintenancePage = () => {
    return (
        <ThemeProvider theme={createTheme(Theme)}>
            <Box
                display="flex"
                width="100%"
                alignItems="center"
                flexDirection="column"
                justifyContent="center"
                height="100vh"
                style={{ backgroundColor: '#f5f5f8' }}
            >
                <Logo height="100px" />
                <Box sx={{ marginTop: 4 }}>
                    <Typography variant="h4" component="h1" gutterBottom>
                        The website is under maintenance
                    </Typography>
                    <Typography variant="body1">
                        We apologize for the inconvenience. Please check back later.
                    </Typography>
                </Box>
            </Box>
        </ThemeProvider>
    );
};

export default MaintenancePage;
