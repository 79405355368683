import * as React from 'react';
import {
    Create,
    Form,
    TextInput,
    SaveButton,
    required,
    minLength,
    maxLength,
    useNotify,
    useRefresh,
    useRedirect,
    usePermissions,
} from 'react-admin';
import { Paper, Box, Typography, FormControlLabel, Checkbox } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useAppContext } from '../context/AppContext';
import Layout from '../components/Layout';
import GROUPS from '../auth/groups';
import { validateText } from '../utils/validation';

const useStyles = makeStyles(() => ({
    createForm: {
        marginRight: '20px',
        padding: '15px',
        width: '50%',
    },
}));

const OrganisationCreate = () => {
    const classes = useStyles();
    const [withProject, setWithProject] = React.useState(true);
    const [isSaving, setIsSaving] = React.useState(false);
    // eslint-disable-next-line no-unused-vars
    const [_appStore, setAppStore] = useAppContext();
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();
    const { permissions } = usePermissions();

    if (!!permissions && !permissions.includes(GROUPS.HSW_ADMIN)) {
        redirect('/');
    }

    const onSuccess = async (data) => {
        if (data.id) {
            setAppStore({
                organisationId: data.id,
            });
        }
        notify('Customer added', { type: 'success' });
        redirect('/projects/create');
        refresh();
    };

    const onFailure = async () => {
        notify('Could not create a customer.', { type: 'error' });
        setIsSaving(false);
    };

    /**
     * Save the customer if the form is valid.
     *
     * A bit of hacky way to check the from is valid.
     * Not the most elegent way to do it but it works.
     */
    const onSave = () => {
        const elements = document.getElementById('create-form').getElementsByClassName('Mui-error');
        if (elements.length > 0) {
            setIsSaving(false);
        } else {
            setIsSaving(true);
        }
    };

    const overrides = {
        create: 'Create New Customer',
        organisations: 'Customers',
    };

    const validText =
        (message = 'Invalid text') =>
        (value) => {
            if (value && value.length > 3) {
                if (!validateText(value)) {
                    return message;
                } else {
                    return '';
                }
            }
            return '';
        };

    return (
        <Layout breadcrumb overrides={overrides} title="Create New Customer" bold>
            <Create
                mutationOptions={{
                    onSuccess: onSuccess,
                    onError: onFailure,
                }}
                className={classes.createRoot}
                component="div"
            >
                <Form id="create-form">
                    <Box display="flex" flexDirection="column">
                        <Box display="flex">
                            <Paper className={classes.createForm} elevation={0}>
                                <Typography variant="h6" gutterBottom>
                                    Customer Details
                                </Typography>
                                <TextInput
                                    source="organisation.name"
                                    label="Customer Name"
                                    variant="outlined"
                                    fullWidth
                                    validate={[
                                        required(),
                                        minLength(2),
                                        maxLength(200),
                                        validText(),
                                    ]}
                                />
                                <TextInput
                                    source="organisation.accountNo"
                                    label="Account Number"
                                    variant="outlined"
                                    fullWidth
                                    validate={[
                                        required(),
                                        minLength(2),
                                        maxLength(100),
                                        validText(),
                                    ]}
                                />
                                <TextInput
                                    source="address.line1"
                                    label="Address"
                                    variant="outlined"
                                    fullWidth
                                    validate={[
                                        required(),
                                        minLength(2),
                                        maxLength(200),
                                        validText(),
                                    ]}
                                />
                                <TextInput
                                    source="address.line2"
                                    label="Address Line 2"
                                    variant="outlined"
                                    fullWidth
                                    validate={[validText()]}
                                />
                                <TextInput
                                    source="address.city"
                                    label="City"
                                    variant="outlined"
                                    fullWidth
                                    validate={[
                                        required(),
                                        minLength(2),
                                        maxLength(200),
                                        validText(),
                                    ]}
                                />
                                <TextInput
                                    source="address.country"
                                    label="Country"
                                    variant="outlined"
                                    fullWidth
                                    validate={[
                                        required(),
                                        minLength(2),
                                        maxLength(200),
                                        validText(),
                                    ]}
                                />
                            </Paper>
                        </Box>
                        <Box display="flex" width="100%" flexDirection="column">
                            <Box>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={withProject}
                                            onChange={(event) =>
                                                setWithProject(event.target.checked)
                                            }
                                            name="withProject"
                                            disabled
                                        />
                                    }
                                    label="Create a project"
                                />
                            </Box>
                            <Box>
                                <SaveButton
                                    saving={isSaving}
                                    disabled={isSaving}
                                    // The reason for the timeout is to allow validation to take place
                                    // to avoid an infinate spinning button
                                    onClick={() => setTimeout(() => onSave(), 300)}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Form>
            </Create>
        </Layout>
    );
};

export default OrganisationCreate;
