import React from 'react';
import DevicesDataMH from './mh';
import DevicesDataRaft from './raft';
import DevicesDataGps from './gps';

const DevicesData = ({ deviceId, createdAt, deviceApplication }) => {
    switch (deviceApplication) {
        case 4:
            return <DevicesDataRaft deviceId={deviceId} createdAt={createdAt} />;
        case 7:
            return <DevicesDataGps deviceId={deviceId} createdAt={createdAt} />;
        default:
            return <DevicesDataMH deviceId={deviceId} createdAt={createdAt} />;
    }
};

export default DevicesData;
