import React, { useEffect, useState } from 'react';
import LineChart, { defaultChartOptions, defaultSeries } from './LineChart';
import { useTamperingDataContext } from '../dataproviders';

const chartOptions = {
    title: {
        ...defaultChartOptions.title,
        text: 'Tampering',
    },
    yAxis: [
        {
            ...defaultChartOptions.yAxis[0],
            title: {
                text: '',
            },
            min: 0,
            max: 2,
            gridLineWidth: 0,
            tickAmount: 0,
        },
    ],
};

const Tampering = () => {
    const { data, isLoading: loadingContext, error, from, to } = useTamperingDataContext();
    const [series, setSeries] = useState();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(!!loadingContext);
        if (loadingContext == true) return;
        const tampering = {
            ...defaultSeries,
            name: 'Tampering',
            data: data || [],
            showInLegend: false,
            // connectNulls: false
        };
        setSeries([tampering]);
    }, [data, loadingContext]);

    return (
        <LineChart
            chartOptions={chartOptions}
            series={series}
            loading={isLoading}
            error={error?.message}
            min={from}
            max={to}
        />
    );
};

export default Tampering;
