import { getAccessToken } from '../../auth/auth0Functions';

const getDeviceCommStatus = async (deviceId) => {
    let httpStatus;
    let commStatus;
    const token = await getAccessToken();

    await fetch(`${process.env.REACT_APP_API_BASE_URL}/devices/${deviceId}/commstatus`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: token,
        },
    })
        .then((response) => {
            httpStatus = response.status;
            if (response.status === 200) {
                return response.json();
            }
        })
        .then((data) => {
            commStatus = data;
            return data;
        })
        .catch((error) => {
            console.log(error);
        });

    return { httpStatus, commStatus };
};

const installDevice = async (siteDevice) => {
    const body = siteDevice;
    const token = await getAccessToken();
    await fetch(`${process.env.REACT_APP_API_BASE_URL}/devices`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: token,
        },
        body: JSON.stringify(body),
    })
        .then((response) => {
            return response.json();
        })
        .catch((error) => {
            console.log(error);
        });

    return true;
};

export { installDevice, getDeviceCommStatus };
