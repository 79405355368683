import React from 'react';
import { LinearProgress } from '@mui/material';
import { Datagrid, useListContext } from 'react-admin';
import Layout from './Layout';

export default function DatagridWrapper(props) {
    const { isLoading, selectedIds } = useListContext();
    const { isRowSelectable, ...otherProps } = props;

    // Send selectedIds to isRowsSelectable function
    const isRowSelectableWithIds = (record) =>
        typeof isRowSelectable === 'function' ? isRowSelectable(record, selectedIds) : null;

    if (isLoading) {
        return (
            <Layout>
                <LinearProgress />
            </Layout>
        );
    }

    return (
        <Datagrid isRowSelectable={isRowSelectableWithIds} {...otherProps}>
            {props.children}
        </Datagrid>
    );
}
