import React, { useCallback, useEffect, useState } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';
import Exporting from 'highcharts/modules/exporting';
import ExportingData from 'highcharts/modules/export-data';
import { getFilename } from './charts';
import sorter from '../../utils/sorter';
import { formatLocalTimezone, formatXaxisLabel, getDateString } from '../utils';
import { levelAlarmOperator } from '../../alarms/alarm';

Exporting(Highcharts);
ExportingData(Highcharts);

const arrowUp = '&#x25B2;';
const arrowDown = '&#x25BC;';

const WaterLevel = (props) => {
    const { data, title, allowExport, dateRange, maxDepth, rules } = props;
    const chartComponent = React.useRef(null);
    const [series, setSeries] = useState([]);

    const handleDownload = () => {
        const chart = chartComponent.current.chart;
        chart.downloadCSV();
    };
    const [options, setOptions] = useState({
        title: {
            text: 'Water Level History',
            align: 'left',
            style: {
                fontWeight: 600,
            },
        },
        subtitle: {
            verticalAlign: 'bottom',
            useHTML: true,
            y: 20,
            text:
                '<b>From:</b> ' +
                formatLocalTimezone(new Date(dateRange.startDate), true) +
                '   ---   ' +
                ' <b>To: </b>' +
                formatLocalTimezone(new Date(dateRange.endDate), true),
        },
        chart: {
            type: 'line',
            zoomType: 'x',
            panKey: 'shift',
            panning: true,
            events: {
                //update Subtitle
                render: function () {
                    updateSubtitle();
                },
            },
            style: {
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                fontWeight: 500,
            },
        },
        accessibility: {
            enabled: false,
        },
        legend: {
            enabled: true,
            layout: 'horizontal',
            verticalAlign: 'top',
            x: 100,
            y: -50,
        },
        rangeSelector: {
            inputEnabled: false,
            buttons: [
                {
                    type: 'all',
                    text: 'All',
                    title: 'View all',
                },
            ],
            selected: 0,
        },
        xAxis: {
            title: 'DateTime',
            type: 'datetime',
            minPadding: 0,
            maxPadding: 0,
            min: dateRange.startDate.getTime(),
            max: dateRange.endDate.getTime(),
            ordinal: false,
            gridLineWidth: 1,
            labels: {
                formatter: function () {
                    return formatXaxisLabel(new Date(this.value));
                },
            },
        },
        yAxis: [
            {
                title: {
                    text: 'Level mm',
                },
                opposite: false,
                min: 0,
                lineWidth: 1,
                labels: {
                    format: '{value:.0f}',
                },
                tickAmount: 10,
            },
        ],
        labels: {
            items: [
                {
                    style: {
                        left: '100px',
                        top: '100px',
                    },
                },
            ],
        },
        tooltip: {
            shared: true,
            formatter: function () {
                return this.points.reduce((s, point) => {
                    return s + '<br/>' + point.series.name + ': ' + point.y + 'mm';
                }, '<b>' + getDateString(new Date(this.x)) + '</b>');
            },
        },
        navigator: {
            height: 22,
            buttonOptions: {
                enabled: true,
            },
            stickToMax: true,
            xAxis: {
                type: 'datetime',
                ordinal: false,
                gridLineWidth: 1,
            },
        },
        plotOptions: {
            line: {
                turboThreshold: 50000,
            },
        },
        series: series,
        exporting: {
            chartOptions: {
                plotOptions: {
                    series: {
                        dataLabels: {
                            enabled: true,
                        },
                    },
                },
            },
            csv: {
                itemDelimiter: ',',
                dateFormat: '%d/%m/%Y %H:%M:%S',
            },
            filename: getFilename(title),
            fallbackToExportServer: false,
            menuItemDefinitions: {
                viewFullscreen: {
                    text: 'Fullscreen',
                },
            },
            buttons: {
                contextButton: {
                    menuItems: [
                        'viewFullscreen',
                        {
                            text: 'Export CSV',
                            onclick: handleDownload,
                        },
                    ],
                },
            },
            enabled: allowExport ?? false,
        },
        navigation: {
            buttonOptions: {
                x: 10,
                y: -10,
            },
        },
        credits: {
            enabled: false,
        },
        time: {
            useUTC: false,
        },
    });

    const updateSubtitle = useCallback(() => {
        if (!chartComponent.current || !chartComponent.current.chart) return;
        const chart = chartComponent.current.chart;

        // Show min values from chart or default to min and max date picker
        const minExtreme = chart.xAxis[0].getExtremes().userMin || dateRange.startDate;
        const maxExtreme = chart.xAxis[0].getExtremes().userMax || dateRange.endDate;
        let text = '';
        if (minExtreme > 0 && maxExtreme > 0) {
            text =
                '<b>From:</b> ' +
                formatLocalTimezone(new Date(minExtreme), true) +
                '   ---   ' +
                ' <b>To: </b>' +
                formatLocalTimezone(new Date(maxExtreme), true);
        }
        chart.setSubtitle({
            text,
        });
    }, [dateRange.startDate, dateRange.endDate]);

    const updateExtremes = useCallback(() => {
        if (!chartComponent.current || !chartComponent.current.chart) return;

        setOptions((o) => {
            return {
                ...o,
                xAxis: {
                    ...o.xAxis,
                    min: dateRange.startDate.getTime(),
                    max: dateRange.endDate.getTime(),
                },
                navigator: {
                    ...o.navigator,
                    xAxis: {
                        ...o.navigator.xAxis,
                        min: dateRange.startDate.getTime(),
                        max: dateRange.endDate.getTime(),
                    },
                    min: dateRange.startDate.getTime(),
                    max: dateRange.endDate.getTime(),
                },
            };
        });
    }, [dateRange.endDate, dateRange.startDate]);

    useEffect(() => {
        let levels = [];

        let dataSeries = [
            {
                type: 'line',
                name: 'Water Level',
                data: data.sort(sorter('x')) ?? [],
                color: '#00AEEF',
                dataGrouping: {
                    enabled: false,
                },
            },
        ];

        if (!!rules && rules.length > 0) {
            for (const rule of rules) {
                if (rule.conditions) {
                    for (const condition of rule.conditions) {
                        if (condition.fact === 'level' && maxDepth) {
                            const value = condition.isPercent
                                ? Math.floor((condition.value / 100) * maxDepth)
                                : condition.value;
                            const type =
                                condition.operator === levelAlarmOperator.lt ? arrowDown : arrowUp;
                            levels.push({ value, type });
                        }
                    }
                }
            }

            levels = levels.sort((a, b) => a.value - b.value);

            for (var level of levels) {
                dataSeries.push({
                    type: 'line',
                    data: [
                        { x: dateRange.startDate, y: level.value },
                        { x: dateRange.endDate, y: level.value },
                    ],
                    name: `${((level.value / maxDepth) * 100).toFixed(0)}% level threshold ${
                        level.type
                    }`,
                    visible: false,
                    color: 'grey',
                    dashStyle: 'Dash',
                    strokeWidth: 7,
                    includeInDataExport: false,
                    dataGrouping: {
                        enabled: false,
                    },
                });
            }
        }

        setSeries(dataSeries);
    }, [data, dateRange, rules, maxDepth]);

    useEffect(() => {
        if (series.length > 0) {
            setOptions((prevOptions) => {
                return { ...prevOptions, series: series };
            });
        }

        updateSubtitle();
        updateExtremes();
    }, [series, updateExtremes, updateSubtitle]);

    return (
        <>
            <HighchartsReact
                ref={chartComponent}
                highcharts={Highcharts}
                constructorType={'stockChart'}
                options={options}
                containerProps={{ style: { height: '100%', width: '100%' } }}
            />
        </>
    );
};

export default WaterLevel;
