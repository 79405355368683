import React from 'react';
import {
    Show,
    SimpleShowLayout,
    TextField,
    ListButton,
    TopToolbar,
    EditButton,
    useRecordContext,
} from 'react-admin';
import Breadcrumb from '../menu/Breadcrumb';

const ShowActions = ({ data }) => (
    <TopToolbar>
        <ListButton />
        <EditButton record={data} />
    </TopToolbar>
);

const Title = () => {
    const record = useRecordContext();
    return <span>{record.name}</span>;
};

const SurveyShow = () => {
    return (
        <>
            <Breadcrumb />
            <Show action={<ShowActions />} title={<Title />}>
                <SimpleShowLayout>
                    <TextField source="id" />
                    <TextField source="name" />
                </SimpleShowLayout>
            </Show>
        </>
    );
};

export default SurveyShow;
