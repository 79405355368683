import React from 'react';
import clsx from 'clsx';
import { makeStyles, createStyles } from '@mui/styles';
import CircularProgress from '@mui/material/CircularProgress';
import { green, red } from '@mui/material/colors';
import Button from '@mui/material/Button';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import DoneIcon from '@mui/icons-material/Done';

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            display: 'flex',
            alignItems: 'center',
        },
        wrapper: {
            margin: theme.spacing(1),
            position: 'relative',
        },
        buttonSuccess: {
            backgroundColor: green[500],
            '&:hover': {
                backgroundColor: green[700],
            },
        },
        buttonFailure: {
            backgroundColor: red[500],
            '&:hover': {
                backgroundColor: red[700],
            },
        },
        buttonProgress: {
            color: green[500],
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -12,
        },
    }),
);

const AsyncButton = ({ children, color, onClick, timeout, ...rest }) => {
    const classes = useStyles();
    const [loading, setLoading] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const [error, setError] = React.useState(false);
    const timer = React.useRef();

    const buttonClassname = clsx({
        [classes.buttonSuccess]: success,
        [classes.buttonFailure]: error,
    });
    React.useEffect(() => {
        return () => {
            clearTimeout(timer.current);
        };
    }, []);
    const handleButtonClick = async (event) => {
        if (!loading) {
            setSuccess(false);
            setLoading(true);
            try {
                timer.current = window.setTimeout(() => {
                    setSuccess(true);
                    setLoading(false);
                }, timeout ?? 2000);
                await onClick(event);
                setSuccess(true);
            } catch (exception) {
                setSuccess(false);
                setLoading(false);
                setError(true);
            } finally {
                setLoading(false);
            }
        }
    };
    return (
        <div className={classes.root}>
            <div className={classes.wrapper}>
                <Button
                    variant="contained"
                    color={color || 'primary'}
                    className={buttonClassname}
                    disabled={loading}
                    startIcon={success && !error ? <DoneIcon /> : error && <PriorityHighIcon />}
                    onClick={handleButtonClick}
                    {...rest}
                >
                    {children}
                </Button>
                {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
            </div>
        </div>
    );
};

export default AsyncButton;
