import React from 'react';
import { ListBase, ResourceContextProvider, TextField, DateField, useLocale } from 'react-admin';
import DatagridWrapper from '../../components/DatagridWrapper';

const DeviceHistory = (props) => {
    const locale = useLocale();
    return (
        <ResourceContextProvider value="devices">
            <ListBase
                disableSyncWithLocation
                resource="devices"
                filter={{ siteId: props.siteId }}
                sort={{ field: 'fromDate', order: 'DESC' }}
            >
                <DatagridWrapper
                    empty={<span>No devices have been installed at this site</span>}
                    isRowSelectable={() => false}
                >
                    <TextField source="deviceId" label="Device ID" sortable={false} />
                    <DateField
                        source="fromDate"
                        label="From"
                        options={{
                            day: 'numeric',
                            month: 'short',
                            year: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric',
                            second: 'numeric',
                            hour12: false,
                        }}
                        locales={locale}
                        showTime={true}
                        sortable={false}
                    />
                    <DateField
                        source="toDate"
                        label="To"
                        options={{
                            day: 'numeric',
                            month: 'short',
                            year: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric',
                            second: 'numeric',
                            hour12: false,
                        }}
                        locales={locale}
                        showTime={true}
                        sortable={false}
                    />
                </DatagridWrapper>
            </ListBase>
        </ResourceContextProvider>
    );
};

export default DeviceHistory;
