import React from 'react';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Layout from './Layout';
import ErrorIcon from '@mui/icons-material/Error';
import classNames from 'classnames';

const useStyles = makeStyles({
    icon: { width: '1em', height: '1em', marginRight: '0.5em' },
    container: {
        display: 'flex',
        alignItems: 'center',
    },
});

const Error = ({ message, icon, ...props }) => {
    const classes = useStyles();
    return (
        <Layout classes={classes.container} {...props}>
            {icon && (
                <ErrorIcon
                    titleAccess="error-icon"
                    className={classNames(classes.icon, props.iconClass)}
                />
            )}
            <Typography variant="body2">{message ?? 'An error occured.'}</Typography>
        </Layout>
    );
};

export default Error;
