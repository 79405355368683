import React from 'react';
import ROOT from '../theme/root';
import { ReactComponent as RightIcon } from '../icons/arrow-right.svg';
import { ReactComponent as LeftIcon } from '../icons/arrow-left.svg';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    container: {
        width: '28px',
        height: '28px',
        backgroundColor: 'white',
        border: ROOT.BORDERS.BORDER,
        borderRadius: '14px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        zIndex: '9999',
        '&:hover': {
            cursor: 'pointer',
        },
        boxSizing: 'content-box',
    },
}));

const ToggleButton = (props) => {
    const classes = useStyles();

    return (
        <div
            onClick={props.onClick}
            className={classes.container}
            style={{ right: props.open ? '-14px' : '-26px' }}
        >
            {props.open ? <LeftIcon width="22px" /> : <RightIcon width="22px" />}
        </div>
    );
};

export default ToggleButton;
