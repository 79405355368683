const getDeviceType = (device) => {
    const deviceTypes = {
        0: 'Commercial',
        1: 'Research Development',
        2: 'Survey Unit',
    };
    return deviceTypes[device.deviceType];
};

const getDeviceApplication = (device) => {
    const deviceApplications = {
        0: 'WWLS',
        1: 'WWSS',
        2: 'SWLS',
        3: 'SWSS',
        4: 'Raft',
        5: 'Tank',
        6: 'Raingarden',
        7: 'GPS',
    };
    return deviceApplications[device.deviceApplication];
};

const deviceStatus = {
    0: 'Deleted',
    1: 'Installed',
    2: 'Unlinked',
};

const deviceApplicationTypes = [
    { id: 0, name: 'WWLS' },
    { id: 1, name: 'WWSS' },
    { id: 2, name: 'SWLS' },
    { id: 3, name: 'SWSS' },
    { id: 4, name: 'Raft' },
    { id: 5, name: 'Tank' },
    { id: 6, name: 'Raingarden' },
    { id: 7, name: 'GPS' },
];
const deviceTypes = [
    { id: 0, name: 'Commercial' },
    { id: 1, name: 'Research Development' },
    { id: 2, name: 'Survey Unit' },
];

const deviceStatuses = [
    { id: 0, name: 'Deleted' },
    { id: 1, name: 'Installed' },
    { id: 2, name: 'Unlinked' },
];

export {
    getDeviceType,
    getDeviceApplication,
    deviceStatus,
    deviceApplicationTypes,
    deviceTypes,
    deviceStatuses,
};
