import React, { createContext, useContext, useState, useEffect } from 'react';
import useAnalytics from '../../hooks/useAnalytics';
import useFetchRainfallData from '../hooks/useFetchRainfallData';

const LevelRainfallContext = createContext();

const LevelRainfallContextProvider = ({
    children,
    siteId,
    rainfallSiteId,
    fromDate,
    toDate,
    useEmptyReadings,
    withSummary,
}) => {
    const fromTimestamp = fromDate.getTime();
    const toTimestamp = toDate.getTime();
    const [level, setLevel] = useState({
        loading: null,
        data: null,
        error: null,
    });
    const [rainfallData, setRainfallData] = useState({
        loading: true,
        data: {
            rainfallData: null,
            siteId: rainfallSiteId,
        },
        error: null,
    });

    const { data, loading, error } = useFetchRainfallData(
        fromTimestamp,
        toTimestamp,
        rainfallSiteId,
    );
    const {
        readings,
        summary,
        loading: loadingReadings,
        error: errorReadings,
    } = useAnalytics(siteId, fromDate, toDate, useEmptyReadings, true, withSummary);

    useEffect(() => {
        let levelReadings = [];
        let rainfallReadings = [];
        let rainfallSiteName = '';
        if (readings) levelReadings = readings.map(({ x, y }) => [x, y]);
        if (data) {
            rainfallReadings = data.data.reduce((prev, curr) => {
                const { timestamp, value } = curr;
                const floatValue = parseFloat(value);
                if (Number.isFinite(floatValue)) prev.push([timestamp, floatValue]);
                return prev;
            }, []);
            rainfallSiteName = data.name;
        }
        setLevel({
            loading: loadingReadings,
            data: {
                level: levelReadings,
                summary,
            },
            error: errorReadings,
        });

        setRainfallData({
            loading,
            data: {
                rainfallData: rainfallReadings,
                siteId: rainfallSiteId,
                name: rainfallSiteName,
            },
            error,
        });
    }, [data, readings, summary, loadingReadings, loading, error, errorReadings, rainfallSiteId]);

    return (
        <LevelRainfallContext.Provider value={{ level, rainfallData, fromDate, toDate }}>
            {children}
        </LevelRainfallContext.Provider>
    );
};

const useLevelRainfallContext = () => {
    const context = useContext(LevelRainfallContext);
    if (!context) {
        throw new Error(
            'useLevelRainfallContext must be used within a LevelRainfallContextProvider',
        );
    }
    return context;
};

export { useLevelRainfallContext, LevelRainfallContextProvider };
