import React from 'react';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { getDateString } from '../utils';

const useStyles = makeStyles(() => ({
    label: {
        color: '#636364',
        fontSize: '14px',
        letterSpacing: '-0.34px',
        lineHeight: '19px',
    },
}));

const LastUpdate = (props) => {
    const { latestReading } = props;
    const classes = useStyles();

    let latestReadingDate;

    if (latestReading) {
        latestReadingDate = new Date(latestReading);
    }

    return (
        <>
            <Box height="40px" display="flex">
                <Box flexGrow={1} display="flex" flexDirection="row-reverse">
                    <span className={classes.label}>
                        Last updated: {getDateString(latestReadingDate, 'N/A')}
                    </span>
                </Box>
            </Box>
        </>
    );
};

export default LastUpdate;
