import React from 'react';
import { Box, Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import MeasurementSurvey from './MeasurementSurvey';
import { makeStyles } from '@mui/styles';
import { useRedirect } from 'react-admin';

const useStyles = makeStyles(() => ({
    dialog: {
        minHeight: '680px',
        maxHeight: '680px',
        minWidth: '940px',
        maxWidth: '940px',
    },
}));

const SiteSurveyDialog = ({ site, isOpen, onClose }) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(isOpen ?? false);
    const handleClose = () => {
        setOpen(false);
        if (onClose) onClose();
    };

    React.useEffect(() => {
        setOpen(isOpen);
    }, [isOpen]);

    return (
        <Dialog
            open={open}
            onClose={() => handleClose()}
            onBackdropClick={() => handleClose()}
            aria-labelledby="site-survey"
            maxWidth="sm"
            fullWidth
            classes={{ paper: classes.dialog }}
            scroll="paper"
        >
            <DialogContent>
                <MeasurementSurvey site={site} onClose={() => handleClose()} />
            </DialogContent>
        </Dialog>
    );
};

const SiteSurvey = ({ site }) => {
    const [openSiteSurvey, setOpenSiteSurvey] = React.useState(false);
    const redirect = useRedirect();

    const handleButtonClick = () => {
        if (site.siteTypeId === 3) {
            redirect('create', 'surveys', null, null, {
                record: {
                    siteId: site.id,
                    surveyTypeId: 6,
                    address: site.address,
                    latitude: site.latitude,
                    longitude: site.longitude,
                },
                approveSurvey: true,
            });
        } else {
            setOpenSiteSurvey(true);
        }
    };

    return (
        <Box>
            <SiteSurveyDialog
                site={site}
                isOpen={openSiteSurvey}
                onClose={() => {
                    setOpenSiteSurvey(false);
                }}
            />
            <Button
                onClick={() => handleButtonClick()}
                color="primary"
                style={{
                    fontSize: '13px',
                    maxWidth: '300px',
                    backgroundColor: '#00AEEF',
                    color: '#fff',
                }}
            >
                Site Survey
            </Button>
        </Box>
    );
};

export default SiteSurvey;
