import * as React from 'react';
import {
    Create,
    SimpleForm,
    TextInput,
    SelectInput,
    ReferenceInput,
    BooleanInput,
    minLength,
    maxLength,
    email,
    useNotify,
    useRefresh,
    useRedirect,
    number,
    Toolbar,
    SaveButton,
    usePermissions,
} from 'react-admin';
import { Grid } from '@mui/material';
import Layout from '../components/Layout';
import { useAppContext } from '../context/AppContext';
import GROUPS from '../auth/groups';
import { validateText } from '../utils/validation';

const required =
    (message = 'Required') =>
    (value) =>
        value ? undefined : message;

const CreateUser = () => {
    const [appStore] = useAppContext();
    const [isSaving, setIsSaving] = React.useState(false);
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();
    const { permissions } = usePermissions();

    if (
        !!permissions &&
        !permissions.includes(GROUPS.HSW_ADMIN) &&
        !permissions.includes(GROUPS.CUSTOMER_ADMIN)
    ) {
        redirect('/');
    }

    /**
     * Save the user if the form is valid.
     *
     * A bit of hacky way to check the from is valid.
     * Not the most elegent way to do it but it works.
     */
    const onSave = () => {
        const elements = document.getElementById('create-form').getElementsByClassName('Mui-error');
        if (elements.length > 0) {
            setIsSaving(false);
        } else {
            setIsSaving(true);
        }
    };

    const PostToolbar = () => (
        <Toolbar>
            <SaveButton
                label="Create"
                saving={isSaving}
                disabled={isSaving}
                // The reason for the timeout is to allow validation to take place
                // to avoid an infinate spinning button
                onClick={() => setTimeout(() => onSave(), 300)}
            />
        </Toolbar>
    );

    const handleOnSuccess = async (data) => {
        if (!data) return;
        notify('User added', { type: 'success' });
        redirect(`/users/${data.id}/show`);
        refresh();
    };

    const handleOnFailure = async (error) => {
        setIsSaving(false);
        notify(
            error && error.message && error.message.toLowerCase().includes('validation error')
                ? 'User could not be added. Email already in use.'
                : 'An error has occurred while creating the user. Please try again.',
            { type: 'error' },
        );
    };

    const validName =
        (message = 'Invalid text') =>
        (value) => {
            if (value && value.length > 3) {
                if (!validateText(value)) {
                    return message;
                } else {
                    return '';
                }
            }
            return '';
        };

    return (
        <Layout breadcrumb title="Create New User" bold>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <Create
                        mutationOptions={{
                            onSuccess: handleOnSuccess,
                            onError: handleOnFailure,
                        }}
                        redirect={redirect}
                    >
                        <SimpleForm toolbar={<PostToolbar />} id="create-form">
                            <ReferenceInput
                                source="organisationId"
                                reference="organisations"
                                defaultValue={
                                    appStore.organisationId !== '-' ? appStore.organisationId : null
                                }
                                filter={
                                    appStore.organisationId !== '-'
                                        ? { id: appStore.organisationId, status: 0 }
                                        : { status: 0 }
                                }
                                perPage={1000}
                                sort={{ field: 'name', order: 'ASC' }}
                                isRequired
                            >
                                <SelectInput
                                    optionText="name"
                                    value="id"
                                    label="Customer"
                                    fullWidth
                                    validate={[required('Required')]}
                                    variant="outlined"
                                />
                            </ReferenceInput>
                            <TextInput
                                source="name"
                                label="Full name"
                                variant="outlined"
                                fullWidth
                                validate={[required(), minLength(2), maxLength(200), validName()]}
                            />
                            <TextInput
                                source="emailAddress"
                                label="Email"
                                variant="outlined"
                                fullWidth
                                validate={[required(), email(), minLength(2), maxLength(200)]}
                            />
                            <TextInput
                                source="phone"
                                label="Mobile"
                                variant="outlined"
                                fullWidth
                                validate={[
                                    minLength(6),
                                    maxLength(12),
                                    number('Only numbers allowed'),
                                ]}
                            />
                            <TextInput
                                source="accountNo"
                                variant="outlined"
                                label="Account reference"
                                fullWidth
                                validate={[minLength(2), maxLength(200), validName()]}
                            />
                            <BooleanInput source="appUser" label="Is App User" />
                        </SimpleForm>
                    </Create>
                </Grid>
            </Grid>
        </Layout>
    );
};

export default CreateUser;
