import React, { useState } from 'react';
import { useRedirect, useNotify } from 'react-admin';
import { createTheme } from '@mui/material/styles';
import { Box, Card, Button, ThemeProvider, Link, Snackbar, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Theme from '../theme/index';
import { ReactComponent as Logo } from '../img/hynds-aquanect-logo-grey.svg';
import ErrorIcon from '@mui/icons-material/Error';
import { forgotPassword } from './auth0Functions';

const useStyles = makeStyles((theme) => ({
    formControl: {
        marginTop: theme.spacing(3),
        minWidth: 120,
        width: '100%',
        textAlign: 'left',
    },
    label: {
        color: '#636364',
        fontSize: '14px',
        lineHeight: '20px',
        marginBottom: '4px',
    },
    submit: {
        color: '#fff',
        backgroundColor: '#00AEEF',
        height: '45px',
        width: '100%',
        marginTop: '32px',
        '&:hover': {
            color: 'white',
            border: '1px solid #00AEEF',
        },
    },
    snackbar: {
        backgroundColor: '#EA2F2F',
        color: 'white',
        borderRadius: '8px',
        padding: '4px 4px',
    },
}));

function getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&'); //eslint-disable-line
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'), //eslint-disable-line
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

function parseJwt(token) {
    var base64Url = token.split('.')[0];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(
        atob(base64) //eslint-disable-line
            .split('')
            .map((c) => {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join(''),
    );

    return JSON.parse(jsonPayload);
}

const PasswordExpiry = () => {
    const classes = useStyles();
    const redirect = useRedirect();
    const notify = useNotify();

    const token = getParameterByName('data');
    const username = parseJwt(token).userName;
    const password_valid_days = process.env.REACT_APP_PASSWORD_EXPIRY_DAYS;

    const [hasError, setHasError] = useState(false);
    const [error] = useState('');
    const [submitting, setSubmitting] = useState(false);

    const handleCloseAlert = () => {
        setHasError(false);
    };

    const submit = async (e) => {
        e.preventDefault();

        setSubmitting(true);
        try {
            await forgotPassword(username)
                .then(() => {
                    setSubmitting(false);
                    notify(
                        'Reset password link has been sent. Please use the link to set your new password.',
                        { type: 'success' },
                    );
                    return;
                })
                .catch(() => {
                    notify('Something went wrong, please try again', { type: 'error' });
                    setSubmitting(false);
                });
        } catch (error) {
            setSubmitting(false);
            notify('Something went wrong, please try again', { type: 'error' });
            return;
        }
    };

    return (
        <ThemeProvider theme={createTheme(Theme)}>
            <Box
                display="flex"
                width="100%"
                alignItems="center"
                flexDirection="column"
                justifyContent="center"
                height="100vh"
                style={{ backgroundColor: '#f5f5f8' }}
            >
                <Logo height="100px" />
                <Card
                    style={{
                        padding: '24px',
                        width: '360px',
                        textAlign: 'center',
                    }}
                >
                    <div
                        style={{
                            marginBottom: '24px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <ErrorIcon style={{ width: '1.5em', height: '1.5em' }} />
                        <div
                            style={{
                                marginLeft: '8px',
                                fontSize: '1.25em',
                                fontWeight: '550',
                            }}
                        >
                            Password Expired
                        </div>
                    </div>
                    <span className={classes.label}>
                        {`You haven't changed your password for ${password_valid_days} days. Please request a reset
                        password link to reset your password.`}
                    </span>
                    <Button
                        type="submit"
                        onClick={submit}
                        color="primary"
                        disabled={submitting}
                        className={classes.submit}
                    >
                        Send me a reset password link
                        {submitting && (
                            <CircularProgress
                                style={{ marginLeft: '8px' }}
                                size={18}
                                color="inherit"
                            />
                        )}
                    </Button>
                    <div style={{ marginTop: '16px' }}>
                        <Link style={{ cursor: 'pointer' }} onClick={() => redirect('/login')}>
                            Go Back
                        </Link>
                    </div>
                </Card>
            </Box>
            <Snackbar
                open={hasError}
                onClose={handleCloseAlert}
                autoHideDuration={5000}
                className={classes.snackbar}
            >
                <div className={classes.snackbar}>{error}</div>
            </Snackbar>
        </ThemeProvider>
    );
};

export default PasswordExpiry;
