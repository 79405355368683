import React from 'react';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ReactComponent as Tampering } from '../../../icons/dash-1.svg';
import { ReactComponent as General } from '../../../icons/dash-2.svg';
import { ReactComponent as Inactive } from '../../../icons/dash-3.svg';
import { ReactComponent as Average } from '../../../icons/average.svg';
import { ReactComponent as Peak } from '../../../icons/peak.svg';
import { useLevelRainfallContext, useAlarmAnalyticsContext } from '../dataproviders';
import Card from './Card';
import { useNotify } from 'react-admin';
import ROOT from '../../../theme/root';

const useStyles = makeStyles(() => ({
    middleLine: {
        borderRight: ROOT.BORDERS.BORDER,
        position: 'relative',
        height: '80%',
        top: '10%',
    },
    container: {
        backgroundColor: '#FFFFFF',
        border: ROOT.BORDERS.BORDER,
        borderRadius: '4px',
        padding: '16px',
    },
}));

const AnalyticsSummary = () => {
    const classes = useStyles();
    const notify = useNotify();
    const { level } = useLevelRainfallContext();
    const {
        general,
        inactive,
        tamper,
        isLoading: loadingAlarm,
        error: errorAlarms,
    } = useAlarmAnalyticsContext();

    // Level data
    let average = 'N/A';
    let peak = 'N/A';
    const loadingLevel = level?.loading || false;
    if (level?.data?.summary && !level?.loading) {
        average =
            level.data.summary.average !== null ? Math.floor(level.data.summary.average) : 'N/A';
        peak = level.data.summary.peak !== null ? level.data.summary.peak : 'N/A';
    }

    // Error message
    let errorMessage = null;
    if (level?.error || errorAlarms) {
        errorMessage = `Error loading ${level?.error ? 'Level Summary' : ''} ${
            errorAlarms ? 'Alarms Summary' : ''
        }`;
        notify(errorMessage, { type: 'error' });
    }

    return (
        <Box className={classes.container}>
            <Typography component="h6" variant="h6" mb="20px" mt="10px">
                Level and alarms summary
            </Typography>
            <Box
                display="flex"
                flexDirection={'row'}
                mt={'20px'}
                gap="150px"
                justifyContent={'space-between'}
            >
                <Box
                    display="flex"
                    justifyContent="center"
                    mb="52px"
                    className={classes.cardContainer}
                    width={'40%'}
                    style={{ border: ROOT.BORDERS.BORDER, borderRadius: 4 }}
                >
                    <Card
                        title="Average level (mm)"
                        color="#00000"
                        Icon={Average}
                        value={average}
                        addBorder={false}
                        isLoading={loadingLevel}
                    />
                    <Card
                        title="Peak level (mm)"
                        color="#00000"
                        Icon={Peak}
                        value={peak}
                        addBorder={true}
                        isLoading={loadingLevel}
                    />
                </Box>
                <Box
                    display="flex"
                    justifyContent="center"
                    mb="52px"
                    className={classes.cardContainer}
                    width={'60%'}
                    style={{ border: ROOT.BORDERS.BORDER, borderRadius: 4 }}
                >
                    <Card
                        title="Tamper alarms"
                        color={ROOT.COLORS.RED}
                        Icon={Tampering}
                        value={errorAlarms ? 'N/A' : tamper.length}
                        addBorder={false}
                        isLoading={loadingAlarm}
                    />
                    <Card
                        title="General alarms"
                        color={ROOT.STATUSCOLOR.WARNING}
                        Icon={General}
                        value={errorAlarms ? 'N/A' : general.length}
                        addBorder={true}
                        isLoading={loadingAlarm}
                    />
                    <Card
                        title="Inactive Alarms"
                        color={ROOT.STATUSCOLOR.INFO}
                        Icon={Inactive}
                        value={errorAlarms ? 'N/A' : inactive.length}
                        addBorder={true}
                        isLoading={loadingAlarm}
                    />
                </Box>
            </Box>
        </Box>
    );
};

export default AnalyticsSummary;
