import React from 'react';
import {
    DateField,
    TextField,
    FunctionField,
    ListContext,
    SelectField,
    useLocale,
} from 'react-admin';
import { statuses } from '../../alarms';
import { transformDescription } from '../../utils/alarmDescription';
import AlarmTypeField from '../../components/AlarmTypeField';
import DatagridWrapper from '../../components/DatagridWrapper';

const SiteAlarms = (props) => {
    let { alarms } = props;
    const locale = useLocale();
    const [page, setPage] = React.useState(1);
    const perPage = 10;
    return (
        <ListContext.Provider
            value={{
                data: alarms,
                sort: { field: 'name', order: 'ASC' },
                page: page,
                perPage: perPage,
                setPage,
                total: alarms.length,
                resource: 'alarms',
                defaultTitle: 'Alarms',
            }}
        >
            <DatagridWrapper
                empty={<span>No site alarms.</span>}
                rowClick={() => '/alarms?filter={"alarmStatus":1}'}
                rowStyle={() => ({
                    height: 50,
                    boxSizing: 'content-box',
                })}
            >
                <TextField source="deviceId" label="Device ID" />
                <FunctionField
                    source="description"
                    label="Details"
                    render={(record) => transformDescription(record.description)}
                />
                <AlarmTypeField source="alarmType" />
                <SelectField source="alarmStatus" choices={statuses} />
                <DateField
                    source="createdAt"
                    label="Date Created"
                    options={{
                        day: 'numeric',
                        month: 'short',
                        year: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                        second: 'numeric',
                        hour12: false,
                    }}
                    locales={locale}
                    showTime
                />
            </DatagridWrapper>
        </ListContext.Provider>
    );
};

export default SiteAlarms;
