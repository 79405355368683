import React from 'react';
import { Button, useRedirect } from 'react-admin';

const CompareButton = (props) => {
    const redirect = useRedirect();
    const { sites } = props;
    const handleClick = () => {
        redirect('/sites/compare', null, null, null, { sites });
    };
    const noValid = !sites || sites.length <= 1;

    return (
        <Button
            label="Compare sites"
            onClick={handleClick}
            disabled={noValid}
            variant="contained"
        />
    );
};

export default CompareButton;
