import React from 'react';
import {
    Edit,
    SimpleForm,
    TextInput,
    ReferenceInput,
    SelectInput,
    required,
    useEditController,
    SaveButton,
    Toolbar,
    usePermissions,
    useRedirect,
    minLength,
    maxLength,
} from 'react-admin';
import { Box, Grid } from '@mui/material';
import Layout from '../components/Layout';
import Loading from '../components/Loading';
import DeleteButton from './Delete';
import { projectStatuses, projectSurveyApproalChoices, validate } from './project';
import GROUPS from '../auth/groups';
import { validText } from './utils';

const EditToolbar = () => {
    return (
        <Toolbar>
            <Box display="flex" justifyContent="space-between" width="100%">
                <SaveButton />
                <DeleteButton />
            </Box>
        </Toolbar>
    );
};

const EditProject = () => {
    const controller = useEditController();
    const { permissions } = usePermissions();
    const redirect = useRedirect();
    const isHyndsAdmin = !!permissions && permissions.includes(GROUPS.HSW_ADMIN);

    if (!controller.record) return <Loading />;
    const overrides = {};
    overrides[controller.record.id] = controller.record.name;

    if (!isHyndsAdmin) {
        redirect('/');
    }

    return (
        <Layout breadcrumb overrides={overrides} title={controller.record.name} bold>
            <Grid container spacing={2}>
                <Grid item xs={12} xm={12} md={6}>
                    <Edit actions={<></>} redirect="show">
                        <SimpleForm toolbar={<EditToolbar />} validate={validate}>
                            <TextInput
                                source="name"
                                label="Project name"
                                variant="outlined"
                                validate={[required(), minLength(2), maxLength(200), validText()]}
                                fullWidth
                            />
                            <ReferenceInput
                                source="organisationId"
                                reference="organisations"
                                perPage={1000}
                            >
                                <SelectInput
                                    optionText="name"
                                    value="id"
                                    label="Customer"
                                    disabled
                                    fullWidth
                                    variant="outlined"
                                />
                            </ReferenceInput>
                            <SelectInput
                                source="projectStatus"
                                variant="outlined"
                                choices={projectStatuses}
                                defaultValue={controller.record.projectStatus}
                                validate={required()}
                                fullWidth
                            />
                            <SelectInput
                                source="automaticSurveyApproval"
                                variant="outlined"
                                choices={projectSurveyApproalChoices}
                                defaultValue={controller.record.automaticSurveyApproval}
                                validate={required()}
                                fullWidth
                            />
                            <ReferenceInput
                                reference="rainfallSites"
                                source="rainfallSiteId"
                                perPage={1000}
                                sort={{ field: 'name', order: 'ASC' }}
                                queryOptions={{
                                    enabled: isHyndsAdmin,
                                }}
                            >
                                <SelectInput
                                    label="Rainfall Site"
                                    value="id"
                                    optionText="name"
                                    fullWidth
                                    variant="outlined"
                                />
                            </ReferenceInput>
                        </SimpleForm>
                    </Edit>
                </Grid>
            </Grid>
        </Layout>
    );
};

export default EditProject;
